import { Flex, Box, Text } from "@radix-ui/themes";
import parseColor from "parse-color";
import { CSSProperties, useEffect, useState } from "react";
import ColorPicker, {
  DEFAULT_PICKER_COLOR,
  UNSET_COLOR_VALUE,
} from "~/components/core/inputs/ColorPicker";

const ColorBox = ({
  color,
  label,
  colorPickerSide,
  onSaveColor,
  onChange,
  style,
  disabled,
}: {
  style?: CSSProperties;
  color?: string;
  label: string;
  colorPickerSide?: "top" | "right" | "bottom" | "left";
  onSaveColor?: (color: string) => void;
  onChange?: (color: string) => void;
  disabled?: boolean;
}) => {
  const defaultHexColor =
    color && color !== UNSET_COLOR_VALUE ? parseColor(color).hex : undefined;
  const [selectedColor, setSelectedColor] = useState(
    defaultHexColor ?? DEFAULT_PICKER_COLOR
  );

  useEffect(() => {
    setSelectedColor(defaultHexColor ?? DEFAULT_PICKER_COLOR);
  }, [defaultHexColor]);

  return (
    <ColorPicker
      defaultColor={color}
      onSaveColor={onSaveColor}
      onChange={(color) => {
        onChange?.(color);
        setSelectedColor(color);
      }}
      side={colorPickerSide}
    >
      <Flex
        align="center"
        gap="8px"
        style={{
          border: "1px solid var(--border-primary)",
          borderRadius: "10px",
          cursor: disabled ? "not-allowed" : "pointer",
          ...style,
        }}
        p="8px"
        direction="row-reverse"
      >
        <Box
          style={{
            width: "34px",
            height: "34px",
            borderRadius: "8px",
            border: "1px solid #0000000F",
            backgroundColor: selectedColor,
          }}
        />
        <Text size="3">{label}</Text>
      </Flex>
    </ColorPicker>
  );
};

export default ColorBox;
