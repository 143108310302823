import EmailEditorPreviewIFrame from "../layout/EmailEditorPreviewIFrame";
import {
  Dialog,
  Flex,
  ScrollArea,
  SegmentedControl,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { useState } from "react";
import { Toaster } from "sonner";
import styled from "styled-components";
import { MonitorIcon, SmartphoneIcon, XIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import useEmailCreativePreviewQuery from "~/hooks/emails/useEmailCreativePreviewQuery";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const SegmentedControlContainer = styled(SegmentedControl.Root)`
  height: 48px;
  background-color: #edebf0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .rt-SegmentedControlIndicator::before {
    margin: 4px;
    border: 1px solid var(--border-primary, #dddddd);
  }
`;

const PreviewContainer = styled(ScrollArea)`
  height: calc(90dvh - 80px);
  background-color: #f0edeb;
`;

const MobilePreviewContainer = styled.div`
  width: 375px;
  height: 667px;
  margin: 20px auto;
  border: 10px solid #333;
  border-radius: 30px;
  overflow: hidden;
`;

const PreviewEmailDialog = ({
  state,
  onOpenChange,
  isLoading,
}: {
  state: {
    open: boolean;
    isSlicedEmail: boolean;
  };
  onOpenChange: (open: boolean) => void;
  isLoading: boolean;
}) => {
  const [device, setDevice] = useState<"desktop" | "mobile">("desktop");
  const { name, id: emailCreativeId } = useEmailState();
  const { previewHtml, isLoading: isPreviewLoading } =
    useEmailCreativePreviewQuery({
      emailCreativeId,
      enabled: state.open && !isLoading,
      getSlicedImages: state.isSlicedEmail,
    });

  return (
    <Dialog.Root open={state.open} onOpenChange={onOpenChange}>
      <Dialog.Content
        size="4"
        maxWidth="95vw"
        maxHeight="90dvh"
        style={{ padding: 0, overflow: "hidden" }}
      >
        <Toaster richColors />
        <Flex
          justify="between"
          align="center"
          p="16px"
          pb="12px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            {name}
          </Dialog.Title>
          <SegmentedControlContainer
            radius="large"
            style={{ backgroundColor: "#F0EDEB" }}
            size="3"
            value={device}
            onValueChange={(value) => setDevice(value as "desktop" | "mobile")}
          >
            <SegmentedControl.Item value="desktop">
              <Flex align="center" gap="8px">
                <MonitorIcon size={20} /> <Text>Desktop</Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item value="mobile">
              <Flex align="center" gap="8px">
                <SmartphoneIcon size={20} /> <Text>Mobile</Text>
              </Flex>
            </SegmentedControl.Item>
          </SegmentedControlContainer>

          <Dialog.Close>
            <AppButton variant="outlined" size="3">
              <XIcon />
              Close
            </AppButton>
          </Dialog.Close>
        </Flex>

        <PreviewContainer>
          {isLoading ? (
            <Flex
              direction="column"
              justify="center"
              align="center"
              height="100%"
              gap="2"
            >
              <Spinner size="3" />
              <Text size="2" color="gray">
                Saving email...
              </Text>
            </Flex>
          ) : isPreviewLoading || !previewHtml ? (
            <Flex
              direction="column"
              justify="center"
              align="center"
              height="100%"
              gap="2"
            >
              <Spinner size="3" />
              <Text size="2" color="gray">
                Loading preview...
              </Text>
            </Flex>
          ) : device === "desktop" ? (
            <EmailEditorPreviewIFrame>
              <div
                style={{
                  position: "relative",
                }}
                dangerouslySetInnerHTML={{
                  __html: previewHtml,
                }}
              />
            </EmailEditorPreviewIFrame>
          ) : (
            <MobilePreviewContainer>
              <EmailEditorPreviewIFrame>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: previewHtml,
                  }}
                />
              </EmailEditorPreviewIFrame>
            </MobilePreviewContainer>
          )}
        </PreviewContainer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default PreviewEmailDialog;
