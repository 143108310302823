import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type CampaignRecommendationCreativeMessagesResponse =
  operations["recommender_api_get_campaign_recommendation_creative_messages"]["responses"][200]["content"]["application/json"];

export const getCampaignRecommendationCreativeMessagesQueryKey = (
  brandID: string | null,
  creativeRecommendationID: string | null
) => {
  return [
    "recommender/campaign-recommendation/creative-messages",
    brandID,
    creativeRecommendationID,
  ];
};

const useCampaignRecommendationCreativeMessagesQuery = (
  brandID: string | null,
  creativeRecommendationID: string | null
) => {
  const {
    data: campaignRecommendationCreativeMessages,
    isLoading,
    isSuccess,
  } = useQuery<CampaignRecommendationCreativeMessagesResponse>({
    queryKey: getCampaignRecommendationCreativeMessagesQueryKey(
      brandID,
      creativeRecommendationID
    ),
    queryFn:
      async (): Promise<CampaignRecommendationCreativeMessagesResponse> => {
        const { data } = await axios.get(
          `/api/v1/recommender/campaign-recommendation/creative-messages`,
          {
            params: {
              creative_recommendation_id: creativeRecommendationID,
              brand_id: brandID,
            },
          }
        );
        return data;
      },
    enabled: !!creativeRecommendationID && !!brandID,
  });

  return {
    campaignRecommendationCreativeMessages,
    isLoading,
    isSuccess,
  };
};

export default useCampaignRecommendationCreativeMessagesQuery;
