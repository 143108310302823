import { ShopifyProductSortKeys } from "@openapi";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

export enum ProductOrder {
  UNSET,
  ASC,
  DESC,
}
export type ProductsFilters = {
  previousPage: number;
  page: number;
  categoryId: string | null;
  name: string;
};
export const getProductsFiltersQuery = (filters: ProductsFilters) => {
  const categoriesFilter =
    filters.categoryId && filters.categoryId !== "all"
      ? `category_id:${filters.categoryId.split("/").at(-1)}`
      : undefined;
  if (filters.name && categoriesFilter) {
    return `${categoriesFilter} AND ${filters.name}`;
  }
  return categoriesFilter || filters.name;
};
export type FilterSetters = {
  updatePage: (addend: number) => void;
  setCategoryId: Dispatch<SetStateAction<string | null>>;
  setName: Dispatch<SetStateAction<string>>;
  toggleSortOrder: (sortType: ShopifyProductSortKeys | null) => void;
};
export type ProductSorters = {
  type: ShopifyProductSortKeys | null;
  order: ProductOrder;
};

export const useProductsFilters = (): {
  filters: ProductsFilters;
  setters: FilterSetters;
  sorters: ProductSorters;
} => {
  //filters
  const [page, setPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(-1);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [name, setName] = useState("");

  //order
  const [order, setOrder] = useState<ProductOrder>(ProductOrder.DESC);

  //sort
  const [sortType, setSortType] = useState<ShopifyProductSortKeys | null>(
    ShopifyProductSortKeys.INVENTORY_TOTAL
  );

  //setters
  const updatePage = (addend: number) => {
    setPreviousPage(page);
    setPage((prev) => prev + addend);
  };
  const toggleSortOrder = useCallback(
    (newSortType: ShopifyProductSortKeys | null) => {
      if (newSortType === sortType) {
        setOrder((prev) => {
          const newOrder = (prev + 1) % (Object.keys(ProductOrder).length / 2);
          if (newOrder === ProductOrder.UNSET) {
            setSortType(null);
          }
          return newOrder;
        });
      } else {
        setSortType(newSortType);
        setOrder(ProductOrder.ASC);
      }
    },
    [sortType]
  );
  const setters = useMemo(() => {
    return {
      updatePage,
      setCategoryId,
      setName,
      toggleSortOrder,
    };
  }, [setCategoryId, setName, setOrder, updatePage]);

  useEffect(() => {
    setPage(0);
    setPreviousPage(-1);
  }, [categoryId, name]);

  const filters = useMemo(() => {
    return {
      page: page,
      previousPage: previousPage,
      categoryId: categoryId,
      name: name,
    };
  }, [page, categoryId, name]);
  const sorters = useMemo(() => {
    return {
      type: sortType,
      order: order,
    };
  }, [order, sortType]);
  return useMemo(() => {
    return {
      setters: setters,
      filters: filters,
      sorters: sorters,
    };
  }, [setters, filters, sorters]);
};
