import { useChatContext } from "./OnboardingChatContext";
import { Flex, IconButton, Text, Box } from "@radix-ui/themes";
import { PencilIcon } from "lucide-react";
import AddCampaignPopover from "~/components/core/inputs/AddCampaignPopover";

const AddCampaignChatMessage = ({ campaignId }: { campaignId: string }) => {
  const { state, dispatch } = useChatContext();
  const campaign = state.plannedCampaigns.find(
    (campaign) => campaign.id === campaignId
  );
  if (!campaign) {
    return null;
  }
  return (
    <Flex direction="column" gap="2" p="2" style={{ width: "100%" }}>
      <Flex align="center" justify="between" style={{ width: "100%" }}>
        <Text weight="bold" size="3">
          {campaign?.title}
        </Text>
        <AddCampaignPopover
          initialCampaign={campaign}
          onAddCampaign={(campaignData) => {
            dispatch({
              type: "EDIT_PLANNED_CAMPAIGN",
              payload: { campaign: campaignData },
            });
          }}
          triggerComponent={
            <IconButton
              variant="ghost"
              aria-label="Edit"
              radius="full"
              size="2"
            >
              <PencilIcon size={16} />
            </IconButton>
          }
        />
      </Flex>

      <Flex gap="3">
        <Flex direction="column" style={{ flex: 1 }}>
          <Text size="1" color="gray">
            Campaign Start Date
          </Text>
          <Text weight="bold" size="3">
            {campaign.startDate}
          </Text>
        </Flex>

        <Flex direction="column" style={{ flex: 1 }}>
          <Text size="1" color="gray">
            Campaign Type
          </Text>
          <Text weight="bold" size="3">
            {campaign.type
              .replace("_", " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())}
          </Text>
        </Flex>
      </Flex>

      <Flex direction="row" gap="2" wrap="wrap">
        {campaign.products.size > 0 ? (
          Array.from(campaign.products.values()).map((product, index) => (
            <Box
              key={product.product_id}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                overflow: "hidden",
                border: "1px solid #DDDDDD",
              }}
            >
              <img
                src={product.image_url ?? ""}
                alt={product.title ?? ""}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))
        ) : (
          <Text size="1" color="gray">
            No products selected
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default AddCampaignChatMessage;
