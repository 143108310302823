import { Flex, Text, TextField } from "@radix-ui/themes";
import React, { forwardRef } from "react";
import styled from "styled-components";

const FormLabel = styled(Text)<{ $color?: string }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ $color }) => $color || "#4C4747"};
`;

const FormInput = styled(TextField.Root)`
  height: 48px;
  border-radius: 12px;
`;

type DraperTextWithLabelProps = {
  label: string;
  width?: string;
  style?: React.CSSProperties;
  text: string;
  onChange: (value: string) => void;
};

const DraperTextWithLabel = forwardRef<
  HTMLInputElement,
  DraperTextWithLabelProps
>(({ label, width, style, text, onChange, ...props }, ref) => {
  return (
    <Flex width={width} direction="column" gap="4px" style={style}>
      <FormLabel as="label" size="2" weight="regular">
        {label}
      </FormLabel>
      <FormInput
        size="3"
        value={text}
        onChange={(e) => onChange(e.target.value)}
        {...props}
        ref={ref}
      />
    </Flex>
  );
});

export default DraperTextWithLabel;
