import { CampaignStatus } from "@openapi";
import {
  Badge,
  Code,
  DataList,
  Dialog,
  Flex,
  IconButton,
  Separator,
  Text,
  Box,
  Table,
  Button,
} from "@radix-ui/themes";
import { CopyIcon } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import InternImageAssetCard from "~/components/intern/InternImageAssetCard";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInternCampaignsCampaignQuery from "~/hooks/intern/useInternCampaignsCampaignQuery";
import useInternClassifyCampaignImageAssetsWithStatus from "~/hooks/intern/useInternClassifyCampaignImageAssetsWithStatus";

const InternCampaignDetailContainer = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const activeBrandID = useActiveBrandID();
  const [isClassifyImageDialogOpen, setIsClassifyImageDialogOpen] =
    useState(false);

  const { classifyCampaignImageAssetsMutation, isClassifying } =
    useInternClassifyCampaignImageAssetsWithStatus({
      onScheduleError(error) {
        alert(error);
      },
      onSuccess(data) {
        setIsClassifyImageDialogOpen(false);
      },
      onError(error) {
        alert(error);
      },
    });

  const { data } = useInternCampaignsCampaignQuery({
    campaignId: id,
  });

  return (
    <Flex gap="4" direction="column" gapY="4" width="100%">
      <Box>
        <DataList.Root>
          <DataList.Item align="center">
            <DataList.Label minWidth="88px">Status</DataList.Label>
            <DataList.Value>
              <Badge
                color={
                  data?.status === CampaignStatus.active ? "jade" : "yellow"
                }
                radius="full"
              >
                {data?.status}
              </Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">ID</DataList.Label>
            <DataList.Value>
              <Flex align="center" gap="2">
                <Code>{data?.id}</Code>
                <IconButton
                  size="1"
                  aria-label="Copy value"
                  color="gray"
                  variant="ghost"
                >
                  <CopyIcon size="16px" />
                </IconButton>
              </Flex>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Type</DataList.Label>
            <DataList.Value>
              <Badge radius="full">{data?.type}</Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Destination</DataList.Label>
            <DataList.Value>
              <Flex align="center" gap="2">
                <Code>{data?.destination}</Code>
                <IconButton
                  size="1"
                  aria-label="Copy value"
                  color="gray"
                  variant="ghost"
                >
                  <CopyIcon size="16px" />
                </IconButton>
              </Flex>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Destination Type</DataList.Label>
            <DataList.Value>
              <Badge radius="full">{data?.destination_type}</Badge>
            </DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">Emails</DataList.Label>
            <DataList.Value>
              {data?.email_creatives?.length ? (
                <Flex direction="column" gap="2">
                  {data.email_creatives.map((email) => (
                    <Box
                      key={email.id}
                      onClick={() =>
                        navigate(`/intern/campaign/${id}/email/${email.id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Code>{email.id}</Code>
                    </Box>
                  ))}
                </Flex>
              ) : (
                <Text>No emails</Text>
              )}
            </DataList.Value>
          </DataList.Item>
        </DataList.Root>
      </Box>
      <Flex>
        <Dialog.Root
          open={isClassifyImageDialogOpen}
          onOpenChange={setIsClassifyImageDialogOpen}
        >
          <Dialog.Trigger>
            <Button onClick={() => setIsClassifyImageDialogOpen(true)}>
              Scrape and Classify Image Assets
            </Button>
          </Dialog.Trigger>
          <Dialog.Content>
            <Dialog.Title>Confirm Action</Dialog.Title>
            <Dialog.Description>
              Are you sure you want to fetch all images related to this campaign
              and populate image assets?
            </Dialog.Description>
            <Flex gap="3" mt="4" justify="end">
              <Dialog.Close>
                <Button variant="outline">Cancel</Button>
              </Dialog.Close>
              <Button
                loading={isClassifying}
                onClick={() => {
                  classifyCampaignImageAssetsMutation({
                    brand_id: activeBrandID,
                    campaign_id: id,
                  });
                }}
              >
                Confirm
              </Button>
            </Flex>
          </Dialog.Content>
        </Dialog.Root>
      </Flex>
      {data?.items?.length ? (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>IDs</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Assets</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.items.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Box>
                    <Flex align="center" gap="2">
                      <Code wrap={"nowrap"}>{item.id}</Code>
                      <IconButton
                        aria-label="Copy value"
                        color="gray"
                        variant="ghost"
                      >
                        <CopyIcon size="16px" />
                      </IconButton>
                    </Flex>
                  </Box>

                  <Box>
                    <Flex align="center" gap="2">
                      <Code>{item.commerce_platform_item_id}</Code>
                      <IconButton
                        size="1"
                        aria-label="Copy value"
                        color="gray"
                        variant="ghost"
                      >
                        <CopyIcon size="16px" />
                      </IconButton>
                    </Flex>
                  </Box>
                </Table.Cell>
                <Table.Cell>
                  <Flex
                    direction={{ initial: "column", md: "row" }}
                    wrap="wrap"
                    gap="2"
                  >
                    {item.valid_assets.map((asset) => (
                      <InternImageAssetCard key={asset.id} asset={asset} />
                    ))}
                  </Flex>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      ) : (
        <Text>No items</Text>
      )}
      <Separator style={{ width: "100%" }} />
      <Box style={{ flex: 1 }}>
        <Outlet />
      </Box>
    </Flex>
  );
};

export default InternCampaignDetailContainer;
