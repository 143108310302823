import SocialLinkContainer from "./modifiers/SocialLinkContainer";
import { EmailElementType } from "@openapi";
import { useMemo } from "react";
import {
  useTemplateEditorDispatch,
  useTemplateEditorState,
} from "~/contexts/TemplateEditorContext";

const emptyLink = {
  id: "",
  type: EmailElementType.link,
  enabled: false,
  text: "",
  link_url: "",
  opens_new_tab: false,
};

const SocialLinksCustomization = () => {
  const { footerOptions } = useTemplateEditorState();
  const dispatch = useTemplateEditorDispatch();

  const links = useMemo(
    () => [
      {
        link: footerOptions.facebook_link ?? emptyLink,
        key: "facebook_link",
        selector: "Facebook",
      },
      {
        link: footerOptions.instagram_link ?? emptyLink,
        key: "instagram_link",
        selector: "Instagram",
      },
      {
        link: footerOptions.twitter_link ?? emptyLink,
        key: "twitter_link",
        selector: "Twitter",
      },
      {
        link: footerOptions.tiktok_link ?? emptyLink,
        key: "tiktok_link",
        selector: "TikTok",
      },
      {
        link: footerOptions.youtube_link ?? emptyLink,
        key: "youtube_link",
        selector: "YouTube",
      },
    ],
    [footerOptions]
  );

  return (
    <>
      {links.map((link) => (
        <SocialLinkContainer
          key={link.key}
          values={link}
          onChange={(values) => {
            dispatch({
              type: "UPDATE_EDITOR_STATE",
              payload: {
                footerOptions: {
                  ...footerOptions,

                  [link.key]: values,
                },
              },
            });
          }}
        />
      ))}
    </>
  );
};

export default SocialLinksCustomization;
