import { CampaignStatus } from "@openapi";
import { Flex, Heading, SegmentedControl, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import CampaignDraftSection from "~/components/home/components/draftSection/CampaignDraftSection";
import RecommenderSection from "~/components/home/components/recommenderSection/RecommenderSection";
import useGetCampaigns from "~/hooks/campaign/useGetCampaigns";
import { HOME_ROUTE } from "~/routes/constants";

export enum HomeRoutes {
  RECOMMENDATIONS = "",
  DRAFTS = "drafts",
}

const getFullRelativePath = (route: HomeRoutes) => {
  return `${HOME_ROUTE}/${route}`;
};
interface HomeTab {
  route: HomeRoutes;
  relativePath: string;
  label: string;
}
const TABS: HomeTab[] = [
  {
    route: HomeRoutes.RECOMMENDATIONS,
    relativePath: getFullRelativePath(HomeRoutes.RECOMMENDATIONS),
    label: "Create New",
  },
  {
    route: HomeRoutes.DRAFTS,
    relativePath: getFullRelativePath(HomeRoutes.DRAFTS),
    label: "Drafts",
  },
];

export const getHomeRoutes = (): RouteObject[] => [
  {
    path: HomeRoutes.RECOMMENDATIONS,
    element: <RecommenderSection />,
  },
  {
    path: HomeRoutes.DRAFTS,
    element: <CampaignDraftSection />,
  },
];

const StyledText = styled.span`
  font-family: var(--heading-font-family);
  font-weight: 600;
`;
const DraftCountContainer = styled(Flex)`
  border-radius: 50%;
  background: var(--gray-3);
  height: 28px;
  width: 28px;
  font-size: 14px;
  font-weight: 600;
`;
const StyledCountText = styled(StyledText)`
  color: var(--violet-9);
`;
const HomeSegmentControl = () => {
  const [activeTab, setActiveTab] = useState(TABS[0].relativePath);
  const navigate = useNavigate();
  const location = useLocation();
  const handleTabChange = (relativePath: string) => {
    setActiveTab(relativePath);
    navigate(relativePath);
  };
  const { data: campaigns } = useGetCampaigns({
    status: CampaignStatus.draft,
    limit: 1,
    includeTotalCount: true,
  });

  useEffect(() => {
    const path = location.pathname;

    if (path.includes(HomeRoutes.DRAFTS)) {
      setActiveTab(getFullRelativePath(HomeRoutes.DRAFTS));
    } else {
      setActiveTab(getFullRelativePath(HomeRoutes.RECOMMENDATIONS));
    }
  }, [location]);

  const draftCount = (
    <DraftCountContainer align="center" justify="center">
      <StyledCountText>
        {campaigns && (campaigns.pages[0]?.total_count ?? 0)}
      </StyledCountText>
    </DraftCountContainer>
  );
  return (
    <DraperSegmentControl
      $size="large"
      $color="var(--text-secondary)"
      value={activeTab}
      radius="large"
      size="3"
      onValueChange={handleTabChange}
      style={{
        backgroundColor: "var(--black-8, #00000014)",
        position: "relative",
      }}
    >
      {TABS.map((tab) => (
        <SegmentedControl.Item value={tab.relativePath} key={tab.route}>
          <Heading
            size="5"
            weight="medium"
            style={{
              padding: "8px",
            }}
          >
            <Flex gap={"2"}>
              <StyledText>{tab.label}</StyledText>
              {tab.route === HomeRoutes.DRAFTS && draftCount}
            </Flex>
          </Heading>
        </SegmentedControl.Item>
      ))}
    </DraperSegmentControl>
  );
};

export default HomeSegmentControl;
