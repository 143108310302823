import ModifierContainer from "./ModifierContainer";
import { Flex, Text } from "@radix-ui/themes";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import SidePanelComponents from "~/components/core/editor/sidepane";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useEmailSectionBrandUpdateMutation from "~/hooks/emails/useEmailSectionBrandUpdateMutation";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isNewSection } from "~/utils/emails/useSectionTypeCheck";

const BrandCustomSectionPropertiesModifier = () => {
  const activeBrandID = useActiveBrandID();
  const [expanded, setExpanded] = useState(true);

  const { selectedSectionId, sections } = useEmailState();
  const section = sections.find((s) => s.id === selectedSectionId);

  const onClose = useCallback(() => {
    setExpanded(false);
  }, []);

  const { updateEmailSectionBrand, isLoading } =
    useEmailSectionBrandUpdateMutation({
      brandID: activeBrandID,
      onSuccess: (data) => {
        toast.success("Properties saved");
        onClose();
      },
      onError: (error) => {
        toast.error("Failed to save properties");
      },
    });

  const onSaveProperties = useCallback(() => {
    if (!section) return;
    if (isNewSection(section)) return;
    updateEmailSectionBrand({
      brand_id: activeBrandID,
      section: section,
    });
  }, [activeBrandID, section, updateEmailSectionBrand]);

  return (
    <ModifierContainer
      backgroundColor="#FCFBFA"
      hideSwitch
      title="Save Properties"
      expanded={expanded}
      onExpandedChange={setExpanded}
    >
      <Flex direction="column" gap="2" width="100%">
        <Text size="2">
          Would you like to set the current customizations (font, colors, size)
          for future emails?
        </Text>

        <Flex gap="2">
          <SidePanelComponents.Button onClick={onClose}>
            Close
          </SidePanelComponents.Button>
          <SidePanelComponents.Button
            style={{ flex: 1 }}
            onClick={onSaveProperties}
            loading={isLoading}
          >
            Save Properties
          </SidePanelComponents.Button>
        </Flex>
      </Flex>
    </ModifierContainer>
  );
};

export default BrandCustomSectionPropertiesModifier;
