import { operations } from "../../../openapi";
import {
  brandColorPalettesQueryKey,
  BrandColorPalettesResponse,
} from "./useBrandColorPalettesQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type UpdateBrandColorPaletteResponse =
  operations["brand_api_update_color_palette"]["responses"][200]["content"]["application/json"];

type UpdateBrandColorPaletteRequestData =
  operations["brand_api_update_color_palette"]["requestBody"]["content"]["application/json"];

const useBrandColorPaletteUpdateMutation = ({
  brandID,
  campaignID,
  onSuccess,
  onError,
}: {
  brandID: string;
  campaignID?: string;
  onSuccess?: (data: UpdateBrandColorPaletteResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();

  const updateBrandColorPalette = useDraperApiPostMutation<
    UpdateBrandColorPaletteResponse,
    UpdateBrandColorPaletteRequestData
  >({
    mutationKey: ["brand-color-palette-update"],
    path: `/brand/${brandID}/stylebook/color_palette`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);

      [
        brandColorPalettesQueryKey(brandID),
        brandColorPalettesQueryKey(brandID, campaignID),
      ].forEach((queryKey) => {
        queryClient.setQueryData<BrandColorPalettesResponse>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;
            return oldData.map((palette) =>
              palette.id === data.id ? data : palette
            );
          }
        );
      });
    },
  });

  return {
    updateBrandColorPalette: updateBrandColorPalette.mutate,
    isLoading: updateBrandColorPalette.isPending,
  };
};

export default useBrandColorPaletteUpdateMutation;
