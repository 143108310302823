import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

export type UpdateEmailSectionTemplateRequest =
  operations["intern_api_update_email_section_template"]["requestBody"]["content"]["application/json"];

export type UpdateEmailSectionTemplateResponse =
  operations["intern_api_update_email_section_template"]["responses"]["200"]["content"]["application/json"];

const useInternEmailSectionTemplateUpdateMutation = ({
  templateId,
  onSuccess,
}: {
  templateId: string;
  onSuccess?: (data: UpdateEmailSectionTemplateResponse) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useDraperApiPatchMutation<
    UpdateEmailSectionTemplateResponse,
    UpdateEmailSectionTemplateRequest
  >({
    mutationKey: ["internEmailSectionTemplates", templateId],
    path: `/intern/email-section-templates/${templateId}`,
    onSuccess: (data) => {
      toast.success("Email section template updated");
      onSuccess?.(data);
    },
    onError: () => {
      toast.error("Failed to update email section template");
    },
  });

  return {
    updateEmailSectionTemplate: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternEmailSectionTemplateUpdateMutation;
