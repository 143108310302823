import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useBrandHasReviewsQuery = () => {
  const activeBrandID = useActiveBrandID();
  const result = useQuery({
    queryKey: ["brand-has-reviews", activeBrandID],
    queryFn: async (): Promise<boolean> => {
      const { data } = await axios.get("/api/v1/brand/has-reviews", {
        params: {
          id: activeBrandID,
        },
      });

      return data;
    },
    enabled: !!activeBrandID,
  });

  return result.data;
};

export default useBrandHasReviewsQuery;
