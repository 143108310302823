import { campaignRecommendationTypeContent } from "../recommenderSection/RecommenderCard";
import { IconType } from "@icons-pack/react-simple-icons";
import { CampaignType, components } from "@openapi";
import { Flex, Text, Separator, Skeleton } from "@radix-ui/themes";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightIcon, PackageOpenIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { CAMPAIGN_OVERVIEW_ROUTE } from "~/routes/constants";

export const campaignTypeContent: {
  [key in CampaignType]: {
    icon: IconType;
    title: string;
  };
} = {
  [CampaignType.new_products]: {
    icon: PackageOpenIcon,
    title: "New Products",
  },
  [CampaignType.inventory_clearance]: {
    icon: PackageOpenIcon,
    title: "Inventory Clearance",
  },
  [CampaignType.sales_event]: {
    icon: PackageOpenIcon,
    title: "Sales Event",
  },
  [CampaignType.other]: {
    icon: PackageOpenIcon,
    title: "Other",
  },
};

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const Image = styled.img<{
  $aspectRatio: string;
  $roundCorners: "left" | "right" | "all";
  height: string;
}>`
  width: 100%;
  height: ${(props) => props.height};
  aspect-ratio: ${(props) => props.$aspectRatio};
  object-fit: cover;
  object-position: top;
  border-radius: ${({ $roundCorners }) => {
    if ($roundCorners === "left") {
      return "12px 0 0 12px";
    }
    if ($roundCorners === "right") {
      return "0 12px 12px 0";
    }
    return "12px";
  }};
  border: none;
`;

const TitleText = styled(DraperText)`
  font-family: var(--heading-font-family);
`;

export const CampaignDraftCardSkeleton = () => {
  return (
    <Card direction="column" p="16px" gap="5" justify="between">
      <Flex direction="column" gap="4">
        <Skeleton style={{ height: "220px", borderRadius: "12px" }} />
        <Skeleton style={{ height: "32px", width: "80%" }} />
        <Flex gap="16px" align="center">
          <Flex align="center" gap="10px">
            <Skeleton
              style={{ height: "24px", width: "24px", borderRadius: "50%" }}
            />
            <Skeleton style={{ height: "20px", width: "100px" }} />
          </Flex>
          <Separator orientation="vertical" size="4" />
          <Skeleton style={{ height: "20px", width: "80px" }} />
        </Flex>
      </Flex>
      <Skeleton
        style={{ height: "40px", width: "150px", borderRadius: "20px" }}
      />
    </Card>
  );
};

const CampaignDraftCard = ({
  campaign,
}: {
  campaign: components["schemas"]["CampaignSchema"];
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const IconComponent = campaign.campaign_recommendation
    ? campaignRecommendationTypeContent[
        campaign.campaign_recommendation.item_set_type
      ].icon
    : campaignTypeContent[campaign.type].icon;

  const images = useMemo(() => {
    const emailImages = campaign.email_images ?? [];
    const adImages = campaign.ad_images ?? [];
    return [emailImages[0], adImages[0], emailImages[1], adImages[1]]
      .filter((image) => !!image)
      .slice(0, 2);
  }, [campaign.email_images, campaign.ad_images]);

  return (
    <Card direction="column" p="16px" gap="5" justify="between">
      <Flex direction="column" gap="4">
        <Flex style={{ borderRadius: "12px" }}>
          <Image
            height="220px"
            $aspectRatio="0.5"
            $roundCorners={images[1] ? "left" : "all"}
            src={images[0] ?? ""}
            alt=""
          />
          {images[1] && (
            <Image
              height="220px"
              $aspectRatio="0.5"
              $roundCorners="right"
              src={images[1]}
              alt=""
            />
          )}
        </Flex>

        <TitleText size="7" clamp={2} weight="medium" wordBreak="break-word">
          {campaign.campaign_recommendation?.creative_recommendations?.[0]
            ?.title ?? campaign.title}
        </TitleText>

        <Flex gap="16px" align="center" style={{ color: "#3b3b3b" }}>
          <Flex align="center" gap="10px">
            {IconComponent && (
              <IconComponent
                size={24}
                style={{ color: "var(--primary-vibrant-orange)" }}
              />
            )}
            <Text size="3" weight="medium">
              {campaign.campaign_recommendation
                ? campaignRecommendationTypeContent[
                    campaign.campaign_recommendation.item_set_type
                  ].title
                : campaignTypeContent[campaign.type].title}
            </Text>
          </Flex>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            {campaign.products_count || 0}{" "}
            {campaign.products_count === 1 ? "Product" : "Products"}
          </Text>
        </Flex>
      </Flex>

      <AppButton
        variant="outlined"
        onClick={() =>
          navigate(CAMPAIGN_OVERVIEW_ROUTE.replace(":id", campaign.id), {
            state: {
              from: location.pathname,
            },
          })
        }
        style={{ width: "max-content" }}
      >
        Resume Editing
        <ArrowRightIcon />
      </AppButton>
    </Card>
  );
};

export default CampaignDraftCard;
