import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type CampaignCalendarResponse =
  operations["campaign_api_get_home_calendar"]["responses"][200]["content"]["application/json"];

export const getCampaignHomeCalendarQueryKey = (
  brandID: string,
  startDate?: Date,
  endDate?: Date
) => {
  const queryKey = ["campaign-home-calendar", brandID];

  if (startDate) {
    queryKey.push(dayjs(startDate).startOf("day").format("YYYY-MM-DD"));
  }

  if (endDate) {
    queryKey.push(dayjs(endDate).startOf("day").format("YYYY-MM-DD"));
  }

  return queryKey;
};

const useCampaignHomeCalendarQuery = ({
  enabled = true,
  startDate,
  endDate,
}: {
  enabled?: boolean;
  startDate?: Date;
  endDate?: Date;
}) => {
  const brandID = useActiveBrandID();

  return useQuery({
    queryKey: getCampaignHomeCalendarQueryKey(brandID, startDate, endDate),
    queryFn: async () => {
      const response = await axios.get<CampaignCalendarResponse>(
        `/api/v1/campaign/home/calendar`,
        {
          params: {
            brand_id: brandID,
            start_date: startDate
              ? dayjs(startDate).startOf("day").format("YYYY-MM-DD")
              : undefined,
            end_date: endDate
              ? dayjs(endDate).startOf("day").format("YYYY-MM-DD")
              : undefined,
          },
        }
      );

      return response.data;
    },
    enabled: enabled && !!brandID,
  });
};

export default useCampaignHomeCalendarQuery;
