import {
  components,
  CSSBorderStyle,
  EmailElementType,
  EmailSectionType,
  EmailSectionWidthType,
} from "@openapi";
import { EmailCreative } from "~/routes/intern/email_editor/context/EmailEditorContext";

const DEFAULT_TEMPLATE: EmailCreative["variants"][number]["sections"][number]["template"] =
  {
    id: "",
    html: "",
    is_base_template: false,
    configurable_parameters: [],
    preview_image: null,
    name: "",
    index: 0,
    section_type: "",
  };

const DEFAULT_BUTTON: components["schemas"]["EmailButtonElementSchema"] = {
  id: "",
  type: EmailElementType.button,
  enabled: true,
  text: "",
  link_url: "",
  background_color: "",
  border_color: "",
  border_style: "",
  border_width: "",
  border_radius: "",
  color: "",
  padding: "",
  text_transform: "",
  font_styles: null,
  opens_new_tab: true,
};

const DEFAULT_TEXT: components["schemas"]["EmailTextElementSchema"] = {
  id: "",
  type: EmailElementType.text,
  enabled: true,
  text: "",
};

const DEFAULT_IMAGE: components["schemas"]["EmailImageElementSchema"] = {
  id: "",
  type: EmailElementType.image,
  image_url: "",
  enabled: true,
  border_color: "",
  border_style: CSSBorderStyle.none,
  border_width: "",
  border_radius: "",
  padding_top: "",
  padding_right: "",
  padding_bottom: "",
  padding_left: "",
};

const DEFAULT_PALETTE: components["schemas"]["ColorPaletteSchema"] = {
  id: "",
  background: "",
  foreground: "",
  accent_background: "",
  accent_foreground: "",
};

const DEFAULT_COLLECTION: components["schemas"]["EmailCollectionElementSchema"] =
  {
    id: "",
    type: EmailElementType.collection,
    enabled: true,
    commerce_platform_item_id: "",
    title: DEFAULT_TEXT,
    description: DEFAULT_TEXT,
    cta_button: DEFAULT_BUTTON,
    background_image: DEFAULT_IMAGE,
  };

const DEFAULT_PRODUCT: components["schemas"]["EmailProductElementSchema"] = {
  id: "",
  index: 0,
  type: EmailElementType.product,
  enabled: true,
  commerce_platform_id: "",
  title: DEFAULT_TEXT,
  price: { ...DEFAULT_TEXT, text: "$10" },
  compare_at_price: { ...DEFAULT_TEXT, text: "<s>$15</s>" },
  description: DEFAULT_TEXT,
  cta_button: DEFAULT_BUTTON,
  image: DEFAULT_IMAGE,
};

const DEFAULT_REVIEW: components["schemas"]["EmailReviewElementSchema"] = {
  id: "",
  type: EmailElementType.review,
  enabled: true,
  review_id: "",
  title: DEFAULT_TEXT,
  body: DEFAULT_TEXT,
  rating: 0,
  reviewer: DEFAULT_TEXT,
  image: DEFAULT_IMAGE,
};

const DEFAULT_LISTICLE: components["schemas"]["EmailTextListElementSchema"] = {
  id: "",
  type: EmailElementType.text_list,
  enabled: true,
  title: DEFAULT_TEXT,
  subtitle: DEFAULT_TEXT,
};

const DEFAULT_MENU_LINK: components["schemas"]["EmailMenuLinkElementSchema"] = {
  id: "",
  type: EmailElementType.menu_link,
  enabled: true,
  text: "",
  link_url: "#",
  opens_new_tab: true,
  index: 0,
};

const DEFAULT_MENU: components["schemas"]["EmailMenuElementSchema"] = {
  id: "",
  type: EmailElementType.menu,
  enabled: true,
  menu_links: [
    { ...DEFAULT_MENU_LINK, index: 0, text: "SHOP" },
    { ...DEFAULT_MENU_LINK, index: 1, text: "ABOUT" },
    { ...DEFAULT_MENU_LINK, index: 2, text: "CONTACT" },
    { ...DEFAULT_MENU_LINK, index: 3, text: "FAQ" },
    { ...DEFAULT_MENU_LINK, index: 4, text: "SUPPORT" },
  ],
};

const DEFAULT_SOCIAL_LINK: components["schemas"]["EmailLinkElementSchema"] = {
  id: "",
  type: EmailElementType.link,
  enabled: true,
  text: "",
  link_url: "#",
  opens_new_tab: true,
};

export const getSectionInitDefaults = (
  type: EmailSectionType
): EmailCreative["variants"][number]["sections"][number] => {
  switch (type) {
    case EmailSectionType.blog:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        title: DEFAULT_TEXT,
        description: DEFAULT_TEXT,
        cta_button: DEFAULT_BUTTON,
        image: DEFAULT_IMAGE,
        palette: DEFAULT_PALETTE,
      };
    case EmailSectionType.collection_grid:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        collections: [DEFAULT_COLLECTION, DEFAULT_COLLECTION],
      };
    case EmailSectionType.cta_card:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        cta_button: DEFAULT_BUTTON,
      };
    case EmailSectionType.hero_collection:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        collection: DEFAULT_COLLECTION,
        value_prop_1: DEFAULT_TEXT,
        value_prop_2: DEFAULT_TEXT,
        value_prop_3: DEFAULT_TEXT,
      };
    case EmailSectionType.hero_product:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        cta_button: DEFAULT_BUTTON,
        image: DEFAULT_IMAGE,
      };
    case EmailSectionType.hero_sales_event:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        promo_code: DEFAULT_TEXT,
        promo_amount: DEFAULT_TEXT,
        discount_text: DEFAULT_TEXT,
        cta_button: DEFAULT_BUTTON,
        image: DEFAULT_IMAGE,
      };
    case EmailSectionType.hero:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        cta_button: DEFAULT_BUTTON,
        image: DEFAULT_IMAGE,
      };
    case EmailSectionType.image:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        image: DEFAULT_IMAGE,
        link_url: "",
      };
    case EmailSectionType.listicle:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        list_items: [DEFAULT_LISTICLE, DEFAULT_LISTICLE],
        cta_button: DEFAULT_BUTTON,
      };
    case EmailSectionType.products:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        subtitle: DEFAULT_TEXT,
        products: [DEFAULT_PRODUCT, DEFAULT_PRODUCT],
      };
    case EmailSectionType.text:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        text: DEFAULT_TEXT,
      };
    case EmailSectionType.reviews:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        title: DEFAULT_TEXT,
        cta_button: DEFAULT_BUTTON,
        reviews: [DEFAULT_REVIEW, DEFAULT_REVIEW],
        selected_product_id: "",
      };
    case EmailSectionType.header:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        width_type: EmailSectionWidthType.full,
        logo: DEFAULT_IMAGE,
        custom_html: null,
        menu: null,
      };
    case EmailSectionType.footer:
      return {
        id: "",
        type,
        index: 0,
        template: DEFAULT_TEMPLATE,
        palette: DEFAULT_PALETTE,
        width_type: EmailSectionWidthType.full,
        menu: DEFAULT_MENU,
        show_social_links: true,
        facebook_link: DEFAULT_SOCIAL_LINK,
        instagram_link: DEFAULT_SOCIAL_LINK,
        twitter_link: DEFAULT_SOCIAL_LINK,
        youtube_link: DEFAULT_SOCIAL_LINK,
        tiktok_link: DEFAULT_SOCIAL_LINK,
      };
  }
};
