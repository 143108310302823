import { Flex, IconButton } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftIcon } from "~/assets/icons";

const FlexSide = styled(Flex)`
  flex: 1;
`;

const Bar = styled(Flex)`
  height: var(--editor-top-bar-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-editor-top-bar);
  backdrop-filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--black-4, #0000000a);
`;

const EditorTopBar = ({
  backButtonDestination,
  backButtonOverride,
  left,
  center,
  right,
}: {
  backButtonDestination: string;
  backButtonOverride?: React.ReactNode;
  left: React.ReactNode;
  center: React.ReactNode;
  right: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <Bar align="center" gap="4" width="100%" justify="between" py="1" px="4">
      <FlexSide align="center" gap="4" justify="start">
        {backButtonOverride ? (
          backButtonOverride
        ) : (
          <IconButton
            size="3"
            radius="full"
            variant="outline"
            style={{
              border: "1px solid var(--border-primary)",
              boxShadow: "none",
            }}
            onClick={() => {
              if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
              } else {
                navigate(backButtonDestination);
              }
            }}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
        {left}
      </FlexSide>

      <Flex align="center" gap="3">
        {center}
      </Flex>

      <FlexSide align="center" gap="3" justify="end">
        {right}
      </FlexSide>
    </Bar>
  );
};

export default EditorTopBar;
