import { Dialog, Flex, Skeleton, Text, RadioGroup } from "@radix-ui/themes";
import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import CloseIconButton from "~/components/core/buttons/CloseIconButton";
import useEmailTemplatesQuery from "~/hooks/emails/useEmailTemplatesQuery";

const PreviewThumbnail = styled.div<{
  background?: string;
  isSelected?: boolean;
}>`
  width: 250px;
  height: 450px;
  border-radius: 8px;
  background-color: var(--gray-4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: top;
  border: 1px solid var(--gray-6);
  position: relative;
  overflow: hidden;
`;

const ScrollContainer = styled(Flex)`
  overflow-x: auto;
  flex: 1;
  position: relative;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  min-height: 500px;
  max-height: 70vh;
`;

const EmailTemplateSelectorDialog = ({
  open,
  onOpenChange,
  onSelect,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSelect: (templateId: string) => void;
}) => {
  const [isNearEnd, setIsNearEnd] = useState(false);
  const isLoadingRef = useRef(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");

  const { templates, error, isLoading, fetchNextPage, hasNextPage } =
    useEmailTemplatesQuery(10);

  const scrollContainerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) {
        return;
      }
      const handleScroll = () => {
        if (isLoadingRef.current || !hasNextPage) {
          return;
        }

        const { scrollLeft, scrollWidth, clientWidth } = node;
        const scrollPercentage = (scrollLeft + clientWidth) / scrollWidth;
        if (scrollPercentage > 0.9 && !isNearEnd && hasNextPage) {
          setIsNearEnd(true);
          isLoadingRef.current = true;
          fetchNextPage().finally(() => {
            isLoadingRef.current = false;
          });
        } else if (scrollPercentage <= 0.9) {
          setIsNearEnd(false);
        }
      };

      if (hasNextPage) {
        node.addEventListener("scroll", handleScroll);
      } else {
        node.removeEventListener("scroll", handleScroll);
      }
    },
    [hasNextPage]
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content style={{ padding: 0, maxWidth: "80vw" }}>
        <Flex direction="column" style={{ height: "100%", maxHeight: "90vh" }}>
          <Flex
            p="4"
            justify="between"
            align="center"
            style={{ borderBottom: "1px solid #E2E2E2" }}
          >
            <Dialog.Title size="4" weight="bold" mb="0">
              Select Template
            </Dialog.Title>
            <Dialog.Close>
              <CloseIconButton />
            </Dialog.Close>
          </Flex>

          <Dialog.Description size="2" m="4">
            Changing the template will regenerate the content of the email, this
            could take a minute or two.
          </Dialog.Description>

          <ScrollContainer ref={scrollContainerRef}>
            <RadioGroup.Root
              value={selectedTemplateId}
              onValueChange={(value) => {
                setSelectedTemplateId(value);
              }}
            >
              <Flex gap="0">
                {templates.map((template) => (
                  <Flex
                    key={template.id}
                    direction="column"
                    gap="2"
                    p="4"
                    onClick={() => {
                      setSelectedTemplateId(template.id);
                    }}
                    style={{
                      borderRight: "1px solid #E2E2E2",
                      flexShrink: 0,
                    }}
                  >
                    <RadioGroup.Item value={template.id}>
                      <Text size="2" color="gray">
                        {template.name}
                      </Text>
                    </RadioGroup.Item>
                    <PreviewThumbnail
                      background={template.preview_image ?? undefined}
                    />
                  </Flex>
                ))}
                {(hasNextPage && isLoading) || error !== null ? (
                  <Flex gap="2">
                    <Skeleton
                      height="400px"
                      width="240px"
                      style={{ borderRadius: "8px" }}
                    />
                    <Skeleton
                      height="400px"
                      width="240px"
                      style={{ borderRadius: "8px" }}
                    />
                  </Flex>
                ) : null}
              </Flex>
            </RadioGroup.Root>
          </ScrollContainer>

          <Flex p="4" justify="end" gap="3">
            <Dialog.Close>
              <AppButton variant="outlined" size="3" radius="large">
                Cancel
              </AppButton>
            </Dialog.Close>
            <Dialog.Close>
              <AppButton
                disabled={!selectedTemplateId}
                variant="primary"
                size="3"
                radius="large"
                onClick={() => {
                  if (selectedTemplateId) onSelect(selectedTemplateId);
                }}
              >
                Use Selected Template
              </AppButton>
            </Dialog.Close>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default EmailTemplateSelectorDialog;
