import { AssetSelectorItem } from "../AssetSelectorDialog";
import { ImageAssetCategory } from "@openapi";
import { Box, Flex, Text } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { DROPZONE_IMAGE_TYPES } from "~/components/core/dropzone/Dropzone";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets from "~/hooks/style-library/useUploadImageAssets";

export const UploadFromDeviceContent = ({
  category,
  setSelectedAssets,
  setUploadError,
  onBack,
}: {
  category: ImageAssetCategory;
  setSelectedAssets: (assets: AssetSelectorItem[]) => void;
  setUploadError: Dispatch<SetStateAction<string | null>>;
  onBack: () => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const { mutate: uploadImageAssets, isPending: isUploadPending } =
    useUploadImageAssets({
      onSuccess: (data) => {
        setSelectedAssets([data.new_assets[0]]);
        onBack();
      },
      onError: (_, displayMessage) => {
        setUploadError?.(displayMessage);
      },
    });

  const handleUpload = (acceptedFiles: File[]) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: category as any,
      images: acceptedFiles as unknown as string[],
    });
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: handleUpload,
    accept: DROPZONE_IMAGE_TYPES,
    multiple: false,
  });

  return (
    <Box
      {...getRootProps()}
      style={{
        width: "100%",
        height: "400px",
        border: "2px dashed",
        borderRadius: "12px",
        borderColor: isDragAccept ? "#3b82f6" : "#d1d5db",
        backgroundColor: "#EDEBF0",
        cursor: "pointer",
      }}
    >
      <input {...getInputProps()} />
      <Flex
        width="100%"
        height="100%"
        direction="column"
        justify="center"
        align="center"
        p="5"
      >
        <UploadIcon size={40} />
        <Text mt="16px" size="4" weight="medium">
          Drag and drop your image here
        </Text>
        <Text mt="8px" size="2" color="gray">
          or
        </Text>
        <AppButton
          style={{ marginTop: "16px" }}
          variant="outlined"
          size="3"
          radius="large"
          loading={isUploadPending}
          onClick={() => setUploadError?.(null)}
        >
          <UploadIcon size={20} />
          <Text size="3" weight="medium">
            Upload Image
          </Text>
        </AppButton>
      </Flex>
    </Box>
  );
};

export default UploadFromDeviceContent;
