import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import {
  useActiveBrandID,
  useCurrentUserDispatch,
} from "~/contexts/CurrentUserContext";

export type CampaignDetailResponse =
  operations["campaign_api_get_campaign_detail"]["responses"][200]["content"]["application/json"];

export type CampaignEmailData =
  CampaignDetailResponse["email_creatives"][number];

export type CampaignAdData = CampaignDetailResponse["ad_creatives"][number];

export const getCampaignDetailsQueryKey = (campaignId?: string) => [
  "campaign",
  campaignId,
];

const useGetCampaignDetails = ({
  campaignId,
  switchToCampaignBrand = false,
}: {
  campaignId?: string;
  switchToCampaignBrand?: boolean;
}) => {
  const activeBrandID = useActiveBrandID();
  const userDispatch = useCurrentUserDispatch();

  const campaignQuery = useQuery<CampaignDetailResponse>({
    queryKey: getCampaignDetailsQueryKey(campaignId),
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/campaign/${campaignId}`, {});
      return data;
    },
    retry: false,
    staleTime: Infinity,
    enabled: !!campaignId,
  });

  // auto switch brand the campaign does not belong to the active brand
  useEffect(() => {
    if (
      !switchToCampaignBrand ||
      !campaignQuery.isSuccess ||
      campaignQuery.data.brand_id === activeBrandID
    ) {
      return;
    }

    userDispatch({
      type: "SET_ACTIVE_BRAND_ID",
      payload: campaignQuery.data.brand_id,
    });
  }, [
    userDispatch,
    campaignQuery.isSuccess,
    campaignQuery.data?.brand_id,
    switchToCampaignBrand,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return campaignQuery;
};

export default useGetCampaignDetails;
