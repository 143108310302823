import { CustomerReview } from "../hooks/useGetProductReviews";
import { Flex, Text } from "@radix-ui/themes";
import { StarIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";

const ReviewCardContainer = styled(Flex)<{ isSelected?: boolean }>`
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  border: ${(props) =>
    props.isSelected
      ? "1px solid var(--border_hover, black)"
      : "1px solid var(--border_primary, #DDDDDD)"};
  box-shadow: ${(props) =>
    props.isSelected
      ? "0px 4px 16px 0px rgba(0, 0, 0, 0.08)"
      : "0px 2px 4px 0px rgba(0, 0, 0, 0.04)"};
  transition: all 0.3s ease;
`;

const ReviewCard = ({
  review,
  isSelected,
  onSelect,
}: {
  review: CustomerReview;
  isSelected?: boolean;
  onSelect?: (review: CustomerReview) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const totalStars = 5;
  const filledStars = Math.min(review.rating ?? 0, totalStars);

  return (
    <ReviewCardContainer
      direction="column"
      gap="2"
      isSelected={isHovered || isSelected}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onSelect?.(review)}
    >
      <Flex direction="row" justify="between" align="center">
        <Flex direction="column">
          <Text size="3" weight="bold" style={{ lineHeight: "24px" }}>
            {review.reviewer}
          </Text>
          <Text
            size="2"
            style={{
              lineHeight: "16px",
              color: "var(--text_tertiary, #888282)",
            }}
          >
            {review.review_date}
          </Text>
        </Flex>

        <Flex direction="row">
          {Array.from({ length: totalStars }, (_, index) => (
            <StarIcon
              key={index}
              style={{
                fill:
                  index < filledStars ? "#F4C23F" : "var(--icon_fill, #D1D1D1)",
                strokeOpacity: "0",
              }}
            />
          ))}
        </Flex>
      </Flex>

      <Text size="2">{review.body}</Text>
    </ReviewCardContainer>
  );
};

export default ReviewCard;
