import CurrentUser from "../auth/CurrentUser";
import { Flex } from "@radix-ui/themes";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
`;

export default function SidePane({
  header,
  navigation,
  padding = "40px",
}: {
  header: React.ReactNode;
  navigation: React.ReactNode;
  padding?: string;
}) {
  return (
    <Container
    // (safari fix) - extra parent div where position: fixed is set, because
    // safari hides overflown fixed children if the parent's overflow is hidden and
    // fixed as well
    >
      <Flex
        id="root-sidepanel"
        direction="column"
        align="center"
        style={{
          padding: padding,
          flexShrink: 0,
        }}
      >
        {header}
        {navigation}

        <CurrentUser />
      </Flex>
    </Container>
  );
}
