import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import SearchableExpandedSelect from "../../../components/campaign/wizard/inputs/SearchableExpandedSelect";
import { CampaignCardCommonProps } from "./CampaignCards";
import useCampaignLandingDestinationProps from "./hooks/useCampaignLandingDestinationProps";
import { DestinationType } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { Flex, Text, Box } from "@radix-ui/themes";
import React from "react";
import FormErrorText from "~/components/auth/FormErrorText";
import InfoText from "~/components/core/InfoText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import LandingDestinationSelector from "~/components/selectors/LandingDestinationSelector";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";

const CampaignLandingDestination: React.FC<CampaignCardCommonProps> = (
  props
) => {
  const {
    cardProps,
    inputProps: {
      destination,
      isSelecting,
      setDestination,
      setIsSelecting,
      isLandingDestinationEnabled,
      setIsLandingDestinationEnabled,
    },
  } = useCampaignLandingDestinationProps(props.step);

  const activeBrandID = useActiveBrandID();
  const { brandData } = useBrandQuery(activeBrandID);

  const text =
    destination?.type != null
      ? `Do you have a landing destination already created for this campaign? If not, the destination of this campaign will be the following ${
          destination.type === DestinationType.collection
            ? "collection"
            : "product"
        } page.`
      : "Do you have a landing destination you wish to use for this campaign? If not, we'll create a collection page for the campaign.";

  return (
    <Form.Root onSubmit={(e) => e.preventDefault()}>
      <CampaignWizardCard {...cardProps} subtitle={text} {...props}>
        <LandingDestinationSelector
          isLandingDestinationEnabled={isLandingDestinationEnabled}
          setIsLandingDestinationEnabled={setIsLandingDestinationEnabled}
          destination={destination}
          setDestination={setDestination}
          isSelecting={isSelecting}
          setIsSelecting={setIsSelecting}
          domain={brandData?.domain ?? ""}
        />
      </CampaignWizardCard>
    </Form.Root>
  );
};

export default CampaignLandingDestination;
