import {
  AdCreativeStatus,
  CampaignStatus,
  CampaignUnifiedStatus,
  components,
  EmailCreativeStatus,
} from "@openapi";
import dayjs from "dayjs";

export const getUnpublishedCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return {
    emails: campaign.email_creatives.filter(
      ({ status }) =>
        status === EmailCreativeStatus.draft ||
        status === EmailCreativeStatus.unpublished
    ),
    ads: campaign.ad_creatives.filter(
      ({ status }) => status === AdCreativeStatus.unpublished
    ),
  };
};

export const getPublishedCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return {
    emails: campaign.email_creatives.filter(
      ({ status }) =>
        status !== EmailCreativeStatus.draft &&
        status !== EmailCreativeStatus.unpublished
    ),
    ads: campaign.ad_creatives.filter(
      ({ status }) => status !== AdCreativeStatus.unpublished
    ),
  };
};

export const areAllCreativesPublished = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return (
    getPublishedCreatives(campaign).emails.length ===
      campaign.email_creatives.length &&
    getPublishedCreatives(campaign).ads.length === campaign.ad_creatives.length
  );
};

export const hasAnyCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return (
    campaign.email_creatives.length > 0 || campaign.ad_creatives.length > 0
  );
};

export const getCampaignUnifiedStatus = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  const hasCreatives =
    campaign.email_creatives.length > 0 || campaign.ad_creatives.length > 0;
  const isPast = dayjs(campaign.date)
    .startOf("day")
    .isBefore(dayjs().startOf("day"));

  // NOTE: Make sure to match this with logic in campaign/api.py
  let status = null;
  if (campaign.status === CampaignStatus.planned) {
    if (isPast) {
      status = CampaignUnifiedStatus.past;
    } else {
      status = CampaignUnifiedStatus.planned;
    }
  } else if (!hasCreatives) {
    status = CampaignUnifiedStatus.in_progress;
  } else if (
    (campaign.email_creatives.length > 0 &&
      campaign.email_creatives.some((creative) => creative.published_at)) ||
    campaign.ad_creatives.length > 0
  ) {
    status = CampaignUnifiedStatus.launched;
  } else if (
    (campaign.email_creatives.length > 0 &&
      campaign.email_creatives.every((creative) => !creative.published_at)) ||
    campaign.ad_creatives.length > 0
  ) {
    status = CampaignUnifiedStatus.designed;
  }

  return status;
};

export const getCampaignUnifiedStatusFromRecommendation = (
  recommendation: components["schemas"]["CampaignRecommendationSchema"]
) => {
  const recommendationDate = dayjs(recommendation.date).startOf("day");
  const today = dayjs().startOf("day");

  if (recommendationDate.isBefore(today)) {
    return CampaignUnifiedStatus.past;
  }
  return CampaignUnifiedStatus.planned;
};
