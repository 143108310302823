import axios from "axios";
import Cookies from "js-cookie";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import NewAudienceDialog from "~/components/dialogs/NewAudienceDialog";
import StyleLibraryCardContent from "~/components/style-library/StyleLibraryCardContent";
import { useQuery } from "@tanstack/react-query";
import { Box, Flex, IconButton, Text } from "@radix-ui/themes";
import { useState, useEffect } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { TargetGoalIcon, UsersIcon, ArrowRotateIcon, GlobalSearchIcon, AwardMedalIcon, PlusIcon, MenuBoardSquarePlusAddIcon, PencilIcon } from "~/assets/icons";
import { AudienceCategory, components, operations } from "@openapi";

type BrandAudience = components["schemas"]["BrandAudienceSchema"];
type GetBrandAudiencesResponse = operations["brand_api_get_brand_audiences"]["responses"]["200"]["content"]["application/json"];

const AudienceCategorySection = ({ category }: { category: AudienceCategory }) => {
  const activeBrandID = useActiveBrandID();
  const [page, setPage] = useState(0);
  const [allAudiences, setAllAudiences] = useState<BrandAudience[]>([]);
  const PAGE_SIZE = 6;

  const displayTitle = {
    demographics: "Demographics",
    geographic: "Geographic",
    lifecycle: "Lifecycle",
    loyalty: "Loyalty",
    custom: "Custom",
  }[category];

  const description = {
    demographics: "Are there audiences of people that have different age and gender characteristics?",
    geographic: "Are there audiences of people that live in different locations?",
    lifecycle: "Are there audiences of people that should be marketed to differently based on their stage in the customer journey?",
    loyalty: "Are there audiences of people you'd like to reward or speak to differently?",
    custom: "Audiences that don't fit into another category. For example, these could be based on self-identified preferences from an on-site quiz or post-purchase survey.",
  }[category];

  const getCategoryIcon = () => {
    switch (category) {
      case AudienceCategory.custom:
        return <TargetGoalIcon />;
      case AudienceCategory.demographics:
        return <UsersIcon />;
      case AudienceCategory.geographic:
        return <GlobalSearchIcon />;
      case AudienceCategory.lifecycle:
        return <ArrowRotateIcon />;
      case AudienceCategory.loyalty:
        return <AwardMedalIcon />;
      default:
        return null;
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["brand-audiences", activeBrandID, category, page],
    queryFn: async (): Promise<GetBrandAudiencesResponse> => {
      if (!activeBrandID) {
        throw new Error("No brand ID provided");
      }

      const { data } = await axios.get(
        `/api/v1/brand/${activeBrandID}/audiences?category=${category}&page=${page}&page_size=${PAGE_SIZE}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    enabled: !!activeBrandID,
    staleTime: 3600000,
  });

  useEffect(() => {
    if (data) {
      if (page === 0) {
        setAllAudiences(data.audiences);
      } else {
        setAllAudiences(prev => {
          const existingIds = new Set(prev.map(a => a.id));
          const newAudiences = data.audiences.filter(a => !existingIds.has(a.id));
          return [...prev, ...newAudiences];
        });
      }
    }
  }, [data, page]);

  const hasMore = data?.has_more || false;

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <StyleLibraryCardContent
      title={displayTitle}
      icon={getCategoryIcon()}
      actions={
        <NewAudienceDialog
          category={category}
          trigger={
            <AppButton variant="ghost" size={"3"}>
              <PlusIcon
                style={{
                  padding: "0.1rem",
                }}
              />
              Add Audience
            </AppButton>
          }
          onCreate={(brandAudience: BrandAudience) => {
            setAllAudiences(prev => {
              const filtered = prev.filter(a => a.id !== brandAudience.id);
              return [brandAudience, ...filtered];
            });
          }}
        />
      }
      isLoading={isLoading && page === 0}
    >
      <Text color="gray" mb="4">{description}</Text>
      <Box
        style={{
          borderTop: '1px solid var(--border-secondary)',
          marginLeft: '-24px',
          marginRight: '-24px',
        }}
      />
      {allAudiences.length > 0 ? (
        <>
          <Flex wrap="wrap" gap="4">
            {allAudiences.map((audience) => (
              <AudienceCard
                key={audience.id}
                audience={audience}
                onUpdate={(brandAudience) => {
                  setAllAudiences(prev => {
                    return prev.map(a => a.id === brandAudience.id ? brandAudience : a);
                  });
                }}
                onDelete={(brandAudience) => {
                  setAllAudiences(prev => prev.filter(a => a.id !== brandAudience.id));
                }}
              />
            ))}
          </Flex>
          {hasMore && (
            <Flex justify="center" mt="4">
              <AppButton variant="outlined" size="2" onClick={handleLoadMore} loading={isLoading && page > 0}>
                See More
              </AppButton>
            </Flex>
          )}
        </>
      ) : (
        <Flex
          align="center"
          justify="center"
          direction="column"
          gap="2"
          p="6"
          style={{
            minHeight: '120px'
          }}
        >
          <MenuBoardSquarePlusAddIcon />
          <Text style={{ color: 'var(--text-tertiary)' }}>No audiences added</Text>
        </Flex>
      )}
    </StyleLibraryCardContent>
  );
};

const AudienceCard = (
  {
    audience,
    onUpdate,
    onDelete
  }: { audience: BrandAudience; onUpdate?: (audience: BrandAudience) => void; onDelete?: (audience: BrandAudience) => void }
) => {
  const isConnected = audience.cdp_audiences.length > 0;

  return (
    <Flex
      direction="column"
      p="3"
      style={{
        border: "1px solid var(--border-secondary)",
        borderRadius: "12px",
        width: "calc(33.33% - 16px)",
        minWidth: "200px",
        position: "relative",
        height: "100px",
      }}
    >
      <Flex justify="between" align="start" mb="2">
        <Text weight="medium" size="3">{audience.title}</Text>
        <Text size="1" color={isConnected ? "green" : "red"}>
          {isConnected ? "Connected" : "Not connected"}
        </Text>
      </Flex>
      <Flex justify="between">
        <Text
          size="1"
          mr="2"
          style={{
            color: "var(--text-tertiary)",
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1.3em',
            maxHeight: '2.6em'
          }}
        >
          {audience.description}
        </Text>
        <Flex justify="end">
          <NewAudienceDialog
            audience={audience}
            category={audience.category}
            trigger={
              <IconButton
                style={{
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                color="gray"
                variant="outline"
              >
                <PencilIcon />
              </IconButton>
            }
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const sections: (() => JSX.Element)[] = [
  () => <AudienceCategorySection key={AudienceCategory.custom} category={AudienceCategory.custom} />,
  () => <AudienceCategorySection key={AudienceCategory.demographics} category={AudienceCategory.demographics} />,
  () => <AudienceCategorySection key={AudienceCategory.geographic} category={AudienceCategory.geographic} />,
  () => <AudienceCategorySection key={AudienceCategory.lifecycle} category={AudienceCategory.lifecycle} />,
  () => <AudienceCategorySection key={AudienceCategory.loyalty} category={AudienceCategory.loyalty} />,
];

export default sections;
