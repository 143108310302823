import HomeTopBar from "./visuals/HomeTopBar";
import { Flex } from "@radix-ui/themes";
import { useGateValue } from "@statsig/react-bindings";
import { Outlet } from "react-router-dom";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import RecommenderSection from "~/components/home/components/recommenderSection/RecommenderSection";

export default function HomeRoute() {
  const isNewHomePage = useGateValue("is_new_home_ui", {
    disableExposureLog: true,
  });

  return (
    <Flex
      gap="6"
      direction="column"
      maxWidth="1248px"
      minWidth="680px"
      width="100%"
      style={{
        padding: "0px 24px 24px 24px",
        alignItems: "center",
        alignContent: "center",
        margin: "0 auto",
      }}
    >
      <BackgroundGradient />
      <HomeTopBar />
      {isNewHomePage ? <RecommenderSection /> : <Outlet />}
    </Flex>
  );
}
