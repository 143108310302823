import {
  CampaignBriefOverviewSection,
  SectionContent,
} from "../CampaignBriefOverview";
import { components } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { MailIcon } from "~/assets/icons";
import CheckboxWithQuantity from "~/components/campaign/wizard/inputs/CheckboxWithQuantity";
import MetaLogo from "~/components/logos/MetaLogo";
import {
  ChannelType,
  useCampaignWizardUpdateCampaignData,
} from "~/contexts/CampaignWizardContext";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { getCampaignRecommendationQueryKey } from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

export const CampaignBriefOverviewChannelsSection = ({
  campaignRecommendationID,
}: {
  campaignRecommendationID: string;
}) => {
  const activeBrandID = useActiveBrandID();
  const queryClient = useQueryClient();
  const { campaignData } = useCampaignWizardState();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();

  const [localChannels, setLocalChannels] = useState<
    components["schemas"]["CampaignChannelsCountRequestData"] | undefined
  >({
    emails: 0,
    meta_ads: 0,
    push_notifications: 0,
    sms: 0,
  });

  const channels = campaignData.channels;

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onMutate: () => {
        if (localChannels) {
          updateCampaignData({
            channels: {
              ...localChannels,
              sms: 0,
              push_notifications: 0,
            },
          });
        }

        const previousCampaignRecommendation = queryClient.getQueryData(
          getCampaignRecommendationQueryKey(
            activeBrandID,
            campaignRecommendationID
          )
        );

        return { previousCampaignRecommendation };
      },
      onError: (error, variables, context) => {
        if (context?.previousCampaignRecommendation) {
          updateCampaignData({
            channels: {
              emails: context.previousCampaignRecommendation.channel_emails,
              meta_ads: context.previousCampaignRecommendation.channel_meta_ads,
              sms: 0,
              push_notifications: 0,
            },
          });
        }
      },
    });

  const onSyncChannelsState = (
    channels:
      | components["schemas"]["CampaignChannelsCountRequestData"]
      | undefined
  ) => {
    setLocalChannels(channels);
  };

  return (
    <CampaignBriefOverviewSection
      title="Channels"
      isLoading={isLoading}
      disabled={localChannels?.emails === 0 && localChannels?.meta_ads === 0}
      editDialogBody={
        <Flex direction="column" gap="24px">
          {localChannels &&
            Object.entries(localChannels)
              .filter(([key]) => key === "emails" || key === "meta_ads")
              .map(([key, value]) => (
                <CheckboxWithQuantity
                  key={key}
                  label={
                    key === "emails"
                      ? "Email"
                      : key === "meta_ads"
                      ? "Meta Ads"
                      : ""
                  }
                  quantity={value}
                  checked={value > 0}
                  setChecked={(checked) => {
                    if (!checked) {
                      setLocalChannels({ ...localChannels, [key]: 0 });
                    }
                  }}
                  setQuantity={(quantity) => {
                    setLocalChannels({ ...localChannels, [key]: quantity });
                  }}
                />
              ))}
        </Flex>
      }
      onEdit={() => {
        onSyncChannelsState(channels);
      }}
      onSave={() => {
        updateCampaignRecommendation({
          campaign_recommendation_id: campaignRecommendationID,
          brand_id: activeBrandID,
          channel_emails: localChannels?.emails,
          channel_meta_ads: localChannels?.meta_ads,
        });
      }}
    >
      <Flex direction="column" gap="8px">
        <SectionContent>
          <Flex direction="row" gap="6">
            {Object.entries(channels || {})
              .filter(
                ([key, value]) =>
                  (key === "emails" || key === "meta_ads") && value > 0
              )
              .map(([key, value]) => {
                const channelType =
                  key === "emails" ? ChannelType.EMAIL : ChannelType.META_ADS;
                return (
                  <Flex key={channelType} align="center" gap="2">
                    {channelType === ChannelType.EMAIL ? (
                      <MailIcon />
                    ) : (
                      <MetaLogo color="rgb(92, 88, 88)" size={24} />
                    )}
                    <Text>{channelType}</Text>
                  </Flex>
                );
              })}
          </Flex>
        </SectionContent>
      </Flex>
    </CampaignBriefOverviewSection>
  );
};
