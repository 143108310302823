import { Text, TextProps } from "@radix-ui/themes";
import React, { useMemo } from "react";
import styled, { CSSProperties } from "styled-components";
import { assertNever } from "~/utils/typeUtils";

const TextClamp = styled(Text)<{
  $clamp?: number;
  $wordBreak?: CSSProperties["wordBreak"];
  $color?: CSSProperties["color"];
  $lineHeight?: CSSProperties["lineHeight"];
  $type?: "text" | "heading";
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ $clamp }) => $clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${({ $wordBreak }) => $wordBreak || "break-all"};
  max-width: 100%;
  color: ${({ $color }) => $color};
  line-height: ${({ $lineHeight }) => $lineHeight};
  ${({ $type }) =>
    $type === "heading" &&
    `
    font-weight: 600;
    font-family: var(--heading-font-family);
  `}
`;

type DraperTextProps = TextProps & {
  clamp?: number;
  colorOverride?: string;
  variant?: "primary" | "secondary" | "tertiary" | "error" | "accent";
  wordBreak?: CSSProperties["wordBreak"];
  type?: "text" | "heading";
  lineHeight?: CSSProperties["lineHeight"];
};

const DraperText = React.forwardRef<HTMLDivElement, DraperTextProps>(
  (
    {
      colorOverride,
      clamp,
      variant = "primary",
      type = "text",
      wordBreak,
      weight,
      lineHeight,
      ...props
    },
    ref
  ) => {
    const color = useMemo(() => {
      if (colorOverride) return colorOverride;
      switch (variant) {
        case "primary":
          return "var(--text-primary)";
        case "secondary":
          return "var(--text-secondary)";
        case "tertiary":
          return "var(--text-tertiary)";
        case "error":
          return "var(--text-error)";
        case "accent":
          return "var(--primary-vibrant-orange)";
        default:
          assertNever(variant);
          return "var(--text-primary)";
      }
    }, [variant, colorOverride]);
    return (
      <TextClamp
        {...props}
        ref={ref}
        $color={color}
        $clamp={clamp}
        $wordBreak={wordBreak}
        $type={type}
        $lineHeight={lineHeight}
      >
        {props.children}
      </TextClamp>
    );
  }
);

export const DraperHeading = React.forwardRef<
  HTMLDivElement,
  Omit<DraperTextProps, "type" | "ref" | "as">
>(({ ...props }, ref) => {
  return (
    <DraperText {...props} ref={ref} type="heading">
      {props.children}
    </DraperText>
  );
});

export default DraperText;
