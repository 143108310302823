import React from "react";
import SidePanelComponents from "~/components/core/editor/sidepane";

type ModifierContainerProps = Pick<
  React.ComponentProps<typeof SidePanelComponents.EditorCard>,
  "children" | "title" | "switchDisabled" | "backgroundColor" | "padding"
> &
  (
    | {
        hideSwitch: true;
        expanded?: boolean;
        onExpandedChange?: (expanded: boolean) => void;
      }
    | {
        hideSwitch: false | undefined;
        checked?: boolean;
        onSwitch: (checked: boolean) => void;
      }
  );

const ModifierContainer: React.FC<ModifierContainerProps> = ({
  title,
  children,
  switchDisabled,
  backgroundColor,
  ...props
}) => {
  const translatedProps: React.ComponentProps<
    typeof SidePanelComponents.EditorCard
  > = {
    title,
    children,
    switchDisabled,
    backgroundColor,
    padding: props.padding,
    ...(props.hideSwitch === true
      ? {
          checkable: false,
          expanded: props.expanded,
          onExpandedChange: props.onExpandedChange,
        }
      : {
          checkable: true,
          checked: props.checked ?? true,
          onCheckedChange: props.onSwitch,
        }),
  };
  return <SidePanelComponents.EditorCard {...translatedProps} />;
};

export default ModifierContainer;
