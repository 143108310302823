import LoadingDots from "../campaign/wizard/misc/LoadingDots";
import MarkAvatar from "../core/avatars/MarkAvatar";
import AppButton from "../core/buttons/AppButton/AppButton";
import EmailSlider from "./EmailSlider";
import { Flex, Text } from "@radix-ui/themes";
import { ArrowRightIcon, ThumbsDown, Trophy } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useFetchEmailInsights from "~/hooks/emails/useFetchEmailInsights";
import { useEmailInsightsStatus } from "~/hooks/useEmailInsightsStatus";
import { HOME_ROUTE } from "~/routes/constants";

const Container = styled(Flex)`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
`;

const Card = styled(Flex)`
  width: 60vw;
  max-width: 1000px;
  background-color: white;
  border-radius: 16px;
  position: relative;
`;

const SliderContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--border_light, #f0f0f0);
  border-bottom: 1px solid var(--border_light, #f0f0f0);
  background: var(--background_soft, #fcfbfa);
  position: relative;
  padding: 32px;
`;

const Button = styled(AppButton)`
  width: max-content;
`;

const Item = styled(Flex)`
  gap: 16px;
  align-items: start;
`;

const ItemNumber = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: var(--text_primary, #121111);
  background: #edebf0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  line-height: 1;
`;

const ItemText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: var(--text_primary, #121111);
  text-align: justify;
`;

const Header = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  color: var(--text_primary, #121111);
`;

const Avatar = styled(MarkAvatar)`
  position: absolute;
  top: -32px;
  left: -32px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
`;

const EmailInsightCard = () => {
  const activeBrandID = useActiveBrandID();
  const navigate = useNavigate();
  const [jobSessionId, setJobSessionId] = useState<string>();
  const { data: initialJobData } = useFetchEmailInsights(activeBrandID);
  const [showSuccessfulEmails, setShowSuccessfulEmails] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (initialJobData?.job_session_id) {
      setJobSessionId(initialJobData.job_session_id);
    }
  }, [initialJobData]);

  const { data: emailInsightsStatus } = useEmailInsightsStatus(
    activeBrandID,
    jobSessionId,
    !!jobSessionId
  );

  const emailInsights = useMemo(() => {
    if (!emailInsightsStatus) {
      return null;
    }

    setIsLoading(!emailInsightsStatus.is_finished);
    return emailInsightsStatus?.email_insights;
  }, [emailInsightsStatus]);

  const hasEmails = useMemo(() => {
    return !!emailInsights?.email_insights.emails.length;
  }, [emailInsights]);

  const insights = useMemo(() => {
    if (!emailInsights) {
      return [];
    }

    return showSuccessfulEmails
      ? emailInsights.email_insights.successful_insights
      : emailInsights.email_insights.non_successful_insights;
  }, [emailInsights, showSuccessfulEmails]);

  const headerComponent = useMemo(() => {
    if (!emailInsights) {
      return <></>;
    }

    const emailText =
      emailInsights.total_emails_sent === 1 ? "email" : "emails";

    if (!hasEmails) {
      return (
        <Header
          style={{
            padding: "40px 32px 48px 32px",
          }}
        >
          You've sent {emailInsights.total_emails_sent} {emailText} over the
          past 12 months.
        </Header>
      );
    }

    return (
      <Header
        style={{
          padding: "40px 32px 48px 32px",
        }}
      >
        You've sent {emailInsights.total_emails_sent} {emailText} over the past
        12 months. First, let's take a look at your best performing email
        campaigns.
      </Header>
    );
  }, [emailInsights, hasEmails]);

  const sliderComponent = useMemo(() => {
    if (!hasEmails) {
      return <></>;
    }

    return (
      <SliderContainer direction="column">
        <EmailSlider
          title={
            showSuccessfulEmails ? (
              <>
                <Trophy size={20} />
                Top Emails
              </>
            ) : (
              <>
                <ThumbsDown size={20} />
                Worst Emails
              </>
            )
          }
          emails={
            emailInsights?.email_insights.emails
              .filter((email) => email.is_successful === showSuccessfulEmails)
              .sort((a, b) => a.position - b.position)
              .map((email) => ({
                subject: email.subject,
                preview: email.preview,
                emailContent: email.email_content,
                openRate: email.openRate ?? 0,
                revenue: email.revenue ?? 0,
              })) ?? []
          }
        />
      </SliderContainer>
    );
  }, [emailInsights, showSuccessfulEmails, hasEmails]);

  const insightsComponent = useMemo(() => {
    if (!hasEmails) {
      return <></>;
    }

    return (
      <Flex direction="column" gap="4" style={{ padding: "32px" }}>
        <Header>
          Here are the top insights on what is working across all your
          campaigns:
        </Header>

        <Flex direction="column" gap="24px">
          {insights.map((item, index) => (
            <Item direction="row" key={index.toString()}>
              <ItemNumber>{index + 1}</ItemNumber>
              <ItemText>{item.recommendation}</ItemText>
            </Item>
          ))}
        </Flex>
      </Flex>
    );
  }, [insights, hasEmails]);

  const footerComponent = useMemo(() => {
    const handleClick = () => {
      if (showSuccessfulEmails && hasEmails) {
        setShowSuccessfulEmails(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        navigate(HOME_ROUTE);
      }
    };

    const buttonContent = !showSuccessfulEmails ? (
      <Text>Close</Text>
    ) : (
      <>
        <Text>Show Worst Emails</Text>
        <ArrowRightIcon size={20} strokeWidth={1.5} />
      </>
    );

    if (!hasEmails) {
      return (
        <Flex justify="end" style={{ padding: "32px" }}>
          <Button radius="large" onClick={handleClick}>
            <Text>Close</Text>
          </Button>
        </Flex>
      );
    }

    return (
      <Flex justify="end" style={{ padding: "32px" }}>
        <Button radius="large" onClick={handleClick}>
          {buttonContent}
        </Button>
      </Flex>
    );
  }, [emailInsights, showSuccessfulEmails, hasEmails]);

  return (
    <Container>
      <Card direction="column">
        <Avatar />
        {isLoading ? (
          <Flex height="200px" justify="center" align="center">
            <Flex>
              <LoadingDots />
            </Flex>
          </Flex>
        ) : (
          <>
            {headerComponent}
            {sliderComponent}
            {insightsComponent}
            {footerComponent}
          </>
        )}
      </Card>
    </Container>
  );
};

export default EmailInsightCard;
