import ProductCard from "../../../../components/campaign/wizard/products/components/ProductCard";
import NumberedPaginator from "../../../../components/campaign/wizard/products/components/numberedPaginator/NumberedPaginator";
import ProductSelectorDialog, {
  MAX_FEATURED_PRODUCTS,
} from "../../../../components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { CampaignBriefOverviewSection } from "../CampaignBriefOverview";
import { MaybeFeaturedProduct } from "../CampaignProducts";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useCampaignWizardUpdateCampaignData } from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  CampaignRecommendationResponse,
  getCampaignRecommendationQueryKey,
} from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

const PAGE_SIZE = 9;

export const CampaignBriefOverviewProductsSection = ({
  campaignRecommendation,
}: {
  campaignRecommendation: CampaignRecommendationResponse;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();

  const [localProducts, setLocalProducts] = useState<
    Map<string, MaybeFeaturedProduct>
  >(new Map());
  const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false);
  const [selectedProductsPage, setSelectedProductsPage] = useState(0);

  const handleConfirmProducts = (
    newProducts: Map<string, MaybeFeaturedProduct>
  ) => {
    setLocalProducts(newProducts);
    setSelectedProductsPage(Math.floor((newProducts.size - 1) / PAGE_SIZE));
    setIsProductSelectorOpen(false);
  };
  const handleDeselect = (id: string) => {
    if (!localProducts.has(id)) {
      return;
    }

    updateCampaignRecommendation({
      campaign_recommendation_id: campaignRecommendation.id,
      brand_id: activeBrandID,
      commerce_platform_product_ids: Array.from(localProducts.values())
        .filter((p) => p.product_id !== id)
        .map((p) => p.product_id),
    });

    const lastPage = Math.floor((localProducts.size - 2) / PAGE_SIZE);
    setLocalProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
    if (selectedProductsPage > lastPage) {
      setSelectedProductsPage((prev) => Math.max(0, prev - 1));
    }
  };

  const onFeatureToggle = (product: MaybeFeaturedProduct) => {
    setLocalProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(product.product_id, {
        ...product,
        is_featured: !product.is_featured,
      });
      return updatedMap;
    });
  };

  useEffect(() => {
    setLocalProducts(
      new Map(
        campaignRecommendation.products?.map((product) => [
          product.product_id,
          {
            ...product,
            is_featured: false,
          },
        ]) ?? []
      )
    );
  }, []);

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onSettled: () => {}, // We need this to override the default onSettled
      onSuccess: (data) => {
        updateCampaignData({
          products: Array.from(localProducts.values()),
        });

        // Manually trigger the creative recommendation regeneration
        queryClient.setQueryData(
          getCampaignRecommendationQueryKey(
            activeBrandID,
            campaignRecommendation.id
          ),
          (oldData: CampaignRecommendationResponse) => {
            return {
              ...oldData,
              active_creative_recommendation: null,
            };
          }
        );
      },
    });

  const featuredProductsCount = useMemo(() => {
    return Array.from(localProducts.values()).filter((p) => p.is_featured)
      .length;
  }, [localProducts]);

  return (
    <CampaignBriefOverviewSection
      title="Products"
      isLoading={isLoading}
      onEdit={
        <ProductSelectorDialog
          initialSelectedProducts={localProducts}
          onConfirm={(selectedProducts) => {
            updateCampaignRecommendation({
              campaign_recommendation_id: campaignRecommendation.id,
              brand_id: activeBrandID,
              commerce_platform_product_ids: Array.from(
                selectedProducts.values()
              ).map((p) => p.product_id),
            });

            handleConfirmProducts(selectedProducts);
          }}
          isOpen={isProductSelectorOpen}
          onOpenChange={setIsProductSelectorOpen}
          isFeatureEnabled={true}
          triggerComponent={
            <AppButton
              variant="outlined"
              size="2"
              radius="large"
              style={{
                borderRadius: "12px",
              }}
              disabled={isLoading}
            >
              <Pencil size={16} /> Edit
            </AppButton>
          }
        />
      }
    >
      <Text size="1" color="gray">
        {localProducts.size} products
      </Text>
      <Flex direction="column" gap="4">
        <Grid gap="3" columns="3" width="auto">
          {Array.from(localProducts)
            .slice(
              selectedProductsPage * PAGE_SIZE,
              selectedProductsPage * PAGE_SIZE + PAGE_SIZE
            )
            .map(([id, product]) => (
              <ProductCard
                key={id}
                onClick={handleDeselect}
                onFeatureToggle={onFeatureToggle}
                data={product}
                isFeatureDisabled={
                  featuredProductsCount >= MAX_FEATURED_PRODUCTS
                }
              />
            ))}
        </Grid>
      </Flex>
      <Flex direction="row" justify="between">
        {localProducts.size > PAGE_SIZE ? (
          <NumberedPaginator
            totalPages={Math.ceil(localProducts.size / PAGE_SIZE)}
            currentPage={selectedProductsPage}
            setCurrentPage={setSelectedProductsPage}
          />
        ) : (
          <div />
        )}
      </Flex>
    </CampaignBriefOverviewSection>
  );
};
