import { useGateValue } from "@statsig/react-bindings";
import { STYLE_LIBRARY_ROUTE } from "../constants";
import audiencesPageSections from "./audiences/sections";
import brandVoicePageSections from "./brand-voice/sections";
import visualPageSections from "./visuals/sections";
import { Flex, Heading, SegmentedControl } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import {
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import MarkAnnouncement from "~/components/home/components/top-right-menu/MarkAnnouncement";
import StyleLibraryPage from "~/components/style-library/StyleLibraryPage";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import BrandVoiceProvider from "~/contexts/BrandVoiceContext";
import { useCurrentUserState, User } from "~/contexts/CurrentUserContext";
import { useMarkPopupContext } from "~/contexts/MarkPopupContext";

enum Routes {
  VISUALS = "",
  VOICE = "voice",
  AUDIENCES = "audiences",
}

const getFullRelativePath = (route: Routes) => {
  return `${STYLE_LIBRARY_ROUTE}/${route}`;
};


interface StyleLibraryTab {
  route: Routes;
  relativePath: string;
  label: string;
}

const getStyleLibraryTabs = (is_audiences_enabled: boolean): StyleLibraryTab[] => {

  const tabs: StyleLibraryTab[] = [
    {
      route: Routes.VISUALS,
      relativePath: getFullRelativePath(Routes.VISUALS),
      label: "Visuals",
    },
    {
      route: Routes.VOICE,
      relativePath: getFullRelativePath(Routes.VOICE),
      label: "Voice",
    },
  ];

  if (is_audiences_enabled) {
    tabs.push({
      route: Routes.AUDIENCES,
      relativePath: getFullRelativePath(Routes.AUDIENCES),
      label: "Audiences",
    });
  }

  return tabs;
};

export const getStyleLibraryRoutes = (): RouteObject[] => [
  {
    path: Routes.VISUALS,
    element: <StyleLibraryPage sections={visualPageSections} />,
  },
  {
    path: Routes.VOICE,
    element: (
      <BrandVoiceProvider>
        <StyleLibraryPage sections={brandVoicePageSections} />
      </BrandVoiceProvider>
    ),
  },
  {
    path: Routes.AUDIENCES,
    element: <StyleLibraryPage sections={audiencesPageSections} />,
  },
];

const StyleLibraryRoute = () => {
  // note that we can't use `Routes` as the value, as the radix-ui SegmentedControl doesn't play well with "" as value
  const { user } = useCurrentUserState();
  const [activeTab, setActiveTab] = useState(getFullRelativePath(Routes.VISUALS));
  const location = useLocation();
  const navigate = useNavigate();
  const { state: popupState } = useMarkPopupContext();
  const is_audiences_enabled = useGateValue(
    "is_audiences_enabled",
    {
      disableExposureLog: true,
    }
  );
  const TABS = getStyleLibraryTabs(is_audiences_enabled);

  useEffect(() => {
    const path = location.pathname;

    if (path.includes(Routes.VOICE)) {
      setActiveTab(getFullRelativePath(Routes.VOICE));
    } else if (path.includes(Routes.AUDIENCES)) {
      setActiveTab(getFullRelativePath(Routes.AUDIENCES));
    } else {
      setActiveTab(getFullRelativePath(Routes.VISUALS));
    }
  }, [location]);

  const handleTabChange = (relativePath: string) => {
    setActiveTab(relativePath);
    navigate(relativePath);
  };
  return (
    <BrandStylingProvider>
      <Flex m="6" direction="column" gap="5">
        <BackgroundGradient variant="3" />
        <Flex
          direction="row"
          justify="between"
          align="center"
          position="relative"
          mb={popupState.markPopupOpen ? "100px" : "0px"}
        >
          <Flex direction="row" gap="5" align="center">
            <Heading size="6">My Brand</Heading>
            <DraperSegmentControl
              $size="large"
              $color="var(--text-secondary)"
              value={activeTab}
              radius="large"
              size="3"
              onValueChange={handleTabChange}
              style={{ backgroundColor: "var(--black-8, #00000014)" }}
            >
              {TABS.map((tab) => (
                <SegmentedControl.Item value={tab.relativePath} key={tab.route}>
                  <Heading
                    size="5"
                    weight="medium"
                    style={{
                      padding: "8px",
                    }}
                  >
                    {tab.label}
                  </Heading>
                </SegmentedControl.Item>
              ))}
            </DraperSegmentControl>
          </Flex>
          {popupState.message && <MarkAnnouncement />}
        </Flex>

        <Outlet />
      </Flex>
    </BrandStylingProvider>
  );
};

export default StyleLibraryRoute;
