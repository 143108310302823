import ModifierContainer from "./ModifierContainer";
import {
  CSSJustifyContent,
  CSSAlignItems,
  CSSBorderStyle,
  EmailSectionWidthType,
} from "@openapi";
import { Flex, SegmentedControl, Select, Text } from "@radix-ui/themes";
import {
  AlignStartVertical,
  AlignEndVertical,
  AlignCenterVertical,
  AlignVerticalJustifyStart,
  AlignVerticalJustifyEnd,
  AlignVerticalJustifyCenter,
  AlignVerticalSpaceAround,
  AlignVerticalSpaceBetween,
} from "lucide-react";
import { useEffect, useState } from "react";
import { FullWidthIcon, CompactWidthIcon } from "~/assets/icons";
import DraperSegmentedControl from "~/components/core/DraperSegmentedControl";
import { getPickerColor } from "~/components/core/inputs/ColorPicker";
import NumberInput from "~/components/core/inputs/NumberInput";
import AddButtonPadding from "~/components/style-library/button-styles/dialogs/AddButtonPadding";
import ColorBox from "~/components/style-library/color-palette/ColorBox";
import { useUpdateSectionField } from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  EmailHeroSectionSchema,
  EmailSalesEventHeroSectionSchema,
} from "~/utils/emails/useSectionTypeCheck";

const VERTICAL_ALIGNMENT_OPTIONS = [
  {
    label: CSSJustifyContent.start,
    icon: AlignVerticalJustifyStart,
  },
  {
    label: CSSJustifyContent.center,
    icon: AlignVerticalJustifyCenter,
  },
  {
    label: CSSJustifyContent.end,
    icon: AlignVerticalJustifyEnd,
  },
  {
    label: CSSJustifyContent.space_between,
    icon: AlignVerticalSpaceBetween,
  },
  {
    label: CSSJustifyContent.space_around,
    icon: AlignVerticalSpaceAround,
  },

  {
    label: CSSJustifyContent.space_evenly,
    icon: AlignVerticalSpaceAround,
  },
];

const HORIZONTAL_ALIGNMENT_OPTIONS = [
  {
    label: CSSAlignItems.start,
    icon: AlignStartVertical,
  },
  {
    label: CSSAlignItems.center,
    icon: AlignCenterVertical,
  },
  {
    label: CSSAlignItems.end,
    icon: AlignEndVertical,
  },
];

const WIDTH_OPTIONS = [
  {
    label: EmailSectionWidthType.full,
    icon: FullWidthIcon,
  },
  {
    label: EmailSectionWidthType.contained,
    icon: CompactWidthIcon,
  },
];

function getVerticalAlignmentLabel(justifyContent: CSSJustifyContent) {
  switch (justifyContent) {
    case CSSJustifyContent.start:
      return "Top";
    case CSSJustifyContent.center:
      return "Center";
    case CSSJustifyContent.end:
      return "Bottom";
    case CSSJustifyContent.space_between:
      return "Space Between";
    case CSSJustifyContent.space_around:
      return "Space Around";
    case CSSJustifyContent.space_evenly:
      return "Space Evenly";
    default:
      return justifyContent;
  }
}

function getHorizontalAlignmentLabel(alignItems: CSSAlignItems) {
  switch (alignItems) {
    case CSSAlignItems.start:
      return "Left";
    case CSSAlignItems.center:
      return "Center";
    case CSSAlignItems.end:
      return "Right";
    default:
      return alignItems;
  }
}

function getWidthLabel(width: EmailSectionWidthType) {
  switch (width) {
    case EmailSectionWidthType.full:
      return "Full";
    case EmailSectionWidthType.contained:
      return "Compact";
    default:
      return width;
  }
}

const HeroLayoutModifier = ({
  selectedSection,
}: {
  selectedSection: EmailHeroSectionSchema | EmailSalesEventHeroSectionSchema;
}) => {
  const isBaseTemplate = selectedSection.template.is_base_template;

  const updateSection = useUpdateSectionField();

  const [expanded, setExpanded] = useState(true);
  const [justifyContent, setJustifyContent] = useState(
    selectedSection.justify_content ?? CSSJustifyContent.center
  );
  const [horizontalAlignment, setHorizontalAlignment] = useState(
    selectedSection.align_items ?? CSSAlignItems.center
  );
  const [width, setWidth] = useState(
    selectedSection.width === "100%" || selectedSection.width === null
      ? EmailSectionWidthType.full
      : EmailSectionWidthType.contained
  );
  const [borderStyle, setBorderStyle] = useState(
    selectedSection.image.border_style
  );
  const [borderColor, setBorderColor] = useState(
    getPickerColor(selectedSection.image.border_color) ?? "#000000"
  );
  const [borderWidth, setBorderWidth] = useState(
    selectedSection.image.border_width
  );

  const [padding, setPadding] = useState<{
    top: number;
    right: number;
    bottom: number;
    left: number;
  }>({
    top: parseInt(selectedSection.image.padding_top ?? "0"),
    right: parseInt(selectedSection.image.padding_right ?? "0"),
    bottom: parseInt(selectedSection.image.padding_bottom ?? "0"),
    left: parseInt(selectedSection.image.padding_left ?? "0"),
  });
  const [imageCornerRadius, setImageCornerRadius] = useState<string>(
    selectedSection.image.border_radius
  );

  const customizableParameters =
    selectedSection.template.configurable_parameters;

  const isVerticalAlignmentOneChild = customizableParameters.includes(
    "justify_content_one_child"
  );
  const showVerticalAlignment =
    customizableParameters.includes("justify_content") ||
    isVerticalAlignmentOneChild;

  useEffect(() => {
    if (isVerticalAlignmentOneChild) {
      const validAlignments = [
        CSSJustifyContent.start,
        CSSJustifyContent.end,
        CSSJustifyContent.center,
      ];
      if (!validAlignments.includes(justifyContent)) {
        setJustifyContent(CSSJustifyContent.center);
        updateSection({
          sectionId: selectedSection.id,
          field: "justify_content",
          value: CSSJustifyContent.center,
        });
      }
    }
  }, [isVerticalAlignmentOneChild]);

  if (!isBaseTemplate) {
    return null;
  }

  const verticalAlignmentComponent = showVerticalAlignment ? (
    <Flex
      direction="column"
      gap="2"
      width="100%"
      px="3"
      pt="0"
      pb="2"
      style={{ borderBottom: "1px solid #E0E0E0" }}
    >
      <Text size="2" color="gray">
        Vertical Alignment
      </Text>
      {isVerticalAlignmentOneChild ? (
        <DraperSegmentedControl
          onValueChange={(value) => {
            setJustifyContent(value as CSSJustifyContent);
            updateSection({
              sectionId: selectedSection.id,
              field: "justify_content",
              value: value,
            });
          }}
          value={justifyContent}
          radius="large"
          $backgroundColor="#FCFBFA"
        >
          {VERTICAL_ALIGNMENT_OPTIONS.slice(0, 3).map((item) => (
            <SegmentedControl.Item key={item.label} value={item.label}>
              <Flex align="center" gap="2">
                <item.icon size={20} />
                {getVerticalAlignmentLabel(item.label as CSSJustifyContent)}
              </Flex>
            </SegmentedControl.Item>
          ))}
        </DraperSegmentedControl>
      ) : (
        <>
          <DraperSegmentedControl
            onValueChange={(value) => {
              setJustifyContent(value as CSSJustifyContent);
              updateSection({
                sectionId: selectedSection.id,
                field: "justify_content",
                value: value,
              });
            }}
            value={justifyContent}
            radius="large"
            $backgroundColor="#FCFBFA"
          >
            {VERTICAL_ALIGNMENT_OPTIONS.slice(0, 2).map((item) => (
              <SegmentedControl.Item key={item.label} value={item.label}>
                <Flex align="center" gap="2">
                  <item.icon size={20} />
                  {getVerticalAlignmentLabel(item.label as CSSJustifyContent)}
                </Flex>
              </SegmentedControl.Item>
            ))}
          </DraperSegmentedControl>

          <DraperSegmentedControl
            onValueChange={(value) => {
              setJustifyContent(value as CSSJustifyContent);
              updateSection({
                sectionId: selectedSection.id,
                field: "justify_content",
                value: value,
              });
            }}
            value={justifyContent}
            radius="large"
            $backgroundColor="#FCFBFA"
          >
            {VERTICAL_ALIGNMENT_OPTIONS.slice(2, 4).map((item) => (
              <SegmentedControl.Item key={item.label} value={item.label}>
                <Flex align="center" gap="2">
                  <item.icon size={20} />
                  {getVerticalAlignmentLabel(item.label as CSSJustifyContent)}
                </Flex>
              </SegmentedControl.Item>
            ))}
          </DraperSegmentedControl>

          <DraperSegmentedControl
            onValueChange={(value) => {
              setJustifyContent(value as CSSJustifyContent);
              updateSection({
                sectionId: selectedSection.id,
                field: "justify_content",
                value: value,
              });
            }}
            value={justifyContent}
            radius="large"
            $backgroundColor="#FCFBFA"
          >
            {VERTICAL_ALIGNMENT_OPTIONS.slice(4, 6).map((item) => (
              <SegmentedControl.Item key={item.label} value={item.label}>
                <Flex align="center" gap="2">
                  <item.icon size={20} />
                  {getVerticalAlignmentLabel(item.label as CSSJustifyContent)}
                </Flex>
              </SegmentedControl.Item>
            ))}
          </DraperSegmentedControl>
        </>
      )}
    </Flex>
  ) : null;

  const showWidthComponent = customizableParameters.includes("width");
  const widthComponent = showWidthComponent ? (
    <Flex
      direction="column"
      gap="2"
      width="100%"
      px="3"
      pt="0"
      pb="2"
      style={{ borderBottom: "1px solid #E0E0E0" }}
    >
      <Text size="2" color="gray">
        Width
      </Text>
      <DraperSegmentedControl
        onValueChange={(value) => {
          setWidth(value as EmailSectionWidthType);
          updateSection({
            sectionId: selectedSection.id,
            field: "width",
            value: value === EmailSectionWidthType.full ? "100%" : "50%",
          });
        }}
        value={width}
        radius="large"
        $backgroundColor="#FCFBFA"
      >
        {WIDTH_OPTIONS.map((item) => (
          <SegmentedControl.Item key={item.label} value={item.label}>
            <Flex align="center" gap="2">
              <item.icon />
              {getWidthLabel(item.label as EmailSectionWidthType)}
            </Flex>
          </SegmentedControl.Item>
        ))}
      </DraperSegmentedControl>
    </Flex>
  ) : null;

  const borderComponent = (
    <Flex direction="column" gap="2" width="100%" px="3" pt="0" pb="2">
      <Text size="2" color="gray">
        Image Border
      </Text>

      <Flex gap="3" align="center" justify="between">
        <ColorBox
          key={0}
          color={borderColor}
          label="Color"
          disabled={false}
          onChange={(color) => {
            setBorderColor(color);
            updateSection({
              sectionId: selectedSection.id,
              field: "image.border_color",
              value: color,
            });
          }}
        />
        <NumberInput
          radius="large"
          value={parseInt(borderWidth)}
          style={{
            width: "75px",
            overflow: "hidden",
          }}
          onValueChange={(value) => {
            const valueString = `${value}px`;
            setBorderWidth(valueString);
            updateSection({
              sectionId: selectedSection.id,
              field: "image.border_width",
              value: valueString,
            });
          }}
        />
        <Select.Root
          onValueChange={(value) => {
            setBorderStyle(value as CSSBorderStyle);
            updateSection({
              sectionId: selectedSection.id,
              field: "image.border_style",
              value: value,
            });
          }}
          value={borderStyle}
          size="3"
        >
          <Select.Trigger color="gray" radius="large" />
          <Select.Content highContrast>
            {Object.values(CSSBorderStyle).map((style) => (
              <Select.Item key={style} value={style}>
                {style.charAt(0).toUpperCase() + style.slice(1).toLowerCase()}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>
    </Flex>
  );

  return (
    <ModifierContainer
      backgroundColor="#FCFBFA"
      hideSwitch
      title="Layout Controls"
      expanded={expanded}
      onExpandedChange={setExpanded}
      padding="0"
    >
      <Flex direction="column" gap="2" width="100%">
        {verticalAlignmentComponent}

        <Flex
          direction="column"
          gap="2"
          width="100%"
          px="3"
          pt="0"
          pb="2"
          style={{ borderBottom: "1px solid #E0E0E0" }}
        >
          <Text size="2" color="gray">
            Horizontal Alignment
          </Text>
          <DraperSegmentedControl
            onValueChange={(value) => {
              setHorizontalAlignment(value as CSSAlignItems);
              updateSection({
                sectionId: selectedSection.id,
                field: "align_items",
                value: value,
              });
            }}
            value={horizontalAlignment}
            radius="large"
            $backgroundColor="#FCFBFA"
          >
            {HORIZONTAL_ALIGNMENT_OPTIONS.map((item) => (
              <SegmentedControl.Item key={item.label} value={item.label}>
                <Flex align="center" gap="2">
                  <item.icon size={20} />
                  {getHorizontalAlignmentLabel(item.label as CSSAlignItems)}
                </Flex>
              </SegmentedControl.Item>
            ))}
          </DraperSegmentedControl>
        </Flex>

        {widthComponent}

        <Flex
          direction="column"
          gap="2"
          width="100%"
          px="3"
          pt="0"
          pb="2"
          style={{ borderBottom: "1px solid #E0E0E0" }}
        >
          <Text size="2" color="gray">
            Image Inset
          </Text>

          <AddButtonPadding
            isAppliedForAllSides={false}
            onPaddingChange={(padding) => {
              setPadding(padding);
              updateSection({
                sectionId: selectedSection.id,
                field: "image.padding_top",
                value: padding.top + "px",
              });
              updateSection({
                sectionId: selectedSection.id,
                field: "image.padding_right",
                value: padding.right + "px",
              });
              updateSection({
                sectionId: selectedSection.id,
                field: "image.padding_bottom",
                value: padding.bottom + "px",
              });
              updateSection({
                sectionId: selectedSection.id,
                field: "image.padding_left",
                value: padding.left + "px",
              });
            }}
            padding={padding}
          />
        </Flex>

        <Flex
          direction="column"
          gap="2"
          width="100%"
          px="3"
          pt="0"
          pb="2"
          style={{ borderBottom: "1px solid #E0E0E0" }}
        >
          <Text size="2" color="gray">
            Image Corner Radius
          </Text>

          <Flex gap="1" align="center">
            <NumberInput
              radius="large"
              value={parseInt(imageCornerRadius)}
              style={{
                width: "75px",
                overflow: "hidden",
              }}
              onValueChange={(value) => {
                const valueString = `${value}px`;
                setImageCornerRadius(valueString);
                updateSection({
                  sectionId: selectedSection.id,
                  field: "image.border_radius",
                  value: valueString,
                });
              }}
            />
            <Text size="2">px</Text>
          </Flex>
        </Flex>

        {borderComponent}
      </Flex>
    </ModifierContainer>
  );
};

export default HeroLayoutModifier;
