import useImageAssetsQuery from "../media/useImageAssetsQuery";
import useShopifyProductsQuery from "../shopify/useShopifyProductsQuery";
import { ImageAssetCategory, ImageAssetSource } from "@openapi";
import _ from "lodash";
import { useMemo } from "react";
import { AssetSelectorCommercePlatformItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { useAdEditorSelectedAdMediaState } from "~/contexts/AdEditorContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useShopifyCollectionImagesQuery from "~/hooks/shopify/useShopifyCollectionImagesQuery";
import { useEmailStateCommerceItemIds } from "~/routes/intern/email_editor/context/EmailEditorContext";

const CATEGORIES = [
  ImageAssetCategory.hero,
  ImageAssetCategory.background_image,
  ImageAssetCategory.lifestyle,
  ImageAssetCategory.logo,
  ImageAssetCategory.product,
];
const SOURCES = [
  ImageAssetSource.image_classification,
  ImageAssetSource.scraping,
  ImageAssetSource.file_upload,
  ImageAssetSource.unknown,
];
const LIMIT = 6;

export const ProductRelatedImagesQueryObject = (
  brandId: string,
  productId: string | undefined
) => {
  return {
    brandId: brandId,
    search: "",
    limit: LIMIT,
    commercePlatformItemId: productId ? [productId] : undefined,
    category: CATEGORIES,
    source: SOURCES,
  };
};

export const DefaultRelatedImagesQueryObject = (
  brandId: string,
  relatedCommerceItemIds: string[]
) => {
  return {
    brandId: brandId,
    search: "",
    limit: LIMIT,
    commercePlatformItemId: relatedCommerceItemIds,
    category: CATEGORIES,
    source: SOURCES,
  };
};

const useGetEmailRelatedImages = ({
  productId,
  collectionId,
  includeRelatedCommerceProductImages:
    _includeRelatedCommerceProductImages = false,
}: {
  productId?: string | null;
  collectionId?: string | null;
  includeRelatedCommerceProductImages?: boolean;
}) => {
  const includeRelatedCommerceProductImages =
    _includeRelatedCommerceProductImages && !productId && !collectionId;
  const emailCommerceItemIds = useEmailStateCommerceItemIds();
  const { ad } = useAdEditorSelectedAdMediaState();
  const adRelatedCommerceItemIds = useMemo(
    () =>
      (ad?.data.campaign_recommended_items ?? [])?.map(
        (item) => item.commerce_platform_item_id
      ),
    [ad?.data.campaign_recommended_items]
  );
  const activeBrandID = useActiveBrandID();

  const relatedCommerceItemIds = useMemo(() => {
    return emailCommerceItemIds || adRelatedCommerceItemIds;
  }, [emailCommerceItemIds, adRelatedCommerceItemIds]);

  const relatedCommerceProductIds = useMemo(() => {
    return relatedCommerceItemIds?.filter((id) =>
      id.startsWith("gid://shopify/Product")
    );
  }, [relatedCommerceItemIds]);

  const isProductQueryAvailable = !!productId;
  const isCollectionQueryAvailable = !!collectionId;
  const isDefaultQueryAvailable =
    !isProductQueryAvailable &&
    !isCollectionQueryAvailable &&
    (!!emailCommerceItemIds.length || !!adRelatedCommerceItemIds.length);

  // product specific product images
  const {
    assetsData,
    isLoading: isProductQueryLoading,
    error: productError,
    fetchNextPage: productFetchNextPage,
    hasNextPage: productHasNextPage,
  } = useImageAssetsQuery({
    ...ProductRelatedImagesQueryObject(activeBrandID, productId ?? undefined),
    enabled: isProductQueryAvailable,
  });
  const productRelatedAssets = useMemo(
    () => assetsData?.flatMap((data) => data.assets) ?? [],
    [assetsData]
  );

  //collection specific product images
  const {
    data: collectionData,
    isLoading: isCollectionQueryLoading,
    isFetching: isCollectionQueryFetching,
    error: collectionError,
    fetchNextPage: collectionFetchNextPage,
    hasNextPage: collectionHasNextPage,
  } = useShopifyCollectionImagesQuery({
    collectionId: collectionId,
    limit: LIMIT,
    enabled: isCollectionQueryAvailable,
  });
  const collectionRelatedAssets: AssetSelectorCommercePlatformItem[] =
    collectionData?.pages.flatMap((page) =>
      page.images.map((url) => ({
        url,
        commerce_platform_item_id: page.id,
      }))
    ) ?? [];

  //email general product images
  const {
    assetsData: defaultAssetsData,
    isLoading: isDefaultImagesQueryLoading,
    error: defaultImagesError,
    fetchNextPage: defaultFetchNextPage,
    hasNextPage: defaultHasNextPage,
  } = useImageAssetsQuery({
    ...DefaultRelatedImagesQueryObject(
      activeBrandID,
      emailCommerceItemIds || adRelatedCommerceItemIds
    ),
    enabled: isDefaultQueryAvailable,
  });

  // related commerce product images
  const {
    productsData: relatedCommerceProductData,
    isLoading: isRelatedCommerceProductLoading,
    error: relatedCommerceProductError,
  } = useShopifyProductsQuery(
    activeBrandID,
    includeRelatedCommerceProductImages ? relatedCommerceProductIds : null
  );

  const defaultRelatedAssets = useMemo(
    () => defaultAssetsData?.flatMap((data) => data.assets) ?? [],
    [defaultAssetsData, relatedCommerceProductData]
  );

  const relatedCommerceProductAssets: AssetSelectorCommercePlatformItem[] =
    useMemo(() => {
      const assets =
        relatedCommerceProductData?.flatMap((product) => {
          let urls = product.image_urls;
          if (!urls) {
            urls = product.image_url ? [product.image_url] : [];
          }
          return (
            urls?.map((url) => ({
              url,
              commerce_platform_item_id: product.product_id,
            })) ?? []
          );
        }) ?? [];

      return _.uniqBy(assets, (asset) => {
        // some products can have the same image (possibly with different ?v= params);
        return asset.url.split("?")[0];
      });
    }, [relatedCommerceProductData]);

  const relatedAssets = useMemo(() => {
    // avoid adding images for commerce items that show up in defaultRelatedAssets, as we can't know if they're duplicates (unless we do complex image checking)
    return [
      ...defaultRelatedAssets,
      ...relatedCommerceProductAssets.filter(
        (asset) =>
          !defaultRelatedAssets.some(
            (defaultAsset) =>
              defaultAsset.commerce_platform_item_id ===
              asset.commerce_platform_item_id
          )
      ),
    ];
  }, [defaultRelatedAssets, relatedCommerceProductAssets]);

  return {
    images: isProductQueryAvailable
      ? productRelatedAssets
      : isCollectionQueryAvailable
      ? collectionRelatedAssets
      : relatedAssets,
    isLoading: isProductQueryAvailable
      ? isProductQueryLoading
      : isCollectionQueryAvailable
      ? isCollectionQueryLoading || isCollectionQueryFetching
      : isDefaultQueryAvailable
      ? isDefaultImagesQueryLoading
      : false,
    error: isProductQueryAvailable
      ? productError
      : isCollectionQueryAvailable
      ? collectionError
      : isDefaultQueryAvailable
      ? defaultImagesError
      : null,
    fetchNextPage: isProductQueryAvailable
      ? productFetchNextPage
      : isDefaultQueryAvailable
      ? defaultFetchNextPage
      : isCollectionQueryAvailable
      ? collectionFetchNextPage
      : null,
    hasNextPage: isProductQueryAvailable
      ? productHasNextPage
      : isDefaultQueryAvailable
      ? defaultHasNextPage
      : isCollectionQueryAvailable
      ? collectionHasNextPage
      : false,
  };
};

export default useGetEmailRelatedImages;
