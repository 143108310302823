import ModifierContainer from "./ModifierContainer";
import * as Avatar from "@radix-ui/react-avatar";
import { Flex, Spinner, Text } from "@radix-ui/themes";
import { useState } from "react";
import { PencilIcon } from "~/assets/icons";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { Product } from "~/components/campaign/wizard/products/components/productSelector/hooks/useGetFilteredProducts";
import SidePanelComponents from "~/components/core/editor/sidepane";
import { useActiveBrandCurrency } from "~/contexts/CurrentUserContext";
import { currencyFormatter } from "~/utils/helpers";

const FeaturedProductModifier = ({
  onChangeProduct,
  isLoading,
  containerTitle = "Featured Product",
  productDetails,
  showProductDetails,
}: {
  onChangeProduct: (product: Product) => void;
  isLoading?: boolean;
  containerTitle?: string;
  productDetails?: Product;
  showProductDetails?: boolean;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const currency = useActiveBrandCurrency();

  return (
    <ModifierContainer title={containerTitle} hideSwitch>
      {showProductDetails && productDetails && (
        <Flex gap="17px" style={{ paddingBottom: "8px" }}>
          <Avatar.Root>
            <Avatar.Image
              src={productDetails.image_url ?? ""}
              alt="image"
              width="40px"
              height="40px"
              style={{
                borderRadius: "6px",
                border: "1px solid #0000000A",
                display: "block",
              }}
            ></Avatar.Image>
          </Avatar.Root>
          <Flex direction="column" gap="5px">
            <Text size={"2"} weight="medium" style={{ color: " #000000" }}>
              {productDetails.title}
            </Text>
            <Text size={"1"} weight="medium" style={{ color: " #00000)" }}>
              {currencyFormatter(currency).format(productDetails.price)}
            </Text>
          </Flex>
        </Flex>
      )}
      <ProductSelectorDialog
        showPaginationNumbers
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        onSelectSingleProduct={(product) => {
          onChangeProduct(product);
          setIsDialogOpen(false);
        }}
        triggerComponent={
          <SidePanelComponents.Button
            variant="outlined"
            onClick={() => setIsDialogOpen(true)}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : <PencilIcon />}
            {isLoading ? "Changing" : "Change"} Product
          </SidePanelComponents.Button>
        }
      />
    </ModifierContainer>
  );
};

export default FeaturedProductModifier;
