import InternBrandInfoCard from "./InternBrandInfoCard";
import InternBrandJobsCard from "./InternBrandJobsCard";
import InternBrandPipelinesCard from "./InternBrandPipelinesCard";
import InternBrandRecommenderCard from "./InternBrandRecommenderCard";
import InternBrandVoiceModelsCard from "./InternBrandVoiceModelsCard";
import { Card, Heading } from "@radix-ui/themes";
import { useState } from "react";

const InternBrandDashboardComponent = () => {
  const styles = {
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "auto",
      gap: "16px",
      gridAutoFlow: "dense",
      padding: "16px",
    },
    card: {
      padding: "16px",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      backgroundColor: "#fff",
      maxHeight: "400px",
    },
    cardHeader: {
      marginBottom: "15px",
    },
    brandCard: {
      gridColumn: "span 2",
      gridRow: "span 4",
    },
    pipelinesCard: {
      gridColumn: "span 4",
      gridRow: "span 4",
      height: "400px",
    },
    jobsCard: {
      gridColumn: "span 4",
      gridRow: "span 4",
    },
    voiceCard: {
      gridColumn: "span 2",
      gridRow: "span 4",
    },
    recommenderCard: {
      gridColumn: "span 3",
      gridRow: "span 4",
    },
    adCard: {
      gridColumn: "span 3",
      gridRow: "span 4",
    },
    button: {
      marginTop: "8px",
      padding: "8px 16px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      textAlign: "center",
    },
    tableContainer: {
      border: "1px solid #e0e0e0",
      borderRadius: "6px",
      padding: "8px",
    },
    tableText: {
      fontSize: "12px",
    },
  };

  const [state, setState] = useState(0);

  const refresh = () => {
    setState((prev) => (prev + 1) % 1000);
  };

  return (
    <div style={styles.grid}>
      <Card style={{ ...styles.card, ...styles.brandCard }}>
        <InternBrandInfoCard parentState={state} refresh={refresh} />
      </Card>
      <Card style={{ ...styles.card, ...styles.pipelinesCard }}>
        <InternBrandPipelinesCard parentState={state} refresh={refresh} />
      </Card>
      <Card style={{ ...styles.card, ...styles.jobsCard }}>
        <InternBrandJobsCard parentState={state} refresh={refresh} />
      </Card>
      <Card style={{ ...styles.card, ...styles.voiceCard }}>
        <InternBrandVoiceModelsCard parentState={state} refresh={refresh} />
      </Card>
      <Card style={{ ...styles.card, ...styles.recommenderCard }}>
        <InternBrandRecommenderCard parentState={state} refresh={refresh} />
      </Card>
      <Card style={{ ...styles.card, ...styles.adCard }}>
        <Heading size="5" style={styles.cardHeader}>
          ---
        </Heading>
      </Card>
    </div>
  );
};

export default InternBrandDashboardComponent;
