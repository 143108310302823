import {
  CampaignBriefOverviewSection,
  SectionContent,
} from "../CampaignBriefOverview";
import useCampaignCustomAssetsProps from "../hooks/useCampaignCustomAssetsProps";
import { Box, Flex, Text } from "@radix-ui/themes";
import { XIcon } from "lucide-react";
import { useEffect } from "react";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { CampaignRecommendationResponse } from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

export const CampaignBriefOverviewAssetsSection = ({
  campaignRecommendation,
}: {
  campaignRecommendation: CampaignRecommendationResponse;
}) => {
  const activeBrandID = useActiveBrandID();
  const {
    props: {
      selectedAssets,
      setSelectedAssets,
      isDialogOpen,
      onToggleDialogOpen,
    },
  } = useCampaignCustomAssetsProps(0, true);

  const removeDroppedFile = (fileName: string) => {
    setSelectedAssets(
      selectedAssets.filter((file) => file.original_filename !== fileName)
    );
  };

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({});

  useEffect(() => {
    setSelectedAssets(campaignRecommendation.image_assets ?? []);
  }, []);

  const assetTiles = (
    <Box
      style={{
        marginTop: "16px",
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
      }}
    >
      {selectedAssets.map((file) => (
        <Flex
          align="center"
          justify="between"
          key={file.original_filename}
          style={{
            padding: "4px",
            borderRadius: "8px",
            backgroundColor: "#F0EDEB",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <img
            src={file.url}
            alt={file.original_filename}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "8px",
              objectFit: "cover",
              marginRight: "12px",
            }}
          />
          <Text as="p" style={{ fontSize: "14px", marginRight: "12px" }}>
            {file.original_filename}
          </Text>
          <XIcon
            onClick={() => removeDroppedFile(file.original_filename)}
            strokeWidth={1.5}
            style={{
              marginLeft: "12px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
          />
        </Flex>
      ))}
    </Box>
  );

  return (
    <CampaignBriefOverviewSection
      title="Additional Assets"
      onEdit={() => {
        setSelectedAssets(campaignRecommendation.image_assets ?? []);
        onToggleDialogOpen(true);
      }}
      isLoading={isLoading}
    >
      <SectionContent>
        {selectedAssets.length > 0 ? (
          assetTiles
        ) : (
          <Text>No image assets added</Text>
        )}
        <AssetSelectorDialog
          campaignId={null}
          isDialogOpen={isDialogOpen}
          onToggleDialogOpen={onToggleDialogOpen}
          preselectedAssets={selectedAssets}
          onSelect={(assetItems) => {
            const assetSchemas = assetItems
              .filter((assetItem) => assetItem)
              .map((assetItem) => assetItem as ImageAssetSchema);
            updateCampaignRecommendation({
              campaign_recommendation_id: campaignRecommendation.id,
              brand_id: activeBrandID,
              image_asset_ids: assetSchemas.map(
                (assetSchema) => assetSchema.id
              ),
            });
            setSelectedAssets(assetSchemas);
          }}
        />
      </SectionContent>
    </CampaignBriefOverviewSection>
  );
};
