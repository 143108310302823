import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useDraperApiDeleteMutation } from "~/utils/useDraperMutation";

export type DeleteEmailSectionTemplateResponse =
  operations["intern_api_delete_email_section_template"]["responses"]["200"]["content"]["application/json"];

interface UseInternEmailSectionTemplateDeleteMutationProps {
  templateId: string;
  onSuccess?: (data: DeleteEmailSectionTemplateResponse) => void;
}

const useInternEmailSectionTemplateDeleteMutation = ({
  templateId,
  onSuccess,
}: UseInternEmailSectionTemplateDeleteMutationProps) => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useDraperApiDeleteMutation<
    DeleteEmailSectionTemplateResponse,
    void
  >({
    mutationKey: ["internEmailSectionTemplates", templateId],
    path: `/intern/email-section-templates/${templateId}`,
    onSuccess: (data) => {
      toast.success("Email section template deleted");
      onSuccess?.(data);
    },
    onError: () => {
      toast.error("Failed to delete email section template");
    },
  });

  return {
    deleteEmailSectionTemplate: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternEmailSectionTemplateDeleteMutation;
