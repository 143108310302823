import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

type TemplateResponse =
  operations["intern_api_get_email_templates"]["responses"][200]["content"]["application/json"];

export const key = (brandId?: string) => ["intern/email-templates", brandId];

const useInternEmailTemplatesQuery = (
  pageSize: number = 10,
  brandId?: string
) => {
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: key(brandId),
    queryFn: async ({ pageParam = 1 }): Promise<TemplateResponse> => {
      const { data } = await axios.get(`/api/v1/intern/email-templates`, {
        params: {
          page: pageParam,
          page_size: pageSize,
          brand_id: brandId,
        },
      });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
  });

  const templates = data?.pages.flatMap((page) => page.templates) ?? [];

  return {
    templates,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useInternEmailTemplatesQuery;
