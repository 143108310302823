import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import AppButton from "../core/buttons/AppButton/AppButton";
import CategorizedSelect from "~/components/campaign/wizard/inputs/CategorizedSelect";
import useCDPAudiencesQuery from "~/hooks/cdp/useCDPAudiencesQuery";
import DialogHeader from "../core/dialog/DialogHeader";
import TextFieldWithLabel from "../core/inputs/TextFieldWithLabel";
import TextAreaWithLabel from "../core/inputs/TextAreaWithLabel";
import DropdownSelect from "../core/inputs/DropdownSelect";
import { toast } from "sonner";
import { Dialog, Flex, Text } from "@radix-ui/themes";
import { useState, useEffect } from "react";
import { AudienceCategory, CDPAudienceType, CDPType, components } from "@openapi";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useMutation } from '@tanstack/react-query';
import { Trash2Icon } from "~/assets/icons";

type AudienceSelectorItem = components["schemas"]["CDPAudienceRequestData"];
type BrandAudience = components["schemas"]["BrandAudienceSchema"];
type CreateBrandAudienceRequestData = components["schemas"]["CreateBrandAudienceRequestData"];
type UpdateBrandAudienceRequestData = components["schemas"]["UpdateBrandAudienceRequestData"];

type NewAudienceDialogProps = {
  category?: AudienceCategory;
  audience?: BrandAudience;
  trigger: React.ReactNode;
  dialogTitle?: string;
  onCreate?: (brandAudience: BrandAudience) => void;
  onUpdate?: (brandAudience: BrandAudience) => void;
  onDelete?: (brandAudience: BrandAudience) => void;
};

const NewAudienceDialog: React.FC<NewAudienceDialogProps> = ({
  category,
  audience,
  trigger,
  onCreate = () => { },
  onUpdate = () => { },
  onDelete = () => { },
  dialogTitle,
}) => {
  const isEditMode = !!audience;
  const activeBrandID = useActiveBrandID();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(audience?.title || "");
  const [selectedCategory, setSelectedCategory] = useState<AudienceCategory>(
    audience?.category || category || AudienceCategory.custom
  );
  const [description, setDescription] = useState(audience?.description || "");
  const [selectedCDPAudience, setSelectedCDPAudience] = useState<AudienceSelectorItem | null>(null);
  const { audiencesData: data, isLoading } = useCDPAudiencesQuery();

  const createMutation = useMutation({
    mutationFn: async (payload: CreateBrandAudienceRequestData) => {
      return axios.post(
        `/api/v1/brand/${activeBrandID}/audience`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
    },
    onSuccess: (data: AxiosResponse<BrandAudience>) => {
      toast.success("Audience created successfully");
      onCreate(data.data);
      setTitle("");
      setSelectedCategory(category || AudienceCategory.custom);
      setDescription("");
      setSelectedCDPAudience(null);
      setOpen(false);
    },
    onError: (error: AxiosError) => {
      toast.error(`Failed to create audience. ${error.response?.data}`);
      console.error("Error creating audience.", error);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      return axios.delete(`/api/v1/brand/${activeBrandID}/audience/${audience!.id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
    },
    onSuccess: () => {
      toast.success("Audience deleted successfully");
      onDelete(audience!);
      setOpen(false);
    },
    onError: (error) => {
      console.error("Error deleting audience:", error);
    }
  });

  const updateMutation = useMutation({
    mutationFn: async (payload: UpdateBrandAudienceRequestData) => {
      return axios.put(
        `/api/v1/brand/${activeBrandID}/audience/${audience!.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
    },
    onSuccess: (data: AxiosResponse<BrandAudience>) => {
      toast.success("Audience updated successfully");
      onUpdate(data.data);
      setOpen(false);
    },
    onError: (error) => {
      console.error("Error updating audience:", error);
    }
  });

  // Set selected audience if we're in edit mode
  useEffect(() => {
    if (isEditMode && audience?.cdp_audiences?.length > 0) {
      const cdpAudience = audience.cdp_audiences[0];
      setSelectedCDPAudience({
        cdp_type: CDPType.klaviyo,
        audience_id: cdpAudience.audience_id,
        audience_name: cdpAudience.audience_name,
        audience_type: cdpAudience.audience_type,
      });
    }
  }, [isEditMode, audience]);

  const handleSelect = (
    id: string,
    name: string,
    cdpType: string,
    audienceType: string
  ) => {
    const audience: AudienceSelectorItem = {
      cdp_type: cdpType as CDPType,
      audience_id: id,
      audience_name: name,
      audience_type: audienceType as CDPAudienceType,
    };

    if (selectedCDPAudience?.audience_id === id) {
      setSelectedCDPAudience(null);
      return;
    }

    setSelectedCDPAudience(audience);
  };

  const handleDelete = () => {
    if (!audience) {
      console.error("No audience to delete");
      return;
    }

    deleteMutation.mutate();
  };

  const handleSubmit = () => {
    if (!selectedCDPAudience || !activeBrandID) {
      console.error("No CDP audience selected or active brand ID not found");
      return;
    }

    if (isEditMode) {
      const payload: UpdateBrandAudienceRequestData = {
        title,
        description,
        category: selectedCategory,
        cdp_audiences: [selectedCDPAudience],
      };
      updateMutation.mutate(payload);
    } else {
      const payload: CreateBrandAudienceRequestData = {
        title,
        description,
        category: selectedCategory,
        cdp_audiences: [selectedCDPAudience],
      };
      createMutation.mutate(payload);
    }
  };

  const isSubmitting = createMutation.isPending || updateMutation.isPending;
  const isFormValid = title.trim() !== "" && selectedCDPAudience !== null && selectedCategory !== null && description.trim() !== "";

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content>
        <DialogHeader title={dialogTitle || (isEditMode ? "Edit Audience" : "New Audience")} />
        <Dialog.Description />
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="3">
            <TextFieldWithLabel
              label="Audience title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
          </Flex>

          <Flex direction="column" gap="1">
            <Text size="2">Audience category</Text>
            <DropdownSelect
              value={selectedCategory}
              onValueChange={(value) => setSelectedCategory(value as AudienceCategory)}
              defaultValue={selectedCategory}
              options={Object.values(AudienceCategory).map(category => ({
                label: category.charAt(0).toUpperCase() + category.slice(1),
                value: category
              }))} />
          </Flex>

          <Flex direction="column" gap="3">
            <TextAreaWithLabel
              label="Description"
              value={description}
              placeholder="Include details on which product categories or collections are relevant to this audience."
              onChange={(e) =>
                setDescription(e.target.value)
              }
            />
          </Flex>

          <Flex direction="column" gap="1">
            <Text size="2">Link this to a Klaviyo audience</Text>
            <CategorizedSelect
              data={data}
              onSelect={handleSelect}
              loading={isLoading}
              selectedAudienceIds={selectedCDPAudience ? [selectedCDPAudience.audience_id] : []}
              singleSelect={true}
            />
          </Flex>

          <Flex pt="16px" width="100%">
            <Flex width="100%" justify="between">
              {isEditMode && (
                <AppButton variant="outlined" radius="large" onClick={handleDelete}>
                  <Trash2Icon />
                  Delete
                </AppButton>
              )}
              <Flex justify="end" gap="2">
                <Dialog.Close>
                  <AppButton variant="outlined" radius="large">
                    Cancel
                  </AppButton>
                </Dialog.Close>
                <AppButton
                  variant="dark"
                  radius="large"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={!isFormValid}
                >
                  {isEditMode ? "Save Changes" : "Add Audience"}
                </AppButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default NewAudienceDialog;
