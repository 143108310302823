import StyleLibraryCardContent from "../StyleLibraryCardContent";
import StyleLibraryCardEmptyContent from "../StyleLibraryCardEmptyContent";
import { components } from "@openapi";
import { PlusIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { useState } from "react";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import PalettePicker from "~/components/core/inputs/PalettePicker";
import ColorPaletteDialog from "~/components/dialogs/ColorPaletteDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandColorPalettesQuery from "~/hooks/brand/useBrandColorPalettesQuery";

const ColorPaletteCardSection = () => {
  const activeBrandID = useActiveBrandID();

  const {
    colorPalettes: brandColorPalettes,
    isLoading: isBrandColorPalettesLoading,
  } = useBrandColorPalettesQuery(activeBrandID);

  const [isAddPaletteDialogOpen, setIsAddPaletteDialogOpen] = useState(false);
  const [activePalette, setActivePalette] = useState<
    components["schemas"]["ColorPaletteSchema"] | undefined
  >(undefined);

  if (isBrandColorPalettesLoading) {
    return <LoadingDots />;
  }

  let content = null;
  if (brandColorPalettes?.length === 0) {
    content = (
      <StyleLibraryCardEmptyContent
        text="No color palettes set"
        button={
          <AppButton
            radius="large"
            style={{
              paddingTop: "12px",
              paddingBottom: "12px",
              height: "fit-content",
            }}
            variant="dark"
            onClick={() => {
              setActivePalette(undefined);
              setIsAddPaletteDialogOpen(true);
            }}
          >
            <PlusIcon />
            Add Color Palette
          </AppButton>
        }
      />
    );
  } else {
    content = brandColorPalettes?.map((palette, index) => {
      return (
        <div
          key={palette.id}
          role="button"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          onClick={() => {
            setActivePalette(palette);
            setIsAddPaletteDialogOpen(true);
          }}
        >
          <PalettePicker palette={palette} />
        </div>
      );
    });
  }

  return (
    <StyleLibraryCardContent
      title="Color Palettes"
      isLoading={isBrandColorPalettesLoading}
      actions={
        (brandColorPalettes?.length ?? 0) > 0 && (
          <AppButton
            variant="ghost"
            radius="large"
            onClick={() => {
              setActivePalette(undefined);
              setIsAddPaletteDialogOpen(true);
            }}
          >
            <PlusIcon /> Add Color Palette
          </AppButton>
        )
      }
    >
      <ColorPaletteDialog
        isOpen={isAddPaletteDialogOpen}
        onOpenChange={setIsAddPaletteDialogOpen}
        activePalette={activePalette}
        hideDeleteButton={(brandColorPalettes?.length ?? 0) <= 1}
      />

      <Flex
        direction={"row"}
        align="start"
        gap="4"
        style={{
          background: "white",
          flexWrap: "wrap",
        }}
      >
        {content}
      </Flex>
    </StyleLibraryCardContent>
  );
};

export default ColorPaletteCardSection;
