import { Card, Flex, Heading, Switch } from "@radix-ui/themes";
import { ChevronUp, ChevronRight } from "lucide-react";
import React, { useMemo } from "react";
import styled from "styled-components";

const ClickableHeader = styled(Flex)`
  cursor: pointer;
  border-radius: 12px;
`;

const CustomCard = styled(Flex)<{ $backgroundColor?: string }>`
  border-radius: 20px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 2px 8px 0px #0000000a;
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
`;

const DisablingContainer = styled(Flex)<{ $disabled: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  transition: opacity 0.3s ease;
`;

type EditorCardProps = {
  title: string;
  switchDisabled?: boolean;
  padding?: "0" | "1" | "2" | "3" | "4";
  backgroundColor?: string;
} & React.ComponentProps<typeof Card> &
  (
    | {
        checkable: false;
        expanded?: boolean;
        onExpandedChange?: (expanded: boolean) => void;
      }
    | {
        checkable: true;
        checked: boolean;
        /** Defaults to `disabled` */
        uncheckedMode?: "disabled" | "hidden";
        onCheckedChange: (checked: boolean) => void;
      }
  );

const EditorCard: React.FC<EditorCardProps> = ({
  children,
  title,
  switchDisabled,
  padding = "3",
  backgroundColor,

  ...props
}) => {
  const content = useMemo(() => {
    if (!props.checkable) {
      return children;
    }
    if (props.uncheckedMode === "hidden") {
      return props.checked ? children : null;
    }
    return (
      <DisablingContainer
        $disabled={!props.checked}
        direction="column"
        gap="4"
        width="100%"
      >
        {children}
      </DisablingContainer>
    );
  }, [props.checkable, children]);

  const checkableSwitch = props.checkable ? (
    <Switch
      checked={props.checked}
      onCheckedChange={props.onCheckedChange}
      disabled={switchDisabled}
    />
  ) : null;

  let defaultHeader = (
    <Flex
      direction="row"
      justify="between"
      minHeight="20px"
      align="center"
      px="3"
      pt="3"
    >
      <Heading size="2">{title}</Heading>
      {checkableSwitch}
    </Flex>
  );

  const isExpandable = !props.checkable && props.expanded !== undefined;
  const isExpanded = isExpandable && props.expanded === true;
  if (isExpandable) {
    defaultHeader = (
      <Flex
        direction="row"
        justify="between"
        minHeight="20px"
        align="center"
        px="3"
        pt="3"
      >
        <ClickableHeader
          onClick={() => props.onExpandedChange?.(!props.expanded)}
          justify="between"
          align="center"
          width="100%"
          gap="2"
        >
          <Heading size="2">{title}</Heading>
          <Flex align="center">
            {props.expanded ? (
              <ChevronUp size={20} />
            ) : (
              <ChevronRight size={20} />
            )}
          </Flex>
        </ClickableHeader>
      </Flex>
    );
  }

  return (
    <CustomCard $backgroundColor={backgroundColor}>
      <Flex direction="column" gap="4" width="100%">
        {defaultHeader}

        {isExpanded || !isExpandable ? (
          <Flex
            direction="column"
            px={padding}
            pb={padding}
            gap="2"
            width="100%"
          >
            {content}
          </Flex>
        ) : (
          <div />
        )}
      </Flex>
    </CustomCard>
  );
};

export default EditorCard;
