import FullscreenSpinner from "@core/FullscreenSpinner";
import { Flex, Heading, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";
import { toast } from "sonner";
import { styled } from "styled-components";
import { SparklesIcon } from "~/assets/icons";
import CampaignOverviewCard from "~/components/campaign/overview/card/CampaignOverviewCard";
import CampaignOverviewAdContent from "~/components/campaign/overview/card/ads/CampaignOverviewAdContent";
import CampaignOverviewEmailContent from "~/components/campaign/overview/card/emails/CampaignOverviewEmailContent";
import useDeleteAdCreativeMutation from "~/hooks/ads/useDeleteAdCreativeMutation";
import useDuplicateAdCreativeMediaMutation from "~/hooks/ads/useDuplicateAdCreativeMutation";
import { useUpdateAdCreativeBaseMutation } from "~/hooks/ads/useUpdateAdCreativeMutation";
import useCampaignGenerationStatusQuery from "~/hooks/campaign/useCampaignGenerationStatusQuery";
import useGetCampaignDetails, {
  getCampaignDetailsQueryKey,
} from "~/hooks/campaign/useGetCampaignDetails";
import useDeleteEmailCreativeMutation from "~/hooks/emails/useDeleteEmailCreativeMutation";
import useDuplicateEmailCreativeMediaMutation from "~/hooks/emails/useDuplicateEmailCreativeMutation";
import { usePatchEmailCreativeMutation } from "~/hooks/emails/usePatchEmailCreativeMutation";
import { AdMediaAspectRatioStrings } from "~/types/ads";

const HorizontalScrollableBody = styled(Flex)`
  padding: 0 16px;
  overflow-x: auto;
`;

const HorizontalScrollGrouping = styled(Flex)`
  gap: 32px;
  scroll-margin-left: 16px;
  padding: 16px;
`;

const EmptyCard = styled(Flex)`
  position: relative;
  border-radius: 16px;
  width: 500px;
  height: 100%;
  display: flex;
  background-color: #fcfbfa;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd7d7;
`;

const CampaignOverviewRouteBody = () => {
  const queryClient = useQueryClient();
  const { id } = useParams() as { id: string };
  const emailRef = useRef<HTMLDivElement | null>(null);
  const adsRef = useRef<HTMLDivElement | null>(null);
  const [isCampaignJobComplete, setIsCampaignJobComplete] = useState<
    boolean | null
  >(null);

  const {
    data: campaignDetail,
    isLoading,
    isError,
    isFetching,
  } = useGetCampaignDetails({ campaignId: id, switchToCampaignBrand: true });
  const { data: campaignGenerationStatus } = useCampaignGenerationStatusQuery(
    id,
    {
      enabled: !isCampaignJobComplete,
    }
  );

  useEffect(() => {
    if (campaignGenerationStatus?.is_campaign_job_complete === false) {
      setIsCampaignJobComplete(false);
    } else if (campaignGenerationStatus?.is_campaign_job_complete === true) {
      if (isCampaignJobComplete === false) {
        // This should mean that the campaign job has been completed
        // and we should update the campaign details
        queryClient.invalidateQueries({
          queryKey: getCampaignDetailsQueryKey(id),
          refetchType: "all",
        });
      }
      setIsCampaignJobComplete(true);
    }
  }, [campaignGenerationStatus?.is_campaign_job_complete]);

  const updateAdCreative = useUpdateAdCreativeBaseMutation({
    onSuccess: ({ request_data: { headline } }) => {
      toast.success(`Ad renamed to: ${headline}`);
    },
    onError: (message) => {
      toast.error(`Failed to rename ad: ${message}`);
    },
  });

  const handleAdRename = (creativeId: string, newHeadline: string) => {
    updateAdCreative.mutate({
      creativeId,
      campaignId: id,
      request_data: {
        headline: newHeadline,
      },
    });
  };

  const deleteAdCreative = useDeleteAdCreativeMutation({
    onSuccess: ({ id }) => {
      const ad = campaignDetail?.ad_creatives.find((ad) => ad.id === id);
      if (!ad) {
        return;
      }
      toast.success(`Ad deleted: ${ad.title}`);
    },
    onError: (message) => {
      toast.error(`Failed to delete ad: ${message}`);
    },
  });

  const handleAdDelete = (creativeId: string) => {
    deleteAdCreative.mutate({
      id: creativeId,
      campaignId: id,
    });
  };

  const deleteEmailCreative = useDeleteEmailCreativeMutation({
    onSuccess: ({ id }) => {
      const email = campaignDetail?.email_creatives.find(
        (email) => email.id === id
      );
      if (!email) {
        return;
      }
      toast.success(`Email deleted: ${email.title}`);
    },
    onError: (message) => {
      toast.error(`Failed to delete email: ${message}`);
    },
  });

  const handleEmailDelete = (creativeId: string) => {
    deleteEmailCreative.mutate({
      id: creativeId,
      campaignId: id,
    });
  };

  const patchEmailCreative = usePatchEmailCreativeMutation({
    campaignId: id,
    onSuccess: () => {
      toast.success(`Email updated`);
    },
    onError: () => {
      toast.error(`Failed to update email`);
    },
  });

  const handleEmailRename = (creativeId: string, newName: string) => {
    patchEmailCreative.mutate({
      id: creativeId,
      name: newName,
    });
  };

  const duplicateAdCreative = useDuplicateAdCreativeMediaMutation({
    onSuccess: (data) => {
      toast.success(`Ad copy created: ${data.title}`);
      setTimeout(() => {
        handleScroll("ads", "end");
      }, 50);
    },
    onError: (message) => {
      toast.error(`Failed to duplicate ad: ${message}`);
    },
  });

  const handleDuplicateAd = (creativeId: string) => {
    duplicateAdCreative.mutate({
      adCreativeId: creativeId,
      campaignId: id,
    });
  };

  const duplicateEmailCreative = useDuplicateEmailCreativeMediaMutation({
    onSuccess: (data) => {
      toast.success(`Email copy created: ${data.title}`);
      setTimeout(() => {
        handleScroll("email", "start");
      }, 50);
    },
    onError: (message) => {
      toast.error(`Failed to duplicate email: ${message}`);
    },
  });

  const handleDuplicateEmail = (creativeId: string) => {
    duplicateEmailCreative.mutate({
      emailCreativeId: creativeId,
      campaignId: id,
    });
  };

  const handleScroll = (
    campaignType: "email" | "ads",
    position?: ScrollLogicalPosition
  ) => {
    if (campaignType === "email" && emailRef.current) {
      scrollIntoView(emailRef.current, {
        behavior: "smooth",
        inline: position ?? "start",
      });
    } else if (campaignType === "ads" && adsRef.current) {
      scrollIntoView(adsRef.current, {
        behavior: "smooth",
        inline: position ?? "start",
      });
    }
  };

  if (isLoading || isFetching || isError || !campaignDetail)
    return <FullscreenSpinner />;

  return (
    <HorizontalScrollableBody>
      <HorizontalScrollGrouping ref={emailRef}>
        {campaignGenerationStatus?.is_campaign_job_complete === false && (
          <EmptyCard>
            <Flex
              gap="6"
              direction="column"
              height="100dvh"
              width="50%"
              justify="center"
              align="center"
              style={{ textAlign: "center" }}
            >
              <SparklesIcon color="#8C3FFF" fill="#8C3FFF" size={30} />

              <Heading size="5">Generating the campaign</Heading>
              <Text size="2">
                This will be a few minutes, you will be notified by email when
                the campaign is ready.
              </Text>
            </Flex>
          </EmptyCard>
        )}

        {campaignDetail.email_creatives.map((email, index) => (
          <CampaignOverviewCard
            key={email.id}
            title={email.title}
            subtitle={email.subtitle}
            count={index + 1}
            variant="email"
            editPath={`/campaign/${id}/email/${email.id}`}
            status={email.status}
            publishedAt={email.published_at}
            previewImages={
              email.preview_image
                ? [{ url: email.preview_image, title: undefined }]
                : []
            }
            onRename={(newHeadline) => handleEmailRename(email.id, newHeadline)}
            onDuplicate={() => handleDuplicateEmail(email.id)}
            onDelete={() => handleEmailDelete(email.id)}
          >
            <CampaignOverviewEmailContent email={email} />
          </CampaignOverviewCard>
        ))}
      </HorizontalScrollGrouping>
      <HorizontalScrollGrouping ref={adsRef}>
        {campaignDetail.ad_creatives.map((ad, index) => (
          <CampaignOverviewCard
            key={ad.id}
            adID={ad.meta_ad_id ?? undefined}
            title={ad.title}
            subtitle={ad.description}
            count={index + 1}
            variant="ad"
            editPath={`/ad/${ad.id}`}
            status={ad.status}
            publishedAt={null}
            previewImages={ad.preview_images.map((image) => ({
              url: image.url,
              title: AdMediaAspectRatioStrings[image.aspect_ratio],
            }))}
            onRename={(newHeadline) => {
              handleAdRename(ad.id, newHeadline);
            }}
            onDuplicate={() => handleDuplicateAd(ad.id)}
            onDelete={() => handleAdDelete(ad.id)}
          >
            <CampaignOverviewAdContent
              ad={ad}
              destination={campaignDetail.destination}
            />
          </CampaignOverviewCard>
        ))}
      </HorizontalScrollGrouping>
    </HorizontalScrollableBody>
  );
};

export default CampaignOverviewRouteBody;
