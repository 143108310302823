import { Box, Button, Heading } from "@radix-ui/themes";
import { toast } from "sonner";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInternGenerateCalendar from "~/hooks/intern/useInternGenerateCalendar";

export default function InternCalendarRoute() {
  const activeBrandID = useActiveBrandID();

  const generateCalendar = useInternGenerateCalendar(activeBrandID, {
    onSuccess: () => {
      toast.success("Calendar generation started");
    },
    onError: (error) => {
      toast.error("Failed to generate calendar: " + error.message);
    },
  });

  return (
    <Box>
      <Heading size="6" mb="4">
        Calendar
      </Heading>
      <Button
        onClick={() => generateCalendar.mutate()}
        disabled={generateCalendar.isPending}
      >
        {generateCalendar.isPending ? "Generating..." : "Generate Calendar"}
      </Button>
    </Box>
  );
}
