import { AssistantAvatar } from "../CampaignBriefOverview";
import {
  Dialog,
  Flex,
  Text,
  VisuallyHidden,
  IconButton,
} from "@radix-ui/themes";
import styled from "styled-components";
import { XIcon } from "~/assets/icons";
import markSrc from "~/assets/mark.png";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const StyledDialogContent = styled(Dialog.Content)`
  padding: 0;
  position: relative;
  overflow: visible; /* Ensure content outside the dialog is visible */
`;

const HeaderContainer = styled(Flex)`
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-primary);
`;

const ContentContainer = styled(Flex)`
  padding: 24px;
  flex-direction: column;
  gap: 16px;
`;

const FooterContainer = styled(Flex)`
  padding: 16px 24px;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid var(--border-primary);
`;

const CampaignBriefOverviewDialog = ({
  open,
  onOpenChange,
  title,
  body,
  onSave,
  disabled = false,
  maxWidth,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  body: React.ReactElement;
  onSave: () => void;
  disabled?: boolean;
  maxWidth?: string;
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <StyledDialogContent maxWidth={maxWidth}>
        <HeaderContainer>
          <Dialog.Title
            style={{
              margin: 0,
            }}
          >
            <Text size="3" weight="bold">
              {title}
            </Text>
          </Dialog.Title>

          <Dialog.Close>
            <IconButton
              style={{
                borderRadius: "8px",
                cursor: "pointer",
              }}
              color="gray"
              variant="outline"
            >
              <XIcon color="#000000A6" />
            </IconButton>
          </Dialog.Close>
        </HeaderContainer>

        <ContentContainer>{body}</ContentContainer>

        <FooterContainer>
          <Dialog.Close>
            <AppButton variant="outlined" radius="large">
              Cancel
            </AppButton>
          </Dialog.Close>
          <Dialog.Close>
            <AppButton
              variant="primary"
              radius="large"
              onClick={onSave}
              disabled={disabled}
            >
              Save Changes
            </AppButton>
          </Dialog.Close>
        </FooterContainer>
      </StyledDialogContent>
    </Dialog.Root>
  );
};

export default CampaignBriefOverviewDialog;
