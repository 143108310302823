import { operations } from "../../../openapi";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type BrandOnboardingChatRequestData =
  operations["brand_api_update_brand_onboarding_chat"]["requestBody"]["content"]["application/json"];

const useBrandOnboardingChatMutation = ({
  brandID,
  onSuccess,
  onError,
  onMutate,
}: {
  brandID: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  onMutate?: () => void;
}) => {
  const updateBrandOnboardingChat = useDraperApiPostMutation<
    null,
    BrandOnboardingChatRequestData
  >({
    mutationKey: ["brand-onboarding-chat"],
    path: `/brand/${brandID}/onboarding/chat`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: () => {
      onSuccess?.();
    },
    onMutate: () => {
      onMutate?.();
    },
  });

  return updateBrandOnboardingChat;
};

export default useBrandOnboardingChatMutation;
