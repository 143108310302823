import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import { META_APP_ID, META_CONFIG_ID } from "../../../utils/constants";
import { useDraperApiPostMutation } from "../../../utils/useDraperMutation";
import { operations } from "@openapi";
import { Button, Flex } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import MetaLogo from "~/components/logos/MetaLogo";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

type DisconnectMetaResponse =
  operations["meta_api_disconnect_meta_account"]["responses"]["200"]["content"]["application/json"];
type DisconnectMetaRequestData =
  operations["meta_api_disconnect_meta_account"]["requestBody"]["content"]["application/json"];

type SelectAdAccountResponse =
  operations["meta_api_select_ad_account"]["responses"]["200"]["content"]["application/json"];
type SelectAdAccountRequestData =
  operations["meta_api_select_ad_account"]["requestBody"]["content"]["application/json"];

export type FacebookSuccessResponse = {
  /** An access token for the person using the webpage. */
  accessToken: string;
};

export type FacebookFailResponse = {
  error: string;
  errorReason: string | null;
  errorDescription: string | null;
};

const META_REDIRECT_URL_PATHS =
  process.env.REACT_APP_META_REDIRECT_URL_PATHS?.split(";") || [
    "/auth/meta/callback",
  ];

export function getFacebookRedirectUrl() {
  const urlPath = window.location.pathname;
  const whitelistedPath =
    META_REDIRECT_URL_PATHS.find((path) => urlPath.startsWith(path)) ??
    META_REDIRECT_URL_PATHS[0];
  return `${window.location.origin}${whitelistedPath}`;
}

export function getFacebookRedirectDefaultState({
  brandId,
}: {
  brandId: string;
}): FacebookCallbackState {
  return {
    state: "kt_meta_login",
    path: window.location.pathname,
    brand_id: brandId,
  };
}

export type FacebookCallbackState = {
  state: string;
  path: string;
  brand_id: string;
} & { [key: string]: string | boolean | number };

export interface FacebookLoginProps {
  showAdAccounts?: boolean;
  searchParams?: { [key: string]: string | boolean | number };
  /** Called prior any redirection, to allow persisting the state */
  beforeRedirect?: (url: string) => void;
}

const FacebookLogin: React.FC<FacebookLoginProps> = ({
  showAdAccounts = true,
  searchParams,
  beforeRedirect,
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();

  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAdAccount, setSelectedAdAccount] = useState<string | null>(
    null
  );

  // handle case where the user presses back, restoring the state
  useEffect(() => {
    window.onpageshow = () => {
      setIsWindowOpen(false);
    };
  });

  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);

  const { isPending: isDisconnecting, mutate: disconnectAccount } =
    useDraperApiPostMutation<DisconnectMetaResponse, DisconnectMetaRequestData>(
      {
        path: "/meta/disconnect",
        onError: (error) => {
          setErrorMessage(error.message);
        },
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ["meta-ad-accounts", activeBrandID],
          });
        },
      }
    );

  const selectAdAccountMutation = useDraperApiPostMutation<
    SelectAdAccountResponse,
    SelectAdAccountRequestData
  >({
    path: "/meta/ad-accounts/select",
    onError: (error) => {
      setErrorMessage(error.content);
    },
    onSuccess: (data) => {
      setSelectedAdAccount(data.account_id);
      setErrorMessage("");
    },
  });

  const redirectToFacebook = () => {
    setIsWindowOpen(true);
    const redirect_uri = getFacebookRedirectUrl();
    const CALLBACK_STATE = {
      ...searchParams,
      ...getFacebookRedirectDefaultState({ brandId: activeBrandID }),
    };
    const url =
      `https://facebook.com/dialog/oauth` +
      `?client_id=${META_APP_ID}` +
      `&config_id=${META_CONFIG_ID}` +
      `&state=${JSON.stringify(CALLBACK_STATE)}` +
      `&response_type=code` +
      `&redirect_uri=${redirect_uri}`;
    beforeRedirect?.(url);
    window.location.href = url;
  };

  const buttonTitle = adAccounts?.length ? "Remove Account" : "Connect";
  const buttonAction = () => {
    adAccounts?.length
      ? disconnectAccount({
          brand_id: activeBrandID,
        })
      : redirectToFacebook();
  };

  return (
    <Flex direction="column" flexShrink={"0"}>
      <AppButton
        variant="outlined"
        size="3"
        radius="large"
        style={
          !Boolean(adAccounts?.length)
            ? { backgroundColor: "#0064e0", color: "#FFFFFF", border: "none" }
            : {}
        }
        onClick={buttonAction}
        loading={adAccounts?.length ? isDisconnecting : isWindowOpen}
      >
        {!Boolean(adAccounts?.length) && <MetaLogo />}
        {buttonTitle}
      </AppButton>
      {!!errorMessage && <p style={{ color: "red" }}>Error: {errorMessage}</p>}
      {showAdAccounts && (
        <div>
          {adAccounts?.map((adAccount) => {
            return (
              <Button
                style={{ width: "fit-content" }}
                highContrast={adAccount.is_selected}
                onClick={() =>
                  selectAdAccountMutation.mutate({
                    brand_id: activeBrandID,
                    ad_account_id: adAccount.account_id,
                  })
                }
              >
                {adAccount.name}
              </Button>
            );
          })}
        </div>
      )}
    </Flex>
  );
};

export default FacebookLogin;
