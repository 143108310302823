import DraperSegmentControl from "../core/DraperSegmentedControl";
import InfoText from "../core/InfoText";
import { ShopifyDiscountValueType } from "@openapi";
import { Flex, Text, TextField, Box, SegmentedControl } from "@radix-ui/themes";
import { useEffect, useRef } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandCurrency } from "~/contexts/CurrentUserContext";
import { currencySymbol } from "~/utils/helpers";

const PromotionSelector = ({
  discountValue,
  discountType,
  showDiscountUI,
  setDiscountValue,
  setDiscountType,
  setShowDiscountUI,
}: {
  discountValue: number | null;
  discountType: ShopifyDiscountValueType;
  showDiscountUI: boolean;
  setDiscountValue: (value: number | null) => void;
  setDiscountType: (type: ShopifyDiscountValueType) => void;
  setShowDiscountUI: (show: boolean) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const currency = useActiveBrandCurrency();

  useEffect(() => {
    autoResizeInput();
  }, [discountValue, discountType]);

  const handleValueChange = (value: number) => {
    if (value <= 0) {
      setDiscountValue(null);
      return;
    }
    if (value > 100 && discountType === "percentage") {
      setDiscountValue(100);
      return;
    }
    setDiscountValue(value);
  };

  const handleDiscountTypeChange = (value: ShopifyDiscountValueType) => {
    if (discountValue && value === "percentage" && discountValue > 100) {
      setDiscountValue(null);
    }
    setDiscountType(value);
  };

  const autoResizeInput = () => {
    if (inputRef.current) {
      inputRef.current.style.width = "50px";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  };

  const yesNoComponent = (
    <Flex direction={"column"} gap="4">
      <Flex gap="4">
        <AppButton
          radius="large"
          variant="soft"
          onClick={() => setShowDiscountUI(false)}
          style={{
            border: showDiscountUI ? undefined : "1px solid var(--text-link)",
          }}
        >
          No
        </AppButton>
        <AppButton
          radius="large"
          variant="soft"
          onClick={() => setShowDiscountUI(true)}
          style={{
            border: showDiscountUI ? "1px solid var(--text-link)" : undefined,
          }}
        >
          Yes
        </AppButton>
      </Flex>
    </Flex>
  );

  return (
    <Flex direction={"column"} gap="4">
      <Flex align={"start"} gap="4" direction="column">
        {yesNoComponent}

        {showDiscountUI && (
          <Box>
            <Text size="1">Discount Amount</Text>
            <TextField.Root
              ref={inputRef}
              value={discountValue ?? ""}
              onChange={(e) =>
                handleValueChange(parseInt(e.currentTarget.value))
              }
              type="number"
              style={{
                fontWeight: 500,
                color: "#4C4747",
                fontSize: "16px",
                height: "50px",
                padding: "0px 4px",
                width: "min-content",
                minWidth: "130px",
              }}
              radius="large"
              size="3"
              mt="2"
            >
              <TextField.Slot pl="2px" pr="0" side="right">
                <DraperSegmentControl
                  onValueChange={handleDiscountTypeChange}
                  radius="large"
                  value={discountType}
                  $buttonWidth="36px"
                >
                  <SegmentedControl.Item value="percentage">
                    %
                  </SegmentedControl.Item>
                  <SegmentedControl.Item value="fixed_amount">
                    {currencySymbol(currency)}
                  </SegmentedControl.Item>
                </DraperSegmentControl>
              </TextField.Slot>
            </TextField.Root>
            <InfoText mt="5">
              If you schedule your campaign in Tempo we'll create the discount
              code in Shopify. You must create your discount code manually if
              sending as a draft to Klaviyo.
            </InfoText>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default PromotionSelector;
