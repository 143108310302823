import { useActiveBrandID } from "../../../../../../../contexts/CurrentUserContext";
import {
  getProductsFiltersQuery,
  ProductOrder,
  ProductsFilters,
  ProductSorters,
} from "./useProductsFilters";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

type GetProductsType =
  operations["shopify_integration_api_products"]["responses"][200]["content"]["application/json"];
type GetProductsRequestParams =
  operations["shopify_integration_api_products"]["parameters"]["query"];
export type Product =
  operations["shopify_integration_api_products"]["responses"][200]["content"]["application/json"]["products"][0];

type PageData = {
  before: string | null;
  after: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  products: Product[];
};
const useGetFilteredProducts = ({
  itemsPerPage,
  filters,
  sorters,
  debounceDelay = 200,
}: {
  itemsPerPage: number;
  filters: ProductsFilters;
  sorters: ProductSorters;
  debounceDelay?: number;
}) => {
  const activeBrandID = useActiveBrandID();
  const [debouncedFilters, setDebouncedFilters] =
    useState<ProductsFilters>(filters);
  const [cachedProductsMap, setCachedProductsMap] = useState(
    new Map<Number, PageData>()
  );
  const setFilters = useCallback(
    _.debounce((filters: ProductsFilters) => {
      setDebouncedFilters(filters);
    }, debounceDelay),
    []
  );
  useEffect(() => {
    setFilters(filters);
  }, [filters, setFilters]);

  const updatePages = (
    page: number,
    before: string | null,
    after: string | null,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    products: Product[]
  ) => {
    setCachedProductsMap((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(page, {
        before,
        after,
        hasNextPage,
        hasPreviousPage,
        products,
      });
      return updatedMap;
    });
  };

  useEffect(() => {
    setCachedProductsMap(new Map());
  }, [debouncedFilters.name, debouncedFilters.categoryId, sorters]);

  const { data, isLoading, error, isSuccess } = useQuery({
    queryKey: [
      "commerce-platform/products",
      activeBrandID,
      itemsPerPage,
      debouncedFilters.name,
      debouncedFilters.categoryId,
      debouncedFilters.page,
      sorters,
    ],
    queryFn: async (): Promise<GetProductsType> => {
      const response = await axios.get("/api/v1/commerce-platform/products", {
        params: {
          brand_id: activeBrandID,
          reverse: sorters.order === ProductOrder.DESC,
          sort_by: sorters.type,
          first: itemsPerPage,
          after:
            debouncedFilters.page === 0
              ? null
              : cachedProductsMap.get(debouncedFilters.previousPage)?.after,
          query: getProductsFiltersQuery(debouncedFilters),
        } as GetProductsRequestParams,
      });
      return response.data;
    },
    enabled: !!activeBrandID,
  });
  useEffect(() => {
    if (!isSuccess || error || isLoading) {
      return;
    }

    updatePages(
      debouncedFilters.page,
      data.page_info.start_cursor,
      data.page_info.end_cursor,
      data.page_info.has_next_page,
      data.page_info.has_previous_page,
      data.products
    );
  }, [isLoading, isSuccess]);

  return useMemo(() => {
    return {
      products: cachedProductsMap.get(debouncedFilters.page)?.products,
      hasNextPage: cachedProductsMap.get(debouncedFilters.page)?.hasNextPage,
      hasPreviousPage: cachedProductsMap.get(debouncedFilters.page)
        ?.hasPreviousPage,
    };
  }, [cachedProductsMap, debouncedFilters.page]);
};

export default useGetFilteredProducts;
