import HomeSegmentControl from "./HomeSegmentControl";
import { Flex, Heading } from "@radix-ui/themes";
import { useGateValue } from "@statsig/react-bindings";
import styled from "styled-components";
import TopRightMenu from "~/components/home/components/top-right-menu/TopRightMenu";
import { useMarkPopupContext } from "~/contexts/MarkPopupContext";

const Container = styled(Flex)`
  transition: all 0.5s;
`;
const HomeTopBar = () => {
  const { state } = useMarkPopupContext();
  const isNewHomePage = useGateValue("is_new_home_ui", {
    disableExposureLog: true,
  });

  return (
    <Container
      mt={"32px"}
      mb={state.markPopupOpen ? "100px" : "0px"}
      justify={"between"}
      align={"center"}
      position={"relative"}
      width={"100%"}
    >
      {isNewHomePage ? (
        <Heading size={"7"}>Home</Heading>
      ) : (
        <HomeSegmentControl />
      )}
      <TopRightMenu />
    </Container>
  );
};
export default HomeTopBar;
