import _ from "lodash";
import { useSetActiveTipTapID } from "~/routes/intern/email_editor/context/EmailEditorContext";

export const getTipTapId3 = (id: string) => {
  return `ttid:${id}`;
};

export function useEditableTipTapElement() {
  const setActiveTipTapID = useSetActiveTipTapID();

  return {
    setEditableOnClick: (id: string) => {
      const tipTapId = getTipTapId3(id);
      const element = document.getElementById(tipTapId);
      if (!element) {
        return;
      }
      element.style.pointerEvents = "auto";
      // give the element a minimum height so that it can still be selected if empty
      if (element.tagName === "TD") {
        element.style.height = "10px";
      } else {
        element.style.minHeight = "10px";
      }
      element.onclick = (e) => {
        e.preventDefault();
        setActiveTipTapID(tipTapId);
      };
    },
  };
}

function getFontFamilyNamesRecursive(element: HTMLElement): string[] {
  const fontFamily = element.style.fontFamily.split(",")[0];
  const children = Array.from(element.children);
  const childFontFamilies = children.flatMap((child) =>
    getFontFamilyNamesRecursive(child as HTMLElement)
  );
  return [fontFamily, ...childFontFamilies];
}

export function getTipTapCustomFonts(id: string): string[] | undefined {
  const tipTapId = getTipTapId3(id);
  const element = document.getElementById(tipTapId);
  if (!element) {
    return;
  }
  const fontFamilies = getFontFamilyNamesRecursive(element);
  return _.uniq(fontFamilies).filter((fontFamily) => !!fontFamily);
}

export function getTipTapElementHeight(id: string): number {
  const tipTapId = getTipTapId3(id);
  const element = document.getElementById(tipTapId);
  if (!element) {
    return 0;
  }
  return element.clientHeight;
}
