import { AdCreativeStatus, EmailCreativeStatus } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import _ from "lodash";
import { Calendar1, MegaphoneIcon, Send } from "lucide-react";
import styled from "styled-components";
import { formatPublishedAt } from "~/utils/timeUtils";

const StatusDot = styled.div<{
  color: string;
  size: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
}>`
  width: ${(props) => parseInt(props.size) * 4}px;
  height: ${(props) => parseInt(props.size) * 4}px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

const CampaignStatusIndicator = ({
  status,
  publishedAt,
  size = "2",
  adID,
}: {
  status: EmailCreativeStatus | AdCreativeStatus;
  publishedAt: string | null;
  adID?: string;
  size?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
}) => {
  let color = "#3499F6";
  let text: string = _.capitalize(status);
  if (
    status === EmailCreativeStatus.draft ||
    status === AdCreativeStatus.draft
  ) {
    color = "#3499F6";
  } else if (
    status === EmailCreativeStatus.unpublished ||
    status === AdCreativeStatus.unpublished
  ) {
    color = "#FEB23F";
  } else if (
    status === EmailCreativeStatus.active ||
    status === AdCreativeStatus.active ||
    status === EmailCreativeStatus.scheduled ||
    status === AdCreativeStatus.scheduled
  ) {
    color = "#00C49F";
  }

  let icon = <StatusDot color={color} size={size} />;
  if (
    status === EmailCreativeStatus.active ||
    status === AdCreativeStatus.active ||
    status === EmailCreativeStatus.scheduled ||
    status === AdCreativeStatus.scheduled
  ) {
    color = "#00C49F";

    if (publishedAt) {
      const now = new Date().getTime();
      const publishDate = publishedAt ? new Date(publishedAt).getTime() : null;
      const isPublished = publishDate && publishDate < now;

      const formattedPublishedAt = formatPublishedAt(publishedAt);

      icon = isPublished ? <Send size={16} /> : <Calendar1 size={16} />;
      text = isPublished
        ? "Sent on " + formattedPublishedAt
        : "Scheduled for " + formattedPublishedAt;
    } else if (adID) {
      icon = <MegaphoneIcon size={16} />;
      text = "Ad ID: " + adID;
    }
  }

  return (
    <Flex align="center" gap="8px">
      {icon}
      <Text size={size} style={{ color }}>
        {text}
      </Text>
    </Flex>
  );
};

export default CampaignStatusIndicator;
