import AddCampaignPopover from "../core/inputs/AddCampaignPopover";
import DatePicker from "../core/inputs/DatePicker";
import AddCampaignChatMessage from "./chat/AddCampaignChatMessage";
import {
  ChatProvider,
  DEFAULT_EMAILS_PER_WEEK,
  useChatContext,
  useOptionHandlers,
  UserOption,
  UserOptionType,
} from "./chat/OnboardingChatContext";
import SummaryChatMessage from "./chat/SummaryChatMessage";
import AppButton from "@components/core/buttons/AppButton/AppButton";
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Popover,
  Text,
  TextField,
} from "@radix-ui/themes";
import dayjs from "dayjs";
import { PencilIcon, SendIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import markSrc from "~/assets/markBig.png";

const ChatContainer = styled(Box)`
  width: 50%;
  min-width: 640px;
  height: 90vh;
  overflow-y: hidden;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.51);
  margin: 24px 0;
  border: 1px solid #00000014;
  box-shadow: 0px 2px 8px 0px #0000000a;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled(Flex)`
  padding: 16px;
  border-bottom: 1px solid #00000014;
  justify-content: center;
  align-items: center;
  background: #ffffff80;
`;

const MarkAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid var(--background_white, #ffffff);
`;

const MessagesContainer = styled(Flex)`
  flex: 1;
  overflow-y: auto;
  padding: 16px;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ChatMessage = styled(Box)<{ $isUser?: boolean; $width?: string }>`
  width: ${({ $width }) => $width || "auto"};
  max-width: 80%;
  padding: 8px 12px;
  border-radius: ${({ $isUser }) => ($isUser ? "20px" : "6px 20px 20px 20px")};
  align-self: ${({ $isUser }) => ($isUser ? "flex-end" : "flex-start")};
  background-color: ${({ $isUser }) => ($isUser ? "#0000000A" : "#FFFFFF80")};
  border: 1px solid var(--black-8, #00000014);
  color: #121111;
  animation: ${fadeInUp} 0.3s ease-out forwards;
`;

const ChatInputContainer = styled(Flex)`
  border-top: 1px solid #00000014;
`;

const ChatInput = styled(TextField.Root)`
  width: 100%;
  height: 56px;
  background: #edebf0;
  border: 1px solid #dddddd;
`;

const ResponseButtonsContainer = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  animation: ${fadeInUp} 0.3s ease-out forwards;
`;

const ResponseButton = styled(AppButton)`
  border-radius: 12px;
`;

// Define the conversation flow with a function that takes the dispatch and state
const useConversationFlow = (): {
  botMessage: string;
  userOptions: UserOption[];
}[] => {
  const optionHandlers = useOptionHandlers();
  return [
    {
      botMessage: "Let's create your first campaign plan.",
      userOptions: [],
    },
    {
      botMessage: "When do you want your campaign to start?",
      userOptions: [
        {
          text: "Next week",
          type: "text",
          action: optionHandlers.nextWeek,
          value: "next_week",
        },
        {
          text: "Next month",
          type: "text",
          action: optionHandlers.nextMonth,
          value: "next_month",
        },
        {
          text: "Open date picker",
          type: "date",
          action: optionHandlers.customDate,
          value: "custom_date",
        },
      ],
    },
    {
      botMessage:
        "Great! We'll start your campaign on {campaignStartDate}. We're going to plan out a whole month of campaigns today.",
      userOptions: [],
    },
    {
      botMessage:
        "We recommend sending 3 emails per week. Do you want to keep this pace?",
      userOptions: [
        {
          text: "Looks good!",
          type: "text",
          action: optionHandlers.standardText,
        },
        {
          text: "1 email",
          type: "text",
          action: () => optionHandlers.setEmailsPerWeek(1),
        },
        {
          text: "2 emails",
          type: "text",
          action: () => optionHandlers.setEmailsPerWeek(2),
        },
        {
          text: "4 emails",
          type: "text",
          action: () => optionHandlers.setEmailsPerWeek(4),
        },
        {
          text: "5 emails",
          type: "text",
          action: () => optionHandlers.setEmailsPerWeek(5),
        },
      ],
    },
    {
      botMessage:
        "Alright, campaigns will now send {emailsPerWeek} emails per week.",
      userOptions: [],
    },
    {
      botMessage:
        "Do you have any campaigns already planned? Let me know some details so I'll make sure they're incorporated.",
      userOptions: [
        {
          text: "Add planned campaigns",
          type: "add_campaign",
          action: optionHandlers.addPlannedCampaigns,
        },
        {
          text: "Continue",
          type: "text",
          action: optionHandlers.standardText,
        },
      ],
    },
    {
      botMessage:
        "Do you have campaigns related to upcoming holidays or cultural events?",
      userOptions: [
        {
          text: "No, continue",
          type: "text",
          action: optionHandlers.standardText,
        },
        {
          text: "Yes, input details",
          type: "text_input",
          action: optionHandlers.setEventContext,
        },
      ],
    },
    {
      botMessage:
        "Have any additional feedback or notes that I should consider?",
      userOptions: [
        {
          text: "No, continue",
          type: "text",
          action: optionHandlers.standardText,
        },
        {
          text: "Yes, input details",
          type: "text_input",
          action: optionHandlers.setFeedbackContext,
        },
      ],
    },
  ];
};

const ChatContent = ({ onNextStep }: { onNextStep: () => void }) => {
  const { state, dispatch } = useChatContext();

  // Create conversation flow with the state and dispatch
  const CONVERSATION_FLOW = useConversationFlow();

  const [messages, setMessages] = useState<
    Array<{
      text: string | React.ReactNode;
      isUser: boolean;
      editableType?: UserOptionType;
    }>
  >([{ text: CONVERSATION_FLOW[0].botMessage, isUser: false }]);
  const [currentStep, setCurrentStep] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [pendingNextStep, setPendingNextStep] = useState<number | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const handleUserResponse = (option: UserOption, customData?: any) => {
    // Execute the option's action if it exists
    let value = null;
    if (option.action) {
      if (option.type === "date" && customData) {
        // For date type, pass the custom date data
        value = option.action(customData);
      } else if (option.type === "add_campaign" && customData) {
        // For add_campaign type, pass the custom campaign data
        value = option.action(customData);
      } else if (option.type === "text_input" && customData) {
        // For text_input type, pass the custom event context data
        value = option.action(customData);
      } else if (typeof option.action === "function") {
        // For other types, pass the option itself
        // Use type assertion to handle the union type
        const actionFn = option.action as (option: UserOption) => any;
        value = actionFn(option);
      }
    }

    // Set the next step as pending, but don't move to it immediately
    // This allows the state to update first
    const nextStep = currentStep + 1;
    if (option.type === "add_campaign") {
      setMessages((prev) => [
        ...prev,
        {
          text: <AddCampaignChatMessage campaignId={customData.id} />,
          isUser: false,
        },
      ]);
      return;
    }

    setMessages((prev) => [...prev, { text: value.text, isUser: true }]);

    if (nextStep < CONVERSATION_FLOW.length) {
      setPendingNextStep(nextStep);
    } else {
      setMessages((prev) => [
        ...prev,
        { text: <SummaryChatMessage onNextStep={onNextStep} />, isUser: false },
      ]);
      setPendingNextStep(nextStep);
    }
  };

  // Effect to handle the pending next step after state updates
  useEffect(() => {
    if (
      pendingNextStep !== null &&
      pendingNextStep < CONVERSATION_FLOW.length
    ) {
      setTimeout(() => {
        let nextBotMessage = CONVERSATION_FLOW[pendingNextStep].botMessage;

        // Replace placeholders with actual values
        let editableType: UserOptionType | undefined = undefined;
        if (
          nextBotMessage.includes("{campaignStartDate}") &&
          state.campaignStartDate
        ) {
          editableType = "date";
        } else if (nextBotMessage.includes("{emailsPerWeek}")) {
          editableType = "emails_per_week";
        }

        setMessages((prev) => [
          ...prev,
          { text: nextBotMessage, isUser: false, editableType },
        ]);
        setCurrentStep(pendingNextStep);
        setPendingNextStep(null);
      }, 500);
    }
  }, [pendingNextStep, CONVERSATION_FLOW, state]);

  const handleDateConfirm = (formattedDate: string) => {
    // Find the date option from current step
    const dateOption = CONVERSATION_FLOW[currentStep].userOptions.find(
      (option) => option.type === "date"
    );

    if (dateOption && formattedDate) {
      // Call handleUserResponse with the date option and the selected date
      handleUserResponse(dateOption, formattedDate);
      setSelectedDate("");
    }
  };

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const textInputOption = CONVERSATION_FLOW[currentStep].userOptions.find(
        (option) => option.type === "text_input"
      );
      if (textInputOption) {
        handleUserResponse(textInputOption, inputValue.trim());
      }
      setInputValue("");
    }
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Move to next step if current step has no user options
  useEffect(() => {
    if (
      currentStep < CONVERSATION_FLOW.length &&
      CONVERSATION_FLOW[currentStep].userOptions.length === 0
    ) {
      const nextStep = currentStep + 1;
      if (nextStep < CONVERSATION_FLOW.length) {
        setPendingNextStep(nextStep);
      }
    }
  }, [currentStep, CONVERSATION_FLOW]);

  return (
    <ChatContainer>
      <ChatHeader>
        <MarkAvatar src={markSrc} fallback="M" />
      </ChatHeader>

      <MessagesContainer direction="column" gap="2">
        {messages.map((message, index) => {
          let editableTypeComponent = null;
          if (message.editableType === "date") {
            editableTypeComponent = (
              <DatePicker
                key={index}
                initialDate={selectedDate}
                onSelect={(formattedDate) => {
                  setSelectedDate(formattedDate);
                }}
                onConfirm={(formattedDate) => {
                  dispatch({
                    type: "SET_CAMPAIGN_START_DATE",
                    payload: formattedDate,
                  });
                }}
                triggerComponent={
                  <IconButton
                    variant="ghost"
                    aria-label="Edit"
                    radius="full"
                    size="2"
                  >
                    <PencilIcon size={16} />
                  </IconButton>
                }
              />
            );
          } else if (message.editableType === "emails_per_week") {
            editableTypeComponent = (
              <Popover.Root>
                <Popover.Trigger>
                  <IconButton
                    variant="ghost"
                    aria-label="Edit"
                    radius="full"
                    size="2"
                  >
                    <PencilIcon size={16} />
                  </IconButton>
                </Popover.Trigger>
                <Popover.Content>
                  <ResponseButtonsContainer gap="2">
                    {[1, 2, 3, 4, 5].map((num) => (
                      <ResponseButton
                        key={num}
                        size="1"
                        variant="soft"
                        onClick={() =>
                          dispatch({
                            type: "SET_EMAILS_PER_WEEK",
                            payload: num,
                          })
                        }
                      >
                        {num} email{num > 1 ? "s" : ""}
                      </ResponseButton>
                    ))}
                  </ResponseButtonsContainer>
                </Popover.Content>
              </Popover.Root>
            );
          }

          let finalText: string | React.ReactNode = message.text;
          if (typeof finalText === "string") {
            if (message.editableType === "date") {
              const displayDate = dayjs(state.campaignStartDate).format(
                "MMMM D, YYYY"
              );
              finalText = finalText.replace("{campaignStartDate}", displayDate);
            } else if (message.editableType === "emails_per_week") {
              if (state.emailsPerWeek === DEFAULT_EMAILS_PER_WEEK) {
                finalText = "Alright, we'll keep it at 3 emails per week.";
              } else {
                finalText = finalText.replace(
                  "{emailsPerWeek}",
                  state.emailsPerWeek.toString()
                );
              }
            }
          }

          return (
            <ChatMessage
              key={index}
              $isUser={message.isUser}
              $width={typeof message.text === "string" ? undefined : "100%"}
            >
              <Flex align="center" gap="2">
                {typeof finalText === "string" ? (
                  <Text>{finalText}</Text>
                ) : (
                  finalText
                )}

                {editableTypeComponent}
              </Flex>
            </ChatMessage>
          );
        })}
        <div ref={messagesEndRef} />
      </MessagesContainer>

      {pendingNextStep === null &&
        currentStep < CONVERSATION_FLOW.length &&
        CONVERSATION_FLOW[currentStep].userOptions.length > 0 && (
          <ChatInputContainer direction="column" p="4" gap="3">
            <ResponseButtonsContainer gap="2">
              {CONVERSATION_FLOW[currentStep].userOptions.map(
                (option, index) => {
                  if (option.type === "text_input") {
                    return (
                      <ResponseButton
                        size="2"
                        variant="soft"
                        radius="large"
                        onClick={() => {
                          textInputRef.current?.focus();
                        }}
                      >
                        {option.text}
                      </ResponseButton>
                    );
                  }
                  if (option.type === "add_campaign") {
                    return (
                      <AddCampaignPopover
                        key={index}
                        onAddCampaign={(campaignData) => {
                          handleUserResponse(option, campaignData);
                        }}
                        triggerComponent={
                          <ResponseButton
                            size="2"
                            variant="soft"
                            radius="large"
                          >
                            {option.text}
                          </ResponseButton>
                        }
                      />
                    );
                  }

                  if (option.type === "date") {
                    return (
                      <DatePicker
                        key={index}
                        initialDate={selectedDate}
                        onSelect={(formattedDate) => {
                          setSelectedDate(formattedDate);
                        }}
                        onConfirm={handleDateConfirm}
                        triggerComponent={
                          <ResponseButton
                            size="2"
                            variant="soft"
                            radius="large"
                          >
                            {option.text}
                          </ResponseButton>
                        }
                      />
                    );
                  }

                  return (
                    <ResponseButton
                      key={index}
                      size="2"
                      variant="soft"
                      onClick={() => handleUserResponse(option)}
                      radius="large"
                    >
                      {option.text}
                    </ResponseButton>
                  );
                }
              )}
            </ResponseButtonsContainer>

            {CONVERSATION_FLOW[currentStep].userOptions.some(
              (option) => option.type === "text_input"
            ) &&
              (pendingNextStep === null ||
                pendingNextStep < CONVERSATION_FLOW.length) && (
                <Flex align="center" gap="2" width="100%">
                  <ChatInput
                    ref={textInputRef}
                    id="prompt"
                    type="text"
                    placeholder={"Type your message here..."}
                    size="3"
                    radius="large"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.currentTarget.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevents any default behavior like submitting forms
                        handleSendMessage();
                      }
                    }}
                  >
                    <TextField.Slot side="right">
                      <IconButton
                        variant="solid"
                        disabled={inputValue === ""}
                        onClick={handleSendMessage}
                      >
                        <SendIcon color="white" size="16" />
                      </IconButton>
                    </TextField.Slot>
                  </ChatInput>
                </Flex>
              )}
          </ChatInputContainer>
        )}
    </ChatContainer>
  );
};

export default function OnboardingMarketingChatStep({
  onNextStep,
}: {
  onNextStep: () => void;
}) {
  return (
    <ChatProvider>
      <ChatContent onNextStep={onNextStep} />
    </ChatProvider>
  );
}
