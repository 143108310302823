import HorizontalScrollableTemplateSelector from "~/components/core/editor/sidepane/HorizontalScrollableTemplateSelector";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isNewSection } from "~/utils/emails/useSectionTypeCheck";

const GeneratedVariantSelector = () => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId, sections } = useEmailState();
  const section = sections.find((section) => section.id === selectedSectionId);

  if (!section || isNewSection(section)) {
    return null;
  }

  return (
    <HorizontalScrollableTemplateSelector
      sectionType={section.type}
      selectedTemplate={section.template}
      onSelectTemplate={(template) => {
        updateSection({
          sectionId: selectedSectionId,
          field: "template",
          value: template,
        });
      }}
    />
  );
};

export default GeneratedVariantSelector;
