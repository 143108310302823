import React from "react";
import { toast } from "sonner";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { RelatedImageType } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/RelatedImages";
import ImageSelector, {
  ImageSelectorProps,
} from "~/components/core/inputs/ImageSelector";
import {
  useAdEditorDispatch,
  useAdEditorSelectedAdMediaState,
} from "~/contexts/AdEditorContext";
import { downloadFile } from "~/utils/fileUtils";

type AdImageSelectorProps = Omit<
  ImageSelectorProps,
  "assetsMetadata" | "onSelect" | "onGenerateComplete" | "campaignId"
> & {
  productId?: string | null;
  onUpload: (file: File) => void;
  hideGenerate?: boolean;
};
const AdImageSelector: React.FC<AdImageSelectorProps> = ({
  productId,
  onUpload,
  hideGenerate = false,
  ...props
}) => {
  const { ad } = useAdEditorSelectedAdMediaState();
  const adDispatch = useAdEditorDispatch();

  const handleImageSelected = async (assets: AssetSelectorItem[]) => {
    const file = await downloadFile(assets[0].url);
    if (!file) {
      toast.error("Failed to retrieve image");
      return;
    }

    adDispatch({
      type: "SET_IS_DIRTY",
      payload: true,
    });
    onUpload(file);
  };

  const handleCrop = (file: File) => {
    adDispatch({
      type: "SET_IS_DIRTY",
      payload: true,
    });
    props.onCrop?.(file);
  };

  return (
    <ImageSelector
      {...props}
      campaignId={ad?.data.campaign.id ?? ""}
      selectedCommercePlatformId={productId ?? undefined}
      type={RelatedImageType.DEFAULT}
      onSelect={handleImageSelected}
      onCrop={handleCrop}
    />
  );
};

export default AdImageSelector;
