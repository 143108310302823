import { operations } from "@openapi";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

type UpdateBrandInspirationsResponse =
  operations["brand_api_update_brand_inspirations"]["responses"][200]["content"]["application/json"];

type UpdateBrandInspirationsParams =
  operations["brand_api_update_brand_inspirations"]["requestBody"]["content"]["application/json"];

export const useBrandUpdateBrandInspirationsMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: UpdateBrandInspirationsResponse) => void;
}) => {
  const activeBrandID = useActiveBrandID();

  return useDraperApiPatchMutation<
    UpdateBrandInspirationsResponse,
    UpdateBrandInspirationsParams
  >({
    path: `/brand/${activeBrandID}/brand-inspirations`,
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });
};
