import { CampaignRecommendation } from "../../hooks/recommendations/useGetCampaignRecommendations";
import HomeCampaignCard from "../recommenderSection/HomeCampaignCard";
import RecommenderCard from "../recommenderSection/RecommenderCard";
import HomeCampaignCardLabels from "./HomeCampaignCardLabels";
import { CampaignStatus, CampaignUnifiedStatus, components } from "@openapi";
import { Flex, Heading, IconButton, Text } from "@radix-ui/themes";
import dayjs from "dayjs";
import {
  ArrowLeft,
  ArrowRight,
  ChevronUp,
  MailIcon,
  Plus,
  X,
} from "lucide-react";
import React, { useState, useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
} from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import AddCampaignPopover from "~/components/core/inputs/AddCampaignPopover";
import MetaLogo from "~/components/logos/MetaLogo";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignDeleteMutation from "~/hooks/campaign/useCampaignDeleteMutation";
import useCampaignHomeCalendarQuery from "~/hooks/campaign/useCampaignHomeCalendarQuery";
import { useCampaignUpdateMutationInHomePage } from "~/hooks/campaign/useCampaignUpdateMutation";
import { useCreateCampaignMutationInHomePage } from "~/hooks/campaign/useCreateCampaignMutation";
import { useRecommenderCampaignRecommendationUpdateMutationInHomePage } from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";
import { NEW_CAMPAIGN_EDIT_ROUTE } from "~/routes/constants";
import {
  getCampaignUnifiedStatus,
  getCampaignUnifiedStatusFromRecommendation,
} from "~/utils/campaign/utils";

// Styled components
const CalendarContainer = styled(Flex)`
  width: 100%;
  border-radius: 32px;
  border: 1px solid var(--black-8, #00000014);
  box-shadow: 0px 2px 8px 0px #0000000a;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CalendarHeader = styled(Flex)`
  width: 100%;
  padding: 24px;
  background-color: #ffffffb8;
  flex-shrink: 0;
`;

const CalendarGrid = styled(Flex)<{ $isMonth?: boolean }>`
  width: 100%;
  border-top: 1px solid var(--border-primary);
  background-color: ${(props) => (!props.$isMonth ? "#ffffff80" : undefined)};
  background: ${(props) =>
    !props.$isMonth
      ? undefined
      : "linear-gradient(279.8deg, #FCF1E7 -31.26%, #FFFFFF 117.68%)"};
  flex-wrap: wrap;
  overflow-y: auto;
  flex: 1;
  max-height: 76vh;
`;
const CalendarDay = styled(Flex)<{ isCurrentMonth?: boolean }>`
  flex: 1;
  min-height: 150px;
  height: auto;
  border-right: 1px solid var(--border-primary);
  border-bottom: 1px solid var(--border-primary);
  padding: 8px;
  min-width: calc(100% / 7);
  max-width: calc(100% / 7);
  opacity: ${(props) => (props.isCurrentMonth === false ? 0.5 : 1)};

  &:nth-child(7n) {
    border-right: none;
  }

  &:nth-last-child(-n + 7) {
    border-bottom: none;
  }

  &:last-child {
    border-right: none;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const DayNumber = styled(Text)`
  font-weight: 600;
  font-size: 14px;
`;

const CalendarCard = styled(Flex)`
  width: 100%;
  background-color: #ffffffb8;
  border-radius: 10px;
  padding: 8px;
  margin-top: 8px;
  cursor: pointer;
  transition: border 0.2s;
  border: 1px dashed #dddddd;

  &:hover {
    border: 1px solid #c5b8b8;
  }
`;

const CalendarCardImage = styled.div<{ url: string | null }>`
  height: 32px;
  width: 32px;
  border: 1px solid #0000000a;
  border-radius: 8px;
  background: ${(props) => (props.url ? `url(${props.url})` : "#f1f1f1")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const IconButtonContainer = styled(IconButton)`
  border-radius: 12px;
`;

const LoadingPlaceholder = styled(Flex)`
  background-color: #f0f0f0;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  margin-top: 8px;
  animation: pulse 1.5s infinite ease-in-out;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const LoadingDayNumber = styled(Flex)`
  background-color: #f0f0f0;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  animation: pulse 1.5s infinite ease-in-out;
`;

const FloatingCampaignCard = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 16px 36px 0px #58422d0a;
  margin: 16px;
  z-index: 10;
  border-radius: 0 0 32px 32px;
  animation: fadeIn 0.1s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
`;

const DayLabelsContainer = styled(Flex)`
  flex-direction: row;
  flex-shrink: 0;
`;

const AddCampaignButton = styled(AppButton)`
  opacity: 0;
`;

type CalendarVariant = "week" | "month";

export type UnifiedCampaign =
  | CampaignRecommendation
  | components["schemas"]["CampaignSchema"];

interface DraperCalendarProps {
  onCampaignClick?: (campaign: UnifiedCampaign) => void;
  variant?: CalendarVariant;
  rightHeaderComponent?: React.ReactNode;
  initialCampaign?: UnifiedCampaign;
}

// Component for handling additional events display
interface DayEventsProps {
  events: UnifiedCampaign[];
  onCampaignClick: (campaign: UnifiedCampaign) => void;
  isDragDisabled: boolean;
}

const DayEventCard = React.forwardRef<
  HTMLDivElement,
  {
    title: string | null;
    imageUrl: string | null;
    status: CampaignUnifiedStatus | null;
    isAdEnabled: boolean;
    isEmailEnabled: boolean;
    onClickCard?: () => void;
    [key: string]: any; // We need this to use as trigger component for AddCampaignPopover
  }
>(
  (
    {
      title,
      imageUrl,
      status,
      onClickCard,
      isAdEnabled,
      isEmailEnabled,
      ...props
    },
    ref
  ) => {
    return (
      <CalendarCard
        direction="column"
        gap="2"
        ref={ref}
        onClick={onClickCard}
        {...props}
      >
        <Flex align="center" gap="2" justify="between">
          <Flex align="center" gap="1">
            <CalendarCardImage url={imageUrl} />
            {isAdEnabled && <MetaLogo color="rgb(92, 88, 88)" size={20} />}
            {isEmailEnabled && <MailIcon size={16} />}
          </Flex>
          {status && <HomeCampaignCardLabels status={status} />}
        </Flex>
        {title && (
          <Text size="2" weight="medium">
            {title}
          </Text>
        )}
      </CalendarCard>
    );
  }
);

const DayEventCampaignCard = ({
  campaign,
  provided,
  onCampaignClick,
  isDragDisabled,
}: {
  campaign: components["schemas"]["CampaignSchema"];
  provided: DraggableProvided;
  onCampaignClick: (campaign: UnifiedCampaign) => void;
  isDragDisabled: boolean;
}) => {
  const navigate = useNavigate();
  const activeBrandID = useActiveBrandID();

  const { updateCampaign, isUpdatingCampaign } =
    useCampaignUpdateMutationInHomePage({});

  const { mutate: deleteCampaign, isPending: isDeletingCampaign } =
    useCampaignDeleteMutation({
      campaignId: campaign.id,
    });

  const isBrandPlannedCampaign = campaign.status === CampaignStatus.planned;
  const status = getCampaignUnifiedStatus(campaign);

  let cardContents = (
    <DayEventCard
      title={campaign.title}
      imageUrl={campaign?.items?.[0]?.image_url || null}
      status={status}
      onClickCard={() => onCampaignClick(campaign)}
      isAdEnabled={(campaign.channels?.meta_ads ?? 0) > 0}
      isEmailEnabled={(campaign.channels?.emails ?? 0) > 0}
    />
  );
  if (isBrandPlannedCampaign) {
    cardContents = (
      <AddCampaignPopover
        title="Add to Calendar"
        initialDate={campaign.date ?? undefined}
        initialCampaign={{
          title: campaign.title,
          startDate: campaign.date ?? "",
          type: campaign.type,
          products: campaign.items
            ? new Map(campaign.items.map((item) => [item.product_id, item]))
            : new Map(),
          id: campaign.id,
        }}
        onAddCampaign={(campaignData) => {
          updateCampaign({
            campaign_id: campaign.id,
            brand_id: activeBrandID,
            date: campaignData.startDate,
            title: campaignData.title,
            type: campaignData.type,
            commerce_platform_product_ids: Array.from(
              campaignData.products.values()
            ).map((product) => product.product_id),
          });
        }}
        onDeleteCampaign={(campaignId) => {
          deleteCampaign();
        }}
        triggerComponent={cardContents}
        primaryButtonOverride={
          <AppButton
            variant="primary"
            onClick={() => {
              navigate(NEW_CAMPAIGN_EDIT_ROUTE.replace(":id", campaign.id));
            }}
            size="3"
            radius="large"
          >
            Create Campaign
          </AppButton>
        }
      />
    );
  }
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        cursor:
          isDragDisabled || isUpdatingCampaign || isDeletingCampaign
            ? "not-allowed"
            : "pointer",
        opacity:
          isDragDisabled || isUpdatingCampaign || isDeletingCampaign ? 0.5 : 1,
        position: "relative",
        ...provided.draggableProps.style,
      }}
    >
      {cardContents}
    </div>
  );
};

const DayEvents: React.FC<DayEventsProps> = ({
  events,
  onCampaignClick,
  isDragDisabled,
}) => {
  const [expanded, setExpanded] = useState(false);
  const additionalEventsCount = events.length - 1;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const eventCountShown = useMemo(() => {
    if (expanded) {
      return events.length;
    }
    return 1;
  }, [expanded, events.length]);

  return (
    <>
      {events.slice(0, eventCountShown).map((event) => (
        <Draggable
          key={event.id}
          draggableId={
            "type" in event
              ? event.id + ":campaign"
              : event.id + ":campaign_recommendation"
          }
          index={0}
          isDragDisabled={isDragDisabled}
        >
          {(provided, snapshot) => {
            let cardContents =
              "type" in event ? (
                <DayEventCampaignCard
                  campaign={event}
                  provided={provided}
                  onCampaignClick={onCampaignClick}
                  isDragDisabled={isDragDisabled}
                />
              ) : (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    cursor: isDragDisabled ? "not-allowed" : "pointer",
                    opacity: isDragDisabled ? 0.5 : 1,
                    position: "relative",
                    ...provided.draggableProps.style,
                  }}
                >
                  <DayEventCard
                    key={event.id}
                    title={event.title}
                    imageUrl={event?.products?.[0]?.image_url || null}
                    status={getCampaignUnifiedStatusFromRecommendation(event)}
                    onClickCard={() => onCampaignClick(event)}
                    isAdEnabled={event.channel_meta_ads ?? 0 > 0}
                    isEmailEnabled={event.channel_emails ?? 0 > 0}
                  />
                </div>
              );
            return cardContents;
          }}
        </Draggable>
      ))}

      {additionalEventsCount > 0 && (
        <CalendarCard justify="center" align="center" onClick={toggleExpanded}>
          {expanded ? (
            <Flex align="center" gap="1">
              <Text size="1">Collapse</Text>
              <ChevronUp size={12} />
            </Flex>
          ) : (
            <Text size="1">+{additionalEventsCount} more</Text>
          )}
        </CalendarCard>
      )}
    </>
  );
};

const DraperCalendar: React.FC<DraperCalendarProps> = ({
  onCampaignClick,
  variant = "week",
  rightHeaderComponent,
  initialCampaign,
}) => {
  const activeBrandID = useActiveBrandID();

  const [selectedCampaign, setSelectedCampaign] =
    useState<UnifiedCampaign | null>(initialCampaign || null);

  const [currentDate, setCurrentDate] = useState(() => {
    // Set initial date based on initialCampaign or default to today
    const baseDate = initialCampaign?.date
      ? dayjs(initialCampaign.date).startOf("day").toDate()
      : dayjs().startOf("day").toDate();

    if (variant === "week") {
      const day = baseDate.getDay(); // 0 is Sunday, 6 is Saturday
      const diff = baseDate.getDate() - day;
      return new Date(baseDate.setDate(diff)); // First day of week
    } else {
      // For month view, set to first day of the month
      return new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
    }
  });

  const goToPrevious = () => {
    const newDate = new Date(currentDate);
    if (variant === "week") {
      newDate.setDate(newDate.getDate() - 7);
    } else {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
    setSelectedCampaign(null);
  };

  const goToNext = () => {
    const newDate = new Date(currentDate);
    if (variant === "week") {
      newDate.setDate(newDate.getDate() + 7);
    } else {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
    setSelectedCampaign(null);
  };

  // Generate calendar days based on variant
  const calendarDays = useMemo(() => {
    if (variant === "week") {
      // Generate week days
      return Array.from({ length: 7 }, (_, i) => {
        const date = new Date(currentDate);
        date.setDate(date.getDate() + i);
        return { date, isCurrentMonth: true };
      });
    } else {
      // Generate month days
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      // Get the first day of the month
      const firstDay = new Date(year, month, 1);
      // Get the last day of the month
      const lastDay = new Date(year, month + 1, 0);

      // Get the day of the week for the first day (0 = Sunday, 6 = Saturday)
      const firstDayOfWeek = firstDay.getDay();

      // Calculate days from previous month to show
      const daysFromPrevMonth = firstDayOfWeek;

      // Calculate total days to show (previous month days + current month days + next month days to complete grid)
      const totalDaysInMonth = lastDay.getDate();
      const totalDaysToShow =
        Math.ceil((daysFromPrevMonth + totalDaysInMonth) / 7) * 7;

      const days = [];

      // Add days from previous month
      const prevMonth = new Date(year, month, 0);
      const prevMonthTotalDays = prevMonth.getDate();

      for (let i = daysFromPrevMonth - 1; i >= 0; i--) {
        const date = new Date(year, month - 1, prevMonthTotalDays - i);
        days.push({ date, isCurrentMonth: false });
      }

      // Add days from current month
      for (let i = 1; i <= totalDaysInMonth; i++) {
        const date = new Date(year, month, i);
        days.push({ date, isCurrentMonth: true });
      }

      // Add days from next month to complete the grid
      const remainingDays = totalDaysToShow - days.length;
      for (let i = 1; i <= remainingDays; i++) {
        const date = new Date(year, month + 1, i);
        days.push({ date, isCurrentMonth: false });
      }

      return days;
    }
  }, [currentDate, variant]);

  // Check if date is today
  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  // Calculate end date based on variant
  const endDate = useMemo(() => {
    if (variant === "week") {
      const end = new Date(currentDate);
      end.setDate(end.getDate() + 6);
      return end;
    } else {
      // For month view, get the last day of the month
      return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }
  }, [currentDate, variant]);

  // Format header date based on variant
  const headerDate = useMemo(() => {
    if (variant === "week") {
      const startMonth = currentDate.toLocaleDateString("en-US", {
        month: "short",
      });
      const startDay = currentDate.getDate();
      const endMonth = endDate.toLocaleDateString("en-US", { month: "short" });
      const endDay = endDate.getDate();
      const year = currentDate.getFullYear();
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`;
    } else {
      return currentDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    }
  }, [currentDate, endDate, variant]);

  // Queries
  const { data: calendarData, isLoading: isLoadingCalendarPage } =
    useCampaignHomeCalendarQuery({
      startDate:
        variant === "week"
          ? currentDate
          : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: endDate,
    });

  // Process calendar data to group events by day
  const eventsByDay = useMemo(() => {
    const events: Record<string, UnifiedCampaign[]> = {};

    if (calendarData) {
      calendarData.campaigns.forEach((campaign) => {
        if (campaign.date) {
          const formattedDate = dayjs(campaign.date)
            .startOf("day") // Ensures midnight
            .format("YYYY-MM-DDTHH:mm:ss");

          if (!events[formattedDate]) {
            events[formattedDate] = [];
          }

          events[formattedDate].push(campaign);
        }
      });
    }

    return events;
  }, [calendarData]);

  const handleCampaignClick = (campaign: UnifiedCampaign) => {
    setSelectedCampaign(campaign);
    if (onCampaignClick) {
      onCampaignClick(campaign);
    }
  };

  // Mutations
  const { updateCampaign, isUpdatingCampaign } =
    useCampaignUpdateMutationInHomePage({});

  const { updateCampaignRecommendation, isCampaignRecommendationUpdating } =
    useRecommenderCampaignRecommendationUpdateMutationInHomePage();

  const { createCampaign, isCreatingCampaign } =
    useCreateCampaignMutationInHomePage();

  // Functions
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId === destination.droppableId) return;

    if (draggableId.includes(":campaign_recommendation")) {
      updateCampaignRecommendation({
        campaign_recommendation_id: draggableId.split(":")[0],
        brand_id: activeBrandID,
        date: destination.droppableId,
      });
    } else {
      updateCampaign({
        campaign_id: draggableId.split(":")[0],
        brand_id: activeBrandID,
        date: destination.droppableId,
      });
    }
  };

  return (
    <CalendarContainer>
      <CalendarHeader justify="between" align="center">
        <Flex align="center" gap="3">
          <IconButtonContainer
            onClick={goToPrevious}
            variant="outline"
            size="3"
            radius="large"
            color="gray"
            disabled={isLoadingCalendarPage}
          >
            <ArrowLeft size={20} />
          </IconButtonContainer>
          <Heading size="3">{headerDate}</Heading>
          <IconButtonContainer
            onClick={goToNext}
            variant="outline"
            size="3"
            radius="large"
            color="gray"
            disabled={isLoadingCalendarPage}
          >
            <ArrowRight size={20} />
          </IconButtonContainer>
        </Flex>

        {rightHeaderComponent}
      </CalendarHeader>

      {variant === "month" && (
        <DayLabelsContainer>
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <Flex
              key={day}
              style={{
                flex: 1,
                justifyContent: "center",
                padding: "8px 0",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {day}
            </Flex>
          ))}
        </DayLabelsContainer>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <CalendarGrid direction="row" $isMonth={variant === "month"}>
          {calendarDays.map(({ date, isCurrentMonth }) => {
            const formattedDate = dayjs(date)
              .startOf("day") // Ensures midnight
              .format("YYYY-MM-DDTHH:mm:ss");

            const eventsForDay = !isLoadingCalendarPage
              ? eventsByDay[formattedDate] || []
              : [];

            return (
              <StrictModeDroppable droppableId={formattedDate} type="campaign">
                {(provided) => {
                  return (
                    <CalendarDay
                      key={formattedDate}
                      direction="column"
                      isCurrentMonth={isCurrentMonth}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Flex justify="between" align="center">
                        {isLoadingCalendarPage ? (
                          <LoadingDayNumber />
                        ) : (
                          <DayNumber
                            style={{
                              color: isToday(date)
                                ? "var(--primary-deep-purple)"
                                : "inherit",
                              fontWeight: isToday(date) ? "700" : "600",
                            }}
                          >
                            {date.getDate()}
                          </DayNumber>
                        )}
                        {variant === "month" && !isLoadingCalendarPage && (
                          <AddCampaignPopover
                            title="Add to Calendar"
                            initialDate={date.toDateString()}
                            onAddCampaign={(campaignData) => {
                              createCampaign({
                                brand_id: activeBrandID,
                                title: campaignData.title,
                                date: campaignData.startDate,
                                type: campaignData.type,
                                commerce_platform_product_ids: Array.from(
                                  campaignData.products.values()
                                ).map((product) => product.product_id),
                              });
                            }}
                            triggerComponent={
                              <AddCampaignButton
                                variant="ghost"
                                size="1"
                                radius="large"
                                disabled={isCreatingCampaign}
                              >
                                <Flex align="center" gap="1">
                                  <Plus size={16} />
                                  Add
                                </Flex>
                              </AddCampaignButton>
                            }
                          />
                        )}
                      </Flex>

                      {isLoadingCalendarPage ? (
                        <>
                          <LoadingPlaceholder />
                        </>
                      ) : (
                        <>
                          {eventsForDay.length > 0 && (
                            <DayEvents
                              events={eventsForDay}
                              onCampaignClick={handleCampaignClick}
                              isDragDisabled={
                                isUpdatingCampaign ||
                                isCampaignRecommendationUpdating
                              }
                            />
                          )}
                        </>
                      )}
                      {provided.placeholder}
                    </CalendarDay>
                  );
                }}
              </StrictModeDroppable>
            );
          })}
        </CalendarGrid>
      </DragDropContext>

      {selectedCampaign && variant === "month" && (
        <FloatingCampaignCard direction="column" gap="3">
          {"type" in selectedCampaign ? (
            <HomeCampaignCard
              key={selectedCampaign.id}
              campaign={selectedCampaign}
              onUpdateCallback={() => setSelectedCampaign(null)}
              onDeleteCallback={() => setSelectedCampaign(null)}
            />
          ) : (
            <RecommenderCard
              key={selectedCampaign.id}
              recommendation={selectedCampaign}
              onUpdateCallback={() => setSelectedCampaign(null)}
              onDeleteCallback={() => setSelectedCampaign(null)}
            />
          )}

          <CloseButton
            size="1"
            variant="ghost"
            onClick={() => setSelectedCampaign(null)}
          >
            <X size={16} />
          </CloseButton>
        </FloatingCampaignCard>
      )}
    </CalendarContainer>
  );
};

export default DraperCalendar;
