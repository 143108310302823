import { operations } from "@openapi";
import { toast } from "sonner";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type InternEmailTemplateScreenshotRequestData =
  operations["intern_api_screenshot_email_template"]["requestBody"]["content"]["application/json"];

const useInternEmailTemplateScreenshotMutation = ({
  templateID,
  onSuccess,
}: {
  templateID: string;
  onSuccess?: () => void;
}) => {
  const { mutate, isPending, isSuccess } = useDraperApiPostMutation<
    undefined,
    InternEmailTemplateScreenshotRequestData
  >({
    mutationKey: ["internEmailTemplateScreenshot"],
    path: `/intern/email-templates/${templateID}/screenshot`,
    onSuccess: () => {
      toast.success("Email template screenshot generated");
      onSuccess?.();
    },
    onError: () => {
      toast.error("Failed to generate email template screenshot");
    },
  });

  return {
    screenshotEmailTemplate: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternEmailTemplateScreenshotMutation;
