import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import { CampaignCardCommonProps } from "./CampaignCards";
import useCampaignPromotionProps from "./hooks/useCampaignPromotionProps";
import { Flex } from "@radix-ui/themes";
import PromotionSelector from "~/components/selectors/PromotionSelector";

const CampaignPromotion: React.FC<CampaignCardCommonProps> = (props) => {
  const {
    cardProps,
    inputProps: {
      discountType,
      discountValue,
      setDiscountType,
      setDiscountValue,
      showDiscountUI,
      setShowDiscountUI,
      initDiscountValue,
    },
  } = useCampaignPromotionProps();

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle={
        initDiscountValue
          ? `We recommend a ${initDiscountValue}% discount but you can change this. We'll create the discount code for you.`
          : "Would you like to include a discount as part of this campaign?"
      }
      {...props}
    >
      <Flex direction="column" gap="24px">
        <PromotionSelector
          discountValue={discountValue}
          discountType={discountType}
          showDiscountUI={showDiscountUI}
          setDiscountValue={setDiscountValue}
          setDiscountType={setDiscountType}
          setShowDiscountUI={setShowDiscountUI}
        />
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignPromotion;
