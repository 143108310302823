import StyleLibraryCardContent from "../StyleLibraryCardContent";
import BrandAssets from "./BrandAssets";
import { ImageAssetCategory } from "@openapi";
import { createRef } from "react";
import { DropzoneRef } from "react-dropzone";
import { PencilIcon, PlusIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

const LogoAssetsCardSection = () => {
  const { data: brandStyle, isLoading } = useBrandStyle();
  const logos = brandStyle?.logos ?? [];
  const dropzoneRef = createRef<DropzoneRef>();

  const triggerUpload = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <StyleLibraryCardContent
      title="Logo Assets"
      isLoading={isLoading}
      actions={
        <AppButton size="3" variant="ghost" onClick={triggerUpload}>
          <PlusIcon />
          Add Logo
        </AppButton>
      }
    >
      <BrandAssets
        assets={logos}
        category={ImageAssetCategory.logo}
        ref={dropzoneRef}
        multiple={false}
        canDelete={logos.length > 1}
      />
    </StyleLibraryCardContent>
  );
};

export default LogoAssetsCardSection;
