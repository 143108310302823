import { getCampaignHomeCalendarQueryKey } from "./useCampaignHomeCalendarQuery";
import { getCampaignHomeQueryKey } from "./useCampaignHomeQuery";
import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useDraperApiDeleteMutation } from "~/utils/useDraperMutation";

export type CampaignDeleteResponse =
  operations["campaign_api_delete_campaign"]["responses"][200]["content"]["application/json"];

const useCampaignDeleteMutation = ({
  campaignId,
  onSuccess,
  onMutate,
  onError,
  onSettled,
}: {
  campaignId: string;
  onSuccess?: (data: CampaignDeleteResponse) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onError?: (error: Error, context: any) => void;
}) => {
  const queryClient = useQueryClient();
  const brandId = useActiveBrandID();

  return useDraperApiDeleteMutation<CampaignDeleteResponse, void>({
    mutationKey: ["campaign-delete", campaignId],
    path: `/campaign/${campaignId}`,
    onMutate: () => {
      onMutate?.();
    },
    onSuccess: (data) => {
      onSuccess?.(data);

      queryClient.setQueriesData(
        {
          queryKey: getCampaignHomeCalendarQueryKey(brandId),
          type: "all",
          exact: false,
        },
        (oldData: any) => {
          if (!oldData) return oldData;

          return {
            campaigns: oldData.campaigns.filter(
              (c: any) => c.id !== campaignId
            ),
          };
        }
      );

      queryClient.setQueriesData(
        {
          queryKey: getCampaignHomeQueryKey(brandId, false),
          type: "all",
          exact: false,
        },
        (oldData: any) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page: any) => {
              return {
                ...page,
                campaigns: page.campaigns.filter(
                  (c: any) => c.id !== campaignId
                ),
              };
            }),
          };
        }
      );

      queryClient.setQueriesData(
        {
          queryKey: getCampaignHomeQueryKey(brandId, true),
          type: "all",
          exact: false,
        },
        (oldData: any) => {
          if (!oldData?.pages?.[0]?.total_count) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map((page: any) => {
              return {
                ...page,
                total_count: page.total_count - 1,
              };
            }),
          };
        }
      );

      toast.success("Campaign deleted");
    },
    onError: (error, context) => {
      onError?.(error, context);
    },
    onSettled,
  });
};

export default useCampaignDeleteMutation;
