import InternGenerateImageAssetDialog from "./InternGenerateImageAssetDialog";
import { operations } from "@openapi";
import {
  Button,
  Card,
  Container,
  Flex,
  Heading,
  Link,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { Cog, RefreshCcw, Sparkles } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useInternGetImageAsset, {
  getImageAssetQueryKey,
} from "~/hooks/intern/useInternGetImageAsset";
import useInternGetImageAssetAnalysis from "~/hooks/intern/useInternGetImageAssetAnalysis";
import useInternGetImageAssetVariants from "~/hooks/intern/useInternGetImageAssetVariants";
import useInternReclassifyImageAsset from "~/hooks/intern/useInternReclassifyImageAsset";

type ReclassifyImageAssetResponse =
  operations["intern_api_reclassify_image_asset"]["responses"][200]["content"]["application/json"];

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const CardHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const InfoCard = styled(Card)`
  position: relative;
  min-width: 340px;
  border-radius: 4px;
  flex: 1;
`;

const CardField = styled(Flex)`
  flex-direction: column;
`;

const SecondaryCard = styled(Card)`
  position: relative;
  border-radius: 4px;
  flex: 1;
`;

const Image = styled.img`
  min-width: 100%;
  max-height: 400px;
  border-radius: 4px;
  object-fit: contain;
  display: block;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px; /* Adjust the size as necessary */
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  @media (min-width: 1512px) {
    flex-direction: row;
    min-height: 600px;
  }
`;

const VariantImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const VariantImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 4px;
  object-fit: contain;
  display: block;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px; /* Adjust the size as necessary */
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

const VariantLabel = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 12px;
  white-space: nowrap;
`;

const FlexContainer = styled(Flex)`
  flex-direction: column;
  @media (min-width: 1256px) {
    flex-direction: row;
  }
`;

const InternAssetComponent = () => {
  const { id: imageAssetId } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const {
    data: imageAsset,
    isLoading,
    isError,
  } = useInternGetImageAsset({ imageAssetId });

  const { data: imageVariants } = useInternGetImageAssetVariants({
    imageAssetId,
  });

  const { data: imageAnalysis } = useInternGetImageAssetAnalysis({
    imageAssetId,
  });

  const [isGenerateImageDialogOpen, setIsGenerateImageDialogOpen] =
    useState<boolean>(false);

  const { mutate: reclassify, isPending: isReclassifyLoading } =
    useInternReclassifyImageAsset(
      imageAssetId,
      getImageAssetQueryKey(imageAssetId),
      {
        onSuccess: (response: ReclassifyImageAssetResponse) => {},
        onError: (error: Error) => {
          alert(error);
        },
      }
    );

  if (isLoading) {
    return (
      <Container size="1" style={{ backgroundColor: "black", color: "white" }}>
        <Spinner />
        <Text>Loading...</Text>
      </Container>
    );
  }

  if (isError || !imageAsset) {
    return (
      <Container size="1" style={{ backgroundColor: "black", color: "white" }}>
        <Text>Error loading image asset.</Text>
      </Container>
    );
  }

  return (
    <>
      <InternGenerateImageAssetDialog
        assetID={imageAsset.id}
        isDialogOpen={isGenerateImageDialogOpen}
        setIsDialogOpen={setIsGenerateImageDialogOpen}
        queryKey={getImageAssetQueryKey(imageAsset.id)}
      />
      <Container size="4">
        <Flex direction="column" gap="2">
          <FlexContainer gap="4">
            <ImageContainer>
              <Image src={imageAsset.url} alt={imageAsset.original_filename} />
            </ImageContainer>
            <InfoCard>
              <Flex direction="column" gap="2">
                <Flex align="center" justify="between">
                  <CardHeading>Image</CardHeading>
                  <Button
                    size="1"
                    variant="surface"
                    title="View in Admin"
                    onClick={(e) => {
                      e.stopPropagation();
                      const currentHost = window.location.hostname;
                      const isLocalhost = currentHost === "localhost";
                      const baseUrl = isLocalhost
                        ? "http://localhost:8000"
                        : "";
                      window.open(
                        `${baseUrl}/admin/media/imageasset/${imageAssetId}/change/`
                      );
                    }}
                  >
                    <Cog size="16px" />
                  </Button>
                </Flex>
                <CardField>
                  <Heading size="1">ID</Heading>
                  <Text size="1">{imageAsset.id}</Text>
                </CardField>
                <CardField>
                  <Heading size="1">Created At</Heading>
                  <Text size="1">{imageAsset.created_at}</Text>
                </CardField>
                <CardField>
                  <Heading size="1">Category</Heading>
                  <Text size="1">{imageAsset.category}</Text>
                </CardField>
                <CardField>
                  <Heading size="1">Source</Heading>
                  <Text size="1">{imageAsset.source}</Text>
                </CardField>
                <CardField>
                  <Heading size="1">Original Filename</Heading>
                  <Text size="1">{imageAsset.original_filename}</Text>
                </CardField>
                <CardField>
                  <Heading size="1">Commerce ID</Heading>
                  <Text size="1">
                    {imageAsset.commerce_platform_item_id ?? "n/a"}
                  </Text>
                </CardField>
                <CardField>
                  <Heading size="1">{"Resolution (wxh)"}</Heading>
                  <Text size="1">
                    {imageAsset.height !== null
                      ? `${imageAsset.width}x${imageAsset.height}`
                      : "n/a"}
                  </Text>
                </CardField>
                <CardField>
                  <Heading size="1">URL</Heading>
                  <Link size="1" href={imageAsset.url}>
                    {imageAsset.url}
                  </Link>
                </CardField>
                <Flex style={{ margin: "8px 0px 8px 0px" }} gap="2">
                  <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                      setIsGenerateImageDialogOpen(true);
                    }}
                  >
                    <Sparkles size="16px" />
                    Generate
                  </Button>
                  <Button
                    loading={isReclassifyLoading}
                    style={{ flex: 1 }}
                    onClick={() => {
                      reclassify();
                    }}
                  >
                    <RefreshCcw size="16px" />
                    Reclassify
                  </Button>
                </Flex>
                {imageAsset.source_image_asset !== null && (
                  <Flex direction="column">
                    <CardHeading size="3">Source Image</CardHeading>
                    <Card
                      onClick={() => {
                        navigate(
                          `/intern/assets/images/${
                            imageAsset.source_image_asset!.id
                          }`
                        );
                      }}
                    >
                      <Flex align="center" gap="2">
                        <img
                          src={imageAsset.source_image_asset.url}
                          alt="Source"
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "4px",
                            objectFit: "cover",
                          }}
                        />
                        <Flex direction="column" gap="1">
                          <CardField>
                            <Heading size="1">ID</Heading>
                            <Text size="1">
                              {imageAsset.source_image_asset.id}
                            </Text>
                          </CardField>
                          <CardField>
                            <Heading size="1">Category</Heading>
                            <Text size="1">
                              {imageAsset.source_image_asset.category}
                            </Text>
                          </CardField>
                        </Flex>
                      </Flex>
                    </Card>
                  </Flex>
                )}
              </Flex>
            </InfoCard>
          </FlexContainer>
          {imageAsset.variant_count > 0 && imageVariants?.variants && (
            <SecondaryCard>
              <Flex direction="column" gap="2">
                <CardHeading size="3">
                  Generated Variants ({imageVariants.variants.length})
                </CardHeading>
                <Flex direction="row" overflow="auto" gap="2">
                  {imageVariants.variants.map((variant) => (
                    <VariantImageContainer
                      key={variant.id}
                      onClick={() => {
                        navigate(`/intern/assets/images/${variant.id}`);
                      }}
                    >
                      <VariantImage
                        src={variant.url}
                        alt={variant.original_filename}
                      />
                      <VariantLabel>{variant.category}</VariantLabel>
                    </VariantImageContainer>
                  ))}
                </Flex>
              </Flex>
            </SecondaryCard>
          )}
          {imageAsset.has_analysis && imageAnalysis && (
            <SecondaryCard>
              <Flex direction="column" gap="2">
                <CardHeading size="3">Analysis</CardHeading>
                <Flex direction="column" overflow="auto" gap="2">
                  <CardField>
                    <Heading size="1">ID</Heading>
                    <Text size="1">{imageAnalysis.id}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Created At</Heading>
                    <Text size="1">{imageAnalysis.created_at}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Image Asset ID</Heading>
                    <Text size="1">{imageAnalysis.image_asset_id}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Confidence</Heading>
                    <Text size="1">{imageAnalysis.confidence}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Subject Description</Heading>
                    <Text size="1">{imageAnalysis.subject_description}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Subject Occupancy</Heading>
                    <Text size="1">{imageAnalysis.subject_occupancy}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Subject Horizontal Alignment</Heading>
                    <Text size="1">
                      {imageAnalysis.subject_horizontal_alignment}
                    </Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Subject Vertical Alignment</Heading>
                    <Text size="1">
                      {imageAnalysis.subject_vertical_alignment}
                    </Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Text Overlay Safe Areas</Heading>
                    <Flex direction="column" gap="2">
                      <Flex>
                        {imageAnalysis.text_overlay_safe_areas.map(
                          (area, i) => (
                            <Flex
                              direction="column"
                              key={i}
                              style={{ marginRight: "24px" }}
                            >
                              <Text
                                size="1"
                                weight="medium"
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                Safe Area {i + 1}
                              </Text>
                              <Text size="1">
                                Confidence: {area.confidence}
                              </Text>
                              <Text size="1">
                                Average Color: {area.average_color}
                              </Text>
                              <Text size="1">
                                Contrast White: {area.contrast_white}
                              </Text>
                              <Text size="1">
                                Contrast Black: {area.contrast_black}
                              </Text>
                              <Text size="1">Min X: {area.norm_min_x}</Text>
                              <Text size="1">Min Y: {area.norm_min_y}</Text>
                              <Text size="1">Max X: {area.norm_max_x}</Text>
                              <Text size="1">Max Y: {area.norm_max_y}</Text>
                            </Flex>
                          )
                        )}
                      </Flex>
                    </Flex>
                  </CardField>
                  <CardField>
                    <Heading size="1">Background Complexity</Heading>
                    <Text size="1">{imageAnalysis.background_complexity}</Text>
                  </CardField>
                  <CardField>
                    <Heading size="1">Background Description</Heading>
                    <Text size="1">{imageAnalysis.background_description}</Text>
                  </CardField>
                </Flex>
              </Flex>
            </SecondaryCard>
          )}
        </Flex>
      </Container>
    </>
  );
};

export default InternAssetComponent;
