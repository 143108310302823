import { operations } from "../../../openapi";
import { getCampaignHomeCalendarQueryKey } from "./useCampaignHomeCalendarQuery";
import { getCampaignHomeQueryKey } from "./useCampaignHomeQuery";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type CreateCampaignResponse =
  operations["campaign_api_create_campaign"]["responses"][200]["content"]["application/json"];

type CreateCampaignRequestData =
  operations["campaign_api_create_campaign"]["requestBody"]["content"]["application/json"];

const useCreateCampaignMutation = ({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess: (data: CreateCampaignResponse) => void;
  onError: (error: Error) => void;
  onSettled?: (data: CreateCampaignResponse | undefined) => void;
}) => {
  const createCampaign = useDraperApiPostMutation<
    CreateCampaignResponse,
    CreateCampaignRequestData
  >({
    mutationKey: ["campaign"],
    path: "/campaign/",
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
    onSettled: (data) => {
      onSettled?.(data);
    },
  });

  return {
    createCampaign: createCampaign.mutate,
    isLoading: createCampaign.isPending,
  };
};

export default useCreateCampaignMutation;

export const useCreateCampaignMutationInHomePage = () => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();

  const { createCampaign, isLoading: isCreatingCampaign } =
    useCreateCampaignMutation({
      onSuccess: (data) => {
        queryClient.setQueriesData(
          {
            queryKey: getCampaignHomeQueryKey(activeBrandID, false),
            type: "all",
            exact: false,
          },
          (oldData: any) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              pages: oldData.pages.map((page: any) => {
                return {
                  ...page,
                  campaigns: [...page.campaigns, data],
                };
              }),
            };
          }
        );

        queryClient.setQueriesData(
          {
            queryKey: getCampaignHomeCalendarQueryKey(activeBrandID),
            type: "all",
            exact: false,
          },
          (oldData: any) => {
            if (!oldData) return oldData;

            return {
              campaigns: [...oldData.campaigns, data],
            };
          }
        );
      },
      onError: (error) => {
        toast.error("Error creating campaign");
      },
    });

  return { createCampaign, isCreatingCampaign };
};
