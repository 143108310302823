import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type BrandColorPalettesResponse =
  operations["brand_api_get_color_palettes"]["responses"][200]["content"]["application/json"];

export const brandColorPalettesQueryKey = (
  brandID: string,
  campaignID?: string
) => ["brand", brandID, "colorPalettes", campaignID];

const useBrandColorPalettesQuery = (brandID: string, campaignID?: string) => {
  const {
    data: colorPalettes,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery<BrandColorPalettesResponse>({
    queryKey: brandColorPalettesQueryKey(brandID, campaignID || undefined),
    queryFn: async (): Promise<BrandColorPalettesResponse> => {
      const { data } = await axios.get(
        `/api/v1/brand/${brandID}/color_palettes`,
        {
          params: {
            campaign_id: campaignID || undefined,
          },
        }
      );

      return data;
    },
  });

  return {
    colorPalettes,
    isLoading: isLoading || isFetching,
    isSuccess,
    key: brandColorPalettesQueryKey(brandID, campaignID),
  };
};

export default useBrandColorPalettesQuery;
