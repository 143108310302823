import CampaignWizardCard, {
  CampaignWizardCardProps,
} from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import { CampaignCardCommonProps } from "./CampaignCards";
import { CampaignType } from "@openapi";
import { Grid } from "@radix-ui/themes";
import { useMemo } from "react";
import {
  StarSparksIcon,
  StorageBoxesIcon,
  BadgePercentIcon,
  LightBulbDots,
} from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useCampaignWizardState,
  useCampaignWizardDispatch,
} from "~/contexts/CampaignWizardContext";

export const campaignTypeButtonText: Record<
  keyof typeof CampaignType,
  {
    text: string;
    icon: React.ReactNode;
  }
> = {
  new_products: {
    text: "New Products",
    icon: <StarSparksIcon />,
  },
  inventory_clearance: {
    text: "Inventory Clearance",
    icon: <StorageBoxesIcon />,
  },
  sales_event: {
    text: "Sales Event",
    icon: <BadgePercentIcon />,
  },
  other: {
    text: "Other",
    icon: <LightBulbDots />,
  },
};

const CampaignTypeCard: React.FC<CampaignCardCommonProps> = (props) => {
  const {
    campaignData: { campaign_type },
  } = useCampaignWizardState();
  const dispatch = useCampaignWizardDispatch();

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children" | "step">>(
    () => ({
      badgeTitle: "Campaign Type",
      backButtonProps: {
        hide: true,
      },
      nextButtonProps: {
        text: "Continue",
        disabled: campaign_type === null,
      },
    }),
    [campaign_type]
  );

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle="What type of campaign will this be?"
      {...props}
    >
      <Grid columns="2" gap="16px">
        {Object.keys(campaignTypeButtonText).map((value) => (
          <AppButton
            key={value}
            onClick={() =>
              dispatch({
                type: "UPDATE_CAMPAIGN_DATA",
                payload: { campaign_type: value as CampaignType },
              })
            }
            variant="soft"
            radius="large"
            style={{
              width: "310px",
              border: "1px solid",
              borderColor:
                campaign_type === value ? "var(--text-link)" : "transparent",
            }}
          >
            {campaignTypeButtonText[value as CampaignType].icon}
            {campaignTypeButtonText[value as CampaignType].text}
          </AppButton>
        ))}
      </Grid>
    </CampaignWizardCard>
  );
};

export default CampaignTypeCard;
