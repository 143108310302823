import { MAX_FEATURED_PRODUCTS } from "../../ProductSelectorDialog";
import { Product } from "../../hooks/useGetFilteredProducts";
import ProductCheckbox from "./ProductCheckbox";
import * as Avatar from "@radix-ui/react-avatar";
import {
  Checkbox,
  Flex,
  Skeleton,
  Table,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";
import { currencyFormatter } from "~/utils/helpers";

const ProductRow = ({
  product,
  canOnlySelectOneProduct,
  onProductSelect,
  onProductDeselect,
  onProductSelects,
  isChecked,
  onFeatureToggle,
  featuredProductsCount,
  isFeatured,
  featureButton,
  currency,
}: {
  product: Product;
  canOnlySelectOneProduct: boolean;
  onProductSelect: (product: Product) => void;
  onProductDeselect: (product: Product) => void;
  onProductSelects?: (product: Product) => void;
  isChecked: boolean;
  onFeatureToggle?: (product: Product) => void;
  featuredProductsCount: number;
  isFeatured: boolean;
  featureButton: React.ReactElement | null;
  currency?: string | null;
}) => {
  const { user } = useCurrentUserState();

  const productPrice = product.compare_at_price ?? product.price;
  const promoPrice = product.compare_at_price ? product.price : null;

  return (
    <Table.Row
      align="center"
      key={product.product_id}
      style={{
        cursor: canOnlySelectOneProduct ? "pointer" : undefined,
      }}
      onClick={
        canOnlySelectOneProduct ? () => onProductSelect(product) : undefined
      }
    >
      <Table.RowHeaderCell>
        <Flex align="center" gap="3">
          {canOnlySelectOneProduct ? null : (
            <ProductCheckbox
              handleCheck={onProductSelect}
              handleMultipleCheck={onProductSelects}
              product={product}
              handleUncheck={onProductDeselect}
              isChecked={isChecked}
            />
          )}
          {!product.image_url ? (
            <Skeleton width="40px" height="40px" />
          ) : (
            <Avatar.Root>
              <Avatar.Image
                src={product.image_url ?? ""}
                alt="image"
                width="40px"
                height="40px"
                style={{
                  borderRadius: "20%",
                  border: "1px solid #0000000A",
                  display: "block",
                }}
              ></Avatar.Image>
              <Avatar.Fallback
                style={{
                  borderRadius: "20%",
                  display: "block",
                  width: "40px",
                  height: "40px",
                  background: "grey",
                }}
              />
            </Avatar.Root>
          )}
        </Flex>
      </Table.RowHeaderCell>
      <Table.Cell style={{ maxWidth: "170px" }}>
        <Flex
          direction="column"
          maxWidth="90%"
          overflow="hidden"
          align="start"
          justify="start"
        >
          <Text
            as="span"
            style={{
              maxWidth: "100%",
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            {product.title}
          </Text>
          <Text
            as="span"
            style={{
              maxWidth: "100%",
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              color: "#888282",
            }}
          >
            {product.category_name}
          </Text>
          {user.status === "LOGGED_IN" && user.isInternal && (
            <Text
              as="span"
              style={{
                maxWidth: "100%",
                textWrap: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 400,
                fontSize: "10px",
                lineHeight: "16px",
                color: "#888282",
              }}
            >
              {product.product_id}
            </Text>
          )}
        </Flex>
      </Table.Cell>
      <Table.Cell>{product.total_inventory}</Table.Cell>
      <Table.Cell>
        {currencyFormatter(currency).format(productPrice)}
      </Table.Cell>
      <Table.Cell>
        {promoPrice ? currencyFormatter(currency).format(promoPrice) : "-"}
      </Table.Cell>
      {onFeatureToggle && (
        <Table.Cell align="center" justify={"center"}>
          {featuredProductsCount >= MAX_FEATURED_PRODUCTS && !isFeatured ? (
            <Tooltip content="Only 6 featured products are allowed">
              {featureButton}
            </Tooltip>
          ) : (
            featureButton
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export const ProductRowSkeleton = () => (
  <Table.Row align="center">
    <Table.RowHeaderCell>
      <Flex align="center" gap="3">
        <Checkbox
          size="3"
          style={{ borderRadius: "8px", marginRight: "24px" }}
          onClick={() => undefined}
          checked={false}
        />
        <Skeleton width="40px" height="40px"></Skeleton>
      </Flex>
    </Table.RowHeaderCell>
    <Table.Cell>
      <Flex direction="column" maxWidth="210px" gap="3">
        <Skeleton width="60px" height="15px" />

        <Skeleton width="100px" height="15px" />
      </Flex>
    </Table.Cell>
    <Table.Cell>
      <Skeleton width="25px" height="15px" />
    </Table.Cell>
    <Table.Cell>
      <Skeleton width="25px" height="15px" />
    </Table.Cell>
    <Table.Cell>
      <Skeleton width="25px" height="15px" />
    </Table.Cell>
    <Table.Cell align="center" justify={"center"}>
      <Tooltip content="Only 6 featured products are allowed">
        <Skeleton width="25px" height="15px" />
      </Tooltip>
    </Table.Cell>
  </Table.Row>
);
export default ProductRow;
