import { useChatContext } from "./OnboardingChatContext";
import { Flex, Text } from "@radix-ui/themes";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandOnboardingChatMutation from "~/hooks/brand/useBrandOnboardingChatMutation";

export default function SummaryChatMessage({
  onNextStep,
}: {
  onNextStep: () => void;
}) {
  const activeBrandID = useActiveBrandID();
  const { state } = useChatContext();
  const { mutate: updateBrandOnboardingChat, isPending: isUpdating } =
    useBrandOnboardingChatMutation({
      brandID: activeBrandID,
      onSuccess: onNextStep,
    });
  return (
    <Flex direction="column" gap="2" p="2" style={{ width: "100%" }}>
      <Text weight="bold" size="3">
        Here is your marketing plan overview:
      </Text>
      <Flex direction="column" gap="2" pl="2">
        <ul style={{ listStyleType: "disc", paddingLeft: "20px", margin: 0 }}>
          <li>
            Campaigns will be planned to start from{" "}
            {state.campaignStartDate || "February 29, 2025"} with the plan to
            send 4 emails per week.
          </li>
          <li>
            {state.plannedCampaigns.length > 0
              ? `${state.plannedCampaigns.length} planned campaign${
                  state.plannedCampaigns.length > 1 ? "s" : ""
                } starting on ${state.plannedCampaigns[0].startDate}`
              : "No planned campaigns"}
          </li>
          {state.eventContext && (
            <li>Will include campaign ideas around "{state.eventContext}"</li>
          )}
        </ul>
      </Flex>
      <Flex justify="end">
        <AppButton
          variant="primary"
          size="3"
          radius="full"
          style={{ width: "100%" }}
          disabled={isUpdating}
          onClick={() => {
            updateBrandOnboardingChat({
              start_date: state.campaignStartDate,
              event_context: state.eventContext,
              feedback_context: state.feedbackContext,
              emails_per_week: state.emailsPerWeek,
              planned_campaigns: state.plannedCampaigns.map((campaign) => ({
                title: campaign.title,
                start_date: campaign.startDate,
                type: campaign.type,
                commerce_platform_product_ids: Array.from(
                  campaign.products.values()
                ).map((product) => product.product_id),
              })),
            });
          }}
        >
          Confirm Plan
        </AppButton>
      </Flex>
    </Flex>
  );
}
