import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BrandCustomSectionPropertiesModifier from "./modifiers/BrandCustomSectionPropertiesModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import FeaturedProductModifier from "./modifiers/FeaturedProductModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ReviewModifier from "./modifiers/ReviewModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { useEffect, useMemo, useState } from "react";
import ReviewSelectorDialog from "~/components/campaign/wizard/products/components/reviewSelector/ReviewSelectorDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionMutation";
import useShopifyProductsQuery from "~/hooks/shopify/useShopifyProductsQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isReviewSection } from "~/utils/emails/useSectionTypeCheck";

const replaceInnerText = (htmlString: string, newText: string) => {
  return htmlString.replace(/>(.*?)</, `>${newText}<`);
};

const ReviewView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();
  const { selectedSectionId, sections, id: emailCreativeId } = useEmailState();
  const selectedSection = sections?.find(
    (section) => section.id === selectedSectionId
  );
  const [isReviewSelectorOpen, setIsReviewSelectorOpen] = useState(false);
  const [executedInitEmail, setExecutedInitEmail] = useState(false);
  const hasReviewSection = selectedSection && isReviewSection(selectedSection);
  const [productId, setProductId] = useState(
    hasReviewSection ? selectedSection.selected_product_id : ""
  );
  const { productsData } = useShopifyProductsQuery(activeBrandId, [
    productId ?? "",
  ]);
  const [hasSelectedReview, setHasSelectedReview] = useState(false);
  const [selectedReviewIds, setSelectedReviewIds] = useState<string[]>(
    hasReviewSection
      ? selectedSection.reviews.map((review) => review.review_id)
      : []
  );

  const { mutate: initEmailSection, isPending } = useInitEmailSectionMutation();

  const productDetails = useMemo(() => {
    if (!productsData) return;
    return productsData[0];
  }, [productsData]);

  useEffect(() => {
    if (executedInitEmail && selectedReviewIds.length > 0) {
      initEmailSection({
        brand_id: activeBrandId,
        section_type: EmailSectionType.reviews,
        email_creative_id: emailCreativeId,
        generate: true,
        generate_fallback_to_default: true,
        id: selectedSection?.id ?? "",
        index: selectedSection?.index ?? 0,
        product_ids: [productId ?? ""],
        selected_review_ids: selectedReviewIds,
        prompt_summary: "Single review section",
      });
      setExecutedInitEmail(false);
    }
  }, [executedInitEmail, selectedReviewIds]);

  if (!hasReviewSection) return null;

  return (
    <>
      <GeneratedVariantSelector />

      <BrandCustomSectionPropertiesModifier />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />

      <FeaturedProductModifier
        isLoading={isPending}
        showProductDetails
        productDetails={productDetails}
        containerTitle="Product"
        onChangeProduct={(product) => {
          setIsReviewSelectorOpen(true);
          setProductId(product.product_id);

          // reset selected reviews if the product is changed
          if (productId !== product.product_id) {
            setSelectedReviewIds([]);
          }
        }}
      />

      <TextModifier
        textElement={selectedSection.title}
        label="Headline"
        fieldName="title.text"
        enabled={selectedSection.title.enabled}
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value,
          })
        }
      />

      <ReviewSelectorDialog
        key="view"
        isOpen={isReviewSelectorOpen}
        selectedProductId={productId ?? ""}
        selectedReviewsIds={selectedReviewIds}
        onOpenChange={(open) => {
          if (!open) {
            if (hasSelectedReview) {
              updateSection({
                sectionId: selectedSection.id,
                field: "selected_product_id",
                value: productId ?? "",
              });
              setExecutedInitEmail(true);
            } else {
              // restore to initial product and reviews
              setProductId(selectedSection.selected_product_id);
              setSelectedReviewIds(
                selectedSection.reviews.map((review) => review.review_id)
              );
            }

            setIsReviewSelectorOpen(false);
            setHasSelectedReview(false);
          }
        }}
        onSelectSingleReview={(review) => {
          setSelectedReviewIds((prev = []) => {
            // Add or remove review depending if the review is already selected
            return prev.includes(review.review_id)
              ? prev.filter((id) => id !== review.review_id)
              : [...prev, review.review_id];
          });

          setHasSelectedReview(true);
        }}
      />

      {selectedSection.reviews.map((review, index) => (
        <ReviewModifier
          key={`review_${index}`}
          reviewElement={review}
          label={`Review ${index + 1}`}
          enabled={review.enabled}
          imageFieldName={`reviews.${index}.image.image_url`}
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSection.id,
              field: `reviews.${index}.enabled`,
              value: enabled,
            });
          }}
          isLoading={isPending}
          onChangeReview={(newReview) => {
            Object.entries(newReview).forEach(([key, value]) => {
              const field = `reviews.${index}.${key}`;

              // Check if the corresponding field in the previous review has a text property
              const previousValue = review[key as keyof typeof review];
              const hasTextProp =
                previousValue &&
                typeof previousValue === "object" &&
                "text" in previousValue;

              if (hasTextProp) {
                updateSection({
                  sectionId: selectedSection.id,
                  field: `${field}.text`,
                  value: replaceInnerText(
                    (previousValue as { text: string }).text,
                    value?.toString() ?? ""
                  ),
                });
              } else {
                updateSection({
                  sectionId: selectedSection.id,
                  field,
                  value: value?.toString() ?? "",
                });
              }
            });

            setSelectedReviewIds((prev) => {
              if (!prev) return [newReview.review_id];
              return prev.map((id) =>
                id === review.review_id ? newReview.review_id : id
              );
            });
          }}
        />
      ))}

      <ButtonModifier
        buttonElement={selectedSection.cta_button}
        label="Call to action"
        fieldName="cta_button"
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "cta_button.enabled",
            value,
          })
        }
      />
    </>
  );
};

export default ReviewView;
