import useGetCampaignRecommendations, {
  CampaignRecommendation,
} from "../../hooks/recommendations/useGetCampaignRecommendations";
import HomeCampaignCard from "./HomeCampaignCard";
import NewCampaignType from "./NewCampaignType";
import RecommenderCard from "./RecommenderCard";
import RecommenderZeroState from "./RecommenderZeroState";
import DraperSegmentedControl2 from "@components/core/DraperSegmentedControl2";
import { DraperHeading } from "@components/core/DraperText";
import AppButton from "@components/core/buttons/AppButton/AppButton";
import { CampaignUnifiedStatus, components } from "@openapi";
import {
  Dialog,
  Flex,
  Heading,
  IconButton,
  Skeleton,
  Spinner,
  Text,
  VisuallyHidden,
} from "@radix-ui/themes";
import { useGateValue } from "@statsig/react-bindings";
import _ from "lodash";
import { Calendar1, X } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import DraperCalendar, {
  UnifiedCampaign,
} from "~/components/home/components/common/CampaignCalendar";
import { MessageType, useMarkPopupDispatch } from "~/contexts/MarkPopupContext";
import useCampaignHomeQuery from "~/hooks/campaign/useCampaignHomeQuery";

type AllCampaignUnifiedStatus =
  | components["schemas"]["CampaignUnifiedStatus"]
  | "all";

const FILTER_OPTIONS: {
  label: string;
  value: Exclude<AllCampaignUnifiedStatus, CampaignUnifiedStatus.in_progress>;
}[] = [
  { label: "All", value: "all" },
  { label: "Planned", value: CampaignUnifiedStatus.planned },
  // { label: "In Progress", value: CampaignUnifiedStatus.in_progress },
  { label: "Designed", value: CampaignUnifiedStatus.designed },
  { label: "Launched", value: CampaignUnifiedStatus.launched },
  { label: "Past", value: CampaignUnifiedStatus.past },
];

const SkeletonCard = styled(Skeleton)`
  border-radius: 16px;
  height: 250px;
  width: 100%;
`;

const EmptyCampaignCard = styled(Flex)`
  padding: 48px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const TotalCountText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.5%;
  text-align: center;
  vertical-align: middle;
  background-color: var(--black-8, #00000014);
  padding: 6px;
  margin-left: 4px;
  border-radius: 100%;
  aspect-ratio: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const StyledDialogContent = styled(Dialog.Content)`
  padding: 0px !important;
  border-radius: 32px !important;
`;

interface SectionContainerProps {
  children: React.ReactNode;
  label: string;
  headerRightComponent?: React.ReactNode;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  label,
  headerRightComponent,
}) => {
  if (headerRightComponent) {
    return (
      <Flex direction="column" gap="5">
        <Flex direction="row" justify="between" align="center">
          <DraperHeading size="5">{label}</DraperHeading>
          {headerRightComponent}
        </Flex>
        {children}
      </Flex>
    );
  }
  return (
    <Flex direction="column" gap="5">
      <DraperHeading size="5">{label}</DraperHeading>
      {children}
    </Flex>
  );
};

const RecommenderSection = () => {
  const isNewHomePage = useGateValue("is_new_home_ui", {
    disableExposureLog: true,
  });

  // State
  const [calendarCampaign, setCalendarCampaign] = useState<
    UnifiedCampaign | null | undefined
  >(undefined);
  const [selectedFilter, setSelectedFilter] =
    useState<AllCampaignUnifiedStatus>("all");

  // Queries
  const {
    data: newHomeData,
    hasNextPage: hasNextNewHomePage,
    fetchNextPage: fetchNextNewHomePage,
    isLoading: isLoadingNewHomePage,
  } = useCampaignHomeQuery({
    enabled: isNewHomePage,
    status: selectedFilter === "all" ? undefined : selectedFilter,
  });

  // Query to get counts for each status
  const { data: plannedData, isPending } = useCampaignHomeQuery({
    enabled: isNewHomePage,
    status: CampaignUnifiedStatus.planned,
    onlyCount: true,
  });

  // const { data: inProgressData } = useCampaignHomeQuery({
  //   enabled: isNewHomePage,
  //   status: CampaignUnifiedStatus.in_progress,
  //   onlyCount: true,
  // });

  const { data: designedData } = useCampaignHomeQuery({
    enabled: isNewHomePage,
    status: CampaignUnifiedStatus.designed,
    onlyCount: true,
  });

  const { data: launchedData } = useCampaignHomeQuery({
    enabled: isNewHomePage,
    status: CampaignUnifiedStatus.launched,
    onlyCount: true,
  });

  const { data: pastData } = useCampaignHomeQuery({
    enabled: isNewHomePage,
    status: CampaignUnifiedStatus.past,
    onlyCount: true,
  });

  const {
    data,
    hasNextPage: hasNextRecommendationsPage,
    fetchNextPage: fetchNextRecommendationsPage,
    isLoading: isLoadingRecommendations,
  } = useGetCampaignRecommendations({
    enabled: !isNewHomePage,
  });

  // Get total counts for each status
  const statusCounts = useMemo(() => {
    return {
      all: newHomeData?.pages[0]?.total_count || 0,
      [CampaignUnifiedStatus.planned]: plannedData?.pages[0]?.total_count || 0,
      // [CampaignUnifiedStatus.in_progress]:
      //   inProgressData?.pages[0]?.total_count || 0,
      [CampaignUnifiedStatus.designed]:
        designedData?.pages[0]?.total_count || 0,
      [CampaignUnifiedStatus.launched]:
        launchedData?.pages[0]?.total_count || 0,
      [CampaignUnifiedStatus.past]: pastData?.pages[0]?.total_count || 0,
    };
  }, [
    newHomeData?.pages[0]?.total_count,
    plannedData?.pages[0]?.total_count,
    // inProgressData?.pages[0]?.total_count,
    designedData?.pages[0]?.total_count,
    launchedData?.pages[0]?.total_count,
    pastData?.pages[0]?.total_count,
  ]);

  const popupDispatch = useMarkPopupDispatch();

  const objects = isNewHomePage
    ? newHomeData?.pages.flatMap((page) => page.campaigns) ?? []
    : data?.pages.flatMap((page) => page.campaign_recommendations) ?? [];

  const hasAnyCampaignRecommendations =
    (data?.pages.flatMap((page) => page.campaign_recommendations) ?? [])
      .length > 0;

  useEffect(() => {
    const message = hasAnyCampaignRecommendations
      ? "We've reviewed your past 12 months of emails and ads and prepared a report to check."
      : "We're currently analyzing your brand voice to tailor the best campaign ideas for you.";
    popupDispatch({
      type: "SET_MESSAGE",
      payload: {
        type: hasAnyCampaignRecommendations
          ? MessageType.INSIGHTS
          : MessageType.ONLY_MESSAGE,
        message: message,
      },
    });
  }, [hasAnyCampaignRecommendations]);

  const isLoading = isNewHomePage
    ? isLoadingNewHomePage
    : isLoadingRecommendations;

  if (!isLoading && !hasAnyCampaignRecommendations && !isNewHomePage) {
    return <RecommenderZeroState />;
  }

  const fetchNextPage = isNewHomePage
    ? fetchNextNewHomePage
    : fetchNextRecommendationsPage;
  const hasNextPage = isNewHomePage
    ? hasNextNewHomePage
    : hasNextRecommendationsPage;

  const headerRightComponent = (
    <DraperSegmentedControl2
      size="0"
      radius="large"
      value={selectedFilter}
      onValueChange={(value) =>
        setSelectedFilter(value as AllCampaignUnifiedStatus)
      }
    >
      {FILTER_OPTIONS.map((option) => (
        <DraperSegmentedControl2.Item key={option.value} value={option.value}>
          <Flex align="center" justify="center">
            <Heading
              size="3"
              weight="medium"
              style={{
                padding: "6px",
              }}
            >
              {option.label}
            </Heading>
            {statusCounts[option.value] > 0 && option.value !== "all" && (
              <TotalCountText>{statusCounts[option.value]}</TotalCountText>
            )}
          </Flex>
        </DraperSegmentedControl2.Item>
      ))}
    </DraperSegmentedControl2>
  );

  const topSection = isNewHomePage ? (
    <SectionContainer label="Your campaign calendar">
      <DraperCalendar
        variant="week"
        onCampaignClick={(campaign) => {
          setCalendarCampaign(campaign);
        }}
        rightHeaderComponent={
          <AppButton
            variant="ghost"
            radius="large"
            onClick={() => setCalendarCampaign(null)}
            color="gray"
          >
            <Flex align="center" gap="2">
              <Calendar1 size={20} />
              <Text size="2" weight="medium">
                Open Calendar
              </Text>
            </Flex>
          </AppButton>
        }
      />
    </SectionContainer>
  ) : (
    <SectionContainer label="Create a new campaign">
      <NewCampaignType />
    </SectionContainer>
  );

  return (
    <Flex direction="column" gap="7" width="100%">
      {topSection}
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage ?? false}
        dataLength={objects.length}
        loader={
          <Flex height="100%" width="100%" justify="center" align="center">
            <Spinner m="5" />
          </Flex>
        }
        scrollThreshold={"90%"}
      >
        <Flex direction="column" gap="7" width="100%">
          <SectionContainer
            label={"Campaigns"}
            headerRightComponent={
              isNewHomePage ? headerRightComponent : undefined
            }
          >
            <Flex direction="column" gap="5">
              {isLoading && (
                <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </>
              )}
              {!isLoading && objects.length === 0 ? (
                <EmptyCampaignCard
                  direction="column"
                  gap="2"
                  justify="center"
                  align="center"
                >
                  <Heading size="4">No campaigns found</Heading>
                  <Text size="2" color="gray">
                    There are no campaigns matching the current filter. Select a
                    different filter or create a new campaign.
                  </Text>
                </EmptyCampaignCard>
              ) : (
                objects.map((recommendation) => {
                  if ("type" in recommendation) {
                    return (
                      <HomeCampaignCard
                        key={recommendation.id}
                        campaign={recommendation}
                      />
                    );
                  }
                  return (
                    <RecommenderCard
                      key={recommendation.id}
                      recommendation={recommendation as CampaignRecommendation}
                    />
                  );
                })
              )}
            </Flex>
          </SectionContainer>
        </Flex>
      </InfiniteScroll>

      <Dialog.Root
        open={calendarCampaign !== undefined}
        onOpenChange={(open) => {
          if (!open) {
            setCalendarCampaign(undefined);
          } else {
            setCalendarCampaign(null);
          }
        }}
      >
        <StyledDialogContent maxWidth={"1600px"}>
          <VisuallyHidden>
            <Dialog.Title>Calendar</Dialog.Title>
            <Dialog.Description>Monthly Calendar View</Dialog.Description>
          </VisuallyHidden>
          <DraperCalendar
            variant="month"
            initialCampaign={calendarCampaign ?? undefined}
            onCampaignClick={(campaign) => {
              setCalendarCampaign(campaign);
            }}
            rightHeaderComponent={
              <Flex align="center" justify="between" gap="6">
                {/* <Flex align="center" gap="3">
                  <Flex align="center">
                    <Switch
                      onCheckedChange={() => {
                        // Toggle logic can be implemented here

                      }}
                    />
                  </Flex>

                  <Text size="2" color="gray">
                    Only show approved
                  </Text>
                </Flex> */}

                <Dialog.Close>
                  <IconButton variant="ghost" radius="large" color="gray">
                    <X size={20} />
                  </IconButton>
                </Dialog.Close>
              </Flex>
            }
          />
        </StyledDialogContent>
      </Dialog.Root>
    </Flex>
  );
};

export default RecommenderSection;
