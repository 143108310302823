import AppButton from "../../buttons/AppButton/AppButton";
import { components } from "@openapi";
import { Flex, RadioCards, Skeleton, Text } from "@radix-ui/themes";
import { Pencil, PlusIcon } from "lucide-react";
import { useMemo, useState } from "react";
import PalettePicker from "~/components/core/inputs/PalettePicker";
import ColorPaletteDialog from "~/components/dialogs/ColorPaletteDialog";
import ModifierContainer from "~/components/editor/editor/sidepanel-views/modifiers/ModifierContainer";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandColorPalettesQuery from "~/hooks/brand/useBrandColorPalettesQuery";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const ColorPaletteTile = ({
  palette,
  onClick,
  onEdit,
}: {
  palette: components["schemas"]["ColorPaletteSchema"];
  onClick: (palette: components["schemas"]["ColorPaletteSchema"]) => void;
  onEdit: (palette: components["schemas"]["ColorPaletteSchema"]) => void;
}) => {
  return (
    <Flex key={palette.id} style={{ position: "relative" }}>
      <RadioCards.Item
        key={palette.id}
        value={palette.id ?? ""}
        onClick={() => {
          onClick(palette);
        }}
        style={{
          cursor: "pointer",
          padding: "0",
          gap: "0",
        }}
      >
        <PalettePicker palette={palette} />
      </RadioCards.Item>

      <div
        style={{
          position: "absolute",
          top: "8px",
          right: "8px",
          zIndex: 10,
        }}
      >
        <AppButton
          style={{
            padding: "6px",
          }}
          variant="ghost"
          radius="full"
          size="1"
          color="gray"
          onClick={(e) => {
            e.stopPropagation();
            onEdit(palette);
          }}
        >
          <Pencil size={14} color="gray" />
        </AppButton>
      </div>
    </Flex>
  );
};

const ColorPaletteModifier = ({
  onClick,
  palette,
  title = "Color Palette",
}: {
  onClick: (palette: components["schemas"]["ColorPaletteSchema"]) => void;
  palette: components["schemas"]["ColorPaletteSchema"];
  title?: string;
}) => {
  const activeBrandID = useActiveBrandID();
  const { campaign_id } = useEmailState();

  const {
    colorPalettes: brandColorPalettes,
    isLoading: isBrandColorPalettesLoading,
  } = useBrandColorPalettesQuery(activeBrandID);

  const {
    colorPalettes: campaignColorPalettes,
    isLoading: isCampaignColorPalettesLoading,
  } = useBrandColorPalettesQuery(activeBrandID, campaign_id);

  // activePalette: null = create new palette, undefined = dialog closed
  const [activePalette, setActivePalette] = useState<
    components["schemas"]["ColorPaletteSchema"] | null | undefined
  >(undefined);

  const allPalettes = useMemo(() => {
    return [...(brandColorPalettes ?? []), ...(campaignColorPalettes ?? [])];
  }, [brandColorPalettes, campaignColorPalettes]);

  const selectedPalette = useMemo(() => {
    return allPalettes?.find(
      (p) =>
        p.foreground === palette.foreground &&
        p.background === palette.background &&
        p.accent_background === palette.accent_background &&
        p.accent_foreground === palette.accent_foreground
    );
  }, [palette, allPalettes]);

  const addNewPaletteButton = (
    <RadioCards.Item
      value="new"
      onClick={() => setActivePalette(null)}
      style={{
        cursor: "pointer",
        padding: "0",
        gap: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80px",
        borderRadius: "8px",
      }}
    >
      <PlusIcon size={16} />
    </RadioCards.Item>
  );

  const paletteSkeletons = (
    <RadioCards.Root
      value={selectedPalette?.id ?? ""}
      columns={{ initial: "4" }}
    >
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton key={index} height="80px" style={{ borderRadius: "8px" }} />
      ))}
    </RadioCards.Root>
  );

  const campaignPalettes = isCampaignColorPalettesLoading ? (
    paletteSkeletons
  ) : (
    <RadioCards.Root
      value={selectedPalette?.id ?? ""}
      columns={{ initial: "4" }}
    >
      {campaignColorPalettes?.map((palette) => (
        <ColorPaletteTile
          key={palette.id}
          palette={palette}
          onClick={onClick}
          onEdit={() => {
            setActivePalette(palette);
          }}
        />
      ))}

      {addNewPaletteButton}
    </RadioCards.Root>
  );

  const brandPalettes = isBrandColorPalettesLoading ? (
    paletteSkeletons
  ) : (
    <RadioCards.Root
      value={selectedPalette?.id ?? ""}
      columns={{ initial: "4" }}
    >
      {(brandColorPalettes ?? []).map((palette) => {
        return (
          <ColorPaletteTile
            key={palette.id}
            palette={palette}
            onClick={onClick}
            onEdit={() => {
              setActivePalette(palette);
            }}
          />
        );
      })}
    </RadioCards.Root>
  );

  return (
    <ModifierContainer title={title} hideSwitch>
      <Flex direction="column" gap="2">
        <Text size="2" weight="medium">
          Campaign Palettes
        </Text>
        {campaignPalettes}
      </Flex>

      <Flex direction="column" gap="2">
        <Text size="2" weight="medium">
          Brand Palettes
        </Text>
        {brandPalettes}
      </Flex>

      <ColorPaletteDialog
        isOpen={activePalette !== undefined}
        onOpenChange={(open) => {
          if (!open) {
            setActivePalette(undefined);
          }
        }}
        activePalette={activePalette ?? undefined}
        showSaveToStyleLibraryOption={true}
      />
    </ModifierContainer>
  );
};

export default ColorPaletteModifier;
