import CampaignPublishDialog from "../../overview/CampaignPublishDialog";
import {
  ItemSetType,
  ShopifyDiscountType,
  ShopifyDiscountValueType,
} from "@openapi";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  ScrollArea,
  SegmentedControl,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { ChevronLeftIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import styled from "styled-components";
import { CheckIcon, ShareIcon } from "~/assets/icons";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import {
  ChannelType,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery, {
  getCampaignRecommendationQueryKey,
} from "~/hooks/campaign/useCampaignRecommendationQuery";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";
import {
  CAMPAIGN_OVERVIEW_ROUTE,
  CAMPAIGN_BRIEF_ROUTE,
  HOME_ROUTE,
} from "~/routes/constants";
import {
  areAllCreativesPublished,
  hasAnyCreatives,
} from "~/utils/campaign/utils";
import { useGenerateButtonProps } from "~/views/campaign/wizard/CampaignBriefOverview";
import { RecommendedCampaignChannelCountDefaults } from "~/views/campaign/wizard/hooks/useCampaignChannelProps";

const WhiteButton = styled(IconButton)`
  background-color: #fff;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const BackButton = styled(AppButton)`
  background: #fff;
  &:hover {
    background-color: #f0f0f0;
  }
`;

type TabType = "brief" | "graphics";

interface CampaignWizardFullPageLayoutProps {
  children: React.ReactNode;
}

const CampaignWizardFullPageLayout: React.FC<
  CampaignWizardFullPageLayoutProps
> = ({ children }) => {
  const queryClient = useQueryClient();
  const dispatch = useCampaignWizardDispatch();
  const { recommendationID, id } = useParams();
  const activeBrandID = useActiveBrandID();
  const navigate = useNavigate();
  const {
    disabled,
    onClick,
    isLoading: isGenerating,
  } = useGenerateButtonProps();
  const { isWizardStateInitialized } = useCampaignWizardState();

  // State
  const [tabs, setTabs] = useState<TabType[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabType>(
    id ? "graphics" : "brief"
  );

  // Queries
  const { campaignRecommendation, isSuccess } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );
  const { data: campaign } = useGetCampaignDetails({
    campaignId: id ?? undefined,
  });

  const finalRecommendationID =
    recommendationID ?? campaign?.campaign_recommendation?.id;
  const finalCampaignID = id ?? campaignRecommendation?.campaign_id;

  useEffect(() => {
    if (campaign) {
      const tabs: TabType[] = [];
      if (campaign.campaign_recommendation) {
        tabs.push("brief");

        // We manually set the campaign recommendation data in the query client
        // because the campaign data has the full campaign recommendation object attached
        // But we don't set campaign data manually below because rec doesn't have full campaign data
        if (
          !queryClient.getQueryData(
            getCampaignRecommendationQueryKey(
              activeBrandID,
              campaign.campaign_recommendation.id
            )
          )
        ) {
          queryClient.setQueryData(
            getCampaignRecommendationQueryKey(
              activeBrandID,
              campaign.campaign_recommendation.id
            ),
            {
              ...campaign.campaign_recommendation,
            }
          );
        }
      }
      tabs.push("graphics");
      setTabs(tabs);
      setSelectedTab("graphics");
    }
  }, [campaign, activeBrandID, queryClient]);

  useEffect(() => {
    if (campaignRecommendation) {
      const tabs: TabType[] = ["brief"];
      if (campaignRecommendation.campaign_id) {
        tabs.push("graphics");
      }
      setTabs(tabs);
      setSelectedTab("brief");
    }
  }, [campaignRecommendation, queryClient]);

  useEffect(() => {
    if (isSuccess && campaignRecommendation && !isWizardStateInitialized) {
      const destination = campaignRecommendation.destination;
      const channelsDefaults =
        RecommendedCampaignChannelCountDefaults[
          campaignRecommendation.item_set_type as ItemSetType
        ];

      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: {
          audiences: campaignRecommendation.audiences ?? undefined,
          asset_ids: campaignRecommendation.image_assets?.map(
            (asset) => asset.id
          ),
          excluded_audiences:
            campaignRecommendation.excluded_audiences ?? undefined,
          campaign_id: campaignRecommendation.campaign_id,
          campaign_recommendation_id: campaignRecommendation.id,
          creative_id:
            campaignRecommendation.active_creative_recommendation?.id ||
            undefined,
          date: campaignRecommendation.date,
          destination: destination ?? undefined,
          discount:
            campaignRecommendation.discount_type &&
            campaignRecommendation.discount_amount
              ? {
                  type: ShopifyDiscountType.coupon,
                  value_type:
                    campaignRecommendation.discount_type === "coupon_flat"
                      ? ShopifyDiscountValueType.fixed_amount
                      : ShopifyDiscountValueType.percentage,
                  amount: campaignRecommendation.discount_amount,
                }
              : null,
          channels: {
            emails:
              campaignRecommendation.channel_emails ??
              channelsDefaults[ChannelType.EMAIL],
            meta_ads:
              campaignRecommendation.channel_meta_ads ??
              channelsDefaults[ChannelType.META_ADS],
            push_notifications: 0,
            sms: 0,
          },
          products: campaignRecommendation?.products?.map((product) => ({
            ...product,
            is_featured: false,
          })),
        },
      });

      dispatch({
        type: "SET_WIZARD_TYPE",
        payload: {
          wizardType: "RECOMMENDED",
          recommendationID: finalRecommendationID ?? "",
        },
      });
    }
  }, [isSuccess, campaignRecommendation, isWizardStateInitialized]);

  const centerElement = (
    <DraperSegmentControl
      $backgroundColor="rgba(0, 0, 0, 0.08)"
      $size="large"
      value={selectedTab}
      radius="large"
      size="3"
      onValueChange={(value) => {
        if (value) {
          setSelectedTab(value as TabType);
        }
      }}
    >
      {tabs.map((tab, index) => (
        <SegmentedControl.Item
          key={index}
          value={tab}
          onClick={() => {
            if (tab === "brief") {
              navigate(
                CAMPAIGN_BRIEF_ROUTE.replace(
                  ":recommendationID",
                  finalRecommendationID ?? ""
                )
              );
            } else if (tab === "graphics") {
              navigate(
                CAMPAIGN_OVERVIEW_ROUTE.replace(":id", finalCampaignID ?? "")
              );
            }
          }}
        >
          <Flex gap="4px" align="center">
            <Heading size="4">
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </Heading>
          </Flex>
        </SegmentedControl.Item>
      ))}
    </DraperSegmentControl>
  );

  let actionButton = null;
  if (selectedTab === "brief") {
    actionButton = (
      <AppButton
        variant="primary"
        onClick={onClick}
        disabled={disabled || isGenerating}
      >
        {isGenerating ? "Generating..." : "Generate Graphics"}
      </AppButton>
    );
  } else if (selectedTab === "graphics") {
    actionButton =
      !campaign || !hasAnyCreatives(campaign) ? null : areAllCreativesPublished(
          campaign
        ) ? (
        <AppButton
          variant="outlined"
          style={{ background: "var(--primary-white)" }}
        >
          <CheckIcon /> Published
        </AppButton>
      ) : (
        <CampaignPublishDialog campaign={campaign} />
      );
  }

  const rightElement = (
    <Flex gap="8px" align="center">
      <WhiteButton
        variant="outline"
        radius="full"
        aria-label="Share"
        size="4"
        color="gray"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          toast.success("Link copied to clipboard");
        }}
      >
        <ShareIcon size={20} />
      </WhiteButton>
      {actionButton}
    </Flex>
  );

  return (
    <Flex direction="column" style={{ height: "100vh", width: "100%" }}>
      <EditorTopBar
        backButtonDestination={HOME_ROUTE}
        backButtonOverride={
          <BackButton
            variant="outlined"
            radius="full"
            aria-label="Back"
            size="4"
            color="gray"
            onClick={() => {
              navigate(HOME_ROUTE);
            }}
          >
            <Flex gap="4px" align="center">
              <ChevronLeftIcon size={20} />
              Home
            </Flex>
          </BackButton>
        }
        left={
          <Heading size="5">
            {campaignRecommendation?.title ?? campaign?.title}
          </Heading>
        }
        center={centerElement}
        right={rightElement}
      />
      <Box style={{ position: "relative", flex: 1, overflow: "hidden" }}>
        <BackgroundGradient />
        <ScrollArea
          style={{
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Flex
            direction="column"
            align="center"
            justify="start"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {children}
          </Flex>
        </ScrollArea>
      </Box>
    </Flex>
  );
};

export default CampaignWizardFullPageLayout;
