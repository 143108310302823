import AppButton from "../core/buttons/AppButton/AppButton";
import ActionCard from "../core/cards/ActionCard";
import { Flex, Skeleton } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";
import useBrandInspirationsQuery from "~/hooks/brand/useBrandInspirationsQuery";
import { useBrandUpdateBrandInspirationsMutation } from "~/hooks/brand/useBrandUpdateBrandInspirationsMutation";

const Card = styled(Flex)<{ selected?: boolean }>`
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px #0000000a;
  border: 1px solid
    ${(props) => (props.selected ? "var(--focus-8)" : "#DDDDDD")};
  padding: 12px;
  cursor: pointer;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  ${(props) => props.selected && "background-color: rgba(139, 84, 255, 0.05);"}

  &:hover {
    background-color: ${(props) =>
      props.selected ? "rgba(139, 84, 255, 0.05)" : "var(--violet-2)"};
    border-color: ${(props) =>
      props.selected ? "var(--focus-8)" : "var(--violet-8)"};
  }
`;

const OnboardingInspiration = ({
  onNext,
  onPrev,
}: {
  onNext: () => void;
  onPrev: () => void;
}) => {
  // State
  const [checkedBrands, setCheckedBrands] = useState<string[]>([]);

  // Queries
  const { brandInspirations, isLoading } = useBrandInspirationsQuery();

  // Mutations
  const { mutate: updateBrandInspirations } =
    useBrandUpdateBrandInspirationsMutation({
      onSuccess: () => {
        onNext();
      },
    });

  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <ActionCard
        footer={
          <>
            <AppButton variant="outlined" onClick={onPrev}>
              Back
            </AppButton>
            <AppButton
              variant="dark"
              onClick={() => {
                updateBrandInspirations(checkedBrands);
              }}
              disabled={checkedBrands.length === 0}
            >
              Confirm Selection
            </AppButton>
          </>
        }
        title="Brand Inspirations"
        subtitle="Choose a brand that you admire and I'll use them to inform our creative direction."
      >
        {/* Content for inspiration gathering could go here */}
        <Flex direction="column" gap="4" width="100%">
          <Flex direction="column" width="100%">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "16px",
                width: "100%",
                padding: "4px 0",
              }}
            >
              {(brandInspirations ?? Array(9).fill(null)).map(
                (brand, index) => (
                  <Skeleton key={index} loading={isLoading}>
                    <Card
                      key={brand?.id}
                      align="center"
                      justify="center"
                      selected={checkedBrands.includes(brand?.id ?? "")}
                      onClick={() => {
                        const brandId = brand?.id;
                        if (!brandId) return;
                        if (checkedBrands.includes(brandId)) {
                          setCheckedBrands(
                            checkedBrands.filter((id) => id !== brandId)
                          );
                        } else {
                          setCheckedBrands([...checkedBrands, brandId]);
                        }
                      }}
                    >
                      <img
                        src={brand?.logo}
                        alt={`Brand ${index + 1}`}
                        style={{ height: "48px" }}
                      />
                    </Card>
                  </Skeleton>
                )
              )}
            </div>
          </Flex>
        </Flex>
      </ActionCard>
    </Flex>
  );
};

export default OnboardingInspiration;
