import { Text } from "@radix-ui/themes";
import styled from "styled-components";

const PaletteSquare = styled.div<{ $background: string }>`
  width: 64px;
  height: 64px;
  background-color: ${({ $background }) => $background};
  border-radius: 8px;
  overflow: hidden;
  padding: 12px 0 0 12px;
  position: relative;
  border: 1px solid #e0e0e0;
`;

const AccentBackground = styled.div<{ $background: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 32px 0 0 32px;
  height: 32px;
  width: 80%;
  background-color: ${({ $background }) => $background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PalettePicker = ({
  palette,
}: {
  palette: {
    foreground: string;
    background: string;
    accent_background: string | null;
    accent_foreground: string | null;
  };
}) => (
  <PaletteSquare $background={palette.background}>
    <Text
      style={{
        color: palette.foreground,
      }}
      weight="medium"
      size="6"
    >
      Aa
    </Text>
    <AccentBackground $background={palette.accent_background ?? "transparent"}>
      <Text
        style={{
          color: palette.accent_foreground ?? "transparent",
        }}
        weight="medium"
        size="4"
      >
        Aa
      </Text>
    </AccentBackground>
  </PaletteSquare>
);

export default PalettePicker;
