import { HOME_ROUTE } from "../constants";
import { Text } from "@radix-ui/themes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import EmailInsightCard from "~/components/emails/EmailInsightCard";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";

const EmailInsights = () => {
  const { user } = useCurrentUserState();
  const navigate = useNavigate();
  const isInternal = user.status === "LOGGED_IN" && user.isInternal;

  useEffect(() => {
    if (!isInternal) {
      navigate(HOME_ROUTE, { replace: true });
    }
  }, [isInternal, navigate]);

  return (
    <>
      <BackgroundGradient variant="3" />
      {isInternal && (
        <>
          <EditorTopBar
            backButtonDestination={""}
            left={
              <Text size="4" style={{ fontWeight: "500" }}>
                Historical Performance Insights
              </Text>
            }
            center={<></>}
            right={<></>}
          />
          <EmailInsightCard />
        </>
      )}
    </>
  );
};

export default EmailInsights;
