import { components, EmailSectionType } from "@openapi";
import { Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CheckIcon } from "~/assets/icons";
import { HoverOverlay } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import {
  useActiveBrandID,
  useCurrentUserState,
} from "~/contexts/CurrentUserContext";
import useEmailSectionTemplatesQuery from "~/hooks/emails/useEmailSectionTemplatesQuery";

const PreviewThumbnail = styled.div<{
  $background?: string;
  $isSelected?: boolean;
}>`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: var(--gray-4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${({ $background }) => $background});
  background-size: cover;
  background-position: center;
  border: 1px solid var(--gray-6);
  position: relative;
  overflow: hidden;
`;

interface HorizontalScrollableTemplateSelectorProps {
  sectionType: EmailSectionType;
  selectedTemplate: components["schemas"]["EmailSectionTemplateSchema"];
  onSelectTemplate: (
    template: components["schemas"]["EmailSectionTemplateSchema"]
  ) => void;
}

const HorizontalScrollableTemplateSelector = ({
  sectionType,
  selectedTemplate,
  onSelectTemplate,
}: HorizontalScrollableTemplateSelectorProps) => {
  const { user } = useCurrentUserState();
  const isInternal = user.status === "LOGGED_IN" && user.isInternal;

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isNearEnd, setIsNearEnd] = useState(false);
  const isLoadingRef = useRef(false);

  const { templates, error, isLoading, fetchNextPage, hasNextPage } =
    useEmailSectionTemplatesQuery(useActiveBrandID(), sectionType);

  const handleScroll = () => {
    if (!scrollContainerRef.current || isLoadingRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    const scrollPercentage = (scrollLeft + clientWidth) / scrollWidth;

    if (scrollPercentage > 0.9 && !isNearEnd && hasNextPage) {
      setIsNearEnd(true);
      isLoadingRef.current = true;
      fetchNextPage().finally(() => {
        isLoadingRef.current = false;
      });
    } else if (scrollPercentage <= 0.9) {
      setIsNearEnd(false);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, [hasNextPage]);

  return (
    <Flex direction="column">
      <Heading size="2">Explore section templates</Heading>
      <Flex
        ref={scrollContainerRef}
        style={{
          overflowX: "auto",
          maxHeight: "196px",
          position: "relative",
          margin: "0 -24px",
        }}
      >
        <Flex gap="2" style={{ minWidth: "min-content", padding: "12px 24px" }}>
          {templates.map((template) => {
            if (isInternal) {
              return (
                <Flex key={template.id} direction="column" align="center">
                  <PreviewThumbnail
                    onClick={() => onSelectTemplate(template)}
                    $background={template.preview_image ?? undefined}
                    $isSelected={template.id === selectedTemplate.id}
                  >
                    <HoverOverlay
                      $isSelected={template.id === selectedTemplate.id}
                    >
                      <CheckIcon color="white" width="24" height="24" />
                    </HoverOverlay>
                  </PreviewThumbnail>
                  <Text size="1" color="gray">
                    {template.name}
                  </Text>
                </Flex>
              );
            }
            return (
              <PreviewThumbnail
                key={template.id}
                onClick={() => onSelectTemplate(template)}
                $background={template.preview_image ?? undefined}
                $isSelected={template.id === selectedTemplate.id}
              >
                <HoverOverlay $isSelected={template.id === selectedTemplate.id}>
                  <CheckIcon color="white" width="24" height="24" />
                </HoverOverlay>
              </PreviewThumbnail>
            );
          })}
          {(hasNextPage && isLoading) ||
            (error !== null && (
              <>
                <Skeleton
                  height="80px"
                  width="80px"
                  style={{ borderRadius: "8px" }}
                />
                <Skeleton
                  height="80px"
                  width="80px"
                  style={{ borderRadius: "8px" }}
                />
                <Skeleton
                  height="80px"
                  width="80px"
                  style={{ borderRadius: "8px" }}
                />
              </>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HorizontalScrollableTemplateSelector;
