import CustomHTMLView from "./modifiers/CustomHTMLView";
import LogoSelector from "./modifiers/LogoSelector";
import VariantSelector from "./modifiers/VariantSelector";
import { CSSBorderStyle, EmailElementType, EmailSectionType } from "@openapi";
import { Button, Flex } from "@radix-ui/themes";
import { PencilIcon } from "~/assets/icons";
import ColorPaletteModifier from "~/components/core/editor/sidepane/ColorPaletteModifier";
import ModifierContainer from "~/components/editor/editor/sidepanel-views/modifiers/ModifierContainer";
import {
  useHeaderFooterState,
  useTemplateEditorDispatch,
} from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";
import { CustomizationViews, isHeaderSection } from "~/types/template-editor";

const HeaderView = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(EmailSectionType.header);
  const dispatch = useTemplateEditorDispatch();

  if (!isHeaderSection(state)) {
    return null;
  }

  const isCustomHTML = state.custom_html?.enabled ?? false;

  return (
    <>
      {!isCustomHTML ? (
        <>
          <Flex gap="16px" direction="column">
            <VariantSelector />
          </Flex>

          <ModifierContainer title="Logo" hideSwitch>
            <LogoSelector
              img={state?.logo ?? null}
              onSelect={(asset) => {
                updateState({
                  ...state,
                  logo: {
                    ...(state.logo ?? {
                      id: "",
                      type: EmailElementType.image,
                      enabled: true,
                      image_url: asset.url,
                      border_radius: "0px",
                      padding_top: "0px",
                      padding_right: "0px",
                      padding_bottom: "0px",
                      padding_left: "0px",
                      border_style: CSSBorderStyle.none,
                      border_color: "unset",
                      border_width: "unset",
                    }),
                    enabled: true,
                    image_url: asset.url,
                  },
                });
              }}
            />
          </ModifierContainer>

          <ColorPaletteModifier
            onClick={(palette) => {
              updateState({
                ...state,
                palette: {
                  ...state.palette,
                  foreground: palette.foreground,
                  background: palette.background,
                  accent_background: palette.accent_background,
                  accent_foreground: palette.accent_foreground,
                },
              });
            }}
            palette={state.palette}
          />

          <ModifierContainer
            title="Menu Links"
            hideSwitch={false}
            checked={state.menu?.enabled ?? false}
            onSwitch={(c) =>
              updateState({
                ...state,
                menu: {
                  ...(state.menu ?? {
                    id: "",
                    type: EmailElementType.menu,
                    enabled: c,
                    menu_links: [],
                  }),
                  enabled: c,
                },
              })
            }
          >
            <Button
              onClick={() =>
                dispatch({
                  type: "CHANGE_CURRENT_VIEW",
                  payload: CustomizationViews.MenuLinks,
                })
              }
              size="4"
              variant="outline"
              color="gray"
              radius="large"
            >
              <PencilIcon />
              Edit
            </Button>
          </ModifierContainer>
        </>
      ) : null}

      <ModifierContainer
        title="Use Custom HTML"
        hideSwitch={false}
        checked={state.custom_html?.enabled ?? false}
        onSwitch={(c) =>
          updateState({
            ...state,
            custom_html: {
              ...(state.custom_html ?? {
                id: "",
                type: EmailElementType.custom_html,
                enabled: c,
                html: "<div style='text-align: center; padding: 20px;'>Hello World</div>",
              }),
              enabled: c,
            },
          })
        }
        padding={"0"}
      >
        {isCustomHTML && <CustomHTMLView />}
      </ModifierContainer>
    </>
  );
};

export default HeaderView;
