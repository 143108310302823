import { HOME_ROUTE } from "./constants";
import KinLogo from "@components/core/KinLogo";
import { Box, Flex } from "@radix-ui/themes";
import { createContext, useContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import OnboardingIntegrationContainer from "~/components/onboarding/OnboardingIntegrationContainer";
import OnboardingIntro from "~/components/onboarding/OnboardingIntro";
import OnboardingMarketingChatContainer from "~/components/onboarding/OnboardingMarketingChatContainer";
import OnboardingStyleLibraryContainer from "~/components/onboarding/OnboardingStyleLibraryContainer";
import useDraperStep from "~/components/onboarding/hooks/useDraperStep";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";
import useBrandUpdateMutation from "~/hooks/brand/useBrandUpdateMutation";
import TEMP_FLAGS from "~/utils/tempFlags";

const ONBOARDING_STEP_VARIABLE = "draper_onboarding_step";

enum OnboardingStep {
  INTRO = 0,
  INTEGRATION = 1,
  STYLE_LIBRARY = 2,
  MARKETING_CHAT = 3,
}

interface OnboardingContextType {
  step: number;
  setStep: (step: number) => void;
  onNextOnboardingStep: () => void;
  isLoading: boolean;
  onboardingSteps: number[];
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined
);

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const activeBrandID = useActiveBrandID();
  const navigate = useNavigate();
  const { step, setStep } = useDraperStep(ONBOARDING_STEP_VARIABLE);
  const user = useAuthenticatedUserState();

  const onboardingSteps = Object.values(OnboardingStep)
    .filter((step) => typeof step !== "string")
    .filter((step) => {
      if (step === OnboardingStep.MARKETING_CHAT) {
        return user.isInternal && TEMP_FLAGS.ONBOARDING_MARKETING_CHAT;
      }
      return true;
    });

  const { updateBrand, isLoading } = useBrandUpdateMutation({
    brandID: activeBrandID,
    onSuccess: () => {
      localStorage.removeItem(ONBOARDING_STEP_VARIABLE);
      // Force refresh if already on HOME_ROUTE by navigating to the same route
      if (window.location.pathname === HOME_ROUTE) {
        window.location.reload();
      } else {
        navigate(HOME_ROUTE);
      }
    },
  });

  const onNextOnboardingStep = () => {
    if (step === onboardingSteps.length - 1) {
      updateBrand({
        is_onboarded: true,
      });
    } else {
      setStep(step + 1);
      localStorage.setItem(ONBOARDING_STEP_VARIABLE, (step + 1).toString());
    }
  };

  const value = {
    step,
    setStep,
    onNextOnboardingStep,
    isLoading,
    onboardingSteps,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error("useOnboarding must be used within an OnboardingProvider");
  }
  return context;
};

function getStepContent(step: OnboardingStep) {
  let content = null;
  switch (step) {
    case OnboardingStep.INTRO:
      content = <OnboardingIntro />;
      break;
    case OnboardingStep.INTEGRATION:
      content = <OnboardingIntegrationContainer />;
      break;
    case OnboardingStep.STYLE_LIBRARY:
      content = <OnboardingStyleLibraryContainer />;
      break;
    case OnboardingStep.MARKETING_CHAT:
      content = <OnboardingMarketingChatContainer />;
      break;
    default:
      throw Error("Onboarding step not implemented");
  }

  return content;
}

const OnboardingContent = () => {
  const { step } = useOnboardingContext();

  return (
    <Flex
      width={"100dvw"}
      height={"100dvh"}
      justify={"center"}
      direction={"column"}
      overflowX={"hidden"}
    >
      <BackgroundGradient />
      <Box position={"fixed"} top={"4"} left={"4"}>
        <KinLogo />
      </Box>
      {getStepContent(step)}
    </Flex>
  );
};

const OnboardingRoute = () => {
  return (
    <OnboardingProvider>
      <OnboardingContent />
    </OnboardingProvider>
  );
};

export default OnboardingRoute;
