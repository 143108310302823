import { MAX_FEATURED_PRODUCTS } from "../ProductSelectorDialog";
import { Product } from "../hooks/useGetFilteredProducts";
import { ProductOrder } from "../hooks/useProductsFilters";
import styles from "./ProductsTable.module.css";
import ProductRow, { ProductRowSkeleton } from "./productRow/ProductRow";
import { ShopifyProductSortKeys } from "@openapi";
import { IconButton, Table } from "@radix-ui/themes";
import { ChevronDown, ChevronsUpDown, ChevronUp, Star } from "lucide-react";
import { useMemo } from "react";
import styled from "styled-components";
import DraperText from "~/components/core/DraperText";
import { useActiveBrandCurrency } from "~/contexts/CurrentUserContext";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

const ProductTitleSortIcons = new Map<ProductOrder, React.ReactNode>([
  [ProductOrder.ASC, <ChevronUp strokeWidth="1.2px" />],
  [ProductOrder.DESC, <ChevronDown strokeWidth="1.2px" />],
  [ProductOrder.UNSET, <ChevronsUpDown strokeWidth="1.2px" />],
]);

const HeaderText = styled(DraperText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000b8;
`;

const ProductsTable = ({
  itemsPerPage,
  products,
  selectProduct,
  selectProducts,
  deselectProduct,
  selectedProducts,
  toggleSortOrder,
  sortOrder,
  selectedSortType,
  onFeatureToggle,
  canOnlySelectOneProduct,
}: {
  itemsPerPage: number;
  selectedProducts: Map<string, MaybeFeaturedProduct>;
  products?: Product[];
  selectProduct: (product: Product) => void;
  selectProducts?: (product: Product) => void;
  deselectProduct: (product: Product) => void;
  toggleSortOrder: (sortType: ShopifyProductSortKeys | null) => void;
  sortOrder: ProductOrder;
  selectedSortType: ShopifyProductSortKeys | null;
  onFeatureToggle?: (product: Product) => void;
  canOnlySelectOneProduct?: boolean;
}) => {
  const getProductTitleSortIcons = (
    order: ProductOrder,
    sortType: ShopifyProductSortKeys | null
  ) => {
    if (sortType === selectedSortType) {
      return ProductTitleSortIcons.get(order);
    }
    return ProductTitleSortIcons.get(ProductOrder.UNSET);
  };

  const nameOrderIcon: React.ReactNode = getProductTitleSortIcons(
    sortOrder,
    ShopifyProductSortKeys.TITLE
  );
  const stockOrderIcon: React.ReactNode = getProductTitleSortIcons(
    sortOrder,
    ShopifyProductSortKeys.INVENTORY_TOTAL
  );
  const currency = useActiveBrandCurrency();

  const featuredProductsCount = useMemo(() => {
    return Array.from(selectedProducts.values()).filter((p) => p.is_featured)
      .length;
  }, [selectedProducts]);

  return (
    <Table.Root variant="ghost">
      <Table.Header className={styles.productsHead}>
        <Table.Row
          style={{
            borderBottom: "none",
            padding: "0px",
            verticalAlign: "middle",
          }}
        >
          <Table.ColumnHeaderCell>
            <HeaderText>Select</HeaderText>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <IconButton
              variant="ghost"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => toggleSortOrder(ShopifyProductSortKeys.TITLE)}
            >
              <HeaderText>Name</HeaderText>
              {nameOrderIcon}
            </IconButton>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <IconButton
              variant="ghost"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() =>
                toggleSortOrder(ShopifyProductSortKeys.INVENTORY_TOTAL)
              }
            >
              <HeaderText>Stock</HeaderText>
              {stockOrderIcon}
            </IconButton>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <HeaderText>Price</HeaderText>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <HeaderText>Promo Price</HeaderText>
          </Table.ColumnHeaderCell>
          {onFeatureToggle && (
            <Table.ColumnHeaderCell>
              <HeaderText>Featured</HeaderText>
            </Table.ColumnHeaderCell>
          )}
        </Table.Row>
      </Table.Header>

      <Table.Body className={styles.productsTable}>
        {products
          ? products.map((product, index) => {
              const isFeatured =
                selectedProducts.get(product.product_id)?.is_featured ?? false;
              const featureButton = onFeatureToggle ? (
                <IconButton
                  variant="ghost"
                  radius="full"
                  size={"2"}
                  color="gray"
                  style={{
                    cursor:
                      isFeatured ||
                      featuredProductsCount < MAX_FEATURED_PRODUCTS
                        ? "pointer"
                        : "not-allowed",
                  }}
                  disabled={
                    !isFeatured &&
                    featuredProductsCount >= MAX_FEATURED_PRODUCTS
                  }
                  onClick={() => onFeatureToggle?.(product)}
                >
                  <Star
                    fill={isFeatured ? "#595D62" : "none"}
                    strokeWidth={1}
                    stroke="#595D62"
                  />
                </IconButton>
              ) : null;

              return (
                <ProductRow
                  key={product.product_id}
                  canOnlySelectOneProduct={canOnlySelectOneProduct ?? false}
                  featureButton={featureButton}
                  featuredProductsCount={featuredProductsCount}
                  isChecked={selectedProducts.has(product.product_id)}
                  isFeatured={isFeatured}
                  onProductDeselect={deselectProduct}
                  onProductSelect={selectProduct}
                  onProductSelects={selectProducts}
                  onFeatureToggle={onFeatureToggle}
                  product={product}
                  currency={currency}
                />
              );
            })
          : Array.from({ length: itemsPerPage }, (_, i) => i).map((i) => (
              <ProductRowSkeleton key={i} />
            ))}
      </Table.Body>
    </Table.Root>
  );
};

export default ProductsTable;
