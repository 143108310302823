import AppButton from "../buttons/AppButton/AppButton";
import { Popover, TextField, Flex } from "@radix-ui/themes";
import dayjs from "dayjs";
import { Calendar1 } from "lucide-react";
import { useRef, useState, useEffect, ReactNode } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import styled from "styled-components";

const CalendarInput = styled.input`
  all: unset;
  cursor: pointer;
  background: white;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type="date"] {
    -moz-appearance: textfield;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const StyledDayPicker = styled(DayPicker)`
  --rdp-accent-color: var(--icon-primary-active);
`;

const DatePicker = ({
  initialDate,
  onSelect,
  isOpen,
  onOpenChange,
  readonly = false,
  triggerComponent,
  onConfirm,
}: {
  initialDate?: string;
  onSelect: (formattedDate: string) => void;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  readonly?: boolean;
  triggerComponent?: ReactNode;
  onConfirm?: (formattedDate: string) => void;
}) => {
  const [selected, setSelected] = useState<Date>(
    initialDate ? new Date(initialDate) : new Date()
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const [internalOpen, setInternalOpen] = useState(false);

  // Use controlled or uncontrolled open state
  const open = isOpen !== undefined ? isOpen : internalOpen;
  const setOpen = (newOpen: boolean) => {
    if (readonly) return; // Don't open if readonly
    if (isOpen !== undefined && onOpenChange) {
      onOpenChange(newOpen);
    } else {
      setInternalOpen(newOpen);
    }
  };

  const handleDaySelect = (date: Date | undefined) => {
    if (readonly) return; // Don't allow selection if readonly
    if (!date) {
      // don't allow deselection, as we always want a date to be selected
      return;
    }
    setSelected(date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    if (inputRef.current) {
      inputRef.current.value = formattedDate;
    }

    // If no confirm button, select immediately
    if (!onConfirm) {
      onSelect(formattedDate);
    }
  };

  const handleConfirm = () => {
    const formattedDate = dayjs(selected)
      .startOf("day") // Ensures midnight
      .format("YYYY-MM-DDTHH:mm:ss");

    onSelect(formattedDate);
    if (onConfirm) {
      onConfirm(formattedDate);
    }
    setOpen(false);
  };

  // Set initial value for the input field once
  useEffect(() => {
    if (inputRef.current && initialDate) {
      const formattedDate = dayjs(initialDate).format("YYYY-MM-DD");
      inputRef.current.value = formattedDate;

      onSelect(formattedDate);
    }
  }, [initialDate]); // Only depend on initialDate, not the ref or onSelect

  // Default input field trigger
  const defaultInputTrigger = (
    <TextField.Root
      style={{
        height: "48px",
        borderRadius: "12px",
        padding: "12px",
        background: "white",
        opacity: readonly ? 0.7 : 1,
      }}
    >
      <CalendarInput
        ref={inputRef}
        type="date"
        readOnly={readonly}
        onClick={(e) => {
          if (!readonly) {
            setOpen(true);
            e.preventDefault();
          }
        }}
      />
      {!/firefox/i.test(navigator.userAgent) && ( // seems very hard to hide the calendar icon on firefox
        <TextField.Slot
          style={{ cursor: readonly ? "default" : "pointer" }}
          onClick={(e) => {
            if (!readonly) {
              setOpen(true);
              e.preventDefault();
            }
          }}
        >
          <Calendar1 size={20} />
        </TextField.Slot>
      )}
    </TextField.Root>
  );

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        {triggerComponent ? triggerComponent : defaultInputTrigger}
      </Popover.Trigger>
      {!readonly && (
        <Popover.Content sideOffset={20} align="start">
          <Flex direction="column" gap="2">
            <StyledDayPicker
              mode="single"
              selected={selected}
              onSelect={handleDaySelect}
              defaultMonth={selected}
              disabled={{ before: new Date() }}
            />
            {onConfirm && (
              <Flex justify="end" width="100%" gap="2">
                <AppButton
                  radius="large"
                  size="4"
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </AppButton>
                <AppButton radius="large" size="4" onClick={handleConfirm}>
                  Save
                </AppButton>
              </Flex>
            )}
          </Flex>
        </Popover.Content>
      )}
    </Popover.Root>
  );
};

export default DatePicker;
