import OnboardingInspiration from "./OnboardingInspiration";
import OnboardingKlaviyo from "./OnboardingKlaviyo";
import OnboardingMeta from "./OnboardingMeta";
import OnboardingShopify from "./OnboardingShopify";
import OnboardingStepper from "./OnboardingStepper";
import useDraperStep from "./hooks/useDraperStep";
import { Flex } from "@radix-ui/themes";
import { useRef, useEffect, useCallback } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import styled from "styled-components";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import { useOnboardingContext } from "~/routes/onboarding";

const INTEGRATION_STEP_VARIABLE = "draper_integration_onboarding_step";

const Stepper = styled(Flex)`
  transform: translateY(-50%);
`;

const SnapContainer = styled.div`
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory; /* Enable vertical scroll snapping */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
`;

const SnapItem = styled.div`
  height: 100vh;
  scroll-snap-align: start; /* Snap each item to the start (top) */
  display: flex;
  align-items: center;
  justify-content: center;
`;

enum InegrationStep {
  SHOPIFY = 0,
  KLAVIYO = 1,
  // META = 2,
  INSPIRATION = 2,
}

export const useIntegrationSteps = () => {
  const user = useAuthenticatedUserState();
  return Object.values(InegrationStep)
    .filter((step) => typeof step !== "string")
    .filter((step) => {
      if (step === InegrationStep.INSPIRATION) {
        return user.isInternal;
      }
      return true;
    });
};

function getIntegrationStepContent(
  step: InegrationStep,
  onNext: () => void,
  onPrev: () => void
) {
  let content = null;
  switch (step) {
    case InegrationStep.SHOPIFY:
      content = <OnboardingShopify onNext={onNext} />;
      break;
    case InegrationStep.KLAVIYO:
      content = <OnboardingKlaviyo onNext={onNext} onPrev={onPrev} />;
      break;
    // case InegrationStep.META:
    //   content = <OnboardingMeta onNext={onNext} onPrev={onPrev} />;
    //   break;
    case InegrationStep.INSPIRATION:
      content = <OnboardingInspiration onNext={onNext} onPrev={onPrev} />;
      break;
    default:
      throw Error("Integration step not implemented");
  }

  return content;
}

const VerticalSnapList = ({
  activeStep,
  setActiveStep,
}: {
  activeStep: InegrationStep;
  setActiveStep: (step: InegrationStep) => void;
}) => {
  const INTEGRATION_STEPS = useIntegrationSteps();
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const scrollable = useRef<HTMLDivElement>(null);
  const isScrollingFromTap = useRef(false);
  const { onNextOnboardingStep } = useOnboardingContext();

  useEffect(() => {
    if (itemRefs.current[activeStep]) {
      scrollIntoView(itemRefs.current[activeStep], {
        behavior: "smooth",
        block: "start",
      });
    }
  }, [activeStep]);
  const handleScroll = () => {
    if (isScrollingFromTap.current) return;

    const scrollPosition =
      scrollable.current?.scrollTop || document.documentElement.scrollTop;
    const viewportHeight = window.innerHeight;

    itemRefs.current.forEach((el, index) => {
      const stepTop = el.offsetTop;
      const stepHeight = el.offsetHeight;
      const stepMiddle = stepTop + stepHeight / 2;

      const viewportMiddle = scrollPosition + viewportHeight / 2;

      // Check if the middle of the step is closest to the middle of the viewport
      if (Math.abs(viewportMiddle - stepMiddle) < stepHeight / 2) {
        // setActiveStep(index);
        // this was overriding the saved localStorage state
        // and it seems to work fine without it
      }
    });
  };

  const handleScrollEnd = useCallback(() => {
    isScrollingFromTap.current = false;
  }, [isScrollingFromTap.current]);

  useEffect(() => {
    if (scrollable.current) {
      scrollable.current.addEventListener("scroll", handleScroll);
      scrollable.current.addEventListener("scrollend", handleScrollEnd);
    }

    return () => {
      if (scrollable.current) {
        scrollable.current.removeEventListener("scroll", handleScroll);
        scrollable.current.removeEventListener("scrollend", handleScrollEnd);
      }
    };
  }, [activeStep]);

  return (
    <SnapContainer ref={scrollable}>
      {INTEGRATION_STEPS.map((item) => (
        <SnapItem
          key={item}
          ref={(el) => {
            if (el) itemRefs.current[item] = el;
          }}
        >
          {getIntegrationStepContent(
            item,
            () => {
              if (item === INTEGRATION_STEPS[INTEGRATION_STEPS.length - 1]) {
                onNextOnboardingStep();
                localStorage.removeItem(INTEGRATION_STEP_VARIABLE);
              } else {
                setActiveStep(item + 1);
              }
              isScrollingFromTap.current = true;
            },
            () => {
              isScrollingFromTap.current = true;
              setActiveStep(item - 1);
            }
          )}
        </SnapItem>
      ))}
    </SnapContainer>
  );
};

const OnboardingIntegrationContainer = () => {
  const { step, setStep } = useDraperStep(INTEGRATION_STEP_VARIABLE);

  return (
    <>
      <Stepper
        position={"absolute"}
        top={"50%"}
        left={"4"}
        direction="column"
        align="center"
      >
        <OnboardingStepper activeStep={step} />
      </Stepper>
      <VerticalSnapList activeStep={step} setActiveStep={setStep} />
    </>
  );
};

export default OnboardingIntegrationContainer;
