import {
  CampaignBriefOverviewSection,
  SectionContent,
} from "../CampaignBriefOverview";
import { ITEM_SET_COUPON_DEFAULTS } from "../hooks/useCampaignPromotionProps";
import {
  components,
  DiscountType,
  ItemSetType,
  ShopifyDiscountType,
  ShopifyDiscountValueType,
} from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import PromotionSelector from "~/components/selectors/PromotionSelector";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
  useCampaignWizardUpdateCampaignData,
} from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  getCampaignRecommendationQueryKey,
  CampaignRecommendationResponse,
} from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

export const CampaignBriefOverviewPromotionSection = ({
  campaignRecommendation,
}: {
  campaignRecommendation: CampaignRecommendationResponse;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const { campaignData } = useCampaignWizardState();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();

  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [discountType, setDiscountType] = useState<ShopifyDiscountValueType>(
    ShopifyDiscountValueType.percentage
  );
  const [showDiscountUI, setShowDiscountUI] = useState<boolean>(false);

  const discount = campaignData.discount;
  const actualDiscountType = campaignRecommendation.discount_type;
  const text =
    actualDiscountType === "coupon_flat"
      ? `$${campaignRecommendation.discount_amount} price reduction for the selected products`
      : actualDiscountType === "coupon_percent"
      ? `${campaignRecommendation.discount_amount}% discount for the selected products`
      : "No promotion specified";

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onSettled: (data, error, variables) => {},
      onSuccess: (data) => {
        updateCampaignData({
          discount:
            discountValue && showDiscountUI
              ? {
                  amount: discountValue,
                  value_type: discountType,
                  type: ShopifyDiscountType.coupon, // (ashleyxue) TODO: Is this a bug?
                }
              : null,
        });

        // Manually trigger the creative recommendation regeneration
        queryClient.setQueryData(
          getCampaignRecommendationQueryKey(
            activeBrandID,
            campaignRecommendation.id
          ),
          (oldData: CampaignRecommendationResponse) => {
            return {
              ...oldData,
              discount_amount: data.discount_amount,
              discount_type: data.discount_type,
              active_creative_recommendation: null,
            };
          }
        );
      },
    });
  const onSyncDiscountState = (
    discount: components["schemas"]["DiscountRequestData"] | null | undefined
  ) => {
    if (discount) {
      setDiscountValue(discount.amount);
      setDiscountType(discount.value_type);
      setShowDiscountUI(true);
    } else {
      const discountDefault =
        ITEM_SET_COUPON_DEFAULTS[
          campaignRecommendation?.item_set_type ?? ItemSetType.new_products
        ] ?? 0;
      if (discountDefault) {
        setDiscountValue(discountDefault);
        setDiscountType(ShopifyDiscountValueType.percentage);
      } else {
        setDiscountValue(null);
        setDiscountType(ShopifyDiscountValueType.percentage);
      }
      setShowDiscountUI(false);
    }
  };

  return (
    <CampaignBriefOverviewSection
      title="Promotion"
      isLoading={isLoading}
      disabled={showDiscountUI && !discountValue}
      editDialogBody={
        <PromotionSelector
          discountValue={discountValue}
          discountType={discountType}
          showDiscountUI={showDiscountUI}
          setDiscountValue={setDiscountValue}
          setDiscountType={setDiscountType}
          setShowDiscountUI={setShowDiscountUI}
        />
      }
      onEdit={() => {
        onSyncDiscountState(discount);
      }}
      onSave={() => {
        updateCampaignRecommendation({
          brand_id: activeBrandID,
          campaign_recommendation_id: campaignRecommendation.id,
          is_discount_enabled: showDiscountUI,
          discount_amount: discountValue,
          discount_type:
            discountType === ShopifyDiscountValueType.percentage
              ? DiscountType.coupon_percent
              : DiscountType.coupon_flat,
        });
      }}
    >
      <SectionContent>{text}</SectionContent>
    </CampaignBriefOverviewSection>
  );
};
