import { useCampaignWizardState } from "../../../contexts/CampaignWizardContext";
import { CampaignBriefOverviewAssetsSection } from "./overview/CampaignBriefOverviewAssetsSection";
import { CampaignBriefOverviewAudienceSection } from "./overview/CampaignBriefOverviewAudienceSection";
import { CampaignBriefOverviewChannelsSection } from "./overview/CampaignBriefOverviewChannelsSection";
import { CampaignBriefOverviewCreativeConceptSection } from "./overview/CampaignBriefOverviewCreativeConceptSection";
import { CampaignBriefOverviewDateSection } from "./overview/CampaignBriefOverviewDateSection";
import CampaignBriefOverviewDialog from "./overview/CampaignBriefOverviewDialog";
import { CampaignBriefOverviewLandingDestinationSection } from "./overview/CampaignBriefOverviewLandingDestinationSection";
import { CampaignBriefOverviewProductsSection } from "./overview/CampaignBriefOverviewProductsSection";
import { CampaignBriefOverviewPromotionSection } from "./overview/CampaignBriefOverviewPromotionSection";
import { CampaignType } from "@openapi";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Skeleton,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Pencil } from "lucide-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import markSrc from "~/assets/mark.png";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { getCampaignGenerationStatusQueryKey } from "~/hooks/campaign/useCampaignGenerationStatusQuery";
import useCampaignRecommendationQuery, {
  getCampaignRecommendationQueryKey,
} from "~/hooks/campaign/useCampaignRecommendationQuery";
import useCreateCampaignMutation from "~/hooks/campaign/useCreateCampaignMutation";
import { getCampaignDetailsQueryKey } from "~/hooks/campaign/useGetCampaignDetails";
import { CAMPAIGN_OVERVIEW_ROUTE } from "~/routes/constants";
import nullthrows from "~/utils/nullthrows";

const SectionCard = styled(Flex)<{ noBorder?: boolean }>`
  width: 100%;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  padding: 24px 48px;
  border-top: ${(props) =>
    props.noBorder ? "none" : "1px solid var(--border-primary)"};
`;

const SectionTitle = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  letter-spacing: -0.5px;
  color: #6933dc;
`;

export const SectionContent = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #121111;
`;

export const CampaignBriefOverviewSection: React.FC<{
  title: string;
  children: React.ReactNode;
  onEdit?: React.ReactNode | (() => void);
  onSave?: () => void;
  editDialogBody?: React.ReactElement;
  isLoading?: boolean;
  disabled?: boolean;
  maxDialogWidth?: string;
}> = ({
  title,
  children,
  onEdit,
  editDialogBody,
  onSave,
  isLoading,
  disabled,
  maxDialogWidth,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const dialog = editDialogBody && (
    <CampaignBriefOverviewDialog
      open={isEditing}
      onOpenChange={setIsEditing}
      title={title}
      body={editDialogBody}
      onSave={() => {
        setIsEditing(false);
        onSave?.();
      }}
      disabled={disabled}
      maxWidth={maxDialogWidth}
    />
  );

  // (ashleyxue): TODO: cleanup, too many cases for onEdit
  return (
    <SectionCard>
      <Flex direction="row" align="center" justify="between">
        <SectionTitle>{title}</SectionTitle>
        {typeof onEdit === "function" && (
          <AppButton
            variant="outlined"
            size="2"
            onClick={() => {
              editDialogBody && setIsEditing(true);
              onEdit();
            }}
            style={{
              borderRadius: "12px",
            }}
            disabled={!!isLoading}
          >
            <Pencil size={16} /> Edit
          </AppButton>
        )}
        {typeof onEdit === "object" && onEdit}
      </Flex>
      {children}

      {dialog}
    </SectionCard>
  );
};

export const CardContainer = styled(Flex)`
  width: 800px;
  position: relative;
  border: 1px solid var(--border-primary);
  border-radius: 24px;
  background-color: var(--primary-white);
  box-shadow: 0px 12px 120px 0px #00000014;
  box-shadow: 0px 12px 32px -16px #0009320a;
  margin: 148px 24px 100px;
`;

export const AssistantAvatar = styled(Avatar)`
  position: absolute;
  transform: translate(-50%, -50%);
  height: 64px;
  width: 64px;
`;

export const useGenerateButtonProps = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams() as { recommendationID: string };
  const { campaignData } = useCampaignWizardState();

  // Mutations
  const { createCampaign, isLoading: isCreatingCampaign } =
    useCreateCampaignMutation({
      onSuccess: (data) => {
        queryClient.setQueryData(
          getCampaignRecommendationQueryKey(activeBrandID, recommendationID),
          (oldData: any) => ({
            ...oldData,
            campaign_id: data.id,
          })
        );

        queryClient.setQueryData(
          getCampaignDetailsQueryKey(data.id),
          (oldData: any) => ({
            ...data,
          })
        );

        // To ensure loading state is reset
        // If this is the first generation, we need to set is_initial_generation_complete
        if (
          data.email_creatives.length === 0 &&
          data.ad_creatives.length === 0
        ) {
          queryClient.setQueryData(
            getCampaignGenerationStatusQueryKey(data.id),
            (oldData: any) => ({
              ...oldData,
              is_initial_generation_complete: false,
            })
          );
        } else {
          queryClient.setQueryData(
            getCampaignGenerationStatusQueryKey(data.id),
            (oldData: any) => ({
              ...oldData,
              is_campaign_job_complete: false,
            })
          );
        }

        navigate(CAMPAIGN_OVERVIEW_ROUTE.replace(":id", data.id));
      },
      onError: (error) => {
        console.error(error);
      },
      onSettled: (data) => {
        queryClient.invalidateQueries({
          queryKey: getCampaignRecommendationQueryKey(
            activeBrandID,
            recommendationID
          ),
        });
        if (data) {
          queryClient.invalidateQueries({
            queryKey: getCampaignDetailsQueryKey(data.id),
            refetchType: "all",
          });
        }
      },
    });

  // Functions
  const onGenerate = () => {
    const products = campaignData.products.map((product) => ({
      id: product.product_id,
      commerce_platform_id: product.product_id,
      is_featured: product.is_featured,
      url: product.url,
    }));
    createCampaign({
      asset_ids: campaignData.asset_ids ?? [],
      audiences: campaignData.audiences ?? [],
      excluded_audiences: campaignData.excluded_audiences ?? [],
      campaign_id: campaignData.campaign_id,
      channels: campaignData.channels!,
      products,
      destination: campaignData.destination ?? {},
      brand_id: activeBrandID,
      creative_id: campaignData.creative_id!,
      campaign_type: campaignData.campaign_type ?? CampaignType.other,
      campaign_recommendation_id: nullthrows(recommendationID),
      discount: campaignData.discount ?? null,
      date: campaignData.date ?? "",
    });
  };

  return {
    disabled:
      !campaignData.date ||
      !campaignData.products?.length ||
      !campaignData.audiences?.length ||
      !campaignData.creative_id ||
      !campaignData.channels,
    onClick: onGenerate,
    isLoading: isCreatingCampaign,
  };
};

const CampaignBriefOverview: React.FC = () => {
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams() as { recommendationID: string };
  const {
    disabled,
    onClick,
    isLoading: isGenerating,
  } = useGenerateButtonProps();
  const { campaignRecommendation, isLoading: isLoadingCampaignRecommendation } =
    useCampaignRecommendationQuery(activeBrandID, recommendationID);

  if (!campaignRecommendation) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner />
      </Flex>
    );
  }

  return (
    <CardContainer>
      <AssistantAvatar src={markSrc} fallback="Mark" />
      <Box style={{ width: "100%" }}>
        <SectionCard>
          <Flex direction="row" align="center" pt="6">
            <Heading size="5">Here's your campaign plan:</Heading>
          </Flex>
        </SectionCard>

        <CampaignBriefOverviewSection title="Strategy">
          <Skeleton loading={isLoadingCampaignRecommendation}>
            <SectionContent>
              {campaignRecommendation?.item_grouping_rationale}
            </SectionContent>
          </Skeleton>
        </CampaignBriefOverviewSection>

        <CampaignBriefOverviewDateSection
          campaignRecommendation={campaignRecommendation}
        />

        <CampaignBriefOverviewProductsSection
          campaignRecommendation={campaignRecommendation}
        />

        <CampaignBriefOverviewPromotionSection
          campaignRecommendation={campaignRecommendation}
        />

        <CampaignBriefOverviewAudienceSection
          campaignRecommendationID={recommendationID}
        />

        <CampaignBriefOverviewLandingDestinationSection
          campaignRecommendationID={recommendationID}
        />

        <CampaignBriefOverviewAssetsSection
          campaignRecommendation={campaignRecommendation}
        />

        <CampaignBriefOverviewChannelsSection
          campaignRecommendationID={recommendationID}
        />

        <CampaignBriefOverviewCreativeConceptSection
          campaignRecommendation={campaignRecommendation}
        />

        <SectionCard>
          <Flex direction="row" align="center" justify="center">
            <AppButton
              variant="primary"
              onClick={onClick}
              disabled={disabled || isGenerating}
            >
              {isGenerating ? "Generating..." : "Generate Graphics"}
            </AppButton>
          </Flex>
        </SectionCard>
      </Box>
    </CardContainer>
  );
};

export default CampaignBriefOverview;
