import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
} from "../../../contexts/CampaignWizardContext";
import AudienceCard from "./CampaignAudience";
import CampaignChannel from "./CampaignChannel";
import CampaignCreatives from "./CampaignCreatives";
import CampaignCustomAssets from "./CampaignCustomAssets";
import CampaignDetails from "./CampaignDetails";
import CampaignLandingDestination from "./CampaignLandingDestination";
import CampaignProducts from "./CampaignProducts";
import CampaignPromotion from "./CampaignPromotion";
import CampaignSummary from "./CampaignSummary";
import CampaignTypeCard from "./CampaignType";

export type CampaignCardCommonProps = {
  step: number;
};

const BlankCard: React.FC<CampaignCardCommonProps> = () => {
  return null;
};

export const RecommendedCampaignCards: Record<
  RecommendedCampaignWizardSteps,
  React.FC<CampaignCardCommonProps>
> = {
  [RecommendedCampaignWizardSteps.SUMMARY]: CampaignSummary,
  [RecommendedCampaignWizardSteps.CHANNEL]: CampaignChannel,
  [RecommendedCampaignWizardSteps.AUDIENCE]: AudienceCard,
  [RecommendedCampaignWizardSteps.PROMOTION]: CampaignPromotion,
  [RecommendedCampaignWizardSteps.PRODUCT]: CampaignProducts,
  [RecommendedCampaignWizardSteps.LANDING_DESTINATION]:
    CampaignLandingDestination,
  [RecommendedCampaignWizardSteps.CREATIVE_CONCEPT]: CampaignCreatives,
  [RecommendedCampaignWizardSteps.GENERATE]: BlankCard,
};

export const NewCampaignCards: Record<
  NewCampaignWizardSteps,
  React.FC<CampaignCardCommonProps>
> = {
  [NewCampaignWizardSteps.TYPE]: CampaignTypeCard,
  [NewCampaignWizardSteps.CUSTOM_ASSETS]: CampaignCustomAssets,
  [NewCampaignWizardSteps.DETAILS]: CampaignDetails,
  [NewCampaignWizardSteps.LANDING_DESTINATION]: CampaignLandingDestination,
  [NewCampaignWizardSteps.CHANNEL]: CampaignChannel,
  [NewCampaignWizardSteps.AUDIENCE]: AudienceCard,
  [NewCampaignWizardSteps.PRODUCT]: CampaignProducts,
  [NewCampaignWizardSteps.PROMOTION]: CampaignPromotion,
  [NewCampaignWizardSteps.CREATIVE_CONCEPT]: CampaignCreatives,
  [NewCampaignWizardSteps.GENERATE]: BlankCard,
};
