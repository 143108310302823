import {
  operations,
  PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider,
} from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";

type CloudSocialAuthTokenResponse =
  operations["media_api_get_social_auth_tokens"]["responses"][200]["content"]["application/json"];

const useCloudProviderAuth = (
  providerId: PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider
) => {
  const [isPolling, setIsPolling] = useState(false);

  const { data: authData, isLoading } = useQuery({
    queryKey: ["cloud-provider-auth", providerId],
    queryFn: async (): Promise<CloudSocialAuthTokenResponse> => {
      const { data } = await axios.get(
        `/api/v1/media/assets/social-auth-tokens/${providerId}`
      );
      return data;
    },
    enabled: true,
    refetchInterval: isPolling ? 3000 : false,
    refetchIntervalInBackground: false,
  });

  const startPolling = () => setIsPolling(true);
  const stopPolling = () => setIsPolling(false);

  return {
    hasToken: authData?.has_token ?? false,
    isLoading,
    startPolling,
    stopPolling,
  };
};

export default useCloudProviderAuth;
