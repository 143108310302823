import ReviewCard from "./components/ReviewCard";
import useGetProductReviews, {
  CustomerReview,
} from "./hooks/useGetProductReviews";
import { Flex, Dialog, IconButton, Text, Spinner } from "@radix-ui/themes";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { XIcon } from "~/assets/icons";

const ReviewSelectorDialog = ({
  selectedProductId,
  isOpen,
  onOpenChange,
  onSelectSingleReview,
  triggerComponent,
  selectedReviewsIds = [],
}: {
  selectedProductId: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onSelectSingleReview?: (review: CustomerReview) => void;
  triggerComponent?: ReactNode;
  selectedReviewsIds?: string[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const productReviews = useGetProductReviews(selectedProductId);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeDialog = () => {
    setIsModalOpen(false);
    onOpenChange?.(false);
  };

  const onSelectReview = (review: CustomerReview) => {
    onSelectSingleReview?.(review);
  };

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Text size="4" weight="bold">
        Select review
      </Text>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
          onClick={closeDialog}
        >
          <XIcon color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );

  const content = useMemo(() => {
    if (!productReviews) {
      return (
        <Flex
          direction="column"
          gap="4"
          style={{ padding: "8px" }}
          align="center"
          justify="center"
        >
          <Spinner />
        </Flex>
      );
    }

    if (!productReviews[0]?.reviews.length) {
      return <Text>No reviews available</Text>;
    }

    return (
      <Flex direction="column" gap="4" style={{ padding: "8px" }}>
        {productReviews[0].reviews.map((review) => (
          <ReviewCard
            key={`review_item_${review.review_id}`}
            review={review}
            onSelect={(review) => onSelectReview(review)}
            isSelected={selectedReviewsIds.includes(review.review_id)}
          />
        ))}
      </Flex>
    );
  }, [productReviews, selectedReviewsIds]);

  return (
    <Dialog.Root open={isModalOpen}>
      <Dialog.Trigger>{triggerComponent || <></>}</Dialog.Trigger>
      <Dialog.Content
        style={{
          padding: "16px",
          width: "480px",
        }}
      >
        <Flex direction="column" gap="2">
          {header}
          <div
            style={{ borderBottom: "1px solid #DDD7D7", margin: "0px -14px" }}
          ></div>
          {content}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ReviewSelectorDialog;
