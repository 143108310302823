import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CheckboxWithQuantity from "../../../components/campaign/wizard/inputs/CheckboxWithQuantity";
import { CampaignCardCommonProps } from "./CampaignCards";
import CampaignMetaConnect from "./CampaignMetaConnect";
import useCampaignChannelProps from "./hooks/useCampaignChannelProps";
import { Flex } from "@radix-ui/themes";
import React from "react";
import { ChannelType } from "~/contexts/CampaignWizardContext";

const CampaignChannel: React.FC<CampaignCardCommonProps> = (props) => {
  const { cardProps, checkboxProps, metaProps, recommendedCountMapper } =
    useCampaignChannelProps();

  if (metaProps.showsMetaConnect) {
    return (
      <CampaignMetaConnect
        onCancel={() => metaProps.setShowsMetaConnect(false)}
        {...props}
      />
    );
  }

  const emailCount = recommendedCountMapper[ChannelType.EMAIL];
  const adCount = recommendedCountMapper[ChannelType.META_ADS];

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle={`We propose ${
        emailCount > 0
          ? `sending ${emailCount} ${emailCount === 1 ? "email" : "emails"}${
              adCount > 0 ? " and " : ""
            }`
          : ""
      }${
        adCount > 0 ? `testing ${adCount} ${adCount === 1 ? "ad" : "ads"}` : ""
      } as part of this campaign. You can modify the channels and touchpoints if you want.`}
      {...props}
    >
      <Flex direction="column" gap="24px">
        {checkboxProps.map((props) => (
          <CheckboxWithQuantity key={props.label} {...props} />
        ))}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignChannel;
