import { operations } from "../../../openapi";
import { getCampaignRecommendationCreativeMessagesQueryKey } from "../campaign/useCampaignCreativeRecommendationMessagesQuery";
import { getCampaignRecommendationQueryKey } from "../campaign/useCampaignRecommendationQuery";
import {
  getCampaignUpdateOnError,
  getCampaignUpdateOnMutate,
  getCampaignUpdateOnSettled,
  getCampaignUpdateOnSuccess,
} from "../campaign/useCampaignUpdateMutation";
import { useGateValue } from "@statsig/react-bindings";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { getCampaignRecommendationsQueryKey } from "~/components/home/hooks/recommendations/useGetCampaignRecommendations";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

type UpdateCampaignRecommendationResponse =
  operations["recommender_api_update_campaign_recommendation"]["responses"]["200"]["content"]["application/json"];
type UpdateCampaignRecommendationRequestData =
  operations["recommender_api_update_campaign_recommendation"]["requestBody"]["content"]["application/json"];

const useRecommenderCampaignRecommendationUpdateMutation = ({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: {
  onMutate?: (variables: UpdateCampaignRecommendationRequestData) => void;
  onSuccess?: (
    data: UpdateCampaignRecommendationResponse,
    variables: UpdateCampaignRecommendationRequestData,
    context: any
  ) => void;
  onError?: (
    error: Error,
    variables: UpdateCampaignRecommendationRequestData,
    context: any
  ) => void;
  onSettled?: (
    data: UpdateCampaignRecommendationResponse | undefined,
    error: any | null,
    variables: UpdateCampaignRecommendationRequestData,
    context: any
  ) => void;
}) => {
  const queryClient = useQueryClient();

  const mutation = useDraperApiPatchMutation<
    UpdateCampaignRecommendationResponse,
    UpdateCampaignRecommendationRequestData
  >({
    mutationKey: ["/recommender/campaign-recommendation"],
    path: "/recommender/campaign-recommendation",
    onMutate: (variables) => {
      return onMutate?.(variables);
    },
    onError: (error, variables, context) => {
      console.log(error);
      onError?.(error, variables, context);
      toast.error("Something went wrong, please try again.");
    },
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      // Use the onSettled callback if it's provided, otherwise invalidate the queries
      // We use this for promotion and landing destination updates since we locally
      // set the active creative recommendation to null when the promotion or
      // destination is updated. And we don't want to invalidate the queries
      // when the creative recommendation is still being updated.
      if (onSettled) {
        onSettled(data, error, variables, context);
      } else {
        queryClient.invalidateQueries({
          queryKey: getCampaignRecommendationQueryKey(
            variables.brand_id,
            variables.campaign_recommendation_id
          ),
        });
        if (variables.active_creative_recommendation_id) {
          queryClient.invalidateQueries({
            queryKey: getCampaignRecommendationCreativeMessagesQueryKey(
              variables.brand_id,
              variables.active_creative_recommendation_id
            ),
          });
        }
      }
    },
  });

  return {
    updateCampaignRecommendation: mutation.mutate,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};

export default useRecommenderCampaignRecommendationUpdateMutation;

export const useRecommenderCampaignRecommendationUpdateMutationInHomePage =
  () => {
    const queryClient = useQueryClient();
    const activeBrandID = useActiveBrandID();
    const isNewHomePage = useGateValue("is_new_home_ui", {
      disableExposureLog: true,
    });

    const {
      updateCampaignRecommendation,
      isLoading: isCampaignRecommendationUpdating,
    } = useRecommenderCampaignRecommendationUpdateMutation({
      onMutate: (variables) => {
        if (isNewHomePage) {
          return getCampaignUpdateOnMutate(
            variables.campaign_recommendation_id,
            queryClient,
            activeBrandID,
            variables.date
          );
        } else {
          queryClient.setQueryData(
            getCampaignRecommendationsQueryKey(activeBrandID),
            (oldData: any) => {
              if (!oldData) return oldData;
              return {
                ...oldData,
                pages: oldData.pages.map((page: any) => {
                  return {
                    ...page,
                    campaign_recommendations: page.campaign_recommendations.map(
                      (c: any) =>
                        c.id === variables.campaign_recommendation_id
                          ? {
                              ...c,
                              date: variables.date,
                            }
                          : c
                    ),
                  };
                }),
              };
            }
          );
        }
      },
      onSuccess: (data, variables, context) => {
        if (isNewHomePage) {
          getCampaignUpdateOnSuccess(
            data,
            variables,
            context,
            queryClient,
            activeBrandID
          );
        } else {
          queryClient.setQueryData(
            getCampaignRecommendationsQueryKey(activeBrandID),
            (oldData: any) => {
              if (!oldData) return oldData;
              return {
                ...oldData,
                pages: oldData.pages.map((page: any) => {
                  return {
                    ...page,
                    campaign_recommendations: page.campaign_recommendations.map(
                      (c: any) => (c.id === data.id ? data : c)
                    ),
                  };
                }),
              };
            }
          );
        }
      },
      onError: (error, variables, context) => {
        toast.error("Error updating campaign");

        if (isNewHomePage) {
          getCampaignUpdateOnError(error, variables, context, queryClient);
        }
      },
      onSettled: (data, error, variables, context) => {
        if (isNewHomePage) {
          getCampaignUpdateOnSettled(
            data,
            error,
            variables,
            context,
            queryClient,
            activeBrandID
          );
        }
      },
    });

    return {
      updateCampaignRecommendation,
      isCampaignRecommendationUpdating,
    };
  };
