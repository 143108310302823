import ChatMessageContainer from "../../chat/ChatMessageContainer";
import SpinningLoader from "../../core/SpinningLoader";
import AppButton from "../../core/buttons/AppButton/AppButton";
import CampaignStatusIndicator from "../CampaignStatusIndicator";
import LoadingDots from "../wizard/misc/LoadingDots";
import { EmailCreativeStatus, operations } from "@openapi";
import { Flex, Text, Tooltip } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState, useRef } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { CheckIcon } from "~/assets/icons";
import DatePicker from "~/components/core/inputs/DatePicker";
import CustomTimePicker from "~/components/core/inputs/TimePicker";
import useGetCampaignDetails, {
  CampaignDetailResponse,
  getCampaignDetailsQueryKey,
} from "~/hooks/campaign/useGetCampaignDetails";
import { getEmailCreativeQueryKey } from "~/hooks/emails/useEmailCreativeQuery";
import { getUnpublishedCreatives } from "~/utils/campaign/utils";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const Root = styled(Flex)`
  border-right: 1px solid var(--border-primary);
  width: 430px;
  flex-shrink: 0;
  height: 100%;
  padding: 24px;
  gap: 24px;
`;

const MessageContainer = styled(Flex)`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--border-primary);
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SchedulingUI = styled(Flex)`
  border-radius: 12px;
  border: 1px solid var(--border-primary);
  padding: 16px;
`;

type EmailPublishRequest =
  operations["emails_api_publish_emails"]["requestBody"]["content"]["application/json"];
type EmailPublishResponse =
  operations["emails_api_publish_emails"]["responses"]["200"]["content"]["application/json"];

type SystemMessageType =
  | "review-meta-ads"
  | "schedule-or-send-to-draft"
  | "schedule-or-review-meta-ads";

export type MessageType = "user" | "ai" | "system";

type Message = {
  id: string;
  message: string | SystemMessageType | React.ReactNode;
  type: MessageType;
};

const ChatButton = ({
  text,
  onClick,
  disabled,
}: {
  text: string;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <AppButton
      disabled={disabled}
      radius="large"
      size="4"
      variant="outlined"
      onClick={onClick}
    >
      <Text size="3" weight="medium">
        {text}
      </Text>
    </AppButton>
  );
};

const ChatSystemMessage = ({
  campaignId,
  selectedEmails,
  onMetaAdsSuccess,
  systemMessage,
  onClearSelectedCreatives,
  addMessage,
}: {
  campaignId: string;
  selectedEmails: string[];
  onMetaAdsSuccess: () => void;
  systemMessage: SystemMessageType;
  onClearSelectedCreatives: (type: "ads" | "emails") => void;
  addMessage: (message: Message) => void;
}) => {
  const { data: campaignDetail, isLoading } = useGetCampaignDetails({
    campaignId,
  });
  const publishDateDefault = campaignDetail?.date ?? null;

  const queryClient = useQueryClient();
  const [showScheduleUI, setShowScheduleUI] = useState(false);
  const [showDraftUI, setShowDraftUI] = useState(false);

  const [scheduleData, setScheduleData] = useState<{
    [key: string]: {
      date: string;
      time: string;
    };
  }>({});

  const selectedEmailCreatives = useMemo(
    () =>
      campaignDetail?.email_creatives.filter((email) =>
        selectedEmails.includes(email.id)
      ) ?? [],
    [selectedEmails, campaignDetail?.email_creatives]
  );

  // Sync scheduleData with selectedEmails
  useEffect(() => {
    // Remove any emails from scheduleData that are no longer selected
    setScheduleData((prevData) => {
      const newData = { ...prevData };
      Object.keys(newData).forEach((emailId) => {
        if (!selectedEmails.includes(emailId)) {
          delete newData[emailId];
        }
      });
      return newData;
    });
  }, [selectedEmails]);

  const publishEmailsMutation = useDraperApiPostMutation<
    EmailPublishResponse,
    EmailPublishRequest
  >({
    path: "/emails/publish",
    mutationKey: ["publish-emails"],
    onSuccess: (data, variables) => {
      toast.success("Emails published successfully");

      // Update the emailCreativeData query cache for each published creative
      data.published_creatives.forEach((creative) => {
        if (creative.published_at) {
          queryClient.setQueryData(
            getEmailCreativeQueryKey(creative.id),
            (oldData: any) => {
              if (!oldData) return oldData;
              return {
                ...oldData,
                published_at: creative.published_at,
              };
            }
          );
        }
      });

      queryClient.setQueryData(
        getCampaignDetailsQueryKey(campaignId),
        (oldData: CampaignDetailResponse) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            email_creatives: oldData.email_creatives.map((creative) => {
              const publishedCreative = data.published_creatives.find(
                (p) => p.id === creative.id
              );
              return publishedCreative || creative;
            }),
          };
        }
      );

      if (!campaignDetail) return;

      const unpublishedEmailCount =
        campaignDetail.email_creatives.filter(
          (email) =>
            !data.published_creatives.find((p) => p.id === email.id) &&
            (email.status === EmailCreativeStatus.unpublished ||
              email.status === EmailCreativeStatus.draft)
        ).length +
        data.published_creatives.filter(
          (email) =>
            email.status === EmailCreativeStatus.unpublished ||
            email.status === EmailCreativeStatus.draft
        ).length;

      const unpublishedAdCount =
        getUnpublishedCreatives(campaignDetail).ads.length;

      const justScheduled = data.published_creatives.filter(
        (email) => email.status === EmailCreativeStatus.active
      );
      const justDrafted = data.published_creatives.filter(
        (email) => email.status === EmailCreativeStatus.draft
      );

      if (showDraftUI) {
        addMessage({
          id: "success",
          message: `Successfully sent ${justDrafted.length} email${
            justDrafted.length === 1 ? "" : "s"
          } as draft${justDrafted.length === 1 ? "" : "s"} to Klaviyo`,
          type: "ai",
        });

        if (campaignDetail?.discount && !campaignDetail.discount.is_active) {
          addMessage({
            id: "draft-discount-warning",
            message:
              "Warning: You must create your discount code manually if sending as a draft to Klaviyo.",
            type: "ai",
          });
        }
      } else {
        addMessage({
          id: "success",
          message: `Successfully scheduled ${justScheduled.length} email${
            justScheduled.length === 1 ? "" : "s"
          }`,
          type: "ai",
        });
      }

      if (unpublishedEmailCount > 0) {
        addMessage({
          id: "ai",
          message: `You have ${unpublishedEmailCount} email${
            unpublishedEmailCount === 1 ? "" : "s"
          } left to schedule. Would you like to schedule them now, or send them as drafts?`,
          type: "ai",
        });
        addMessage({
          id: "system",
          message: "schedule-or-send-to-draft" as SystemMessageType,
          type: "system",
        });
      } else {
        addMessage({
          id: "ai",
          message: "Done! You've scheduled all your emails.",
          type: "ai",
        });
        if (unpublishedAdCount > 0) {
          addMessage({
            id: "ai",
            message: "Would you like to review the Meta ads?",
            type: "ai",
          });
          addMessage({
            id: "system",
            message: "review-meta-ads" as SystemMessageType,
            type: "system",
          });
        }
      }

      onClearSelectedCreatives("emails");
    },
    onError: (error) => {
      toast.error("Failed to publish emails");
      showDraftUI && setShowDraftUI(false);
    },
  });

  const handleSchedule = () => {
    publishEmailsMutation.mutate({
      creatives: selectedEmails.map((id) => ({
        id,
        publish_timestamp: scheduleData[id]?.date
          ? new Date(
              `${scheduleData[id].date}T${scheduleData[id].time}`
            ).toISOString()
          : undefined,
      })),
    });
  };

  // Purposely not including selectedEmails.length in dependency array as we only want the initial value
  const draftUI = useMemo(() => {
    const emailText =
      selectedEmails.length === 1 ? "draft email" : "draft emails";
    return publishEmailsMutation.isSuccess
      ? `Successfully sent ${emailText} to Klaviyo`
      : `Sending ${emailText} to Klaviyo...`;
  }, [publishEmailsMutation.isSuccess]);

  // Purposely not including selectedEmails.length in dependency array as we only want the initial value
  const scheduledUI = useMemo(() => {
    const isDisabled = selectedEmails.length === 0;

    const cancelButton = (
      <ChatButton
        text="Cancel"
        onClick={() => setShowScheduleUI(false)}
        disabled={publishEmailsMutation.isPending || isDisabled}
      />
    );

    const scheduleMutationButton = (
      <ChatButton
        text="Schedule"
        onClick={handleSchedule}
        disabled={
          publishEmailsMutation.isSuccess ||
          publishEmailsMutation.isPending ||
          Object.keys(scheduleData).length === 0 ||
          Object.values(scheduleData).some(
            (data) => !data.date || !data.time
          ) ||
          isDisabled
        }
      />
    );

    const dataToRead = publishEmailsMutation.isSuccess
      ? Object.keys(scheduleData)
      : selectedEmails;
    return (
      <SchedulingUI direction="column" gap="16px" width="100%">
        {dataToRead.map((id, index) => {
          const pickerComponents = (
            <Flex gap="8px">
              <DatePicker
                initialDate={publishDateDefault ?? undefined}
                onSelect={(formattedDate) => {
                  setScheduleData((prev) => ({
                    ...prev,
                    [id]: {
                      date: formattedDate,
                      time: prev[id]?.time,
                    },
                  }));
                }}
              />
              <CustomTimePicker
                onSelect={(formattedTime) => {
                  setScheduleData((prev) => ({
                    ...prev,
                    [id]: {
                      date: prev[id]?.date || "",
                      time: formattedTime,
                    },
                  }));
                }}
              />
            </Flex>
          );
          return (
            <Flex key={id} direction="column" gap="8px" width="100%">
              <Text size="2" weight="bold">
                {
                  campaignDetail?.email_creatives.find(
                    (email) => email.id === id
                  )?.title
                }
              </Text>
              {publishEmailsMutation.isSuccess ? (
                <CampaignStatusIndicator
                  status={EmailCreativeStatus.active}
                  publishedAt={
                    scheduleData[id]?.date
                      ? new Date(
                          `${scheduleData[id].date}T${scheduleData[id].time}`
                        ).toISOString()
                      : null
                  }
                />
              ) : (
                pickerComponents
              )}
            </Flex>
          );
        })}
        {!publishEmailsMutation.isSuccess && (
          <Flex justify="end" align="center" gap="2">
            {cancelButton}
            {scheduleMutationButton}
          </Flex>
        )}
      </SchedulingUI>
    );
  }, [
    publishEmailsMutation.isSuccess,
    publishEmailsMutation.isPending,
    scheduleData,
    selectedEmails,
  ]);

  if (isLoading || !campaignDetail) {
    return <LoadingDots />;
  }

  if (showDraftUI) {
    return (
      <MessageContainer>
        <Flex gap="12px" align="center">
          {publishEmailsMutation.isPending ? <SpinningLoader /> : <CheckIcon />}
          <Text size="3" weight="medium">
            {draftUI}
          </Text>
        </Flex>
      </MessageContainer>
    );
  }

  if (showScheduleUI) {
    return scheduledUI;
  }

  const noEmailsSelected = selectedEmails.length === 0;
  const isAnyEmailAudienceMissing =
    !noEmailsSelected &&
    selectedEmailCreatives.some((email) => email.audiences.length === 0);

  const isDisabled = isAnyEmailAudienceMissing || noEmailsSelected;
  let errorMessage = undefined;
  if (isAnyEmailAudienceMissing) {
    errorMessage =
      "Please select at least one audience for each email to schedule.";
  } else if (noEmailsSelected) {
    errorMessage = "Please select at least one email.";
  }

  const reviewMetaAdsButton = (
    <ChatButton
      text="Review Meta Ads"
      onClick={onMetaAdsSuccess}
      disabled={publishEmailsMutation.isPending}
    />
  );

  if (systemMessage === "review-meta-ads") {
    return reviewMetaAdsButton;
  }

  const handleClick = () => {
    setShowScheduleUI(true);
  };

  const handleSendToDraft = () => {
    setShowDraftUI(true);

    publishEmailsMutation.mutate({
      creatives: selectedEmails.map((id) => ({ id })),
    });
  };

  const scheduleButton = errorMessage ? (
    <Tooltip content={errorMessage}>
      <span>
        <ChatButton
          text="Schedule"
          onClick={handleClick}
          disabled={isDisabled || publishEmailsMutation.isPending}
        />
      </span>
    </Tooltip>
  ) : (
    <ChatButton
      text="Schedule"
      onClick={handleClick}
      disabled={isDisabled || publishEmailsMutation.isPending}
    />
  );
  const draftButton = errorMessage ? (
    <Tooltip content={errorMessage}>
      <span>
        <ChatButton
          text="Send to Klaviyo as draft"
          onClick={handleSendToDraft}
          disabled={isDisabled || publishEmailsMutation.isPending}
        />
      </span>
    </Tooltip>
  ) : (
    <ChatButton
      text="Send to Klaviyo as draft"
      onClick={handleSendToDraft}
      disabled={isDisabled || publishEmailsMutation.isPending}
    />
  );

  return (
    <>
      {scheduleButton}
      {systemMessage === "schedule-or-send-to-draft" && draftButton}
      {systemMessage === "schedule-or-review-meta-ads" && reviewMetaAdsButton}
    </>
  );
};

const CampaignPublishDialogChatEmailContainer = ({
  campaignId,
  selectedEmails,
  toggleSelectedCampaignType,
  onClearSelectedCreatives,
}: {
  campaignId: string;
  selectedEmails: string[];
  toggleSelectedCampaignType: () => void;
  onClearSelectedCreatives: (type: "ads" | "emails") => void;
}) => {
  // Use ref to store messages to avoid re-renders
  const messagesRef = useRef<Message[]>([]);
  const [messageQueue, setMessageQueue] = useState<Message[]>([]);

  const { data: campaignDetail, isLoading } = useGetCampaignDetails({
    campaignId,
  });

  // Initialize message queue when campaign details are ready
  useEffect(() => {
    if (!isLoading && campaignDetail && messagesRef.current.length === 0) {
      const unpublishedEmailsCount =
        getUnpublishedCreatives(campaignDetail).emails.length;
      const unpublishedAdsCount =
        getUnpublishedCreatives(campaignDetail).ads.length;

      let newMessages: Message[] = [];

      if (unpublishedEmailsCount > 0) {
        newMessages = [
          {
            id: "0",
            message: `You have ${unpublishedEmailsCount} email${
              unpublishedEmailsCount === 1 ? "" : "s"
            } to schedule, or you can send ${
              unpublishedEmailsCount === 1 ? "it" : "them"
            } to Klaviyo as ${
              unpublishedEmailsCount === 1 ? "a draft" : "drafts"
            }.`,
            type: "ai" as MessageType,
          },
          {
            id: "1",
            message: "schedule-or-send-to-draft" as SystemMessageType,
            type: "system" as MessageType,
          },
        ];
      } else {
        newMessages = [
          {
            id: "0",
            message: "You've already scheduled all your emails.",
            type: "ai" as MessageType,
          },
        ];

        if (unpublishedAdsCount > 0) {
          newMessages = [
            {
              id: "1",
              message: "Should we start reviewing the Meta ads?",
              type: "ai" as MessageType,
            },
            {
              id: "2",
              message: "review-meta-ads" as SystemMessageType,
              type: "system" as MessageType,
            },
          ];
        }
      }

      messagesRef.current = [];
      setMessageQueue(newMessages);
    }
  }, [isLoading, campaignDetail]);

  // Process message queue
  useEffect(() => {
    if (messageQueue.length > 0) {
      const timer = setTimeout(() => {
        const newMessages = [...messagesRef.current, messageQueue[0]];
        messagesRef.current = newMessages;
        setMessageQueue((prev) => prev.slice(1));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [messageQueue]);

  const emailsChatUI = useMemo(
    () =>
      isLoading || messagesRef.current.length === 0 || !campaignDetail ? (
        <ChatMessageContainer message={<LoadingDots />} animate={true} />
      ) : (
        messagesRef.current.map((message) => {
          if (message.type === "user" || message.type === "ai") {
            return (
              <ChatMessageContainer
                key={message.id}
                message={message.message as string}
                animate={true}
              />
            );
          }
          const messageComponent = (
            <ChatSystemMessage
              campaignId={campaignId}
              selectedEmails={selectedEmails}
              onMetaAdsSuccess={toggleSelectedCampaignType}
              addMessage={(message: Message) => {
                setMessageQueue((prev) => [
                  ...prev,
                  {
                    ...message,
                    id: String(messagesRef.current.length),
                  },
                ]);
              }}
              systemMessage={message.message as SystemMessageType}
              onClearSelectedCreatives={onClearSelectedCreatives}
            />
          );
          return (
            <ChatMessageContainer
              key={message.id}
              message={messageComponent}
              animate={true}
            />
          );
        })
      ),
    [messagesRef.current, isLoading, selectedEmails]
  );

  return (
    <Root direction="column" gap="2" overflowY="auto">
      {emailsChatUI}
    </Root>
  );
};

export default CampaignPublishDialogChatEmailContainer;
