import {
  operations,
  PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider,
} from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

type CloudAssetsRequestParams =
  operations["media_api_list_cloud_assets"]["parameters"]["query"];

type CloudAssetsResponse =
  operations["media_api_list_cloud_assets"]["responses"][200]["content"]["application/json"];

const useCloudImageAssetsQuery = (
  providerId: PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider,
  params: CloudAssetsRequestParams & { enabled?: boolean } = {}
) => {
  const { enabled = true, ...queryParams } = params;
  const fetchAssets = async ({
    pageParam = null,
  }: {
    pageParam: unknown;
  }): Promise<CloudAssetsResponse> => {
    const params = {
      ...queryParams,
      page_token: pageParam as string | null,
      page_size: queryParams.page_size
        ? Number(queryParams.page_size)
        : undefined,
    };

    const { data } = await axios.get<CloudAssetsResponse>(
      `/api/v1/media/assets/cloud/${providerId}`,
      { params }
    );
    return data;
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isSuccess,
    isLoading,
    isFetching,
  } = useInfiniteQuery<CloudAssetsResponse>({
    queryKey: ["media-list-cloud-assets", providerId, queryParams.folder_id],
    queryFn: fetchAssets,
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.next_page_token,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled,
  });

  const folders = data?.pages[0]?.folders ?? [];
  const assets = data?.pages.flatMap((page) => page.assets) ?? [];
  return {
    assets,
    folders,
    isLoading: isLoading || isFetching,
    isSuccess,
    error,
    fetchNextPage,
    hasNextPage,
  };
};

export default useCloudImageAssetsQuery;
