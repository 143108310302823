import {
  EmailElementType,
  EmailSectionType,
  EmailSectionWidthType,
} from "@openapi";
import {
  CustomizationViews,
  TemplateEditorInterface,
} from "~/types/template-editor";

// TODO ashleyxue: clean this up
export const templateEditorInitialState: TemplateEditorInterface = {
  currentView: CustomizationViews.TypeSelect,
  headerOptions: {
    id: "",
    type: EmailSectionType.header,
    template: {
      id: "",
      html: "",
      index: 0,
      name: "",
      section_type: "",
      is_base_template: false,
      configurable_parameters: [],
      preview_image: "",
    },
    palette: {
      id: "",
      background: "",
      foreground: "",
      accent_background: "",
      accent_foreground: "",
    },
    width_type: EmailSectionWidthType.contained,
    menu: {
      id: "",
      type: EmailElementType.menu,
      enabled: true,
      menu_links: [],
    },
    custom_html: {
      id: "",
      type: EmailElementType.custom_html,
      enabled: true,
      html: "",
    },
  },
  footerOptions: {
    id: "",
    type: EmailSectionType.footer,
    template: {
      id: "",
      html: "",
      index: 0,
      name: "",
      section_type: "",
      is_base_template: false,
      configurable_parameters: [],
      preview_image: "",
    },
    width_type: EmailSectionWidthType.contained,
    show_social_links: false,
    palette: {
      id: "palette-1",
      background: "#FFFFFF",
      foreground: "#000000",
      accent_background: "#000000",
      accent_foreground: "#FFFFFF",
    },
    menu: null,
    custom_html: null,
  },
  previewMode: "desktop",
};

export const getParentView = (
  currentView: CustomizationViews,
  viewType?: TemplateEditorInterface["viewType"]
): CustomizationViews => {
  switch (currentView) {
    case CustomizationViews.HeaderOptions:
    case CustomizationViews.FooterOptions:
      return CustomizationViews.TypeSelect;
    case CustomizationViews.MenuLinks:
      return viewType === EmailSectionType.header
        ? CustomizationViews.HeaderOptions
        : CustomizationViews.FooterOptions;
    case CustomizationViews.SocialLinks:
      return CustomizationViews.FooterOptions;
    default:
      return CustomizationViews.TypeSelect;
  }
};
