import SpinningLoader from "../core/SpinningLoader";
import AppButton from "../core/buttons/AppButton/AppButton";
import { Flex, Text } from "@radix-ui/themes";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Banknote,
  MailOpen,
} from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { currencyFormatter } from "~/utils/helpers";

const SlideButton = styled(AppButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid var(--border_primary, #dddddd);
  border-radius: 12px;
  padding: 8px;
  &:hover {
    background: #f5f5f5;
  }
`;

const PaginationContainer = styled(Flex)`
  gap: 8px;
  justify-content: center;
  margin-top: 24px;
`;

const PaginationDot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active
      ? "var(--primary, #2F048A)"
      : " var(--background_light_grey, #EDEBF0)"};
  cursor: pointer;
  transition: background-color 0.2s;
`;

const EmailContent = styled(Flex)`
  flex-direction: column;
  gap: 24px;
  padding: 0 48px;
`;

const TopEmailsHeader = styled(Flex)`
  direction: row;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
`;

const EmailImageContainer = styled(Flex)`
  width: 225px;
  height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > div {
    width: 225px;
    transform-origin: top left;
    position: relative;
    user-select: none;

    & * {
      max-width: 100%;
      max-height: 100%;
      font-size: 14px !important;
      line-height: 1.2 !important;
      pointer-events: none;
    }

    & img {
      object-fit: contain;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const EmailDetailsContainer = styled(Flex)`
  flex-direction: column;
  gap: 2px;
`;

const EmailInfoContainer = styled(Flex)`
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const EmailSubject = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--text_primary, #121111);
`;

const EmailNumberBadge = styled(Flex)`
  background: var(--secondary, #2f048a14);
  padding: 4px 8px;
  border-radius: 32px;
`;

const EmailPreview = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--text_secondary, #4c4747);
`;

const EmailStatsContainer = styled(Flex)`
  flex-direction: column;
  gap: 4px;
`;

const StatLabel = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text_secondary, #4c4747);
`;

const StatValue = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text_primary, #121111);
`;

type EmailSliderProps = {
  emails: EmailSliderItem[];
  title: React.ReactNode;
};

export type EmailSliderItem = {
  subject: string;
  preview: string;
  emailContent: string;
  openRate: number;
  revenue: number;
};

const EmailSlider = ({ emails, title }: EmailSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const emailScrollableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentSlide(0);
  }, [emails]);

  useEffect(() => {
    setIsContentLoaded(false);
    emailScrollableContainerRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setIsContentLoaded(true);
    }, 700);
  }, [currentSlide]);

  const handlePrevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === 0 ? emails.length - 1 : prev - 1));
  }, [emails.length]);

  const handleNextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === emails.length - 1 ? 0 : prev + 1));
  }, [emails.length]);

  const currentEmail = emails[currentSlide];

  return (
    <>
      <SlideButton
        onClick={handlePrevSlide}
        aria-label="Previous Email"
        style={{ left: "16px" }}
      >
        <ArrowLeftIcon
          size={20}
          strokeWidth={1.25}
          style={{ color: "var(--icon_primary, #595D62)" }}
        />
      </SlideButton>

      <EmailContent>
        <TopEmailsHeader>{title}</TopEmailsHeader>

        <Flex direction="row" gap="32px">
          <EmailImageContainer ref={emailScrollableContainerRef}>
            {isContentLoaded ? (
              <div
                dangerouslySetInnerHTML={{ __html: currentEmail.emailContent }}
              />
            ) : (
              <SpinningLoader />
            )}
          </EmailImageContainer>

          <EmailDetailsContainer>
            <EmailInfoContainer>
              <EmailSubject>
                <Flex direction="row" gap="8px" align="center">
                  <EmailNumberBadge>Nr. {currentSlide + 1}</EmailNumberBadge>
                  {currentEmail.subject}
                </Flex>
              </EmailSubject>
              <EmailPreview>{currentEmail.preview}</EmailPreview>
            </EmailInfoContainer>

            <EmailStatsContainer>
              <Flex direction="column" gap="4px">
                <Flex direction="row" gap="8px" align="center">
                  <MailOpen size={16} />
                  <StatLabel>Open Rate:</StatLabel>
                </Flex>
                <StatValue>{currentEmail.openRate.toFixed(2)}%</StatValue>
              </Flex>

              <Flex direction="column" gap="4px">
                <Flex direction="row" gap="8px" align="center">
                  <Banknote size={18} />
                  <StatLabel>Revenue:</StatLabel>
                </Flex>
                <StatValue>
                  {currencyFormatter("USD").format(currentEmail.revenue)}
                </StatValue>
              </Flex>
            </EmailStatsContainer>
          </EmailDetailsContainer>
        </Flex>
      </EmailContent>

      <SlideButton
        onClick={handleNextSlide}
        aria-label="Next Email"
        style={{ right: "16px" }}
      >
        <ArrowRightIcon
          size={20}
          strokeWidth={1.25}
          style={{ color: "var(--icon_primary, #595D62)" }}
        />
      </SlideButton>

      <PaginationContainer>
        {emails.map((_, index) => (
          <PaginationDot
            key={index}
            active={currentSlide === index}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </PaginationContainer>
    </>
  );
};

export default EmailSlider;
