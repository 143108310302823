import { key as emailTemplatesKey } from "@hooks/emails/useEmailTemplatesQuery";
import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

export type UpdateEmailTemplateRequest =
  operations["intern_api_update_email_template"]["requestBody"]["content"]["application/json"];

export type UpdateEmailTemplateResponse =
  operations["intern_api_update_email_template"]["responses"]["200"]["content"]["application/json"];

const useInternEmailTemplateUpdateMutation = ({
  templateId,
  onSuccess,
}: {
  templateId: string;
  onSuccess?: (data: UpdateEmailTemplateResponse) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useDraperApiPatchMutation<
    UpdateEmailTemplateResponse,
    UpdateEmailTemplateRequest
  >({
    mutationKey: ["internEmailTemplates", templateId],
    path: `/intern/email-templates/${templateId}`,
    onSuccess: (data) => {
      toast.success("Email template updated");
      queryClient.invalidateQueries({ queryKey: [emailTemplatesKey] });
      onSuccess?.(data);
    },
    onError: () => {
      toast.error("Failed to update email template");
    },
  });

  return {
    updateEmailTemplate: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternEmailTemplateUpdateMutation;
