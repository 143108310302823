import { getViewValues } from "../../layout/EmailEditorAside";
import { EmailSectionType } from "@openapi";
import { Flex, Grid, Spinner, Text } from "@radix-ui/themes";
import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { RelatedImageType } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/RelatedImages";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { Product } from "~/components/campaign/wizard/products/components/productSelector/hooks/useGetFilteredProducts";
import ReviewSelectorDialog from "~/components/campaign/wizard/products/components/reviewSelector/ReviewSelectorDialog";
import SidePanelComponents from "~/components/core/editor/sidepane";
import ArticleSelectorDialog from "~/components/dialogs/ArticleSelectorDialog";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandHasReviewsConfiguredQuery from "~/hooks/brand/useBrandHasReviewsConfiguredQuery";
import useBrandHasReviewsQuery from "~/hooks/brand/useBrandHasReviewsQuery";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionMutation";
import {
  EmailEditorViews,
  usePushEditorView,
  useEmailState,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const BlockContainer = styled(Flex)<{ $isPending: boolean }>`
  padding: 24px 16px 24px 16px;
  border: 1px solid#ddd7d7;
  border-radius: 12px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: ${({ $isPending: isPending }) =>
    isPending ? "not-allowed" : "pointer"};
  pointer-events: ${({ $isPending: isPending }) =>
    isPending ? "none" : "auto"};
  transition: all 0.2s ease;
  background-color: ${({ $isPending: isPending }) =>
    isPending ? "rgba(0,0,0,0.1)" : "white"};
  &:hover {
    border: 1px solid#9f9594;
    box-shadow: 0px 2px 4px 0px #0000000a;
    box-shadow: 0px 4px 16px 0px #00000014;
  }
`;

const AddBlockView = () => {
  const activeBrandId = useActiveBrandID();
  const pushView = usePushEditorView();
  const {
    selectedSectionId,
    sections,
    id: emailCreativeId,
    campaign_id,
    isEditingDisabled,
  } = useEmailState();
  const selectedSection = sections?.find(
    (section) => section.id === selectedSectionId
  );
  const [pendingView, setPendingView] = useState<EmailEditorViews | null>(null);
  const [isChangeImageDialogOpen, setIsChangeImageDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedReviewIds, setSelectedReviewIds] = useState<string[]>([]);
  const { mutate: initEmailSection, isPending } = useInitEmailSectionMutation();
  const hasReviews = useBrandHasReviewsQuery();
  const hasReviewsConfigured = useBrandHasReviewsConfiguredQuery();

  const isReviewsEnabled = useMemo(() => {
    return hasReviews && hasReviewsConfigured;
  }, [hasReviews, hasReviewsConfigured]);

  useEffect(() => {
    if (isPending) {
      return;
    }
    setPendingView(null);
  }, [isPending]);

  if (!selectedSection) {
    return null;
  }

  return (
    <Flex direction="column" gap="24px" mb="24px">
      <SidePanelComponents.MarkMessage>
        Select what type of block you want to insert
      </SidePanelComponents.MarkMessage>
      <Grid gap="16px" columns="2">
        {[
          EmailEditorViews.hero,
          EmailEditorViews.products,
          EmailEditorViews.image,
          EmailEditorViews.text,
          EmailEditorViews.cta_card,
          EmailEditorViews.hero_product,
          EmailEditorViews.hero_collection,
          EmailEditorViews.collection_grid,
          EmailEditorViews.listicle,
          EmailEditorViews.blog,
          ...(hasReviewsConfigured ? [EmailEditorViews.reviews] : []),
        ].map((view) => {
          const { name, Icon } = getViewValues({ view, isEditingDisabled });

          const content = (
            <>
              {pendingView === view ? (
                <Flex justify={"center"} align={"center"}>
                  <Spinner size="3" />
                </Flex>
              ) : (
                Icon && <Icon />
              )}
              <Text align="center">
                {pendingView === view ? "Preparing..." : name}
              </Text>
            </>
          );

          if (view === EmailEditorViews.hero_product) {
            return (
              <ProductSelectorDialog
                key={view}
                onSelectSingleProduct={(product) => {
                  initEmailSection({
                    brand_id: activeBrandId,
                    section_type: EmailSectionType.hero_product,
                    email_creative_id: emailCreativeId,
                    generate: true,
                    generate_fallback_to_default: true,
                    id: selectedSection.id,
                    index: selectedSection.index,
                    product_ids: [product.product_id],
                    prompt_summary: "Single featured product section",
                  });
                }}
                triggerComponent={
                  <BlockContainer $isPending={isPending} key={view}>
                    {content}
                  </BlockContainer>
                }
              />
            );
          } else if (view === EmailEditorViews.reviews) {
            return (
              <React.Fragment key={view}>
                {selectedProduct == null ? (
                  <ProductSelectorDialog
                    showPaginationNumbers
                    onSelectSingleProduct={(product) => {
                      setSelectedProduct(product);
                    }}
                    triggerComponent={
                      <BlockContainer
                        $isPending={!isReviewsEnabled || isPending}
                        key={view}
                      >
                        {content}
                      </BlockContainer>
                    }
                  />
                ) : (
                  <ReviewSelectorDialog
                    isOpen={selectedProduct !== null}
                    selectedProductId={selectedProduct.product_id}
                    onOpenChange={(open) => {
                      if (!open) {
                        if (selectedReviewIds.length > 0) {
                          initEmailSection({
                            brand_id: activeBrandId,
                            section_type: EmailSectionType.reviews,
                            email_creative_id: emailCreativeId,
                            generate: true,
                            generate_fallback_to_default: true,
                            id: selectedSection.id,
                            index: selectedSection.index,
                            product_ids: selectedProduct
                              ? [selectedProduct.product_id]
                              : [],
                            selected_review_ids: selectedReviewIds,
                            prompt_summary: "Single review section",
                          });
                        }

                        setSelectedProduct(null);
                      }
                    }}
                    selectedReviewsIds={selectedReviewIds}
                    onSelectSingleReview={(review) => {
                      setSelectedReviewIds((prev = []) =>
                        prev.includes(review.review_id)
                          ? prev.filter((id) => id !== review.review_id)
                          : [...prev, review.review_id]
                      );
                    }}
                    triggerComponent={
                      <BlockContainer $isPending={isPending} key={view}>
                        {content}
                      </BlockContainer>
                    }
                  />
                )}
              </React.Fragment>
            );
          } else if (
            view === EmailEditorViews.collection_grid ||
            view === EmailEditorViews.hero_collection
          ) {
            const handleInit = (collectionIds?: string[]) => {
              initEmailSection({
                brand_id: activeBrandId,
                section_type:
                  view === EmailEditorViews.hero_collection
                    ? EmailSectionType.hero_collection
                    : EmailSectionType.collection_grid,
                id: selectedSection.id,
                index: selectedSection.index,
                generate: true,
                generate_fallback_to_default: true,
                email_creative_id: emailCreativeId,
                prompt_summary: "no prompt",
                collection_ids: collectionIds,
              });
              setPendingView(view);
            };
            return (
              <BlockContainer
                key={view}
                $isPending={isPending}
                onClick={() => handleInit()}
              >
                {content}
              </BlockContainer>
            );
          } else if (view === EmailEditorViews.blog) {
            return (
              <ArticleSelectorDialog
                key={view}
                onConfirm={(articleIds) => {
                  initEmailSection({
                    brand_id: activeBrandId,
                    section_type: EmailSectionType.blog,
                    id: selectedSection.id,
                    index: selectedSection.index,
                    generate: false,
                    article_ids: articleIds,
                  });
                }}
                triggerComponent={
                  <BlockContainer $isPending={isPending} key={view}>
                    {content}
                  </BlockContainer>
                }
                limit={1}
              />
            );
          } else if (view === EmailEditorViews.products) {
            return (
              <BlockContainer
                $isPending={isPending}
                key={view}
                onClick={() => {
                  initEmailSection({
                    brand_id: activeBrandId,
                    section_type: EmailSectionType.products,
                    id: selectedSection.id,
                    index: selectedSection.index,
                    generate: true,
                    generate_fallback_to_default: true,
                    email_creative_id: emailCreativeId,
                    prompt_summary: "Only generate up to 6 products",
                  });
                  setPendingView(view);
                }}
              >
                {content}
              </BlockContainer>
            );
          }

          return (
            <BlockContainer
              key={view}
              $isPending={isPending}
              onClick={() => {
                if (view === EmailEditorViews.image) {
                  setIsChangeImageDialogOpen(true);
                } else {
                  pushView(view);
                }
              }}
            >
              {content}
            </BlockContainer>
          );
        })}
      </Grid>

      <AssetSelectorDialog
        campaignId={campaign_id}
        isDialogOpen={isChangeImageDialogOpen}
        onToggleDialogOpen={setIsChangeImageDialogOpen}
        type={RelatedImageType.DEFAULT}
        preselectedAssets={[]}
        singleAsset
        onSelect={(assets) => {
          initEmailSection({
            brand_id: activeBrandId,
            section_type: EmailSectionType.image,
            id: selectedSection.id,
            index: selectedSection.index,
            generate: false,
            asset_id: (assets[0] as ImageAssetSchema)?.id,
            email_creative_id: emailCreativeId,
          });
        }}
      />
    </Flex>
  );
};

export default AddBlockView;
