import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import { VoiceGuidelineItemContainer } from "../common/VoiceGuidelineItem";
import EditMissionStatement from "./EditMissionStatement";
import { useMemo, useState } from "react";
import { PencilIcon, PlusIcon, TargetGoalIcon } from "~/assets/icons";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useBrandVoiceDispatch,
  useBrandVoiceState,
} from "~/contexts/BrandVoiceContext";

const ZeroState = () => {
  return (
    <DraperText variant="tertiary">
      No mission statement declared yet
    </DraperText>
  );
};

const MissionStatementCardSection = () => {
  const voiceState = useBrandVoiceState();
  const voiceDispatch = useBrandVoiceDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    voiceDispatch({
      type: "PATCH",
      payload: { isEditingItem: true },
    });
    setIsEditing(true);
  };

  const handleEditComplete = () => {
    voiceDispatch({
      type: "PATCH",
      payload: { isEditingItem: false },
    });
    setIsEditing(false);
  };

  const content = useMemo(() => {
    if (voiceState.isLoading) {
      return <LoadingDots />;
    }
    if (isEditing) {
      return <EditMissionStatement onClose={handleEditComplete} />;
    }
    if (voiceState.missionStatement) {
      return (
        <DraperText variant="secondary" clamp={4} wordBreak="break-word">
          {voiceState.missionStatement}
        </DraperText>
      );
    }
    return <ZeroState />;
  }, [voiceState.missionStatement, voiceState.isLoading, isEditing]);

  return (
    <StyleLibraryCardContent
      title="Mission Statement"
      icon={<TargetGoalIcon />}
      disabled={voiceState.isGenerating}
      actions={
        !isEditing && (
          <AppButton
            variant="ghost"
            size="3"
            onClick={handleEdit}
            disabled={voiceState.isEditingItem || voiceState.isGenerating}
          >
            {!!voiceState.missionStatement ? (
              <>
                <PencilIcon size={20} strokeWidth={1.25} /> Edit
              </>
            ) : (
              <>
                <PlusIcon
                  style={{
                    padding: "0.1rem",
                  }}
                />{" "}
                Add Mission Statement
              </>
            )}
          </AppButton>
        )
      }
    >
      <VoiceGuidelineItemContainer $limitWidth={isEditing}>
        {content}
      </VoiceGuidelineItemContainer>
    </StyleLibraryCardContent>
  );
};

export default MissionStatementCardSection;
