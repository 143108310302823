import LoadingDots from "../campaign/wizard/misc/LoadingDots";
import { Flex, Heading, Tooltip } from "@radix-ui/themes";
import { CSSProperties } from "styled-components";
import { QuestionMarkCircledIcon } from "~/assets/icons";

interface StyleLibraryCardContentProps {
  children: React.ReactNode;
  actions?: React.ReactNode;
  title: string;
  titleStyle?: CSSProperties;
  icon?: React.ReactNode;
  helperText?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const StyleLibraryCardContent: React.FC<StyleLibraryCardContentProps> = ({
  actions,
  children,
  title,
  titleStyle,
  icon,
  helperText,
  isLoading,
  disabled,
}) => {
  return (
    <Flex
      direction="column"
      gap="4"
      width="100%"
      style={{
        opacity: disabled ? 0.7 : 1,
      }}
    >
      <Flex direction="row" justify="between" align="center">
        <Flex gap="2" align="center" style={titleStyle}>
          {icon}
          <Heading size="5">{title}</Heading>
          {helperText && (
            <Tooltip
              content={helperText}
              align="center"
              side="top"
              sideOffset={5}
            >
              <QuestionMarkCircledIcon size={20} />
            </Tooltip>
          )}
        </Flex>
        <Flex gap="4">{actions}</Flex>
      </Flex>
      {isLoading && <LoadingDots />}
      {!isLoading && children}
    </Flex>
  );
};

export default StyleLibraryCardContent;
