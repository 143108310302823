import { useActiveBrandID } from "../../../../../../../contexts/CurrentUserContext";
import { getProductsFiltersQuery, ProductsFilters } from "./useProductsFilters";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

type GetProductsRequestParams =
  operations["shopify_integration_api_products"]["parameters"]["query"];

const useGetFilteredProductsCount = ({
  filters,
  debounceDelay = 200,
}: {
  filters: ProductsFilters;
  debounceDelay?: number;
}) => {
  const activeBrandID = useActiveBrandID();
  const [debouncedFilters, setDebouncedFilters] =
    useState<ProductsFilters>(filters);
  const setFilters = useCallback(
    _.debounce((filters: ProductsFilters) => {
      setDebouncedFilters(filters);
    }, debounceDelay),
    []
  );
  useEffect(() => {
    setFilters(filters);
  }, [filters, setFilters]);

  const { data } = useQuery({
    queryKey: [
      "commerce-platform/products/count",
      debouncedFilters.name,
      debouncedFilters.categoryId,
      activeBrandID,
    ],
    queryFn: async (): Promise<number> => {
      const response = await axios.get(
        "/api/v1/commerce-platform/products/count",
        {
          params: {
            brand_id: activeBrandID,
            query: getProductsFiltersQuery(debouncedFilters),
          } as GetProductsRequestParams,
        }
      );
      return response.data;
    },
    enabled: !!activeBrandID,
  });

  return useMemo(() => (data ? data : 0), [data]);
};

export default useGetFilteredProductsCount;
