import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type TemplateResponse =
  operations["emails_api_get_email_templates"]["responses"][200]["content"]["application/json"];

export const key = (brandId: string) => ["emails/email-templates", brandId];

const useEmailTemplatesQuery = (pageSize: number = 10) => {
  const activeBrandID = useActiveBrandID();
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: key(activeBrandID),
    queryFn: async ({ pageParam = 1 }): Promise<TemplateResponse> => {
      const { data } = await axios.get(`/api/v1/emails/email-templates`, {
        params: {
          page: pageParam,
          page_size: pageSize,
          brand_id: activeBrandID,
        },
      });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
  });

  const templates = data?.pages.flatMap((page) => page.templates) ?? [];

  return {
    templates,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useEmailTemplatesQuery;
