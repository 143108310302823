import {
  CampaignBriefOverviewSection,
  SectionContent,
} from "../CampaignBriefOverview";
import { components } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import LandingDestinationSelector from "~/components/selectors/LandingDestinationSelector";
import { useCampaignWizardUpdateCampaignData } from "~/contexts/CampaignWizardContext";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";
import { CampaignRecommendationResponse } from "~/hooks/campaign/useCampaignRecommendationQuery";
import { getCampaignRecommendationQueryKey } from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

export const CampaignBriefOverviewLandingDestinationSection = ({
  campaignRecommendationID,
}: {
  campaignRecommendationID: string;
}) => {
  const { campaignData } = useCampaignWizardState();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();
  const activeBrandID = useActiveBrandID();
  const queryClient = useQueryClient();
  const [isLandingDestinationEnabled, setIsLandingDestinationEnabled] =
    useState<boolean>(false);
  const [isSelecting, setIsSelecting] = useState<boolean>(true);
  const [localDestination, setLocalDestination] = useState<
    components["schemas"]["CampaignRecommendationDestinationSchema"]
  >({});
  const { brandData } = useBrandQuery(activeBrandID);

  const onSyncDestinationState = (
    destination:
      | components["schemas"]["CampaignRecommendationDestinationSchema"]
      | undefined
  ) => {
    if (destination && destination.url) {
      setLocalDestination(destination);
      setIsLandingDestinationEnabled(true);
      setIsSelecting(true);
    } else {
      setIsLandingDestinationEnabled(false);
      setIsSelecting(false);
    }
  };

  const destination = campaignData.destination ?? {};

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onSettled: (data, error, variables) => {},
      onSuccess: (data) => {
        updateCampaignData({
          destination: localDestination,
        });

        // If the landing destination is enabled AND there is no existing landing destination title on the campaign recommendation,
        // we need to regenerate the creative recommendation
        if (
          !isLandingDestinationEnabled &&
          !data.active_creative_recommendation?.collection_title
        ) {
          queryClient.setQueryData(
            getCampaignRecommendationQueryKey(
              activeBrandID,
              campaignRecommendationID
            ),
            (oldData: CampaignRecommendationResponse) => {
              return {
                ...oldData,
                destination: localDestination,
                active_creative_recommendation: null,
              };
            }
          );
        }
      },
    });

  return (
    <CampaignBriefOverviewSection
      title="Landing Destination"
      isLoading={isLoading}
      disabled={isLandingDestinationEnabled && !localDestination.url}
      editDialogBody={
        <LandingDestinationSelector
          isLandingDestinationEnabled={isLandingDestinationEnabled}
          setIsLandingDestinationEnabled={setIsLandingDestinationEnabled}
          destination={localDestination}
          setDestination={setLocalDestination}
          isSelecting={isSelecting}
          setIsSelecting={setIsSelecting}
          domain={brandData?.domain ?? ""}
          isNewUI
        />
      }
      onEdit={() => {
        onSyncDestinationState(campaignData.destination);
      }}
      onSave={() => {
        updateCampaignRecommendation({
          campaign_recommendation_id: campaignRecommendationID,
          brand_id: activeBrandID,
          destination: localDestination,
          is_destination_enabled: isLandingDestinationEnabled,
        });
      }}
    >
      <SectionContent>
        {destination.name && destination.url ? (
          <Flex direction="column" gap="2">
            <Text>
              <Text weight="bold">{destination.name || "Not specified"}</Text>
            </Text>
            {destination.url && (
              <Text size="2" color="gray">
                URL: {destination.url}
              </Text>
            )}
          </Flex>
        ) : (
          <Text>
            No landing destination was selected, we will generate one for you.
          </Text>
        )}
      </SectionContent>
    </CampaignBriefOverviewSection>
  );
};
