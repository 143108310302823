import { components } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type EmailEmbeddedSvgSchema =
  components["schemas"]["EmailEmbeddedSvgSchema"];

export const useGetEmailEmbeddedSvgs = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["emails/embedded-svg-templates"],
    queryFn: async (): Promise<EmailEmbeddedSvgSchema[]> => {
      const { data } = await axios.get("/api/v1/emails/embedded-svg-templates");
      return data;
    },
  });

  return { data, error, isLoading };
};
