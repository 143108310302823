import { components, ImageAssetCategory } from "@openapi";
import { useMemo } from "react";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { getEmailStateImageUrl } from "~/types/template-vars";

const LogoSelector = ({
  img,
  onSelect,
}: {
  img: components["schemas"]["EmailImageElementSchema"] | null;
  onSelect: (asset: AssetSelectorItem) => void;
}) => {
  const imageUrl = useMemo(() => {
    if (!img) return undefined;
    return getEmailStateImageUrl(img);
  }, [img]);

  const handleImageSelected = async (assets: AssetSelectorItem[]) => {
    onSelect(assets[0]);
  };

  return (
    <ImageSelector
      campaignId={null}
      value={imageUrl}
      onSelect={handleImageSelected}
      buttonText="Change Logo"
      addButtonText="Add Logo"
      categories={[ImageAssetCategory.logo, ImageAssetCategory.other]}
      categoryForUploadedImages={ImageAssetCategory.logo}
      includeDefaultCategories={false}
    />
  );
};

export default LogoSelector;
