import AnnouncementPopup from "./AnnouncementPopup";
import { Button, Flex } from "@radix-ui/themes";
import { ArrowRightIcon, Bug, XIcon } from "lucide-react";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";
import {
  MessageType,
  useMarkPopupContext,
  useToggleMarkPopup,
} from "~/contexts/MarkPopupContext";
import { INSIGHTS_ROUTE } from "~/routes/constants";

const MarkAnnounceAvatar = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
`;

const StyledMarkAvatar = styled(MarkAvatar)<{ $isDisplayed?: boolean }>`
  position: absolute;
  transition: all 0.6s;
  cursor: pointer;
  transform: ${(props) =>
    props.$isDisplayed
      ? "translate(0%, 0%) scale(1)"
      : "translate(-100%, 0%) scale(0.5)"};
`;

const XIconContainer = styled(Flex)<{ $isDisplayed?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  cursor: pointer;
  transform: ${(props) =>
    props.$isDisplayed
      ? "translate(0%, 0%) scale(1)"
      : "translate(100%, 0%) scale(0.5)"};
`;

const StyledButton = styled(Button)`
  border-radius: 120px;
  padding: 12px 20px;
  height: 48px;
  width: 220px;
  gap: 8px;
  background: var(--secondary, #2f048a14);
  color: var(--primary, #2f048a);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`;

const MarkAnnouncement: React.FC = () => {
  const { state } = useMarkPopupContext();
  const toggleMarkPopup = useToggleMarkPopup();
  const { user } = useCurrentUserState();
  const isInternal = user.status === "LOGGED_IN" && user.isInternal;

  const { message, isInsights } = useMemo(() => {
    if (typeof state.message === "string") {
      return { message: state.message, isInsights: false };
    }

    return {
      message: state.message?.message,
      isInsights: state.message?.type === MessageType.INSIGHTS,
    };
  }, [state.message]);

  useEffect(() => {
    if (state.markPopupOpen && !state.message) {
      toggleMarkPopup();
    }
  }, [state.markPopupOpen, state.message]);

  const handleToggleMarkPopup = () => {
    if (!state.markPopupOpen && !state.message) {
      return;
    }
    toggleMarkPopup();
  };
  return (
    <Flex direction="column">
      <MarkAnnounceAvatar onClick={handleToggleMarkPopup}>
        <StyledMarkAvatar $isDisplayed={!state.markPopupOpen} size={"4"} />
        <XIconContainer
          $isDisplayed={state.markPopupOpen}
          align={"center"}
          justify={"center"}
        >
          <XIcon />
        </XIconContainer>
      </MarkAnnounceAvatar>
      <AnnouncementPopup
        text={message}
        isOpen={state.markPopupOpen}
        direction={isInsights ? "row" : "column"}
      >
        {isInternal && isInsights && (
          <Link
            to={INSIGHTS_ROUTE}
            style={{
              pointerEvents: "auto",
              margin: "12px",
            }}
          >
            <StyledButton>
              <Bug size={16} />
              Review Insights
              <ArrowRightIcon size={20} strokeWidth={1.5} />
            </StyledButton>
          </Link>
        )}
      </AnnouncementPopup>
    </Flex>
  );
};

export default MarkAnnouncement;
