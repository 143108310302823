import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export type CampaignGenerationStatusResponse =
  operations["campaign_api_get_campaign_generation_status"]["responses"][200]["content"]["application/json"];

export const getCampaignGenerationStatusQueryKey = (campaignId: string) => {
  return ["campaign-generation-status", campaignId];
};

const useCampaignGenerationStatusQuery = (
  campaignId: string,
  options?: {
    refetchInterval?: number | false;
    enabled?: boolean;
  }
) => {
  const { data, isLoading, isError, ...rest } = useQuery<
    CampaignGenerationStatusResponse,
    Error | AxiosError
  >({
    queryKey: getCampaignGenerationStatusQueryKey(campaignId),
    queryFn: async (): Promise<CampaignGenerationStatusResponse> => {
      const { data } = await axios.get(
        `/api/v1/campaign/${campaignId}/generation-status`
      );
      return data;
    },
    refetchInterval: options?.refetchInterval ?? 5000,
    retry: false,
    staleTime: Infinity,
    enabled: options?.enabled !== false,
  });

  return {
    data,
    isLoading,
    isError,
    ...rest,
  };
};

export default useCampaignGenerationStatusQuery;
