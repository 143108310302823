import ModifierContainer from "./ModifierContainer";
import { ImageAssetCategory } from "@openapi";
import { Label } from "@radix-ui/react-label";
import { Flex, TextField } from "@radix-ui/themes";
import { toast } from "sonner";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { RelatedImageType } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/RelatedImages";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const ImageModifier = ({
  initialImageUrl,
  initialLinkUrl,
  onAssetChange,
  productId,
  collectionId,
  onLinkUrlChange,
}: {
  initialImageUrl?: string;
  initialLinkUrl?: string;
  collectionId?: string | null;
  productId?: string | null;
  onAssetChange: (assets: AssetSelectorItem) => void;
  onLinkUrlChange?: (linkUrl: string) => void;
}) => {
  const { campaign_id } = useEmailState();
  const activeBrandID = useActiveBrandID();

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useUploadImageAssets({
      onSuccess(data) {
        onAssetChange?.(data.new_assets[0] as ImageAssetSchema);
      },
      showErrorToast: true,
    });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: getFormImageAssetCategory(ImageAssetCategory.other),
      images: [file as unknown as string],
    });
  };

  return (
    <ModifierContainer
      title={initialImageUrl ? "Image" : "Featured Image (optional)"}
      hideSwitch
    >
      <ImageSelector
        showCarousel
        campaignId={campaign_id}
        value={initialImageUrl}
        onSelect={(assets) => onAssetChange(assets[0])}
        onCrop={handleUpload}
        isUploading={isUploading}
        selectedCommercePlatformId={productId ?? collectionId ?? undefined}
        type={RelatedImageType.DEFAULT}
        includeRelatedCommerceProductImages={!productId && !collectionId}
      />

      {initialLinkUrl && onLinkUrlChange && (
        <Flex direction="column" gap="8px">
          <Label htmlFor="linkUrl">Link URL</Label>
          <TextField.Root
            required
            name="link"
            value={initialLinkUrl}
            onChange={(e) => {
              onLinkUrlChange(e.target.value);
            }}
            type="url"
            placeholder="https://www.example.com"
          />
        </Flex>
      )}
    </ModifierContainer>
  );
};

export default ImageModifier;
