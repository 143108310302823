import {
  components,
  EmailEmbeddedSvgType,
  EmailEmbeddedSvgVariant,
} from "@openapi";
import Handlebars, { HelperOptions } from "handlebars";
import parseColor from "parse-color";
import { useGetEmailEmbeddedSvgs } from "~/hooks/emails/useGetEmailEmbeddedSvgs";

export function useHandlebarsInit() {
  const { data: embeddedSvgTemplates } = useGetEmailEmbeddedSvgs();

  // doesn't seem to be used - should use `any` instead
  Handlebars.registerHelper(
    "or",
    function (
      this: any,
      a: boolean,
      b: boolean,
      options: Handlebars.HelperOptions
    ) {
      if (a || b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    }
  );
  Handlebars.registerHelper("isEven", function (index: number): boolean {
    return index % 2 === 0;
  });
  Handlebars.registerHelper("isOdd", function (index: number): boolean {
    return index % 2 === 1;
  });
  Handlebars.registerHelper("eq", function (a: any, b: any): boolean {
    return a == b;
  });
  Handlebars.registerHelper("length", function (arr: any[]): number {
    return arr.length;
  });
  Handlebars.registerHelper("editable", function (): void {
    // Do nothing - we're only enabling this while editing
  });

  Handlebars.registerHelper(
    "default",
    (option: string | null, defaultValue: string | null) => {
      return option || defaultValue;
    }
  );

  Handlebars.registerHelper(
    "withDefaults",
    function (_obj: Record<string, unknown>, options: HelperOptions) {
      const obj = { ..._obj };
      Object.keys(options.hash).forEach((key) => {
        if (
          !(key in obj) ||
          obj[key] === undefined ||
          obj[key] === "" ||
          obj[key] === null ||
          (Array.isArray(obj[key]) && obj[key].length === 0)
        ) {
          obj[key] = options.hash[key];
        }
      });
      return obj;
    }
  );
  Handlebars.registerHelper("chunk", function (array, size) {
    if (!Array.isArray(array)) return [];
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }

    return chunked;
  });
  Handlebars.registerHelper("take", function (array, count) {
    if (!Array.isArray(array)) return [];
    return array.slice(0, count);
  });
  Handlebars.registerHelper(
    "slice",
    function (array, start: number, end: number) {
      if (!Array.isArray(array)) return [];
      return array.slice(start || 0, end || array.length);
    }
  );
  Handlebars.registerHelper("and", function (): boolean {
    var args = Array.prototype.slice.call(arguments, 0, -1);
    return args.every(function (arg) {
      return !!arg;
    });
  });
  Handlebars.registerHelper("any", function (): boolean {
    var args = Array.prototype.slice.call(arguments, 0, -1);
    return args.some(function (arg) {
      return !!arg;
    });
  });
  Handlebars.registerHelper("divide", function (a: number, b: number): number {
    if (b === 0) return 0; // Prevent division by zero
    return parseFloat((a / b).toFixed(2)); // Return float with 2 decimal places
  });
  Handlebars.registerHelper("minus", function (a: number, b: number): number {
    return a - b;
  });
  Handlebars.registerHelper("inc", function (value: number): number {
    return value + 1;
  });
  Handlebars.registerHelper("ceil", function (value: number): number {
    return Math.ceil(value);
  });

  Handlebars.registerHelper("merge", function (base, overrides) {
    return { ...base, ...overrides };
  });

  Handlebars.registerHelper("concat", function (...args) {
    return args.slice(0, args.length - 1).join("");
  });

  Handlebars.registerHelper("array", function () {
    return Array.from(arguments).slice(0, arguments.length - 1);
  });

  Handlebars.registerHelper("makeObject", function (options) {
    return options.hash;
  });

  Handlebars.registerHelper("encodeColor", function (color) {
    return encodeURIComponent(color);
  });

  Handlebars.registerHelper("normalizeRow", function (row, size) {
    const normalized = new Array(size).fill(null);
    for (let i = 0; i < row.length; i++) {
      if (row.length === 1) {
        normalized[1] = row[i];
      } else if (row.length === 2) {
        normalized[0] = row[0];
        normalized[2] = row[1];
      } else {
        normalized[i] = row[i];
      }
    }
    return normalized;
  });

  Handlebars.registerHelper(
    "loop",
    function (n: number, options: HelperOptions) {
      var accum = "";
      for (var i = 0; i < n; ++i) accum += options.fn({ index: i });
      return accum;
    }
  );

  const shiftColor = (color: string, amount: number) => {
    const parsedColor = parseColor(color);
    let overflow = 0;
    let rgb = parsedColor.rgb.map((component) => {
      const adjusted = component + amount;
      if (adjusted > 255) {
        overflow += adjusted % 255;
        return 255;
      } else if (adjusted < 0) {
        overflow -= (0 - adjusted) % 255;
        return 0;
      }
      return adjusted;
    });
    overflow = Math.floor(overflow / 3);
    if (overflow > 0) {
      rgb = rgb.map((component) => {
        return Math.max(0, Math.min(255, component - overflow));
      });
    }
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  };

  Handlebars.registerHelper(
    "shiftColor",
    function (color: string, amount: number, index: number) {
      return shiftColor(color, amount * (index + 1));
    }
  );

  const embeddedSvgCompiledTemplates: Partial<
    Record<
      EmailEmbeddedSvgType,
      Partial<Record<EmailEmbeddedSvgVariant, Handlebars.TemplateDelegate>>
    >
  > = {};

  const getEmbeddedSvgCompiledTemplate = (
    type: EmailEmbeddedSvgType,
    variant: EmailEmbeddedSvgVariant
  ) => {
    if (!embeddedSvgCompiledTemplates[type]) {
      embeddedSvgCompiledTemplates[type] = {};
    }
    if (!embeddedSvgCompiledTemplates[type][variant]) {
      const template = embeddedSvgTemplates?.find(
        (value) => value.type == type && value.variant === variant
      );
      if (!template) {
        return undefined;
      }
      embeddedSvgCompiledTemplates[type][variant] = Handlebars.compile(
        template?.content ?? ""
      );
    }
    return embeddedSvgCompiledTemplates[type][variant];
  };

  Handlebars.registerHelper(
    "svg",
    function (
      type: EmailEmbeddedSvgType,
      variant: EmailEmbeddedSvgVariant,
      options: HelperOptions
    ) {
      const template = getEmbeddedSvgCompiledTemplate(type, variant);
      return template?.({
        width: options.hash.width,
        height: options.hash.height,
        fill: options.hash.fill,
      });
    }
  );

  // NOTE: mjml uses "display: inline-block" for buttons, probably for a reason...
  // but we use "display: block" to make sure the clickable area is full width
  Handlebars.registerPartial(
    "button",
    `{{#if enabled}}
<tr>
    <td align={{#if align}}"{{align}}"{{else}}"center"{{/if}} vertical-align="middle" style="
        font-size:0px;
        word-break:break-word;
        {{#if (default outer_padding default_outer_padding)}}padding: {{default outer_padding default_outer_padding}};{{/if}}
    ">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
            border-collapse:separate;
            line-height:100%;
            {{#if (default width default_width)}}width: {{default width default_width}};{{/if}}
        ">
            <tbody>
                <tr>
                    <td 
                        role="presentation" 
                        align="center"
                        valign="middle"
                        {{#if (default background_color default_background_color)}}bgcolor="{{default background_color default_background_color}}"{{/if}}
                        style="
                            cursor: auto;
                            {{#if (default background_color default_background_color)}}background: {{default background_color default_background_color}};{{/if}}
                            {{#if (default border_color default_border_color)}}border: {{default border_color default_border_color}};{{/if}}
                            {{#if (default border_radius default_border_radius)}}border-radius: {{default border_radius default_border_radius}};{{/if}}
                            {{#if (default border_style default_border_style)}}border-style: {{default border_style default_border_style}};{{/if}}
                            {{#if (default border_width default_border_width)}}border-width: {{default border_width default_border_width}};{{/if}}
                            {{#if (default padding default_padding)}}mso-padding-alt: {{default padding default_padding}};{{/if}}
                        "
                    >
                        <a
                          {{editable id}}
                          id="ttid:{{id}}"
                          {{#if opens_new_tab}}target="_blank"{{/if}}
                          href="{{link_url}}"
                          style="
                              display: block;
                              cursor: pointer;
                              line-height: 120%;
                              margin: 0;
                              mso-padding-alt: 0px;
                              text-align: center;
                              text-decoration: {{#if (default text_decoration default_text_decoration)}}{{default text_decoration default_text_decoration}}{{else}}none{{/if}};
                              {{#if (default background_color default_background_color)}}background: {{default background_color default_background_color}};{{/if}}
                              {{#if (default border_radius default_border_radius)}}border-radius: {{default border_radius default_border_radius}};{{/if}}
                              {{#if (default color default_color)}}color: {{default color default_color}};{{/if}}
                              {{#if (default font_styles.family default_font_family)}}font-family: {{default font_styles.family default_font_family}}, Helvetica Neue, Arial, sans-serif;{{/if}}
                              {{#if (default font_styles.size default_font_size)}}font-size: {{default font_styles.size default_font_size}};{{/if}}
                              {{#if (default font_styles.style default_font_style)}}font-style: {{default font_styles.style default_font_style}};{{/if}}
                              {{#if (default font_styles.weight default_font_weight)}}font-weight: {{default font_styles.weight default_font_weight}};{{/if}}
                              {{#if (default padding default_padding)}}padding: {{default padding default_padding}};{{/if}}
                              {{#if (default text_transform default_text_transform)}}text-transform: {{default text_transform default_text_transform}};{{/if}}
                              {{#if (default line_height default_line_height)}}line-height: {{default line_height default_line_height}};{{/if}}
                              {{#if (default letter_spacing default_letter_spacing)}}letter-spacing: {{default letter_spacing default_letter_spacing}};{{/if}}
                              {{#if box_shadow}}box-shadow: {{box_shadow}};{{/if}}
                              {{style}}
                          "
                        >
                            {{{ default text default_text }}}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </td>
</tr>
{{/if}}`
  );
  Handlebars.registerPartial(
    "text",
    `{{#if enabled}}
  <tr>
  <td
    {{editable id}}
    id="ttid:{{id}}" 
    style="
        word-break: break-word;
        padding-bottom: {{#if padding_bottom}}{{padding_bottom}}{{else}}12px{{/if}};
        {{#if text_align}}text-align: {{text_align}};{{/if}}
        {{#if color}}color: {{color}};{{/if}}
        {{#if text_decoration}}text-decoration: {{text_decoration}};{{/if}}
        {{#if padding_top}}padding-top: {{padding_top}};{{/if}}
        {{#if padding_left}}padding-left: {{padding_left}};{{/if}}
        {{#if padding_right}}padding-right: {{padding_right}};{{/if}}
        {{#if font_size}}font-size: {{font_size}};{{/if}}
        {{#if font_weight}}font-weight: {{font_weight}};{{/if}}
        {{#if font_style}}font-style: {{font_style}};{{/if}}
        {{#if line_height}}line-height: {{line_height}};{{/if}}
        {{#if letter_spacing}}letter-spacing: {{letter_spacing}};{{/if}}
        {{style}}
      ">
        {{{default text default_text }}}{{{suffix}}}
    </td>
  </tr>
{{/if}}`
  );

  Handlebars.registerPartial(
    "two_texts",
    `{{#each texts}}
      {{#if enabled}}
      <td 
        id="ttid:{{id}}" 
        style="
          {{#if text_align}}text-align: {{text_align}};{{/if}}
          {{#if color}}color: {{color}};{{/if}}
          {{#if text_decoration}}text-decoration: {{text_decoration}};{{/if}}
          padding-bottom: {{#if padding_bottom}}{{padding_bottom}}{{else}}12px{{/if}};
          {{style}}
        " 
        {{editable id}}
      >
          {{{text}}}
      </td>
      {{/if}}
    {{/each}}`
  );

  Handlebars.registerPartial(
    "img",
    `<tr>
    <td align="{{#if align}}{{align}}{{else}}center{{/if}}" style="font-size:0px;word-break:break-word;">
        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
        <tbody>
            <tr>
            <td style="
              width:{{#if width}}{{width}}{{else}}600{{/if}}px;
              {{#if padding_top}}padding-top: {{padding_top}};{{/if}}
              {{#if padding_right}}padding-right: {{padding_right}};{{/if}}
              {{#if padding_bottom}}padding-bottom: {{padding_bottom}};{{/if}}
              {{#if padding_left}}padding-left: {{padding_left}};{{/if}}
            ">
              {{#if link_url}}<a href="{{link_url}}" target="_blank" style="text-decoration: none;">{{/if}}
                <img 
                    src="{{default image_url default_image_url}}" 
                    alt="{{default alt default_alt}}" 
                    title="{{default alt default_alt}}" 
                    style="
                        border:0;
                        display:block;
                        outline:none;
                        text-decoration:none;
                        height:{{#if height}}{{height}}px{{else}}auto{{/if}};
                        width:100%;
                        box-sizing:border-box;
                        {{#if border_style}}border-style:{{border_style}};{{/if}}
                        {{#if border_color}}border-color:{{border_color}};{{/if}}
                        {{#if border_width}}border-width:{{border_width}};{{/if}}
                        {{#if border_radius}}border-radius:{{border_radius}};{{/if}}
                        {{#if max_height}}max-height: {{max_height}};{{/if}}
                        {{#if min_height}}min-height: {{min_height}};{{/if}}
                        {{#if aspect_ratio}}aspect-ratio: {{aspect_ratio}};{{/if}}
                        {{#if object_fit}}object-fit: {{object_fit}};{{/if}}
                        {{#if object_position}}object-position: {{object_position}};{{/if}}
                        {{style}}
                    "
                    {{#if class_names}}class="{{class_names}}"{{/if}}
                    width="{{#if width}}{{width}}{{else}}600{{/if}}"
                    height="{{#if height}}{{height}}{{else}}auto{{/if}}"
                />
              {{#if link_url}}</a>{{/if}}
            </td>
            </tr>
        </tbody>
        </table>
    </td>
  </tr>`
  );

  Handlebars.registerPartial(
    "rating",
    `{{#if enabled}}
    <td
      style="
        {{#if text_align}}text-align: {{text_align}};{{/if}}
        {{#if color}}color: {{color}};{{/if}}
        {{#if text_decoration}}text-decoration: {{text_decoration}};{{/if}}
        font-size: {{default font_size '16px'}};
        font-family: Helvetica Neue, Arial, sans-serif;
        {{style}}
      "
    >
      {{#loop (ceil rating)}}&#9733;{{/loop}}{{#loop (minus 5 (ceil rating))}}&#9734;{{/loop}}
    </td>
    {{/if}}`
  );

  Handlebars.registerPartial(
    "embedded_svg",
    "{{{svg type version width=width height=height fill=fill}}}"
  );
}
