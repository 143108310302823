import classes from "./TipTap.module.css";
import { FontSize } from "./TipTapFontSizeExtension";
import BoldExtension, { isBold } from "./tip-tap/BoldExtension";
import { EscapeBlur } from "./tip-tap/EscapeBlurExtension";
import HighlightExtension from "./tip-tap/HighlightExtension";
import ItalicExtension, { isItalic } from "./tip-tap/ItalicExtension";
import LineHeight from "./tip-tap/LineHeightExtension";
import ListItemExtension from "./tip-tap/ListItemExtension";
import StrikethroughExtension, {
  isStrikethrough,
} from "./tip-tap/StrikethroughExtension";
import UnderlineExtension, { isUnderline } from "./tip-tap/UnderlineExtension";
import LineHeightButton from "./tip-tap/buttons/LineHeightButton";
import { EmailSectionType } from "@openapi";
import { Button, Separator, Text, Theme, Tooltip } from "@radix-ui/themes";
import Color from "@tiptap/extension-color";
import Font from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import {
  useEditor,
  EditorContent,
  mergeAttributes,
  Editor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { debounce } from "lodash";
import {
  Bold,
  Italic,
  Strikethrough,
  Baseline,
  Highlighter as HighlighterIcon,
  Underline as UnderlineIcon,
  Link as LinkIcon,
  List,
  ListOrdered,
  RemoveFormatting,
} from "lucide-react";
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useMemo,
} from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import ColorPicker from "~/components/core/inputs/ColorPicker";
import LinkInput from "~/components/core/inputs/LinkInput";
import NumberInput from "~/components/core/inputs/NumberInput";
import BrandFontSelect from "~/components/style-library/typography/BrandFontSelect/BrandFontSelect";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import {
  useApplyTextStyle,
  useEmailState,
  useSetActiveTipTapID,
  useSetIsEmailDirty,
  useUpdateNestedObjectById,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(), // Retain existing attributes like `href`, `target`, `rel`
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style") || null,
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }

          return {
            style: attributes.style,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class") || null,
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }

          return {
            class: attributes.class,
          };
        },
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const extensions = [
  StarterKit.configure({
    bold: false,
    italic: false,
    strike: false,
    listItem: false,
  }),
  ListItemExtension,
  BoldExtension,
  ItalicExtension,
  StrikethroughExtension,
  UnderlineExtension,
  HighlightExtension,
  TextStyle,
  Color,
  CustomLink.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
  }),
  Font,
  FontSize,
  EscapeBlur,
  LineHeight,
];

// Required for blur events to correctly report relatedTarget
const FocusableButton = forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Button>
>((props, ref) => {
  return props.title ? (
    <Tooltip content={props.title}>
      <Button tabIndex={-1} {...props} ref={ref} />
    </Tooltip>
  ) : (
    <Button tabIndex={-1} {...props} ref={ref} />
  );
});

export const ToolbarButton = styled(FocusableButton)<{
  $isSelected: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  color: ${(props) =>
    props.$isSelected ? "#1f2937" : "#6b7280"}; /* text-neutral-500 */
  border-radius: 6px;
  border-width: 1px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0,0,0,0.1)" : "white"};
  border-color: transparent;
  white-space: nowrap;
  opacity: 1;
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width || "40px"};
  padding: ${(props) => (props.width ? "0 0.50rem" : "0")};
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  &:hover {
    background-color: ${(props) =>
      props.$isSelected
        ? "rgba(0,0,0,0.1)"
        : "#f5f5f5"}; /* hover:bg-neutral-100 */
    color: ${(props) =>
      props.$isSelected ? "#1f2937" : "#6b7280"}; /* hover:text-neutral-800 */
  }
`;

const Toolbar = styled.div`
  position: fixed;
  color: black;
  display: inline-flex;
  line-height: 1; /* Adjusts leading-none */
  gap: 0.5rem; /* 0.5rem (gap-0.5) */
  flex-direction: row;
  padding: 0.5rem; /* 1rem (p-1) */
  align-items: center;
  background-color: white;
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* shadow-sm */
  border: 1px solid #e5e7eb; /* border-neutral-200 */
`;

const EditorContainer = styled(EditorContent)`
  .ProseMirror:focus {
    outline: none;
  }
`;

const formatFontFamilyName = (fontFamily: string): string => {
  return fontFamily.split(",")[0].replace(/['"]+/g, "");
};

export const getDefaultProperties = (
  tipTapID: string | null
): {
  fontSize: string;
  fontFamily: string;
  lineHeight: string;
} => {
  if (!tipTapID) {
    return {
      fontSize: "",
      fontFamily: "",
      lineHeight: "",
    };
  }
  // tipTapID is the id of the editor container
  // the first child is actual <span> element we want to read styles from
  const targetElement = document.getElementById(tipTapID)?.children?.[0];
  if (!targetElement) {
    return {
      fontSize: "",
      fontFamily: "",
      lineHeight: "",
    };
  }
  return {
    fontSize: window.getComputedStyle(targetElement).fontSize ?? "",
    fontFamily: window.getComputedStyle(targetElement).fontFamily ?? "",
    lineHeight: window.getComputedStyle(targetElement).lineHeight ?? "",
  };
};

const formatFontSize = (fontSize: string): string => {
  return fontSize.replace("px", "");
};
// Add these helper functions
const findElementPathInSection = (
  section: any,
  elementId: string,
  path: string[] = []
): string[] | null => {
  if (!section) return null;

  // Check if current object has the ID
  if (section.id === elementId) {
    return path;
  }

  // Check arrays (like products)
  if (Array.isArray(section)) {
    for (let i = 0; i < section.length; i++) {
      const result = findElementPathInSection(section[i], elementId, [
        ...path,
        i.toString(),
      ]);
      if (result) return result;
    }
  }

  // Check object properties
  if (typeof section === "object") {
    for (const [key, value] of Object.entries(section)) {
      if (typeof value === "object") {
        const result = findElementPathInSection(value, elementId, [
          ...path,
          key,
        ]);
        if (result) return result;
      }
    }
  }

  return null;
};

const getElementInfo = (section: any, elementId: string) => {
  const path = findElementPathInSection(section, elementId);

  if (!path) return null;

  // Check if this element is within an array by looking for numeric indices in path
  const isInArray = path.some((segment) => !isNaN(Number(segment)));

  // Get the field type (last non-numeric segment in path)
  const fieldType = path.filter((segment) => isNaN(Number(segment))).pop();

  return {
    isInArray,
    fieldType,
    path,
  };
};

const fixElementFontSize = ({
  elementId,
  editor,
}: {
  elementId: string;
  editor: Editor;
}) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }
  const computedFontSize = window.getComputedStyle(element).fontSize;
  const fontSize = editor.getAttributes("textStyle")?.fontSize;
  if ((computedFontSize === "0px" || computedFontSize === "0") && !fontSize) {
    editor.chain().setFontSize("16px").run();
  }
};

const Tiptap = () => {
  const { activeTipTapID, selectedSectionId, sections } = useEmailState();
  const setIsEmailDirty = useSetIsEmailDirty();
  const section = (sections ?? []).find((s) => s.id === selectedSectionId);

  const elementInfo = useMemo(() => {
    if (!activeTipTapID) return null;
    return getElementInfo(section, activeTipTapID.split(":")[1]);
  }, [section, activeTipTapID]);

  const showApplyAll = useMemo(() => {
    if (!activeTipTapID || !section?.type || !elementInfo) return false;

    // For now, explicitly only show for fields within these section types
    // Should work generally though...
    return (
      elementInfo.isInArray &&
      (section.type === EmailSectionType.collection_grid ||
        section.type === EmailSectionType.listicle ||
        section.type === EmailSectionType.products)
    );
  }, [activeTipTapID, section?.type, elementInfo?.isInArray]);

  const updateNestedObjectById = useUpdateNestedObjectById();
  const setActiveTipTapID = useSetActiveTipTapID();
  const applyTextStyle = useApplyTextStyle();

  const toolbarRef = useRef<HTMLDivElement>(null);
  //  id of the currently focused element, set based on the editor focus/blur events
  const [focusedTipTapID, setFocusedTipTapID] = useState<string | null>(null);

  const editor = useEditor({
    extensions,
    onFocus: ({ editor }) => {
      setFocusedTipTapID(activeTipTapID);
      if (activeTipTapID) {
        fixElementFontSize({
          elementId: activeTipTapID,
          editor,
        });
      }
    },
    onBlur: ({ event }) => {
      if (focusedTipTapID !== activeTipTapID) {
        return;
      }

      if (
        toolbarRef.current &&
        toolbarRef.current.contains(event.relatedTarget as Node)
      ) {
        // Prevent blurring when clicking on toolbar
        event.preventDefault();
        return;
      }
      setFocusedTipTapID(null);
      if (activeTipTapID && selectedSectionId) {
        setActiveTipTapID("");
        setEditorElement(null);
      }
    },
    onUpdate({ editor }) {
      setIsEmailDirty(true);

      // avoid having font-size of 0
      // this can happen when the user deletes all text in a paragraph
      if (focusedTipTapID) {
        fixElementFontSize({
          elementId: focusedTipTapID,
          editor,
        });
      }
    },
  });

  // force blur if user clicks on toolbar, and later clicks outside the editor
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!focusedTipTapID || !editor || focusedTipTapID !== activeTipTapID) {
        return;
      }

      const clickedElement = event.target as Node;
      const isClickInToolbar = toolbarRef.current?.contains(clickedElement);
      const isClickInEditor = editor.options.element?.contains(clickedElement);

      if (!isClickInToolbar && !isClickInEditor) {
        setFocusedTipTapID(null);
        if (activeTipTapID && selectedSectionId) {
          setActiveTipTapID("");
          setEditorElement(null);
        }
        editor.commands.blur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editor, focusedTipTapID, activeTipTapID, selectedSectionId]);

  const [editorElement, setEditorElement] = useState<HTMLElement | null>(null);

  const updateEditorPosition = useCallback(() => {
    if (!toolbarRef.current || !editorElement) return;
    const fadeThreshold = 80; // Start fading when the editor is 80px from the top

    const { top, left, width, bottom } = editorElement.getBoundingClientRect();
    let offset = top - 1.5 * toolbarRef.current.offsetHeight;

    toolbarRef.current.style.display = "inline-flex";

    if (offset < fadeThreshold) {
      offset = bottom + toolbarRef.current.offsetHeight / 2;
    }
    toolbarRef.current.style.top = `${offset < 0 ? 0 : offset}px`;

    const editorWidth = toolbarRef.current.offsetWidth;
    const leftOffset = (editorWidth - width) / 2;

    toolbarRef.current.style.left = `${Math.max(left - leftOffset, 0)}px`; // Center horizontally

    const opacity = Math.max(0, Math.min(1, offset / fadeThreshold));
    toolbarRef.current.style.opacity = opacity.toString();
  }, [toolbarRef, editorElement]);

  const handleScroll = useCallback(() => {
    requestAnimationFrame(updateEditorPosition);
  }, [updateEditorPosition]);

  const debouncedUpdatePosition = useCallback(
    debounce(updateEditorPosition, 100),
    [updateEditorPosition]
  );

  useEffect(() => {
    const editorPreview = document.getElementById("email-editor-preview");
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateEditorPosition);
    });
    if (editorPreview) {
      editorPreview.addEventListener("scroll", handleScroll);
      resizeObserver.observe(editorPreview);
    }
    return () => {
      if (editorPreview) {
        editorPreview.removeEventListener("scroll", handleScroll);
      }
      resizeObserver.disconnect();
    };
  }, [handleScroll]);

  useEffect(() => {
    if (editor) {
      editor.on("update", debouncedUpdatePosition);
    }
    return () => {
      if (editor) {
        editor.off("update", debouncedUpdatePosition);
      }
    };
  }, [editor, debouncedUpdatePosition]);

  const maybeElementUrl = useMemo(() => {
    if (
      editorElement &&
      "href" in editorElement &&
      typeof editorElement.href === "string"
    ) {
      return editorElement.getAttribute("href");
    }
  }, [editorElement]);

  const maybeSetElementUrl = useCallback(
    (url: string) => {
      if (editorElement && "href" in editorElement) {
        editorElement.href = url;
        return true;
      }
      return false;
    },
    [editorElement]
  );

  const maybeSaveElementUrl = useCallback(
    ({ objectId }: { objectId: string }) => {
      if (!editorElement || !("href" in editorElement)) {
        return;
      }
      updateNestedObjectById({
        objectId: objectId,
        field: "link_url",
        value: (editorElement.href as string) || "#",
      });
    },
    [editorElement]
  );

  // update values on blur (including on focus over another element)
  const [previousActiveTipTapID, setPreviousActiveTipTapID] = useState<
    string | null
  >(null);
  useEffect(() => {
    if (
      previousActiveTipTapID &&
      previousActiveTipTapID !== activeTipTapID &&
      editor
    ) {
      const json = editor.getJSON();
      let html = editor.getHTML();
      // remove wrapping <p>..</p> tags if there is one (non-styled) paragraph wrapping the entire content
      if (
        json.content?.length === 1 &&
        json.content[0].type === "paragraph" &&
        html.startsWith("<p>")
      ) {
        html = html.slice(3, html.length - 4);
      }
      updateNestedObjectById({
        objectId: previousActiveTipTapID.split(":")[1],
        field: "text",
        value: html,
      });

      maybeSaveElementUrl({
        objectId: previousActiveTipTapID.split(":")[1],
      });
    }
    setPreviousActiveTipTapID(activeTipTapID);
  }, [activeTipTapID, previousActiveTipTapID, editor]);

  useEffect(() => {
    if (activeTipTapID) {
      updateEditorPosition();
    }
  }, [activeTipTapID, updateEditorPosition]);

  useEffect(() => {
    if (!editor || !activeTipTapID) {
      setEditorElement(null);
      if (toolbarRef.current) {
        toolbarRef.current.style.visibility = "hidden";
      }
      return;
    }

    const targetElement = document.getElementById(activeTipTapID);
    if (!targetElement) return;

    targetElement.dataset.originalDisplay = targetElement.style.display;

    const newEditorID = `editor:${activeTipTapID}`;
    let newEditorElement = document.getElementById(newEditorID);
    if (!newEditorElement) {
      newEditorElement = targetElement.cloneNode() as HTMLElement;
      newEditorElement.id = newEditorID;
      newEditorElement.className = classes.editContainer;
      targetElement.parentNode?.insertBefore(
        newEditorElement,
        targetElement.nextSibling
      );
    }
    newEditorElement.style.display =
      newEditorElement.dataset.originalDisplay ?? "";
    targetElement.style.display = "none";

    setEditorElement(newEditorElement);

    if (toolbarRef.current) {
      toolbarRef.current.style.visibility = "visible";
    }

    // Set editor content and focus
    editor.commands.setContent(targetElement.innerHTML);
    editor.commands.focus("all");
    editor.setEditable(true);

    // Cleanup function
    return () => {
      if (targetElement) {
        targetElement.style.display =
          editorElement?.dataset.originalDisplay || "";
      }
      if (toolbarRef.current) {
        toolbarRef.current.style.visibility = "hidden";
      }
      editorElement && (editorElement.style.display = "none");
    };
  }, [editor, activeTipTapID, editorElement]);

  if (!editor) return null;

  const toolbar = createPortal(
    <Theme>
      <BrandStylingProvider>
        <Toolbar ref={toolbarRef}>
          <NumberInput
            radius="large"
            value={formatFontSize(
              editor.getAttributes("textStyle")?.fontSize ??
                getDefaultProperties(activeTipTapID).fontSize
            )}
            style={{
              maxWidth: "74px",
              overflow: "hidden",
            }}
            onValueChange={(value) => {
              editor
                .chain()
                .setFontSize(value + "px")
                .run();
            }}
          />
          <BrandFontSelect
            loadSelectedGoogleFont
            size={"3"}
            value={formatFontFamilyName(
              editor.getAttributes("textStyle")?.fontFamily ??
                getDefaultProperties(activeTipTapID).fontFamily
            )}
            onChange={(value) => {
              editor
                .chain()
                .focus()
                .setFontFamily(
                  value.name
                    ? `${value.name}, Helvetica Neue, Arial, sans-serif`
                    : "Helvetica Neue, Arial, sans-serif"
                )
                .run();
            }}
            style={{
              maxWidth: "140px",
            }}
          />
          <ToolbarButton
            onClick={() => {
              editor.chain().focus().toggleBold().run();
            }}
            $isSelected={isBold(editor)}
          >
            <Bold size={16} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.chain().focus().toggleItalic().run();
            }}
            $isSelected={isItalic(editor)}
          >
            <Italic size={16} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.chain().focus().toggleStrikethrough().run();
            }}
            $isSelected={isStrikethrough(editor)}
          >
            <Strikethrough size={16} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.chain().focus().toggleUnderline().run();
            }}
            $isSelected={isUnderline(editor)}
          >
            <UnderlineIcon size={16} />
          </ToolbarButton>
          <ColorPicker
            align="start"
            defaultColor={editor.getAttributes("textStyle")?.color || undefined}
            onClearColor={() => {
              editor.chain().focus().unsetColor().run();
            }}
            onChange={(color) => {
              editor.chain().setColor(color).run();
            }}
            onSaveColor={(color) => {
              editor.chain().focus().setColor(color).run();
            }}
          >
            <ToolbarButton
              $isSelected={!!editor.getAttributes("textStyle")?.color}
            >
              <Baseline size={16} />
            </ToolbarButton>
          </ColorPicker>
          <ColorPicker
            align="start"
            defaultColor={
              editor.getAttributes("textStyle")?.highlight || undefined
            }
            onClearColor={() => {
              editor.chain().focus().unsetHighlight().run();
            }}
            onChange={(color) => {
              editor.chain().setHighlight(color).run();
            }}
            onSaveColor={(_) => {
              editor.chain().focus().run();
            }}
          >
            <ToolbarButton
              $isSelected={!!editor.getAttributes("textStyle")?.highlight}
            >
              <HighlighterIcon size={16} />
            </ToolbarButton>
          </ColorPicker>
          <LinkInput
            defaultUrl={maybeElementUrl ?? editor.getAttributes("link")?.href}
            onSaveLink={(url) => {
              if (maybeSetElementUrl(url)) {
                return;
              }
              if (url) {
                editor.chain().focus().setLink({ href: url }).run();
              } else {
                editor.chain().focus().unsetLink().run();
              }
            }}
          >
            <ToolbarButton $isSelected={editor.isActive("link")}>
              <LinkIcon size={16} />
            </ToolbarButton>
          </LinkInput>
          <LineHeightButton editor={editor} activeTipTapID={activeTipTapID} />
          <ToolbarButton
            $isSelected={editor.isActive("bulletList")}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <List size={16} />
          </ToolbarButton>
          <ToolbarButton
            $isSelected={editor.isActive("orderedList")}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          >
            <ListOrdered size={16} />
          </ToolbarButton>
          <ToolbarButton
            title="Remove formatting"
            $isSelected={false}
            onClick={() =>
              editor
                .chain()
                .focus()
                .clearNodes()
                .setMark("textStyle", {
                  fontWeight: null,
                  fontStyle: null,
                  textDecoration: null,
                  color: null,
                  highlight: null,
                  lineHeight: null,
                })
                .run()
            }
          >
            <RemoveFormatting size={16} />
          </ToolbarButton>
          {showApplyAll && (
            <>
              <Separator orientation="vertical" color="gray" mx="2" />

              <ToolbarButton
                $isSelected={false}
                width="unset"
                mr="2"
                size={"2"}
                variant="ghost"
                disabled={
                  !activeTipTapID ||
                  editor.isEmpty ||
                  editor.getText().trim() === ""
                }
                onClick={() => {
                  if (!activeTipTapID) return;
                  const json = editor.getJSON();
                  let html = editor.getHTML();

                  // remove wrapping <p>..</p> tags if there is one (non-styled) paragraph wrapping the entire content
                  if (
                    json.content?.length === 1 &&
                    json.content[0].type === "paragraph" &&
                    html.startsWith("<p>")
                  ) {
                    html = html.slice(3, html.length - 4);
                  }
                  const targetPath =
                    elementInfo?.path[elementInfo.path.length - 1];

                  if (!targetPath || !html) return;

                  applyTextStyle({
                    sectionId: selectedSectionId,
                    fieldPath: elementInfo.path,
                    value: html,
                  });
                }}
              >
                <Text size="1" color="gray">
                  Apply All
                </Text>
              </ToolbarButton>
            </>
          )}
        </Toolbar>
      </BrandStylingProvider>
    </Theme>,
    document.getElementById("root") as HTMLElement
  );

  return (
    <div>
      {editorElement &&
        createPortal(<EditorContainer editor={editor} />, editorElement)}
      {toolbar}
    </div>
  );
};

export default Tiptap;
