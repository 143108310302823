import ButtonModifier from "./ButtonModifier";
import ModifierContainer from "./ModifierContainer";
import SectionTextModifier from "./SectionTextModifier";
import { components, EmailElementType, ImageAssetCategory } from "@openapi";
import { Card, Flex, Switch, Button } from "@radix-ui/themes";
import { GripVertical, Image, X } from "lucide-react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { toast } from "sonner";
import DraperText from "~/components/core/DraperText";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { cleanHtmlText } from "~/components/editor/utils";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const ListCard = ({
  listicleElement,
  fieldName,
  enabled,
  onSwitchToggle,
}: {
  listicleElement: components["schemas"]["EmailTextListElementSchema"];
  fieldName: string;
  enabled: boolean;
  onSwitchToggle: (enabled: boolean) => void;
}) => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId, campaign_id } = useEmailState();
  const activeBrandID = useActiveBrandID();
  const { mutate: uploadImageAssets, isPending: isUploading } =
    useUploadImageAssets({
      onSuccess(data) {
        // If no image exists, create new image element
        if (!listicleElement.image) {
          updateSection({
            sectionId: selectedSectionId,
            field: `${fieldName}.image`,
            value: {
              id: "",
              type: EmailElementType.image,
              image_url: data.new_assets[0].url,
              enabled: true,
              border_radius: "0px",
              border_style: "none" as components["schemas"]["CSSBorderStyle"],
              border_color: "",
              border_width: "0px",
              padding_top: null,
              padding_right: null,
              padding_bottom: null,
              padding_left: null,
            },
          });
        } else {
          // Update existing image URL
          updateSection({
            sectionId: selectedSectionId,
            field: `${fieldName}.image.image_url`,
            value: data.new_assets[0].url,
          });

          // Ensure image is enabled
          updateSection({
            sectionId: selectedSectionId,
            field: `${fieldName}.image.enabled`,
            value: true,
          });
        }
      },
      onError() {
        toast.error("Failed to upload image");
      },
    });

  const handleAssetSelected = (assets: any) => {
    if (assets && assets.length > 0) {
      // If no image exists, create new image element
      if (!listicleElement.image) {
        updateSection({
          sectionId: selectedSectionId,
          field: `${fieldName}.image`,
          value: {
            id: "",
            type: EmailElementType.image,
            image_url: assets[0].url,
            enabled: true,
            border_radius: "0px",
            border_style: "none" as components["schemas"]["CSSBorderStyle"],
            border_color: "",
            border_width: "0px",
            padding_top: null,
            padding_right: null,
            padding_bottom: null,
            padding_left: null,
          },
        });
      } else {
        // Update existing image URL
        updateSection({
          sectionId: selectedSectionId,
          field: `${fieldName}.image.image_url`,
          value: assets[0].url,
        });

        // Ensure image is enabled
        updateSection({
          sectionId: selectedSectionId,
          field: `${fieldName}.image.enabled`,
          value: true,
        });
      }
    }
  };

  const handleAssetUpload = (file: File) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: getFormImageAssetCategory(ImageAssetCategory.other),
      images: [file as unknown as string],
    });
  };

  const handleRemoveImage = () => {
    // First disable the image instead of setting to null
    updateSection({
      sectionId: selectedSectionId,
      field: `${fieldName}.image.enabled`,
      value: false,
    });
  };

  return (
    <Card>
      <Flex align="center" justify="between" mb="2" gap="2">
        <Flex align="center" gap="2">
          <GripVertical size={20} />
          <DraperText size={"3"} weight={"medium"} clamp={1}>
            {cleanHtmlText(listicleElement.title.text)}
          </DraperText>
        </Flex>
        <Switch
          checked={enabled}
          onCheckedChange={onSwitchToggle}
          color="gray"
          highContrast
        />
      </Flex>
      <Flex direction="column" gap="2">
        <SectionTextModifier
          textElement={listicleElement.title}
          fieldName={`${fieldName}.title.text`}
          label="Title"
        />

        <SectionTextModifier
          textElement={listicleElement.subtitle}
          fieldName={`${fieldName}.subtitle.text`}
          label="Subtitle"
        />

        <Flex direction="column" gap="2">
          <DraperText size="2" weight="medium">
            Image
          </DraperText>

          {listicleElement.image && listicleElement.image.enabled ? (
            <Flex direction="column" gap="2">
              <Flex
                align="center"
                justify="between"
                style={{
                  position: "relative",
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
              >
                <img
                  src={listicleElement.image.image_url}
                  alt="Listicle item"
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "block",
                    objectFit: "cover",
                  }}
                />
                <Button
                  variant="soft"
                  radius="full"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    minWidth: "auto",
                    padding: "4px",
                  }}
                  onClick={handleRemoveImage}
                >
                  <X size={16} />
                </Button>
              </Flex>
              <ImageSelector
                campaignId={campaign_id}
                value={listicleElement.image.image_url}
                onSelect={handleAssetSelected}
                onCrop={handleAssetUpload}
                isUploading={isUploading}
              />
            </Flex>
          ) : (
            <ImageSelector
              campaignId={campaign_id}
              value=""
              onSelect={handleAssetSelected}
              onCrop={handleAssetUpload}
              isUploading={isUploading}
            />
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

const ListicleModifier = ({
  items,
  ctaButton,
}: {
  items: components["schemas"]["EmailTextListElementSchema"][];
  ctaButton?: components["schemas"]["EmailButtonElementSchema"];
}) => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId } = useEmailState();

  return (
    <DragDropContext
      onDragEnd={(result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }
        const newListItems = Array.from(items);
        const [reorderedProduct] = newListItems.splice(source.index, 1);
        newListItems.splice(destination.index, 0, reorderedProduct);

        updateSection({
          sectionId: selectedSectionId,
          field: "list_items",
          value: newListItems,
        });
      }}
    >
      <ModifierContainer title="Listicle" hideSwitch={true}>
        <StrictModeDroppable
          droppableId="listicle-modifier-droppable"
          type="group"
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Flex direction="column" gap="2">
                {items.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id || String(index)}
                      draggableId={item.id || String(index)}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <ListCard
                            fieldName={`list_items.${index}`}
                            listicleElement={item}
                            enabled={item.enabled}
                            onSwitchToggle={(enabled) => {
                              updateSection({
                                sectionId: selectedSectionId,
                                field: `list_items.${index}.enabled`,
                                value: enabled,
                              });
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Flex>
            </div>
          )}
        </StrictModeDroppable>

        {ctaButton && (
          <Flex direction="column" gap="3" mt="4">
            <ButtonModifier
              buttonElement={ctaButton}
              fieldName="cta_button"
              onSwitchToggle={(enabled) => {
                updateSection({
                  sectionId: selectedSectionId,
                  field: "cta_button.enabled",
                  value: enabled,
                });
              }}
            />
          </Flex>
        )}
      </ModifierContainer>
    </DragDropContext>
  );
};

export default ListicleModifier;
