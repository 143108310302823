import RadioWithText from "../campaign/wizard/inputs/RadioWithText";
import { components, operations } from "@openapi";
import * as Label from "@radix-ui/react-label";
import {
  Flex,
  IconButton,
  ScrollArea,
  TextField,
  Text,
  Box,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { SendIcon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationCreativeMessagesQuery, {
  CampaignRecommendationCreativeMessagesResponse,
} from "~/hooks/campaign/useCampaignCreativeRecommendationMessagesQuery";
import nullthrows from "~/utils/nullthrows";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type GenCreactiveRecommendationUpdateResponse =
  operations["recommender_api_gen_creative_recommendation_update"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationUpdateRequestData =
  operations["recommender_api_gen_creative_recommendation_update"]["requestBody"]["content"]["application/json"];

type GenCreactiveRecommendationsResponse =
  operations["recommender_api_gen_creative_recommendations"]["responses"]["200"]["content"]["application/json"];

// TODO: ONLY FOR RECOMMENDED WIZARD FOR NOW
const CampaignCreativeSelector = ({
  rootCreativeID,
  activeCreativeID,
  onActiveCreativeChange,
  shouldListenToDestinationUpdates,
  discountData,
  onDiscountCodeChange,
  onCollectionTitleChange,
}: {
  rootCreativeID: string | null;
  activeCreativeID: string | null;
  onActiveCreativeChange: (
    creative: GenCreactiveRecommendationsResponse[0]
  ) => void;
  shouldListenToDestinationUpdates: boolean;
  discountData: components["schemas"]["DiscountRequestData"] | null;
  onDiscountCodeChange: (discountCode: string) => void;
  onCollectionTitleChange: (collectionTitle: string) => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const queryClient = useQueryClient();

  const activeMessageRef = useRef<HTMLDivElement>(null);
  const [prompt, setPrompt] = useState("");

  // Functions
  const onSendPrompt = () => {
    genCreactiveRecommendationUpdate({
      brand_id: activeBrandID,
      creative_recommendation_id: nullthrows(rootCreativeID),
      should_generate_collection_title: shouldListenToDestinationUpdates,
      prompt: prompt,
      discount_data: discountData,
    });
    setPrompt("");
  };

  // Queries
  const { campaignRecommendationCreativeMessages } =
    useCampaignRecommendationCreativeMessagesQuery(
      activeBrandID,
      rootCreativeID
    );

  // Mutations
  const {
    mutate: genCreactiveRecommendationUpdate,
    isPending: isCreativeUpdating,
    isSuccess: isCreativeUpdateSuccess,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationUpdateResponse,
    GenCreactiveRecommendationUpdateRequestData
  >({
    mutationKey: ["/recommender/creative-recommendations/update"],
    path: "/recommender/creative-recommendations/update",
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
        context.previousCreative
      );
    },
    onSuccess: (data) => {
      if (data) {
        onActiveCreativeChange(data);
      }
    },
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
      });

      const previousCreative: CampaignRecommendationCreativeMessagesResponse =
        queryClient.getQueryData([
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ]) ?? [];

      const lastMessage =
        previousCreative.length > 0
          ? previousCreative[previousCreative.length - 1]
          : null;

      let newData = null;
      if (lastMessage) {
        newData = [
          ...previousCreative.slice(0, -1),
          {
            message: newMessage.prompt,
            recommendation: lastMessage.recommendation,
          },
          {
            message: null,
            recommendation: null,
          },
        ];
      } else {
        newData = [
          {
            message: null,
            recommendation: null,
          },
        ];
      }

      // Optimistically update to the new message
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
        newData
      );

      return { previousCreative };
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
      });
    },
  });

  // Effects
  useEffect(() => {
    if (activeMessageRef.current) {
      activeMessageRef.current.scrollTop =
        activeMessageRef.current.scrollHeight;
    }
  }, [activeMessageRef, campaignRecommendationCreativeMessages]);

  const promptComponent = (
    <Box width={"100%"}>
      <Label.Root htmlFor="prompt">
        <Text as="div" size="3" mb={"2"}>
          Adjust campaign idea
        </Text>
      </Label.Root>
      <TextField.Root
        id="prompt"
        type="text"
        placeholder={"eg. make the tone of voice more informal"}
        size="3"
        radius="large"
        value={prompt}
        onChange={(e) => setPrompt(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevents any default behavior like submitting forms
            onSendPrompt();
          }
        }}
        style={{
          height: "45px",
        }}
      >
        <TextField.Slot side="right">
          <IconButton
            variant="solid"
            disabled={prompt === "" || isCreativeUpdating}
            onClick={onSendPrompt}
          >
            <SendIcon color="white" size="20" />
          </IconButton>
        </TextField.Slot>
      </TextField.Root>
    </Box>
  );

  return (
    <Flex direction="column" gap="24px" style={{ maxHeight: "45vh" }}>
      <ScrollArea ref={activeMessageRef}>
        <Flex
          direction="column"
          gap="24px"
          pr="4"
          position={"relative"}
          align={"end"}
        >
          {(campaignRecommendationCreativeMessages ?? []).map((c) => {
            const creative = c.recommendation; // could be null from optimistic updating
            const message = c.message;
            return (
              <React.Fragment key={creative?.id ?? ""}>
                <RadioWithText
                  isActive={activeCreativeID === creative?.id}
                  onClick={() => onActiveCreativeChange(creative)}
                  key={creative?.id}
                  value={creative?.id}
                  title={creative?.title}
                  description={creative?.description}
                  collectionTitle={creative?.collection_title ?? null}
                  generatedDiscountCode={creative?.discount_code ?? null}
                  onDiscountCodeChange={onDiscountCodeChange}
                  onCollectionTitleChange={onCollectionTitleChange}
                />

                {message && (
                  <Box
                    style={{
                      borderRadius: "20px 8px 20px 20px",
                      border: "1px solid #DDD7D7",
                      padding: "20px",
                      backgroundColor: "#F0EDEB",
                    }}
                  >
                    {message}
                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </Flex>
      </ScrollArea>

      {promptComponent}
    </Flex>
  );
};

export default CampaignCreativeSelector;
