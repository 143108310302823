import { EmailSectionType, operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import Cookies from "js-cookie";

export type GenerateEmailSectionStatusRequestData =
  operations["emails_api_generate_email_section_status"]["parameters"]["query"];
export type GenerateEmailSectionStatusResponse =
  operations["emails_api_generate_email_section_status"]["responses"][200]["content"]["application/json"] & {
    error?: string;
  };
export type GenerateEmailSectionStatusProps = {
  brandId: string;
  jobSessionId: string | null;
  sectionType: EmailSectionType;
};

const useGenerateEmailSectionStatusQuery = ({
  brandId,
  jobSessionId,
  sectionType,
}: GenerateEmailSectionStatusProps) => {
  const { data, error } = useQuery<GenerateEmailSectionStatusResponse>({
    queryKey: [
      "emails_api_generate_email_section_status",
      brandId,
      jobSessionId,
    ],
    queryFn: async (): Promise<GenerateEmailSectionStatusResponse> => {
      const params: GenerateEmailSectionStatusRequestData = {
        brand_id: brandId,
        job_session_id: jobSessionId!,
        section_type: sectionType as any,
      };
      const response = await axios.get(
        "/api/v1/emails/section/generate-status",
        {
          params,
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    enabled: jobSessionId !== null,
    refetchInterval: 1500,
    retry: (failureCount, error) => {
      if (isAxiosError(error)) {
        // Network error (no response received)
        if (error.code === "ECONNABORTED" || !error.response) {
          return failureCount < 2;
        }
        // 404 might mean that the job hasn't been created yet
        if (
          error.response?.status === 404 ||
          (error.response?.status >= 500 && error.response?.status < 600)
        ) {
          return failureCount < 2;
        }
      }
      return false;
    },
    retryDelay: 1500,
    staleTime: Infinity,
  });

  if (error && !data?.section) {
    console.log("Error in fetching email section generation status", error);
    return {
      is_finished: true,
      error: "Failed to generate email section",
      section: null,
    };
  }
  return data;
};

export default useGenerateEmailSectionStatusQuery;
