import { CampaignUnifiedStatus, components } from "@openapi";
import { Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";
import DraperPill from "~/components/core/DraperPill";
import DraperText from "~/components/core/DraperText";

const StyledPill = styled(Flex)<{
  $backgroundColor: string;
  $borderColor: string;
  $textColor: string;
}>`
  border-radius: 32px;
  border-width: 1px;
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
  background: ${(props) => props.$backgroundColor};
  border: 1px solid ${(props) => props.$borderColor};
  color: ${(props) => props.$textColor};
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.5px;
  align-items: center;
  justify-content: center;
`;

interface HomeCampaignCardLabelsProps {
  status: CampaignUnifiedStatus;
}

const HomeCampaignCardLabels: React.FC<HomeCampaignCardLabelsProps> = ({
  status,
}) => {
  switch (status) {
    case CampaignUnifiedStatus.planned:
      return (
        <StyledPill
          $backgroundColor="#2F048A14"
          $borderColor="#DDDDDD"
          $textColor="#594E6F"
        >
          <DraperText
            size={"1"}
            weight={"regular"}
            clamp={1}
            style={{
              color: "inherit",
            }}
          >
            Planned
          </DraperText>
        </StyledPill>
      );
    case CampaignUnifiedStatus.in_progress:
      return (
        <StyledPill
          $backgroundColor="#2F048A0A"
          $borderColor="#2F048A1C"
          $textColor="#594E6F"
        >
          <DraperText
            size={"1"}
            weight={"regular"}
            clamp={1}
            style={{
              color: "inherit",
            }}
          >
            In Progress
          </DraperText>
        </StyledPill>
      );
    case CampaignUnifiedStatus.designed:
      return (
        <StyledPill
          $backgroundColor="#EDF7B51A"
          $borderColor="#9AD406"
          $textColor="#66BA00"
        >
          <DraperText
            size={"1"}
            weight={"regular"}
            clamp={1}
            style={{
              color: "inherit",
            }}
          >
            Designed
          </DraperText>
        </StyledPill>
      );
    case CampaignUnifiedStatus.launched:
      return (
        <StyledPill
          $backgroundColor="#31C1651A"
          $borderColor="#31C165"
          $textColor="#31C165"
        >
          <DraperText
            size={"1"}
            weight={"regular"}
            clamp={1}
            style={{
              color: "inherit",
            }}
          >
            Launched
          </DraperText>
        </StyledPill>
      );
    case CampaignUnifiedStatus.past:
      return (
        <StyledPill
          $backgroundColor="#2F048A14"
          $borderColor="#DDDDDD"
          $textColor="#594E6F"
        >
          <DraperText
            size={"1"}
            weight={"regular"}
            clamp={1}
            style={{ color: "inherit" }}
          >
            Past
          </DraperText>
        </StyledPill>
      );
    default:
      return null;
  }
};

export default HomeCampaignCardLabels;
