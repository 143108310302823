import DraperGeneratingLoading from "@core/DraperGeneratingLoading";
import FullscreenSpinner from "@core/FullscreenSpinner";
import { Flex } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCampaignGenerationStatusQuery from "~/hooks/campaign/useCampaignGenerationStatusQuery";
import { getCampaignDetailsQueryKey } from "~/hooks/campaign/useGetCampaignDetails";
import CampaignOverviewRouteBody from "~/routes/campaign/overview";

const CampaignOverviewV2 = () => {
  const queryClient = useQueryClient();
  const { id: campaignId } = useParams() as { id: string };

  const [progressValue, setProgressValue] = useState(1);
  const [isInitGenComplete, setIsInitGenComplete] = useState<boolean | null>(
    null
  );

  const { data, isLoading, isFetching, isPending, isError } =
    useCampaignGenerationStatusQuery(campaignId, {
      enabled: !isInitGenComplete,
    });

  useEffect(() => {
    if (data?.is_initial_generation_complete === false) {
      setIsInitGenComplete(false);
    } else if (data?.is_initial_generation_complete === true) {
      if (isInitGenComplete === false) {
        // This should mean that the campaign job has been completed
        // and we should update the campaign details
        queryClient.invalidateQueries({
          queryKey: getCampaignDetailsQueryKey(campaignId),
          refetchType: "all",
        });
      }
      setIsInitGenComplete(true);
    }
  }, [data?.is_initial_generation_complete]);

  useEffect(() => {
    if (progressValue < 99) {
      const timer = setTimeout(() => {
        // Calculate the next progress value by halving the remaining distance to 100
        const remaining = 100 - progressValue;
        const increment = remaining / 5;
        setProgressValue(progressValue + increment);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [progressValue]);

  if (isLoading || !data) return <FullscreenSpinner />;
  if (isError) return <div>Error loading campaign status</div>;

  // If generation is not complete, show the loading screen
  if (!data.is_initial_generation_complete) {
    return <DraperGeneratingLoading />;
  }

  return (
    <Flex
      direction="column"
      width="100%"
      height="100dvh"
      style={{ padding: "108px 24px 24px" }}
    >
      <CampaignOverviewRouteBody />
    </Flex>
  );
};

export default CampaignOverviewV2;
