import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import { Card, Container, DataList, Flex, Heading } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

interface Product {
  product_id: string;
  title: string;
  description: string;
  price: number;
  image_url: string;
}

interface Collection {
  collection_id: string;
  title: string;
  description: string;
  image_url: string | null;
}

interface ItemSet {
  id: string;
  created_at: string;
  execution_date: string;
  evaluation_date: string;
  type: string;
  lookback_days: number;
  products: Product[];
  collections: Collection[];
}

interface ApiResponse {
  item_sets: ItemSet[];
}

interface CampaignRecommendation {
  title: string;
  description: string;
}

export default function RecommenderRoute() {
  const [itemSets, setItemSets] = useState<ItemSet[]>([]);

  const [recommendations] = useState<{
    [key: string]: CampaignRecommendation[];
  }>({});

  const activeBrandID = useActiveBrandID();

  const { data, isLoading } = useQuery<ApiResponse>({
    queryKey: ["recommended-item-sets", activeBrandID],
    queryFn: async (): Promise<ApiResponse> => {
      const response = await axios.post(
        "/api/v1/recommender/get-recommended-item-sets",
        {
          brand_id: activeBrandID,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return response.data;
    },
    enabled: !!activeBrandID,
    retry: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data) {
      setItemSets(data.item_sets);
    }
  }, [data]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const formatDate = (dateString: string, timeZone: string = "UTC") => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
      timeZone: timeZone,
    }).format(date);
  };

  function toTitleCase(inputString: string) {
    return inputString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const handleEditClick = (itemSetId: string) => {
    const url = window.location.origin;
    const isLocalhost = url === "http://localhost:3000";
    const baseUrl = isLocalhost ? "http://localhost:8000" : url;
    const editPath = `${baseUrl}/admin/recommender/recommendeditemset/${itemSetId}/change/`;
    window.open(editPath);
  };

  const styles = {
    itemSet: {
      marginBottom: "20px",
    },
    itemScroll: {
      display: "flex",
      overflowX: "auto" as "auto",
      gap: "10px",
    },
    item: {
      width: "100%",
      maxWidth: "250px",
      border: "1px solid #ccc",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      textAlign: "center" as "center",
      flex: "0 0 auto" as "auto",
    },
    itemImage: {
      width: "100px",
      height: "100px",
      objectFit: "cover" as "cover",
      borderRadius: "5px",
    },
    itemTitle: {
      margin: "10px 0",
      overflow: "hidden",
      textOverflow: "ellipsis" as "ellipsis",
      whiteSpace: "nowrap" as "nowrap",
    },
    itemDescription: {
      margin: "5px 0",
      overflow: "hidden",
      textOverflow: "ellipsis" as "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical" as "vertical",
      height: "auto",
    },
    loading: {
      textAlign: "center" as "center",
      padding: "20px",
      fontSize: "18px",
    },
    recommendations: {
      marginTop: "10px",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "#f1f1f1",
    },
    recommendationTitle: {
      fontWeight: "bold" as "bold",
    },
    button: {
      padding: "8px 16px",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: "#007bff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      height: "30px",
    },
    buttonLoading: {
      backgroundColor: "#5a9bd6",
    },
    buttonIcon: {
      marginRight: "8px",
    },
    header: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  };

  return (
    <Container size="4">
      {isLoading ? (
        <div style={styles.loading}>Loading...</div>
      ) : (
        itemSets.map((itemSet) => (
          <Card key={itemSet.id} style={styles.itemSet}>
            <Flex direction="column" gap="16px" style={{ padding: "4px" }}>
              <Flex
                direction="row"
                justify="between"
                align="center"
                style={styles.header}
              >
                <Heading size="5">
                  {toTitleCase(itemSet.type.replace(/_/g, " "))}
                </Heading>
                <Flex gap="8px">
                  <button
                    onClick={() => handleEditClick(itemSet.id)}
                    style={styles.button}
                  >
                    {"Edit"}
                  </button>
                </Flex>
              </Flex>
              <div>
                <DataList.Root size="1">
                  <DataList.Item>
                    <DataList.Label minWidth="88px">ID</DataList.Label>
                    <DataList.Value>{itemSet.id}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label minWidth="88px">Created At</DataList.Label>
                    <DataList.Value>
                      {formatDate(itemSet.created_at)}
                    </DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label minWidth="88px">Executed At</DataList.Label>
                    <DataList.Value>
                      {formatDate(itemSet.execution_date)}
                    </DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label minWidth="88px">
                      Evaluated At
                    </DataList.Label>
                    <DataList.Value>
                      {formatDate(itemSet.evaluation_date)}
                    </DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label minWidth="88px">
                      Lookback Days
                    </DataList.Label>
                    <DataList.Value>{itemSet.lookback_days}</DataList.Value>
                  </DataList.Item>
                  <DataList.Item>
                    <DataList.Label minWidth="88px">Items</DataList.Label>
                    <DataList.Value>
                      {itemSet.products.length | itemSet.collections.length}
                    </DataList.Value>
                  </DataList.Item>
                </DataList.Root>
              </div>
              <div style={styles.itemScroll}>
                {itemSet.products.length > 0 &&
                  itemSet.products.map((product) => (
                    <div key={product.product_id} style={styles.item}>
                      <img
                        src={product.image_url}
                        alt={product.title}
                        style={styles.itemImage}
                      />
                      <h3 style={styles.itemTitle}>{product.title}</h3>
                      <p style={styles.itemDescription}>
                        {truncateText(product.description, 150)}
                      </p>
                      <p style={styles.itemDescription}>
                        ${product.price.toFixed(2)}
                      </p>
                    </div>
                  ))}
                {itemSet.collections.length > 0 &&
                  itemSet.collections.map((collection) => (
                    <div key={collection.collection_id} style={styles.item}>
                      <h3 style={styles.itemTitle}>{collection.title}</h3>
                      <p style={styles.itemDescription}>
                        {truncateText(collection.description, 150)}
                      </p>
                    </div>
                  ))}
              </div>
              {recommendations[itemSet.id] && (
                <div style={styles.recommendations}>
                  {recommendations[itemSet.id].map((recommendation, index) => (
                    <div key={index}>
                      <p style={styles.recommendationTitle}>
                        {recommendation.title}
                      </p>
                      <p>{recommendation.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </Flex>
          </Card>
        ))
      )}
    </Container>
  );
}
