import { Flex } from "@radix-ui/themes";
import React from "react";
import styled, { keyframes } from "styled-components";

const Preview = styled.img<{
  $maxWidth?: string;
  $maxHeight?: string;
  $showBorder?: boolean;
}>`
  padding: 16px;
  max-width: ${(props) => props.$maxWidth || "100px"};
  max-height: ${(props) => props.$maxHeight || "100px"};
  border: ${(props) => (props.$showBorder ? "1px dashed #ddd7d7" : "none")};
  border-radius: 6px;
  background-color: var(--background_light_grey_2);
`;

const imageAnimation = keyframes`
  0% { 
    top: 0;
    right: 0;
  }
  50% { 
    top: -100%;
    right: -100%;
  }
  100% {
    top: -200%;
    right: -200%;
  }
`;

const ImageLoadingOverlay = styled.div`
  position: absolute;
  width: 300%;
  height: 300%;

  background: linear-gradient(
    223.18deg,
    rgba(255, 255, 255, 0) 23.59%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 66.66%
  );

  animation: ${imageAnimation} 3s infinite;
`;

type ImagePreviewProps = {
  isLoading?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  showBorder?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const ImagePreview: React.FC<ImagePreviewProps> = ({
  isLoading = false,
  showBorder = true,
  ...props
}) => {
  return (
    <Flex position="relative" overflow="hidden" minWidth="fit-content">
      <Preview {...props} $showBorder={showBorder} />
      {isLoading && <ImageLoadingOverlay />}
    </Flex>
  );
};

export default ImagePreview;
