import DraperSegmentControl from "../core/DraperSegmentedControl";
import AppButton from "../core/buttons/AppButton/AppButton";
import LogoAssetsCardSection from "../style-library/assets/LogoAssetsCardSection";
import ButtonStylesCardSection from "../style-library/button-styles/ButtonStylesCardSection";
import ColorPaletteCardSection from "../style-library/color-palette/ColorPaletteCardSection";
import TypographyCardSection from "../style-library/typography/TypographyCardSection";
import OnboardingCard from "./OnboardingCard";
import {
  getIsLogoComplete,
  getIsColorPaletteComplete,
  getIsTypographyComplete,
  getIsButtonComplete,
} from "./OnboardingStyleLibraryLoading";
import { Flex, SegmentedControl, Text } from "@radix-ui/themes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XIcon } from "~/assets/icons";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandColorPalettesQuery, {
  BrandColorPalettesResponse,
} from "~/hooks/brand/useBrandColorPalettesQuery";
import { BrandStylingResponse } from "~/hooks/data/useBrandStyleQuery";
import { useOnboardingContext } from "~/routes/onboarding";

enum StyleTabStep {
  LOGO = "0",
  PALETTE = "1",
  TYPOGRAPHY = "2",
  BUTTON = "3",
}

interface StyleTab {
  step: StyleTabStep;
  title: string;
  component: React.ReactNode;
}

const isComplete = (
  step: StyleTabStep,
  data: BrandStylingResponse | BrandColorPalettesResponse | undefined
) => {
  if (!data) {
    return false;
  }

  switch (step) {
    case StyleTabStep.LOGO:
      return getIsLogoComplete(data as BrandStylingResponse);
    case StyleTabStep.PALETTE:
      return getIsColorPaletteComplete(data as BrandColorPalettesResponse);
    case StyleTabStep.TYPOGRAPHY:
      return getIsTypographyComplete(data as BrandStylingResponse);
    case StyleTabStep.BUTTON:
      return getIsButtonComplete(data as BrandStylingResponse);
  }
};

const StyleTabSteps: StyleTab[] = [
  {
    step: StyleTabStep.LOGO,
    title: "Logo",
    component: <LogoAssetsCardSection />,
  },
  {
    step: StyleTabStep.PALETTE,
    title: "Color Palette",
    component: <ColorPaletteCardSection />,
  },
  {
    step: StyleTabStep.TYPOGRAPHY,
    title: "Typography",
    component: <TypographyCardSection />,
  },
  {
    step: StyleTabStep.BUTTON,
    title: "Buttons",
    component: <ButtonStylesCardSection />,
  },
];

const StyleTabIcon: React.FC<{ isComplete: boolean }> = ({ isComplete }) => {
  return isComplete ? (
    <CheckIcon color="#000000" size={20} />
  ) : (
    <XIcon color="#000000" size={20} />
  );
};

const StyleLibraryReview = () => {
  const activeBrandID = useActiveBrandID();
  const [tab, setTab] = useState(StyleTabStep.LOGO);
  const { data } = useBrandStyle();
  const { colorPalettes } = useBrandColorPalettesQuery(activeBrandID);
  const { onNextOnboardingStep, isLoading: isLoadingNextOnboardingStep } =
    useOnboardingContext();

  const selectedTab = StyleTabSteps.find((step) => step.step === tab)!;

  const header = (
    <Flex justify="between" align="center" gap="24px" p="16px">
      <DraperSegmentControl
        $size="large"
        value={tab}
        defaultValue={StyleTabStep.LOGO}
        onValueChange={(value) => setTab(value as StyleTabStep)}
      >
        {StyleTabSteps.map((step) => (
          <SegmentedControl.Item key={step.step} value={step.step}>
            <Flex align="center" gap="2">
              <StyleTabIcon
                isComplete={isComplete(
                  step.step,
                  step.step === StyleTabStep.PALETTE ? colorPalettes : data
                )}
              />
              <Text weight="medium">{step.title}</Text>
            </Flex>
          </SegmentedControl.Item>
        ))}
      </DraperSegmentControl>
    </Flex>
  );

  const handleNext = () => {
    const selectedTabIndex = StyleTabSteps.findIndex(
      (step) => step.step === tab
    );
    const nextTab = StyleTabSteps[selectedTabIndex + 1];
    if (nextTab) {
      setTab(nextTab.step);
      return;
    }

    const firstIncompleteStep = StyleTabSteps.find(
      (step) =>
        !isComplete(
          step.step,
          step.step === StyleTabStep.PALETTE ? colorPalettes : data
        )
    );
    if (firstIncompleteStep) {
      setTab(firstIncompleteStep.step);
      return;
    }

    onNextOnboardingStep();
  };

  return (
    <Flex
      direction={"column"}
      justify={"start"}
      align={"center"}
      gap="6"
      overflowY="auto"
      py="4"
    >
      <OnboardingCard
        width="100%"
        maxWidth="70vw"
        primary={
          <AppButton
            variant="dark"
            disabled={
              !isComplete(
                selectedTab.step,
                selectedTab.step === StyleTabStep.PALETTE ? colorPalettes : data
              )
            }
            onClick={handleNext}
            loading={isLoadingNextOnboardingStep}
          >
            Continue
          </AppButton>
        }
        header={header}
      >
        {selectedTab.component}
      </OnboardingCard>
    </Flex>
  );
};

export default StyleLibraryReview;
