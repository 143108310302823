import ModifierContainer from "./ModifierContainer";
import { components, ImageAssetCategory } from "@openapi";
import { Grid, Spinner, Text } from "@radix-ui/themes";
import { useState } from "react";
import { toast } from "sonner";
import { PencilIcon } from "~/assets/icons";
import { AssetSelectorItem } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import ReviewSelectorDialog from "~/components/campaign/wizard/products/components/reviewSelector/ReviewSelectorDialog";
import { CustomerReview } from "~/components/campaign/wizard/products/components/reviewSelector/hooks/useGetProductReviews";
import SidePanelComponents from "~/components/core/editor/sidepane";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetEmailRelatedImages from "~/hooks/emails/useGetEmailRelatedImages";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isReviewSection } from "~/utils/emails/useSectionTypeCheck";

const ReviewModifier = ({
  reviewElement,
  label,
  enabled = false,
  onSwitchToggle,
  isLoading,
  onChangeReview,
  imageFieldName,
}: {
  reviewElement: components["schemas"]["EmailReviewsSectionSchema"]["reviews"][0];
  label: string;
  enabled?: boolean;
  onSwitchToggle?: (enabled: boolean) => void;
  isLoading: boolean;
  onChangeReview: (review: CustomerReview) => void;
  imageFieldName: string;
}) => {
  const activeBrandID = useActiveBrandID();
  const { selectedSectionId, sections, campaign_id } = useEmailState();
  const updateSection = useUpdateSectionField();
  const [isReviewSelectorOpen, setIsReviewSelectorOpen] = useState(false);

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  const hasSelectedReviewSection =
    selectedSection && isReviewSection(selectedSection);

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useUploadImageAssets({
      onSuccess(data) {
        onAssetChange(data.new_assets[0] as ImageAssetSchema);
      },
      showErrorToast: true,
    });

  if (!hasSelectedReviewSection) {
    return null;
  }

  const image = reviewElement.image;

  const onAssetChange = (asset: AssetSelectorItem) => {
    updateSection({
      sectionId: selectedSection.id,
      field: imageFieldName,
      value: asset.url,
    });
  };

  const handleAssetUpload = (file: File) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: getFormImageAssetCategory(ImageAssetCategory.other),
      images: [file as unknown as string],
    });
  };

  return (
    <ModifierContainer
      title={label}
      hideSwitch={false}
      checked={enabled}
      onSwitch={(checked) => {
        onSwitchToggle?.(checked);
      }}
      padding={enabled ? "3" : "0"}
    >
      {enabled && (
        <>
          <Grid gap="12px">
            <SidePanelComponents.Button
              variant="outlined"
              onClick={() => {
                setIsReviewSelectorOpen(true);
              }}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : <PencilIcon />}
              {isLoading ? "Changing" : "Change"} Review
            </SidePanelComponents.Button>

            <Text
              size={"3"}
              weight="medium"
              style={{ color: "var(--text_secondary, #4C4747)" }}
            >
              Image
            </Text>

            <ImageSelector
              campaignId={campaign_id}
              value={image.image_url}
              onSelect={(assets) => {
                updateSection({
                  sectionId: selectedSection.id,
                  field: imageFieldName,
                  value: assets[0].url,
                });
              }}
              onCrop={handleAssetUpload}
              isUploading={isUploading}
            />
          </Grid>

          <ReviewSelectorDialog
            key="view"
            isOpen={isReviewSelectorOpen}
            selectedProductId={selectedSection.selected_product_id ?? ""}
            onOpenChange={(open) => {
              if (!open) {
                setIsReviewSelectorOpen(false);
              }
            }}
            selectedReviewsIds={[reviewElement.review_id]}
            onSelectSingleReview={(review) => {
              onChangeReview(review);
              setIsReviewSelectorOpen(false);
            }}
            triggerComponent={<></>}
          />
        </>
      )}
    </ModifierContainer>
  );
};

export default ReviewModifier;
