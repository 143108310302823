import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

type GenerateCalendarResponse =
  operations["intern_api_generate_calendar"]["responses"][200]["content"]["application/json"];

type UseInternGenerateCalendarOptions = {
  onSuccess?: (response: GenerateCalendarResponse) => void;
  onError?: (error: Error) => void;
};

const useInternGenerateCalendar = (
  brandId: string | undefined,
  { onSuccess, onError }: UseInternGenerateCalendarOptions = {}
) => {
  const mutate = useMutation<GenerateCalendarResponse, Error, void>({
    mutationFn: async (): Promise<GenerateCalendarResponse> => {
      const { data } = await axios.post(
        "/api/v1/intern/calendar/generate",
        {
          brand_id: brandId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess,
    onError,
  });

  return mutate;
};

export default useInternGenerateCalendar;
