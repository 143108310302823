import {
  brandColorPalettesQueryKey,
  BrandColorPalettesResponse,
} from "./useBrandColorPalettesQuery";
import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { toast } from "sonner";

type DeleteColorPaletteResponse =
  operations["brand_api_delete_color_palette"]["responses"][200]["content"]["application/json"];

const useBrandColorPaletteDeleteMutation = ({
  brandID,
  paletteID,
  campaignID,
  onSuccess,
}: {
  brandID: string;
  paletteID: string;
  campaignID?: string;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<DeleteColorPaletteResponse, AxiosError>({
    mutationFn: async () => {
      const { data } = await axios.delete(
        `/api/v1/brand/${brandID}/stylebook/color_palette/${paletteID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      toast.success("Color palette deleted");
      onSuccess?.();

      [
        brandColorPalettesQueryKey(brandID),
        brandColorPalettesQueryKey(brandID, campaignID),
      ].forEach((queryKey) => {
        queryClient.setQueryData<BrandColorPalettesResponse>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;
            return oldData.filter((palette) => palette.id !== paletteID);
          }
        );
      });
    },
    onError: (error) => {
      toast.error("Failed to delete color palette");
    },
  });

  return {
    deleteBrandColorPalette: mutation.mutate,
    isLoading: mutation.isPending,
  };
};

export default useBrandColorPaletteDeleteMutation;
