import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const key = (
  emailCreativeId: string,
  getSlicedImages: boolean = false
) => ["email-creative-preview", emailCreativeId, getSlicedImages];

const useEmailCreativePreviewQuery = ({
  emailCreativeId,
  enabled,
  getSlicedImages = false,
}: {
  emailCreativeId: string;
  enabled: boolean;
  getSlicedImages: boolean;
}) => {
  const {
    data: previewHtml,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
    error,
  } = useQuery<string>({
    queryKey: key(emailCreativeId, getSlicedImages),
    queryFn: async (): Promise<string> => {
      const { data } = await axios.get<string>(
        `/api/v1/emails/email-creative/${emailCreativeId}/preview-html`,
        {
          params: {
            get_sliced_images: getSlicedImages,
          },
        }
      );
      return data;
    },
    enabled: enabled,
  });

  return {
    previewHtml: previewHtml ?? null,
    isLoading: isLoading || isFetching,
    isSuccess,
    error,
    refetch,
  };
};

export default useEmailCreativePreviewQuery;
