import ButtonModifier from "./ButtonModifier";
import ModifierContainer from "./ModifierContainer";
import SectionTextModifier from "./SectionTextModifier";
import { components, EmailSectionType, ImageAssetCategory } from "@openapi";
import { Flex, IconButton } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";
import { CropIcon, ImageIcon, PencilIcon } from "~/assets/icons";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { RelatedImageType } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/RelatedImages";
import DraperText from "~/components/core/DraperText";
import SidePanelComponents from "~/components/core/editor/sidepane";
import ArticleSelectorDialog from "~/components/dialogs/ArticleSelectorDialog";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionMutation";
import useUploadImageAssets, {
  getFormImageAssetCategory,
} from "~/hooks/style-library/useUploadImageAssets";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

const IconButtonContainer = styled(IconButton)`
  border-radius: 8px;
  padding: 8px;
`;

const BlogPostModifier = ({
  blogElement,
}: {
  blogElement: components["schemas"]["EmailBlogSectionSchema"];
}) => {
  const updateSection = useUpdateSectionField();
  const activeBrandID = useActiveBrandID();
  const { selectedSectionId, sections, campaign_id } = useEmailState();

  const [isAssetDialogOpen, setIsAssetDialogOpen] = useState(false);
  const [isCropImageDialogOpen, setIsCropImageDialogOpen] = useState(false);

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );
  const fileName = getFilenameFromUrl(blogElement.image.image_url);

  // Mutations
  const { mutate: initEmailSection, isPending } = useInitEmailSectionMutation();
  const { mutate: uploadImageAssets } = useUploadImageAssets({
    onSuccess: (data) => {
      updateSection({
        sectionId: selectedSectionId,
        field: `image.image_url`,
        value: data.new_assets[0].url,
      });
      setIsCropImageDialogOpen(false);
    },
    showErrorToast: true,
  });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      brand_id: activeBrandID,
      category: getFormImageAssetCategory(ImageAssetCategory.other),
      images: [file as unknown as string],
    });
  };

  if (!selectedSection) {
    return null;
  }

  const dialog = (
    <ArticleSelectorDialog
      onConfirm={(articleIds) => {
        initEmailSection({
          brand_id: activeBrandID,
          section_type: EmailSectionType.blog,
          id: selectedSection.id,
          index: selectedSection.index,
          generate: false,
          article_ids: articleIds,
        });
      }}
      triggerComponent={
        <SidePanelComponents.Button>
          <PencilIcon />
          Change Article
        </SidePanelComponents.Button>
      }
      limit={1}
    />
  );
  return (
    <ModifierContainer title="Blog Post" hideSwitch>
      {dialog}

      <Flex direction="column" gap="3">
        <SectionTextModifier
          textElement={blogElement.title}
          label="Title"
          fieldName="title.text"
        />
        <SectionTextModifier
          label="Description"
          textElement={blogElement.description}
          fieldName="description.text"
        />

        <Flex justify="between" align="center">
          <DraperText clamp={1} size={"2"}>
            Image
          </DraperText>
          <Flex gap="2">
            <IconButtonContainer
              color="gray"
              variant="outline"
              radius="large"
              onClick={() => setIsAssetDialogOpen(true)}
            >
              <ImageIcon />
            </IconButtonContainer>
            <IconButtonContainer
              color="gray"
              variant="outline"
              radius="large"
              onClick={() => setIsCropImageDialogOpen(true)}
            >
              <CropIcon />
            </IconButtonContainer>
          </Flex>
        </Flex>

        <ButtonModifier
          buttonElement={blogElement.cta_button}
          fieldName="cta_button"
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSectionId,
              field: "cta_button.enabled",
              value: enabled,
            });
          }}
        />
      </Flex>

      <AssetSelectorDialog
        singleAsset
        type={RelatedImageType.DEFAULT}
        campaignId={campaign_id}
        isDialogOpen={isAssetDialogOpen}
        onToggleDialogOpen={setIsAssetDialogOpen}
        onSelect={(assets) => {
          updateSection({
            sectionId: selectedSectionId,
            field: `image.image_url`,
            value: assets[0].url,
          });
          setIsAssetDialogOpen(false);
        }}
        preselectedAssets={[
          {
            url: blogElement.image.image_url,
            commerce_platform_item_id: null,
          },
        ]}
      />
      <CropImageDialog
        open={isCropImageDialogOpen}
        onOpenChange={setIsCropImageDialogOpen}
        img={blogElement.image.image_url}
        onCrop={(data: string) => {
          const file = dataURIToFile(data, (fileName ?? "") + "_cropped.png");
          handleUpload(file);
        }}
      />
    </ModifierContainer>
  );
};

export default BlogPostModifier;
