import { Button, Table } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { CircleCheck, CircleX } from "lucide-react";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useBrandUpdateMutation from "~/hooks/brand/useBrandUpdateMutation";
import useInternBrandsQuery, {
  InternBrandsResponse,
  getInternBrandsQueryKey,
} from "~/hooks/intern/useInternBrandsQuery";
import useInternCreateBrandAdminProfileMutation from "~/hooks/intern/useInternCreateBrandAdminProfileMutation";

const InternRootBrandRow = ({ brand }: { brand: InternBrandsResponse[0] }) => {
  const user = useAuthenticatedUserState();
  const userAdminBrands = user.brands;
  const queryClient = useQueryClient();

  const { updateBrand, isLoading: isUpdating } = useBrandUpdateMutation({
    brandID: brand.id!,
    onSuccess: (data, vars) => {
      queryClient.setQueryData(
        getInternBrandsQueryKey(),
        (old: InternBrandsResponse) => {
          return old.map((b) => {
            if (b.id === brand.id) {
              return { ...b, is_onboarded: vars.is_onboarded };
            }
            return b;
          });
        }
      );
    },
  });
  const {
    mutate: createBrandAdminProfile,
    isPending: isCreatingBrandAdminProfile,
  } = useInternCreateBrandAdminProfileMutation();

  let actionButton = null;

  if (userAdminBrands.find((b) => b.id === brand.id)) {
    actionButton = (
      <Button
        variant="outline"
        size="1"
        onClick={() => updateBrand({ is_onboarded: !brand.is_onboarded })}
        disabled={isUpdating}
        style={{ marginLeft: "8px" }}
      >
        {brand.is_onboarded ? "Mark Not Onboarded" : "Mark Onboarded"}
      </Button>
    );
  } else {
    actionButton = (
      <Button
        variant="outline"
        size="1"
        onClick={() => createBrandAdminProfile(brand.id!)}
        disabled={isCreatingBrandAdminProfile}
      >
        Become Admin
      </Button>
    );
  }

  return (
    <Table.Row key={brand.id}>
      <Table.Cell>{brand.name}</Table.Cell>
      <Table.Cell>
        {brand.is_onboarded ? (
          <CircleCheck size={16} color="green" />
        ) : (
          <CircleX size={16} color="red" />
        )}
      </Table.Cell>
      <Table.Cell>{actionButton}</Table.Cell>
    </Table.Row>
  );
};

const InternRootContainer = () => {
  const { brandsData, isLoading } = useInternBrandsQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Table.Root
      style={{
        border: "1px solid #e0e0e0",
        borderRadius: "12px",
        padding: "16px",
        backgroundColor: "#fff",
      }}
    >
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Is Onboarded</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {brandsData?.map((brand) => (
          <InternRootBrandRow key={brand.id} brand={brand} />
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default InternRootContainer;
