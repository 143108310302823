import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type EmailInsightsResponse =
  operations["emails_api_get_email_insights_stats"]["responses"][200]["content"]["application/json"];

const getOneYearAgo = () => {
  const today = new Date();
  const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));

  return oneYearAgo.toISOString().split("T")[0]; // formats to yyyy-MM-dd
};

const useFetchEmailInsights = (brandId: string, sinceDate?: Date | null) => {
  return useQuery({
    queryKey: ["emailInsights", brandId],
    queryFn: async (): Promise<EmailInsightsResponse> => {
      const { data } = await axios.get("/api/v1/emails/insights", {
        params: {
          brand_id: brandId,
          since: sinceDate ?? getOneYearAgo(),
        },
      });
      return data;
    },
    enabled: !!brandId,
  });
};

export default useFetchEmailInsights;
