import { key as emailTemplatesKey } from "@hooks/emails/useEmailTemplatesQuery";
import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type CreateEmailTemplateRequest =
  operations["intern_api_create_email_template"]["requestBody"]["content"]["application/json"];

export type CreateEmailTemplateResponse =
  operations["intern_api_create_email_template"]["responses"]["200"]["content"]["application/json"];

const useInternEmailTemplateCreateMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: CreateEmailTemplateResponse) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useDraperApiPostMutation<
    CreateEmailTemplateResponse,
    CreateEmailTemplateRequest
  >({
    mutationKey: ["internEmailTemplates"],
    path: `/intern/email-templates/`,
    onSuccess: (data) => {
      toast.success("Email template created");
      queryClient.invalidateQueries({ queryKey: [emailTemplatesKey] });
      onSuccess?.(data);
    },
    onError: () => {
      toast.error("Failed to create email template");
    },
  });

  return {
    createEmailTemplate: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternEmailTemplateCreateMutation;
