import { MaybeFeaturedProduct } from "../CampaignProducts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useCenterCampaignCard from "~/hooks/campaign/useCenterCampaignCard";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";
import useShopifyCollectionProductsQuery from "~/hooks/shopify/useShopifyCollectionProductsQuery";

const PAGE_SIZE = 9;

export const useCampaignProductsProps = (step: number) => {
  const dispatch = useCampaignWizardDispatch();
  const { wizardType, campaignData, isWizardStateInitialized, currentStep } =
    useCampaignWizardState();
  const destination = campaignData?.destination;
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const { centerCurrentCard } = useCenterCampaignCard({
    cardStep: step,
  });

  // State
  const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false);

  // Queries
  const { data: campaignDetails } = useGetCampaignDetails({
    campaignId: campaignData?.campaign_id ?? undefined,
  });

  const {
    campaignRecommendation,
    isLoading: isLoadingRecommendation,
    isSuccess: isSuccessRecommendation,
    isError: isErrorRecommendation,
  } = useCampaignRecommendationQuery(
    activeBrandID,
    wizardType === "RECOMMENDED" ? recommendationID ?? null : null
  );

  const {
    collectionProductsData,
    isLoading: isLoadingCollectionProducts,
    isSuccess: isSuccessCollectionProducts,
    isError: isErrorCollectionProducts,
  } = useShopifyCollectionProductsQuery(
    activeBrandID,
    wizardType === "NEW" ? destination?.collection_id ?? null : null
  );

  const isDataLoading = isLoadingCollectionProducts || isLoadingRecommendation;

  const [selectedProductsPage, setSelectedProductsPage] = useState(0);
  const description =
    isErrorCollectionProducts || isErrorRecommendation
      ? "There was an error loading the associated products. Please feel free to add products manually."
      : "Here are the products we've selected for the campaign. Feel free to make changes.";

  //products
  const selectProduct = (selectedProduct?: MaybeFeaturedProduct | null) => {
    if (!selectedProduct) {
      return;
    }
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(selectedProduct.product_id, selectedProduct);
      return updatedMap;
    });
  };
  const selectProducts = (
    selectedProducts: Map<string, MaybeFeaturedProduct>
  ) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map([...prev, ...selectedProducts]);
      return updatedMap;
    });
    centerCurrentCard();
  };
  const handleDeselect = (id: string) => {
    if (!selectedProducts.has(id)) {
      return;
    }
    handleShowAlert(selectedProducts.get(id));
    const lastPage = Math.floor((selectedProducts.size - 2) / PAGE_SIZE);
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
    if (selectedProductsPage > lastPage) {
      setSelectedProductsPage((prev) => Math.max(0, prev - 1));
    }
    centerCurrentCard();
  };
  const [selectedProducts, setSelectedProducts] = useState<
    Map<string, MaybeFeaturedProduct>
  >(new Map<string, MaybeFeaturedProduct>([]));
  const [isSelectedProductsStateReady, setIsSelectedProductsStateReady] =
    useState(false);
  useEffect(() => {
    if (!isWizardStateInitialized) {
      return;
    }
    console.log("initializing wizard state", campaignData);
    setSelectedProducts(
      new Map(
        campaignData.products?.map((product) => [
          product.product_id,
          product,
        ]) ?? []
      )
    );
    // a way to go around the 2 separate states updating each other, without doing more serious refactoring
    //  basically, we use this to avoid resetting the initial state of the products
    //  Alternatively, might work to move state updates on button click/moving of the current step instead
    setIsSelectedProductsStateReady(true);
  }, [isWizardStateInitialized]);

  const onFeatureToggle = (product: MaybeFeaturedProduct) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(product.product_id, {
        ...product,
        is_featured: !product.is_featured,
      });
      return updatedMap;
    });
  };

  //repopulating the state of selected products only once, and under the conditions above
  useEffect(() => {
    if (
      !isSelectedProductsStateReady ||
      (!isSuccessCollectionProducts && destination?.collection_id) ||
      wizardType !== "NEW" ||
      currentStep !== step ||
      campaignData.products?.length
    ) {
      return;
    }
    // If there is a campaign id, we need to repopulate the state with the campaign products
    if (campaignDetails?.items?.length) {
      selectProducts(
        new Map(
          campaignDetails.items.map((product) => [
            product.product_id,
            {
              ...product,
              is_featured: false,
            },
          ])
        )
      );
    } else {
      // Otherwise, we repopulate the state with the collection products
      selectProducts(
        new Map(
          collectionProductsData?.products.map((product) => [
            product.product_id,
            {
              ...product,
              is_featured: false,
            },
          ])
        )
      );
    }
  }, [isSelectedProductsStateReady, collectionProductsData, currentStep]);

  //repopulating the state of selected products only once, and under the conditions above
  useEffect(() => {
    if (
      !isSelectedProductsStateReady ||
      !isSuccessRecommendation ||
      wizardType !== "RECOMMENDED" ||
      campaignData.products?.length
    ) {
      return;
    }
    selectProducts(
      new Map(
        campaignRecommendation?.products?.map((product) => [
          product.product_id,
          {
            ...product,
            is_featured: false,
          },
        ])
      )
    );
  }, [isSelectedProductsStateReady, campaignRecommendation]);

  useEffect(() => {
    // Skip if the wizard has already been prepopulated with products
    if (!isSelectedProductsStateReady || campaignData.products?.length) {
      return;
    }
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        products: Array.from(selectedProducts.values()),
      },
    });
  }, [selectedProducts, dispatch, isSelectedProductsStateReady]);

  //popup
  const [showAlert, setShowAlert] = useState(false);
  const [removedProduct, setRemovedProduct] =
    useState<MaybeFeaturedProduct | null>();
  const [timeoutTimeId, setTimeoutTimeId] = useState<NodeJS.Timeout>();

  const handleShowAlert = (product?: MaybeFeaturedProduct) => {
    setRemovedProduct(product);
    setShowAlert(true);
    clearTimeout(timeoutTimeId);
    const timeId = setTimeout(() => {
      setRemovedProduct(null);
      setShowAlert(false);
    }, 3000);
    setTimeoutTimeId(timeId);
  };
  const onUndo = () => {
    selectProduct(removedProduct);
    setRemovedProduct(null);
    setShowAlert(false);
  };
  const handleConfirmProducts = (
    newProducts: Map<string, MaybeFeaturedProduct>
  ) => {
    setSelectedProducts(newProducts);
    setSelectedProductsPage(Math.floor((newProducts.size - 1) / PAGE_SIZE));
    setIsProductSelectorOpen(false);
    centerCurrentCard();
  };

  return {
    isProductSelectorOpen,
    setIsProductSelectorOpen,
    selectedProducts,
    selectedProductsPage,
    setSelectedProductsPage,
    handleDeselect,
    onFeatureToggle,
    isDataLoading,
    description,
    showAlert,
    removedProduct,
    onUndo,
    handleConfirmProducts,
  };
};
