import {
  CampaignCalendarResponse,
  getCampaignHomeCalendarQueryKey,
} from "./useCampaignHomeCalendarQuery";
import {
  CampaignHomeResponse,
  getCampaignHomeQueryKey,
  invalidateCampaignHomeQueryCount,
} from "./useCampaignHomeQuery";
import { components, operations } from "@openapi";
import {
  InfiniteData,
  QueryClient,
  useQueryClient,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import { toast } from "sonner";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  getCampaignUnifiedStatus,
  getCampaignUnifiedStatusFromRecommendation,
} from "~/utils/campaign/utils";
import { useDraperApiPatchMutation } from "~/utils/useDraperMutation";

export type CampaignUpdateRequestData =
  operations["campaign_api_update_campaign"]["requestBody"]["content"]["application/json"];

export type CampaignUpdateResponse =
  operations["campaign_api_update_campaign"]["responses"][200]["content"]["application/json"];

const useCampaignUpdateMutation = ({
  onSuccess,
  onMutate,
  onError,
  onSettled,
}: {
  onSuccess?: (
    data: CampaignUpdateResponse,
    vars: CampaignUpdateRequestData,
    context: any
  ) => void;
  onMutate?: (params: CampaignUpdateRequestData) => void;
  onSettled?: (
    data: CampaignUpdateResponse | undefined,
    error: Error | null,
    variables: CampaignUpdateRequestData,
    context: any
  ) => void;
  onError?: (
    error: Error,
    variables: CampaignUpdateRequestData,
    context: any
  ) => void;
}) => {
  return useDraperApiPatchMutation<
    CampaignUpdateResponse,
    CampaignUpdateRequestData
  >({
    mutationKey: ["campaign-update"],
    path: `/campaign/`,
    onSuccess,
    onSettled,
    onMutate,
    onError,
  });
};

export default useCampaignUpdateMutation;

export const getCampaignUpdateOnMutate = (
  campaignID: string,
  queryClient: QueryClient,
  activeBrandID: string,
  date?: string | null
) => {
  const oldCampaignsHomePageQueries = queryClient.getQueriesData<
    InfiniteData<CampaignHomeResponse>
  >({
    queryKey: getCampaignHomeQueryKey(activeBrandID, false),
    type: "all",
    exact: false,
  });

  const oldCampaignsCalendarQueries =
    queryClient.getQueriesData<CampaignCalendarResponse>({
      queryKey: getCampaignHomeCalendarQueryKey(activeBrandID),
      type: "all",
      exact: false,
    });

  let oldCampaign = null;

  for (const query of oldCampaignsHomePageQueries) {
    for (const page of query[1]?.pages ?? []) {
      for (const campaign of page.campaigns) {
        if (campaign.id === campaignID) {
          oldCampaign = campaign;
        }
      }
    }
  }
  for (const query of oldCampaignsCalendarQueries) {
    for (const campaign of query[1]?.campaigns ?? []) {
      if (campaign.id === campaignID) {
        oldCampaign = campaign;
      }
    }
  }

  queryClient.setQueriesData(
    {
      queryKey: getCampaignHomeQueryKey(activeBrandID, false),
      type: "all",
      exact: false,
    },
    (oldData: any) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        pages: oldData.pages.map((page: any) => {
          return {
            ...page,
            campaigns: page.campaigns.map((c: any) => {
              if (c.id === campaignID) {
                oldCampaign = c;
                return {
                  ...c,
                  date,
                };
              }
              return c;
            }),
          };
        }),
      };
    }
  );

  queryClient.setQueriesData(
    {
      queryKey: getCampaignHomeCalendarQueryKey(activeBrandID),
      type: "all",
      exact: false,
    },
    (oldData: any) => {
      if (!oldData) return oldData;

      return {
        campaigns: oldData.campaigns.map((c: any) => {
          if (c.id === campaignID) {
            return {
              ...c,
              date,
            };
          }
          return c;
        }),
      };
    }
  );

  return {
    oldCampaignsHomePageQueries,
    oldCampaignsCalendarQueries,
    oldUnifiedStatus: oldCampaign
      ? "type" in oldCampaign
        ? getCampaignUnifiedStatus(
            oldCampaign as components["schemas"]["CampaignSchema"]
          )
        : getCampaignUnifiedStatusFromRecommendation(
            oldCampaign as components["schemas"]["CampaignRecommendationSchema"]
          )
      : undefined,
  };
};

export const getCampaignUpdateOnSuccess = (
  data: any,
  vars: any,
  context: any,
  queryClient: QueryClient,
  activeBrandID: string
) => {
  console.log("getCampaignUpdateOnSuccess", data, vars, context);
  // CALENDAR QUERY MANAGEMENT
  if (context.oldCampaignsCalendarQueries) {
    // For each query, find the keys that contain the new data.date
    // and update the query data
    for (const query of context.oldCampaignsCalendarQueries) {
      const queryKey = query[0];
      const queryData = query[1];

      // if key has length 4 and the last two values are dates, then we need to update the query data
      if (
        queryKey.length === 4 &&
        typeof queryKey[2] === "string" &&
        typeof queryKey[3] === "string" &&
        !isNaN(Date.parse(queryKey[2])) &&
        !isNaN(Date.parse(queryKey[3]))
      ) {
        // These are valid date strings in the query key
        const startDate = dayjs(queryKey[2]).startOf("day");
        const endDate = dayjs(queryKey[3]).startOf("day");
        const campaignDate = dayjs(data.date).startOf("day");

        // Check if the campaign's date falls within this range
        if (
          data.date &&
          startDate.isBefore(campaignDate) &&
          endDate.isAfter(campaignDate)
        ) {
          // Update this query data
          const campaignExists = queryData.campaigns.some(
            (c: any) => c.id === data.id
          );
          if (campaignExists) {
            queryClient.setQueryData(queryKey, {
              ...queryData,
              campaigns: queryData.campaigns.map((c: any) => {
                if (c.id === data.id) {
                  return data;
                }
                return c;
              }),
            });
          } else {
            queryClient.setQueryData(queryKey, {
              ...queryData,
              campaigns: [...queryData.campaigns, data],
            });
          }
        }
      }
    }

    queryClient.setQueriesData(
      {
        queryKey: getCampaignHomeCalendarQueryKey(activeBrandID),
        type: "all",
        exact: false,
      },
      (oldData: any) => {
        if (!oldData) return oldData;

        return {
          campaigns: oldData.campaigns.map((c: any) => {
            if (c.id === data.id) {
              return data;
            }
            return c;
          }),
        };
      }
    );
  }

  // HOME PAGE QUERY MANAGEMENT
  const newUnifiedStatus =
    "type" in data
      ? getCampaignUnifiedStatus(data)
      : getCampaignUnifiedStatusFromRecommendation(data);
  // If the unified status has changed, we need to remove it from the old unified status and
  // add it to the new unified status
  if (newUnifiedStatus !== context.oldUnifiedStatus) {
    // Remove the campaign from the old unified status
    queryClient.setQueryData(
      getCampaignHomeQueryKey(
        activeBrandID,
        false,
        context.oldUnifiedStatus ?? undefined
      ),

      (oldData: any) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => {
            return {
              ...page,
              campaigns: page.campaigns.filter((c: any) => c.id !== data.id),
            };
          }),
        };
      }
    );

    queryClient.setQueryData(
      getCampaignHomeQueryKey(
        activeBrandID,
        false,
        newUnifiedStatus ?? undefined
      ),
      (old: InfiniteData<CampaignHomeResponse> | undefined) => {
        if (!old) return old;
        const sortByDate = (
          a:
            | components["schemas"]["CampaignSchema"]
            | components["schemas"]["CampaignRecommendationSchema"],
          b:
            | components["schemas"]["CampaignSchema"]
            | components["schemas"]["CampaignRecommendationSchema"]
        ) => {
          // Handle null dates by putting them at the beginning
          if (!a.date && !b.date) return 0;
          if (!a.date) return -1;
          if (!b.date) return 1;
          // If both have dates, compare them normally (oldest to newest)
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        };

        // Flatten all campaigns from all pages
        let allCampaigns = old.pages.flatMap((page) => page.campaigns);

        // Remove the campaign from its current position
        allCampaigns = allCampaigns.filter(
          (campaign) => campaign.id !== data.id
        );

        // Add the updated campaign
        allCampaigns.push(data);

        // Sort all campaigns
        allCampaigns.sort(sortByDate);

        // If new campaign is the last one, skip this update as it should come in the next page
        const lastCampaign = allCampaigns?.[allCampaigns.length - 1];
        if (lastCampaign?.id === data.id) {
          return old;
        }

        // Calculate items per page based on first page
        const itemsPerPage = old.pages[0].campaigns.length;

        // Redistribute campaigns across pages, push
        const newPages = [];
        for (let i = 0; i < old.pages.length; i++) {
          const pageCampaigns = allCampaigns.slice(
            i * itemsPerPage,
            (i + 1) * itemsPerPage
          );
          newPages.push({
            campaigns: pageCampaigns,
            has_more: true, // TODO: Check if this is correct
            total_count: null,
          });
        }

        return {
          ...old,
          pages: newPages,
        };
      }
    );
  } else {
    // Else just update the campaign data in the existing queries
    queryClient.setQueriesData(
      {
        queryKey: getCampaignHomeQueryKey(activeBrandID, false),
        type: "all",
        exact: false,
      },
      (oldData: any) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => {
            return {
              ...page,
              campaigns: page.campaigns.map((c: any) => {
                if (c.id === data.id) {
                  return data;
                }
                return c;
              }),
            };
          }),
        };
      }
    );
  }
};

export const getCampaignUpdateOnError = (
  error: Error,
  variables: any,
  context: any,
  queryClient: QueryClient
) => {
  if (context.oldCampaignsHomePageQueries) {
    context.oldCampaignsHomePageQueries.forEach(
      ([queryKey, queryData]: any) => {
        queryClient.setQueryData(queryKey, queryData);
      }
    );
  }

  if (context.oldCampaignsCalendarQueries) {
    context.oldCampaignsCalendarQueries.forEach(
      ([queryKey, queryData]: any) => {
        queryClient.setQueryData(queryKey, queryData);
      }
    );
  }
};

export const getCampaignUpdateOnSettled = (
  data: any,
  error: Error | null,
  variables: any,
  context: any,
  queryClient: QueryClient,
  activeBrandID: string
) => {
  if (!data) return;

  if (context.oldUnifiedStatus) {
    invalidateCampaignHomeQueryCount(
      activeBrandID,
      queryClient,
      context.oldUnifiedStatus
    );
  }

  const newUnifiedStatus =
    "type" in data
      ? getCampaignUnifiedStatus(data)
      : getCampaignUnifiedStatusFromRecommendation(data);
  if (newUnifiedStatus && newUnifiedStatus !== context.oldUnifiedStatus) {
    invalidateCampaignHomeQueryCount(
      activeBrandID,
      queryClient,
      newUnifiedStatus
    );
  }
};

export const useCampaignUpdateMutationInHomePage = ({
  onSuccess,
  onMutate,
  onError,
}: {
  onSuccess?: (
    data: CampaignUpdateResponse,
    vars: CampaignUpdateRequestData,
    context: any
  ) => void;
  onMutate?: (params: CampaignUpdateRequestData) => void;
  onError?: (
    error: Error,
    vars: CampaignUpdateRequestData,
    context: any
  ) => void;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();

  const { mutate: updateCampaign, isPending: isUpdatingCampaign } =
    useCampaignUpdateMutation({
      onMutate: (params) => {
        return getCampaignUpdateOnMutate(
          params.campaign_id,
          queryClient,
          activeBrandID,
          params.date
        );
      },
      onSuccess: (data, vars, context) => {
        onSuccess?.(data, vars, context);
        getCampaignUpdateOnSuccess(
          data,
          vars,
          context,
          queryClient,
          activeBrandID
        );
      },
      onError: (error, variables, context) => {
        toast.error("Error updating campaign");

        getCampaignUpdateOnError(error, variables, context, queryClient);
      },
      onSettled: (data, error, variables, context) => {
        getCampaignUpdateOnSettled(
          data,
          error,
          variables,
          context,
          queryClient,
          activeBrandID
        );
      },
    });

  return { updateCampaign, isUpdatingCampaign };
};
