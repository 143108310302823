import { components } from "@openapi";
import { operations } from "@openapi";
import * as Tooltip from "@radix-ui/react-tooltip";
import {
  Box,
  Button,
  Dialog,
  Flex,
  Heading,
  Select,
  Text,
  Table,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { CheckCircleIcon, LoaderIcon, Plus, XCircleIcon } from "lucide-react";
import { useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type CreateBrandPipelineJobRequestBody =
  operations["brand_api_create_brand_pipeline_job"]["requestBody"]["content"]["application/json"];

type CreateBrandPipelineJobResponse =
  operations["brand_api_create_brand_pipeline_job"]["responses"][200]["content"]["application/json"];

type BrandPipelineType = components["schemas"]["BrandPipelineType"];

const BrandPipelineTypes = {
  onboarding: "onboarding" as BrandPipelineType,
};

const styles = {
  tableContainer: {
    border: "1px solid lightgray",
    borderRadius: "8px",
    padding: "4px",
    overflow: "auto",
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "bold",
    align: "center",
  },
  tableText: {
    fontSize: "12px",
    whiteSpace: "normal",
    align: "center",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    width: "100%",
    height: "100%",
  },
};

interface InternBrandPipelinesCardProps {
  parentState: number;
  refresh: () => void;
}

const InternBrandPipelinesCard: React.FC<InternBrandPipelinesCardProps> = ({
  refresh,
}) => {
  const activeBrandID = useActiveBrandID();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [selectedPipelineType, setSelectedPipelineType] =
    useState<BrandPipelineType>(BrandPipelineTypes.onboarding);

  // const { data } = useQuery({
  //   queryKey: ["brand-pipelines-status", activeBrandID],
  //   queryFn: async (): Promise<GetBrandPipelinesStatusResponse> => {
  //     const { data } = await axios.get(
  //       `/api/v1/brand/${activeBrandID}/pipelines/status`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-CSRFToken": Cookies.get("csrftoken") ?? "",
  //         },
  //       }
  //     );
  //     return data;
  //   },
  //   retry: true,
  //   staleTime: 3600,
  // });

  const { mutate: createPipelineJobMutation } = useMutation<
    CreateBrandPipelineJobResponse,
    AxiosError,
    CreateBrandPipelineJobRequestBody
  >({
    mutationFn: async (
      requestBody
    ): Promise<CreateBrandPipelineJobResponse> => {
      const { data } = await axios.post(
        `/api/v1/brand/${activeBrandID}/pipeline`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (response) => {
      setIsDialogOpen(false);
      if (response.success) {
        refresh();
        alert("Pipeline job successfully started.");
      } else {
        alert(response.error);
      }
    },
    onError: (error) => {
      setIsDialogOpen(false);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      alert(`Error: ${errorMessage}`);
    },
  });

  const renderRowWithTooltip = (
    name: string,
    status: string,
    lastRunAt: string | null
  ) => {
    const formatDate = (dateString: string | null) => {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    };
    const getStatusIcon = (status: string) => {
      switch (status.toLowerCase()) {
        case "good":
          return <CheckCircleIcon size="16px" color="green" />;
        case "bad":
          return <XCircleIcon size="16px" color="red" />;
        default:
          return <LoaderIcon size="16px" />;
      }
    };
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Table.Row>
            <Table.Cell style={styles.tableText}>{name}</Table.Cell>
            <Table.Cell style={styles.tableText}>
              {getStatusIcon(status)}
            </Table.Cell>
          </Table.Row>
        </Tooltip.Trigger>
        <Tooltip.Content side="top">
          <div
            style={{
              padding: "8px",
              background: "white",
              border: "solid 1px #dfdfdf",
              borderRadius: "4px",
            }}
          >
            <Text size="1">Last run at: {formatDate(lastRunAt)}</Text>
          </div>
        </Tooltip.Content>
      </Tooltip.Root>
    );
  };

  return (
    <Box height="100%">
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Content>
          <Dialog.Title>Create a Pipeline Job</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Select the type of pipeline job you'd like to create for the
            currently selected brand. Progress will be tracked via Jobs.
          </Dialog.Description>
          <Flex>
            <Select.Root
              defaultValue={selectedPipelineType}
              onValueChange={(value) =>
                setSelectedPipelineType(value as BrandPipelineType)
              }
            >
              <Select.Trigger style={{ width: "100%" }} />
              <Select.Content>
                <Select.Group>
                  {Object.values(BrandPipelineTypes).map((option) => (
                    <Select.Item key={option} value={option}>
                      {option}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                createPipelineJobMutation({
                  brand_id: activeBrandID,
                  pipeline_type: selectedPipelineType,
                });
              }}
            >
              Create
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
      <Flex direction="column" height="100%" gap="18px">
        <Flex justify="between">
          <Heading size="5">Pipelines</Heading>
          <Flex gap="8px">
            <Button onClick={() => setIsDialogOpen(true)}>
              <Plus size="18px" />
            </Button>
            <Button
              onClick={() => window.open("https://mage.getkinn.com", "_blank")}
            >
              Open Mage
            </Button>
          </Flex>
        </Flex>
        <Flex direction="column" flexGrow="1">
          <div style={styles.grid}>
            <Flex direction="column" gap="8px">
              <Heading size="2">Injestion</Heading>
              <Flex
                direction="column"
                style={styles.tableContainer}
                flexGrow="1"
              >
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      {["Pipeline", "Status"].map((header, headerIndex) => (
                        <Table.Cell
                          key={headerIndex}
                          style={styles.tableHeader}
                        >
                          {header}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderRowWithTooltip(
                      "Shopify",
                      "loading",
                      "loading"
                      // data ? data.shopify.status : "loading",
                      // data?.shopify?.last_run_at || "loading"
                    )}
                    {renderRowWithTooltip(
                      "Meta",
                      "loading",
                      "loading"
                      // data ? data.meta.status : "loading",
                      // data?.meta?.last_run_at || "loading"
                    )}
                    {renderRowWithTooltip(
                      "Klaviyo",
                      "loading",
                      "loading"
                      // data ? data.klaviyo.status : "loading",
                      // data?.klaviyo?.last_run_at || "loading"
                    )}
                  </Table.Body>
                </Table.Root>
              </Flex>
            </Flex>
            <Flex direction="column" gap="8px">
              <Heading size="2">Recommender</Heading>
              <Flex
                direction="column"
                style={styles.tableContainer}
                flexGrow="1"
              >
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                      {["Pipeline", "Status"].map((header, headerIndex) => (
                        <Table.Cell
                          key={headerIndex}
                          style={styles.tableHeader}
                        >
                          {header}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderRowWithTooltip(
                      "Recommender",
                      "loading",
                      "loading"
                      // data ? data.recommender.status : "loading",
                      // data?.recommender?.last_run_at || "loading"
                    )}
                  </Table.Body>
                </Table.Root>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InternBrandPipelinesCard;
