import CloseIconButton from "../core/buttons/CloseIconButton";
import PalettePicker from "../core/inputs/PalettePicker";
import ColorBox from "../style-library/color-palette/ColorBox";
import { components } from "@openapi";
import { Flex, Dialog, Spinner, Box, Checkbox, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandColorPaletteCreateMutation from "~/hooks/brand/useBrandColorPaletteCreateMutation";
import useBrandColorPaletteDeleteMutation from "~/hooks/brand/useBrandColorPaletteDeleteMutation";
import useBrandColorPaletteUpdateMutation from "~/hooks/brand/useBrandColorPaletteUpdateMutation";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const EMPTY_PALETTE: components["schemas"]["ColorPaletteSchema"] = {
  id: null,
  background: "#FFFFFF",
  foreground: "#000000",
  accent_background: "#000000",
  accent_foreground: "#FFFFFF",
};

const ColorPaletteDialog = ({
  activePalette,
  isOpen,
  onOpenChange,
  hideDeleteButton = false,
  showSaveToStyleLibraryOption = false,
}: {
  activePalette?: components["schemas"]["ColorPaletteSchema"];
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  hideDeleteButton?: boolean;
  showSaveToStyleLibraryOption?: boolean;
}) => {
  const activeBrandID = useActiveBrandID();

  const { campaign_id } = useEmailState();

  const [palette, setPalette] = useState<
    components["schemas"]["ColorPaletteSchema"]
  >(activePalette ?? EMPTY_PALETTE);

  const [saveToStyleLibraryEnabled, setSaveToStyleLibraryEnabled] =
    useState(false);

  useEffect(() => {
    if (!isOpen) {
      setPalette(EMPTY_PALETTE);
      setSaveToStyleLibraryEnabled(false);
    } else if (activePalette) {
      setPalette(activePalette);
    }
  }, [isOpen, activePalette]);

  const { createBrandColorPalette, isLoading: isCreating } =
    useBrandColorPaletteCreateMutation({
      brandID: activeBrandID,
      campaignID: saveToStyleLibraryEnabled
        ? undefined
        : campaign_id || undefined,
      onSuccess: () => {
        onOpenChange(false);
        toast.success("Color palette created");
      },
    });

  const { updateBrandColorPalette, isLoading: isUpdating } =
    useBrandColorPaletteUpdateMutation({
      brandID: activeBrandID,
      campaignID: campaign_id,
      onSuccess: () => {
        onOpenChange(false);
        toast.success("Color palette updated");
      },
    });

  const { deleteBrandColorPalette, isLoading: isDeleting } =
    useBrandColorPaletteDeleteMutation({
      brandID: activeBrandID,
      paletteID: palette.id!,
      campaignID: campaign_id,
      onSuccess: () => {
        onOpenChange(false);
      },
    });

  const isLoading = isCreating || isUpdating || isDeleting;

  const saveToStyleLibraryCheckbox =
    showSaveToStyleLibraryOption && !palette?.id ? (
      <Flex align="center" p="16px" direction="row" gap="2">
        <Checkbox
          size="3"
          style={{ borderRadius: "8px" }}
          checked={saveToStyleLibraryEnabled}
          onClick={() =>
            setSaveToStyleLibraryEnabled(!saveToStyleLibraryEnabled)
          }
        />
        <Text>Save color palette to Style Library</Text>
      </Flex>
    ) : null;

  const header = (
    <Flex
      justify="between"
      align="center"
      p="16px"
      style={{ borderBottom: "1px solid #E2E2E2" }}
    >
      <Dialog.Title size="4" weight="medium" mb="0">
        {palette?.id ? "Edit Color Palette" : "Add Color Palette"}
      </Dialog.Title>
      <Dialog.Close disabled={isLoading}>
        <CloseIconButton />
      </Dialog.Close>
    </Flex>
  );
  const content = (
    <Flex direction="column" pb="24px" px="0">
      <Flex
        mb="24px"
        style={{
          borderBottom: "1px solid #E2E2E2",
          backgroundColor: "#F0EDEB",
        }}
        p="4"
        align="center"
        justify="center"
      >
        <PalettePicker palette={palette} />
      </Flex>

      <Flex direction="row" gap="4" p="16px" wrap="wrap">
        <ColorBox
          key={0}
          color={palette.background}
          label="Background"
          disabled={isLoading}
          onChange={(color) => {
            setPalette({
              ...palette,
              background: color,
            });
          }}
          colorPickerSide={"right"}
        />
        <ColorBox
          key={1}
          color={palette.foreground}
          label="Foreground"
          disabled={isLoading}
          onChange={(color) => {
            setPalette({
              ...palette,
              foreground: color,
            });
          }}
          colorPickerSide="right"
        />
        <ColorBox
          key={2}
          color={palette.accent_background}
          label="Accent Background"
          disabled={isLoading}
          onChange={(color) => {
            setPalette({
              ...palette,
              accent_background: color,
            });
          }}
          colorPickerSide="right"
        />
        <ColorBox
          key={3}
          color={palette.accent_foreground}
          label="Accent Foreground"
          disabled={isLoading}
          onChange={(color) => {
            setPalette({
              ...palette,
              accent_foreground: color,
            });
          }}
          colorPickerSide="right"
        />
      </Flex>

      {saveToStyleLibraryCheckbox}
    </Flex>
  );

  let saveButtonText = isCreating ? "Creating" : "Create Palette";
  if (palette?.id) {
    saveButtonText = isUpdating ? "Updating" : "Update Palette";
  }
  const footer = (
    <Flex
      ml="auto"
      gap="3"
      p="24px"
      style={{ borderTop: "1px solid #DDD7D7" }}
      justify={"between"}
    >
      {!hideDeleteButton && palette?.id ? (
        <AppButton
          radius="large"
          variant="outlined"
          disabled={isLoading}
          color="red"
          onClick={() => deleteBrandColorPalette()}
        >
          {isDeleting && <Spinner />}
          Delete
        </AppButton>
      ) : (
        <Box />
      )}

      <Flex gap="3">
        <Dialog.Close>
          <AppButton radius="large" variant="outlined" disabled={isLoading}>
            Cancel
          </AppButton>
        </Dialog.Close>
        <AppButton
          variant="dark"
          radius="large"
          disabled={isLoading}
          onClick={() =>
            palette?.id
              ? updateBrandColorPalette({
                  ...palette,
                  id: palette.id,
                })
              : createBrandColorPalette({
                  palette: palette,
                  campaign_id: saveToStyleLibraryEnabled
                    ? undefined
                    : campaign_id || undefined,
                })
          }
        >
          {isCreating || isUpdating ? <Spinner /> : null}
          {saveButtonText}
        </AppButton>
      </Flex>
    </Flex>
  );

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content
        style={{
          width: "50%",
          padding: "0",
        }}
      >
        {header}

        {content}

        {footer}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ColorPaletteDialog;
