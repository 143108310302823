import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import ActionAlert from "../../../components/campaign/wizard/products/components/ActionAlert";
import ProductCard from "../../../components/campaign/wizard/products/components/ProductCard";
import NumberedPaginator from "../../../components/campaign/wizard/products/components/numberedPaginator/NumberedPaginator";
import ProductSelectorDialog, {
  MAX_FEATURED_PRODUCTS,
} from "../../../components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { CampaignCardCommonProps } from "./CampaignCards";
import { useCampaignProductsProps } from "./hooks/useCampaignProductsProps";
import { components } from "@openapi";
import { Flex, Grid, Text } from "@radix-ui/themes";
import React, { useMemo } from "react";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";

type ShopifyProductInfo = components["schemas"]["ShopifyProductInfo"];

export type MaybeFeaturedProduct = Omit<
  ShopifyProductInfo,
  "total_inventory"
> & {
  is_featured: boolean;
};

const PAGE_SIZE = 9;

const CampaignProducts: React.FC<CampaignCardCommonProps> = (props) => {
  const {
    isProductSelectorOpen,
    setIsProductSelectorOpen,
    selectedProducts,
    selectedProductsPage,
    setSelectedProductsPage,
    handleDeselect,
    onFeatureToggle,
    isDataLoading,
    description,
    showAlert,
    removedProduct,
    onUndo,
    handleConfirmProducts,
  } = useCampaignProductsProps(props.step);

  const featuredProductsCount = useMemo(() => {
    return Array.from(selectedProducts.values()).filter((p) => p.is_featured)
      .length;
  }, [selectedProducts]);

  const featuredProductsSection = Array.from(selectedProducts).filter(
    ([_, product]) => product.is_featured
  ).length ? (
    <>
      <Text size="1">Featured Product(s)</Text>
      <Grid gap="3" columns="3" width="auto">
        {Array.from(selectedProducts)
          .filter(([_, product]) => product.is_featured)
          .map(([_, product]) => (
            <ProductCard
              key={product.product_id}
              onClick={handleDeselect}
              data={product}
              isFeatureDisabled={featuredProductsCount >= MAX_FEATURED_PRODUCTS}
              onFeatureToggle={onFeatureToggle}
            />
          ))}
      </Grid>
    </>
  ) : null;

  return (
    <CampaignWizardCard
      badgeTitle="Products"
      nextButtonProps={{
        text: "Looks good",
        disabled: !selectedProducts.size,
      }}
      subtitle={description}
      {...props}
    >
      {featuredProductsSection}
      <Text size="1">{selectedProducts.size} products selected</Text>
      {isDataLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Flex direction="column" gap="4">
            <Grid gap="3" columns="3" width="auto">
              {Array.from(selectedProducts)
                .slice(
                  selectedProductsPage * PAGE_SIZE,
                  selectedProductsPage * PAGE_SIZE + PAGE_SIZE
                )
                .map(([id, product]) => (
                  <ProductCard
                    key={id}
                    onClick={handleDeselect}
                    onFeatureToggle={onFeatureToggle}
                    data={product}
                    isFeatureDisabled={
                      featuredProductsCount >= MAX_FEATURED_PRODUCTS
                    }
                  />
                ))}
            </Grid>
          </Flex>
          <Flex direction="row" justify="between">
            {selectedProducts.size > PAGE_SIZE ? (
              <NumberedPaginator
                totalPages={Math.ceil(selectedProducts.size / PAGE_SIZE)}
                currentPage={selectedProductsPage}
                setCurrentPage={setSelectedProductsPage}
              />
            ) : (
              <div />
            )}
            <ProductSelectorDialog
              initialSelectedProducts={selectedProducts}
              onConfirm={handleConfirmProducts}
              isOpen={isProductSelectorOpen}
              onOpenChange={setIsProductSelectorOpen}
              isFeatureEnabled={true}
            />
          </Flex>
        </>
      )}

      {showAlert && (
        <ActionAlert
          title={"Product removed"}
          subtitle={removedProduct?.title ?? ""}
          undo={onUndo}
        />
      )}
    </CampaignWizardCard>
  );
};

export default CampaignProducts;
