import KinLogo from "../core/KinLogo";
import AppButton from "../core/buttons/AppButton/AppButton";
import ActionCard from "../core/cards/ActionCard";
import { operations } from "@openapi";
import * as Form from "@radix-ui/react-form";
import {
  AlertDialog,
  Box,
  Button,
  Flex,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { CircleCheck } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type BrandShopifyConfigResponse =
  operations["brand_api_get_brand_shopify_config"]["responses"][200]["content"]["application/json"];

type LoginParams =
  operations["shopify_integration_api_login"]["requestBody"]["content"]["application/json"];
type LoginResponse =
  operations["shopify_integration_api_login"]["responses"][200]["content"]["application/json"];

const ConnectedViewRoot = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 16px;
`;

const OnboardingShopify = ({ onNext }: { onNext: () => void }) => {
  const activeBrandID = useActiveBrandID();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const login = useMutation<LoginResponse, Error, LoginParams>({
    mutationFn: async (params: LoginParams): Promise<LoginResponse> => {
      const { data } = await axios.post(
        "/api/v1/commerce-platform/login",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      const redirect = data.redirect_url;
      if (redirect) {
        window.location.href = redirect;
      }
    },
    onError: (error) => {
      alert(error);
      console.error("Error connecting Shopify store", error);
    },
  });
  const { data: shopifyConfigData } = useQuery<BrandShopifyConfigResponse>({
    queryKey: ["brand-shopify-config-", activeBrandID],
    queryFn: async (): Promise<BrandShopifyConfigResponse> => {
      const { data } = await axios.get(
        `/api/v1/brand/${activeBrandID}/shopify-config`
      );
      return data;
    },
    enabled: !!activeBrandID,
  });

  const form = (
    <Form.Root
      onSubmit={(e) => {
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.currentTarget));

        login.mutate({
          brand_id: activeBrandID,
          shop: data?.store as string,
        });
      }}
    >
      <Flex gap="4" direction={"row"} align={"end"}>
        <Box width={"100%"}>
          <Form.Field name="store" autoFocus>
            <Form.Label>
              <Text size={"1"}>Shopify Store</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                size="3"
                radius="large"
                required
                placeholder="store-name.myshopify.com"
              />
            </Form.Control>
            <Form.Message match="valueMissing" style={{ color: "red" }}>
              <Text size={"1"}> Please enter your Shopify Store</Text>
            </Form.Message>
          </Form.Field>
        </Box>

        <Form.Submit asChild>
          <AppButton
            size="3"
            radius="large"
            disabled={login.isPending || login.isSuccess}
          >
            {login.isPending || login.isSuccess ? "Connecting" : "Connect"}
          </AppButton>
        </Form.Submit>
      </Flex>
    </Form.Root>
  );

  const isShopifyConnected =
    activeBrandID !== null && shopifyConfigData?.id != null;

  const connectedView = (
    <ConnectedViewRoot justify={"between"} align={"center"} gap="8" p="5">
      <Flex align={"center"} gap="2">
        <Flex
          p="2"
          width={"48px"}
          height={"48px"}
          style={{
            backgroundColor: "#FF6B6B",
            borderRadius: "8px",
            border: "1px solid #DDD7D7",
          }}
        >
          <KinLogo color="white" />
        </Flex>

        <Text size={"2"} weight={"bold"}>
          Tempo App
        </Text>
      </Flex>
      <Flex align={"center"} gap="2">
        <CircleCheck size={24} color="#50A044" />
        <Text size={"2"}>Shopify app installed.</Text>
      </Flex>
    </ConnectedViewRoot>
  );

  return (
    <Flex direction={"column"} justify={"center"} align={"center"} gap="8">
      <AlertDialog.Root
        open={isErrorDialogOpen}
        onOpenChange={setIsErrorDialogOpen}
      >
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Error</AlertDialog.Title>
          <AlertDialog.Description size="2">
            There was an issue fetching your assets. Please try again later.
          </AlertDialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Ok
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
      <ActionCard
        footer={
          <AppButton
            variant="dark"
            disabled={!isShopifyConnected}
            onClick={onNext}
          >
            Continue
          </AppButton>
        }
        title="Shopify Account"
        subtitle="You need to install our Shopify app so we can extract your brand assets for generating marketing campaigns."
      >
        <Box width={"100%"}>{isShopifyConnected ? connectedView : form}</Box>
      </ActionCard>
    </Flex>
  );
};

export default OnboardingShopify;
