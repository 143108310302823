import { Flex, Progress, Text, Box, Heading } from "@radix-ui/themes";
import { SparklesIcon } from "lucide-react";
import { useState } from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const ShimmerProgress = styled(Progress)`
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 100%;
    animation: ${shimmer} 5s infinite;
    pointer-events: none;
  }
`;

const DraperGeneratingLoading = () => {
  const [progressValue, setProgressValue] = useState(1);

  useEffect(() => {
    if (progressValue < 99) {
      const timer = setTimeout(() => {
        // Calculate the next progress value by halving the remaining distance to 100
        const remaining = 100 - progressValue;
        const increment = remaining / 5;
        setProgressValue(progressValue + increment);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [progressValue]);

  return (
    <Flex
      gap="6"
      direction="column"
      height="100dvh"
      width="50%"
      justify="center"
      align="center"
      style={{ textAlign: "center" }}
    >
      <SparklesIcon color="#8C3FFF" fill="#8C3FFF" size={40} />

      <Heading size="7">Generating the campaign</Heading>
      <Text size="4">
        This will be a few minutes, you will be notified by email when the
        campaign is ready.
      </Text>
      <Box maxWidth="400px">
        <ShimmerProgress
          value={progressValue}
          size="3"
          style={{ height: "16px", width: "400px", backgroundColor: "white" }}
          radius="large"
        />
      </Box>
    </Flex>
  );
};

export default DraperGeneratingLoading;
