import { useActiveBrandID } from "../../../../../../../contexts/CurrentUserContext";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type GetProductReviewsResponse =
  operations["shopify_integration_api_get_product_reviews"]["responses"][200]["content"]["application/json"];

export type CustomerReview =
  operations["shopify_integration_api_get_product_reviews"]["responses"][200]["content"]["application/json"][0]["reviews"][0];

const useGetProductReviews = (product_id: string) => {
  const activeBrandID = useActiveBrandID();

  const { data: productReviews } = useQuery({
    queryKey: ["product-reviews", product_id],
    queryFn: async (): Promise<GetProductReviewsResponse> => {
      const response = await axios.get(
        "/api/v1/commerce-platform/product/reviews",
        {
          params: {
            brand_id: activeBrandID,
            product_id: product_id,
          },
        }
      );
      return response.data;
    },
  });

  return productReviews;
};

export default useGetProductReviews;
