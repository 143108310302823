import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface SegmentedControlProps {
  value: string;
  onValueChange: (value: string) => void;
  children: React.ReactNode;
  radius?: "small" | "medium" | "large";
  size?: "1" | "2" | "3" | "0";
  style?: React.CSSProperties;
}

interface SegmentedControlItemProps {
  value: string;
  children: React.ReactNode;
}

const SegmentedControlRoot = styled.div<{
  $radius?: "small" | "medium" | "large";
  $size?: "1" | "2" | "3" | "0";
}>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: ${(props) => {
    switch (props.$size) {
      case "0":
        return "0px";
      case "1":
        return "2px";
      case "3":
        return "6px";
      default:
        return "4px";
    }
  }};
  background-color: #ffffffb8;
  width: fit-content;
  box-sizing: border-box;
`;

const SegmentedControlIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 250ms ease, width 250ms ease;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px #0000001f;
  }
`;

const SegmentedControlItemButton = styled.button<{ $active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 12px;
  z-index: 1;
  color: ${(props) =>
    props.$active ? "var(--icon-primary-active)" : "#4C4747"};
  transition: color 250ms ease;

  &:focus {
    outline: none;
  }
`;

const DraperSegmentedControl2: React.FC<SegmentedControlProps> & {
  Item: React.FC<SegmentedControlItemProps>;
} = ({
  value,
  onValueChange,
  children,
  radius = "medium",
  size = "2",
  style,
}) => {
  const [indicatorStyle, setIndicatorStyle] = useState({
    transform: "translateX(0)",
    width: 0,
  });
  const rootRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<Map<string, HTMLButtonElement>>(new Map());

  useEffect(() => {
    const updateIndicator = () => {
      const activeItem = itemRefs.current.get(value);
      if (activeItem && rootRef.current) {
        const rootRect = rootRef.current.getBoundingClientRect();
        const activeRect = activeItem.getBoundingClientRect();

        setIndicatorStyle({
          transform: `translateX(${activeRect.left - rootRect.left}px)`,
          width: activeRect.width,
        });
      }
    };

    updateIndicator();
    window.addEventListener("resize", updateIndicator);

    return () => {
      window.removeEventListener("resize", updateIndicator);
    };
  }, [value]);

  return (
    <SegmentedControlRoot
      ref={rootRef}
      $radius={radius}
      $size={size}
      style={style}
    >
      <SegmentedControlIndicator style={indicatorStyle} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            ref: (node: HTMLButtonElement) => {
              if (node) {
                itemRefs.current.set(child.props.value, node);
              }
            },
            active: child.props.value === value,
            onClick: () => onValueChange(child.props.value),
          });
        }
        return child;
      })}
    </SegmentedControlRoot>
  );
};

const Item = React.forwardRef<
  HTMLButtonElement,
  SegmentedControlItemProps & {
    active?: boolean;
    onClick?: () => void;
  }
>(({ value, children, active, onClick }, ref) => {
  return (
    <SegmentedControlItemButton ref={ref} $active={!!active} onClick={onClick}>
      {children}
    </SegmentedControlItemButton>
  );
});

DraperSegmentedControl2.Item = Item;

export default DraperSegmentedControl2;

// For compatibility with the existing code
export const SegmentedControl = {
  Item,
};
