import HorizontalScrollableTemplateSelector from "~/components/core/editor/sidepane/HorizontalScrollableTemplateSelector";
import { useHeaderFooterState } from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";

const VariantSelector = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(state.type);

  return (
    <HorizontalScrollableTemplateSelector
      sectionType={state.type}
      selectedTemplate={state.template}
      onSelectTemplate={(template) => {
        updateState({
          ...state,
          template,
        });
      }}
    />
  );
};

export default VariantSelector;
