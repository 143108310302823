import { components } from "@openapi";
import { DownloadIcon } from "@radix-ui/react-icons";
import { Heading, Text, Button } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInternBrandRecentEmailsQuery from "~/hooks/intern/useInternBrandRecentEmailsQuery";

type BrandSentEmail = components["schemas"]["BrandSentEmail"];

const RecentEmailsContainer = () => {
  const [selectedRecord, setSelectedRecord] = useState<BrandSentEmail | null>(
    null
  );
  const activeBrandID = useActiveBrandID();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const {
    brandSentEmails = [],
    isLoading: isInitLoading,
    isSuccess: isInitSuccess,
  } = useInternBrandRecentEmailsQuery(activeBrandID);

  useEffect(() => {
    if (brandSentEmails && brandSentEmails.length > 0) {
      setSelectedRecord(brandSentEmails[0]);
    }
  }, [brandSentEmails]);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = (iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow?.document)!;
      doc.open();
      doc.write(selectedRecord?.email_html || "No email template found");
      doc.close();
    }
  }, [selectedRecord]);

  if (isInitLoading) return <div>Loading...</div>;

  if (!isInitSuccess) return <div>Error</div>;

  return (
    <Container>
      <List>
        {brandSentEmails.map((record: BrandSentEmail) => (
          <Email
            key={record.template_id}
            $isSelected={selectedRecord?.template_id === record.template_id}
            onClick={() => setSelectedRecord(record)}
          >
            <Text size="1">
              {record.sent_at && new Date(record.sent_at).toLocaleDateString()}
            </Text>
            <Heading size="2" mb="1">
              {record.email_subject}
            </Heading>
            <Text size="2" as="p">
              {record.email_preview_text}
            </Text>
          </Email>
        ))}
      </List>
      {selectedRecord && (
        <PreviewContainer>
          <Button
            onClick={() => download(selectedRecord)}
            size="2"
            style={{ position: "absolute", right: "20px" }}
          >
            <DownloadIcon /> Download HTML
          </Button>
          <EmailPreview
            ref={iframeRef}
            title="Email Preview"
            sandbox="allow-same-origin"
          />
        </PreviewContainer>
      )}
    </Container>
  );
};

const download = (record: BrandSentEmail) => {
  const blob = new Blob([record.email_html], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${record.template_id}.txt`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const List = styled.div`
  width: 400px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  padding: 20px;
  padding-top: 0;
`;

const Email = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${(props) =>
    props.$isSelected ? "#e0e0e0" : "transparent"};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const PreviewContainer = styled.div`
  width: 100%;
  padding: 10px;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const EmailPreview = styled.iframe`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border: none;
`;

export default RecentEmailsContainer;
