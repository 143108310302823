import ModifierContainer from "./ModifierContainer";
import { PlusIcon, Cross2Icon, ImageIcon } from "@radix-ui/react-icons";
import { Flex, Avatar, IconButton } from "@radix-ui/themes";
import { GripVertical } from "lucide-react";
import { useState } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import { RelatedImageType } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/RelatedImages";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import DraperText from "~/components/core/DraperText";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import SidePanelComponents from "~/components/core/editor/sidepane";
import { cleanHtmlText } from "~/components/editor/utils";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { EmailProductsSectionSchema } from "~/utils/emails/useSectionTypeCheck";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

const ProductInfo = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
`;

const AvatarContainer = styled(Avatar)`
  & .rt-AvatarImage {
    object-fit: contain;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: var(--radius-3);
  overflow: hidden;
  flex-shrink: 0;
`;

const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const ProductsListModifier = ({
  products,
  onAddProduct,
}: {
  products: EmailProductsSectionSchema["products"];
  onAddProduct: (products: Map<string, MaybeFeaturedProduct>) => void;
}) => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId, campaign_id } = useEmailState();
  const [hoveredProductId, setHoveredProductId] = useState<string | null>(null);
  const [productIndexToUpdate, setProductIndexToUpdate] = useState<
    number | null
  >(null);
  const platformId =
    productIndexToUpdate !== null
      ? products[productIndexToUpdate].commerce_platform_id
      : null;

  return (
    <DragDropContext
      onDragEnd={(result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId !== source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }
        const newProducts = Array.from(products);
        const [reorderedProduct] = newProducts.splice(source.index, 1);
        newProducts.splice(destination.index, 0, reorderedProduct);
        // Update the index of each product in the new order

        updateSection({
          sectionId: selectedSectionId,
          field: "products",
          value: newProducts.map((product, index) => ({
            ...product,
            index: index,
          })),
        });
      }}
    >
      <ModifierContainer title="Products" hideSwitch>
        <Flex direction="column" gap="1">
          <StrictModeDroppable
            droppableId="product-modifier-droppable"
            type="group"
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {products.map((product, index) => (
                  <Draggable
                    key={product.commerce_platform_id}
                    draggableId={product.commerce_platform_id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Flex gap="2" align="center" p="2" key={product.id}>
                          <GripVertical
                            color="#595D62"
                            size={20}
                            style={{
                              cursor: "grab",
                              flexShrink: 0,
                            }}
                          />
                          <AvatarWrapper
                            onMouseEnter={() =>
                              setHoveredProductId(product.commerce_platform_id)
                            }
                            onMouseLeave={() => setHoveredProductId(null)}
                            onClick={() => setProductIndexToUpdate(index)}
                          >
                            <AvatarContainer
                              alt={cleanHtmlText(product.title.text)}
                              src={product.image.image_url}
                              fallback=""
                              style={{
                                overflow: "hidden",
                              }}
                            />
                            {hoveredProductId ===
                              product.commerce_platform_id && (
                              <AvatarOverlay
                                style={{ borderRadius: "inherit" }}
                              >
                                <ImageIcon color="white" />
                              </AvatarOverlay>
                            )}
                          </AvatarWrapper>
                          <ProductInfo>
                            <DraperText size="2" weight="medium" clamp={1}>
                              {cleanHtmlText(product.title.text)}
                            </DraperText>
                            <DraperText size="1" clamp={1}>
                              {cleanHtmlText(product.price.text)}
                            </DraperText>
                          </ProductInfo>
                          <IconButton
                            variant="ghost"
                            color="gray"
                            radius="large"
                            onClick={() => {
                              updateSection({
                                sectionId: selectedSectionId,
                                field: "products",
                                value: products.filter(
                                  (p) =>
                                    p.commerce_platform_id !==
                                    product.commerce_platform_id
                                ),
                              });
                            }}
                          >
                            <Cross2Icon />
                          </IconButton>
                        </Flex>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </Flex>
        <ProductSelectorDialog
          initialSelectedProducts={
            new Map(
              products.map((product) => [
                product.commerce_platform_id,
                {
                  product_id: product.commerce_platform_id,
                  title: product.title.text,
                  description: product.description.text,
                  short_description: product.description.text,
                  price: parseFloat(product.price.text),
                  compare_at_price: product.compare_at_price
                    ? parseFloat(product.compare_at_price.text) || 0.0
                    : 0.0,
                  url: product.cta_button.link_url,
                  image_url: product.image.image_url,
                  is_featured: false, // Assuming default is not featured
                },
              ])
            )
          }
          onConfirm={onAddProduct}
          triggerComponent={
            <SidePanelComponents.Button>
              <PlusIcon />
              Add Product
            </SidePanelComponents.Button>
          }
        />

        <AssetSelectorDialog
          singleAsset
          campaignId={campaign_id}
          isDialogOpen={productIndexToUpdate !== null}
          onToggleDialogOpen={(open) => {
            if (open === false) {
              setProductIndexToUpdate(null);
            }
          }}
          onSelect={(asset) => {
            if (productIndexToUpdate === null) {
              return;
            }
            updateSection({
              sectionId: selectedSectionId,
              field: `products.${productIndexToUpdate}.image.image_url`,
              value: asset[0].url,
            });
            setProductIndexToUpdate(null);
          }}
          sourceObjectID={
            productIndexToUpdate !== null
              ? products[productIndexToUpdate].commerce_platform_id
              : undefined
          }
          type={RelatedImageType.PRODUCT}
          preselectedAssets={
            productIndexToUpdate !== null
              ? [
                  {
                    url: products[productIndexToUpdate].image.image_url,
                    commerce_platform_item_id:
                      products[productIndexToUpdate].commerce_platform_id,
                  },
                ]
              : []
          }
        />
      </ModifierContainer>
    </DragDropContext>
  );
};

export default ProductsListModifier;
