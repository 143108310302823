import StyleLibraryCardContent from "../../StyleLibraryCardContent";
import VoiceGuidelineCategorySection from "./VoiceGuidelineCategorySection";
import { BrandVoiceCategory } from "@openapi";
import { useState } from "react";
import { PlusIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useBrandVoiceDispatch,
  useBrandVoiceState,
} from "~/contexts/BrandVoiceContext";

type VoiceCardWithInsertProps = {
  insertButtonText: string;
} & Omit<React.ComponentProps<typeof StyleLibraryCardContent>, "children"> &
  Pick<
    React.ComponentProps<typeof VoiceGuidelineCategorySection>,
    "category" | "exampleLabel"
  >;

const VoiceCardWithInsert: React.FC<VoiceCardWithInsertProps> = ({
  insertButtonText,
  actions,
  category,
  exampleLabel,
  ...props
}) => {
  const voiceState = useBrandVoiceState();
  const voiceDispatch = useBrandVoiceDispatch();
  const [isInserting, setIsInserting] = useState(false);
  const handleAdd = () => {
    voiceDispatch({
      type: "PATCH",
      payload: { isEditingItem: true },
    });
    setIsInserting(true);
  };
  const handleCloseInsert = () => {
    voiceDispatch({
      type: "PATCH",
      payload: { isEditingItem: false },
    });
    setIsInserting(false);
  };
  return (
    <StyleLibraryCardContent
      actions={
        <>
          {!isInserting && (
            <AppButton
              onClick={handleAdd}
              variant="ghost"
              size={"3"}
              disabled={voiceState.isEditingItem || voiceState.isGenerating}
            >
              <PlusIcon
                style={{
                  padding: "0.1rem",
                }}
              />
              {insertButtonText}
            </AppButton>
          )}
          {actions}
        </>
      }
      disabled={voiceState.isGenerating}
      {...props}
    >
      <VoiceGuidelineCategorySection
        category={category}
        exampleLabel={exampleLabel}
        showInsert={isInserting}
        onCloseInsert={handleCloseInsert}
      />
    </StyleLibraryCardContent>
  );
};

export default VoiceCardWithInsert;
