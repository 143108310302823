import { Flex, Text } from "@radix-ui/themes";
import { Info } from "lucide-react";
import React from "react";
import styled from "styled-components";

const StyledText = styled(Text)`
  flex: 1;
  line-height: 24px;
`;

type InfoTextProps = Omit<React.ComponentProps<typeof Flex>, "children"> & {
  children: string;
};

const InfoText: React.FC<InfoTextProps> = ({ children, ...props }) => {
  return (
    <Flex align="start" gap="2" mt="2" {...props}>
      <Flex height="24px" align="center">
        <Info size={16} />
      </Flex>
      <StyledText size="3" color="gray">
        {children}
      </StyledText>
    </Flex>
  );
};

export default InfoText;
