import nullthrows from "../utils/nullthrows";
import { assertNever } from "../utils/typeUtils";
import { operations } from "@openapi";
import { Grid } from "@radix-ui/themes";
import { StatsigProvider } from "@statsig/react-bindings";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import FullscreenSpinner from "~/components/core/FullscreenSpinner";

type CurrentUserResponse =
  operations["user_api_user"]["responses"][200]["content"]["application/json"];

export type UserStatus =
  | "LOADING"
  | "LOGGED_OUT"
  | "UNAUTHORIZED"
  | "LOGGED_IN";

export interface Brand {
  id: string;
  name: string;
  domain: string;
  currency_code?: string | null;
}

interface CommonUserFields {
  email: string;
  firstName: string;
  lastName: string;
  isInternal: boolean;
}

interface UnauthorizedUser extends CommonUserFields {
  status: "UNAUTHORIZED";
}

interface LoggedInUser extends CommonUserFields {
  id: string;
  status: "LOGGED_IN";
  brands: Brand[];
  activeBrand: Brand;
}

export const displayShortName = (user: LoggedInUser) =>
  user.firstName || user.lastName || user.email;

export type User =
  | { status: "LOADING" | "LOGGED_OUT" }
  | UnauthorizedUser
  | LoggedInUser;

interface AppContextInterface {
  user: { needsRefetch: Boolean } & User;
}

const initialState: AppContextInterface = {
  user: {
    status: "LOADING",
    needsRefetch: false,
  },
};

interface ActionSetUser {
  type: "SET_USER";
  payload: User;
}
interface ActionSetActiveBrandID {
  type: "SET_ACTIVE_BRAND_ID";
  payload: string;
}
interface ActionSetUserNeedsRefetch {
  type: "SET_USER_NEEDS_REFETCH";
  payload: {
    needsRefetch: Boolean;
  };
}

export type CurrentUserAction =
  | ActionSetUser
  | ActionSetActiveBrandID
  | ActionSetUserNeedsRefetch;

function reducer(
  state: AppContextInterface,
  action: CurrentUserAction
): AppContextInterface {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: {
          needsRefetch: false,
          ...action.payload,
        },
      };
    case "SET_ACTIVE_BRAND_ID":
      if (state.user.status !== "LOGGED_IN") {
        throw new Error("User is not authenticated");
      }
      const brand = state.user.brands.find((b) => b.id === action.payload);
      if (!brand) {
        throw new Error("Brand not found");
      }
      return {
        ...state,
        user: {
          ...state.user,
          activeBrand: brand,
        },
      };
    case "SET_USER_NEEDS_REFETCH":
      return {
        ...state,
        user: {
          ...state.user,
          needsRefetch: true,
          status: "LOADING",
        },
      };
    default:
      assertNever(action);
      return state;
  }
}

const CurrentUserContext = createContext<AppContextInterface>(initialState);
const DispatchContext = createContext<React.Dispatch<CurrentUserAction> | null>(
  null
);

export const CurrentUserProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleUserData = useCallback(
    ({
      data,
      isError,
    }: {
      data: CurrentUserResponse | undefined;
      isError: boolean;
    }) => {
      if (!data || isError) {
        dispatch({
          type: "SET_USER",
          payload: {
            status: "LOGGED_OUT",
          },
        });
      } else {
        const adminBrands = data?.admin_profiles.map((item) => item.brand);
        const isInternal = data?.is_internal;
        const commonUserFields: CommonUserFields = {
          email: data?.email,
          firstName: data?.first_name,
          lastName: data?.last_name,
          isInternal: data?.is_internal,
        };

        const payload: LoggedInUser | UnauthorizedUser =
          !!adminBrands.length || isInternal
            ? {
                ...commonUserFields,
                id: data.id,
                status: "LOGGED_IN",
                brands: adminBrands,
                activeBrand: data?.active_brand ?? adminBrands[0],
              }
            : {
                ...commonUserFields,
                status: "UNAUTHORIZED",
              };
        dispatch({
          type: "SET_USER",
          payload: payload,
        });
      }
    },
    []
  );

  const { isLoading, isError, refetch } = useQuery({
    queryKey: ["current-user"],
    queryFn: async (): Promise<CurrentUserResponse> => {
      const { data } = await axios.get("/api/v1/user/");
      handleUserData({ data, isError });
      return data;
    },
    retry: true,
    staleTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    if (!state.user.needsRefetch) {
      return;
    }
    refetch().then(handleUserData);
  }, [state.user.needsRefetch, refetch, handleUserData]);

  const dispatchValue = useMemo(() => dispatch, [dispatch]);
  const cuMemo = useMemo(() => state, [state]);

  const statsigUser = useMemo(
    () => ({
      userID: state.user.status === "LOGGED_IN" ? state.user.id : "",
      email: state.user.status === "LOGGED_IN" ? state.user.email : "",
      customIDs: {
        brandID:
          state.user.status === "LOGGED_IN" ? state.user.activeBrand.id : "",
      },
    }),
    [state.user]
  );

  // We need this because the statsig user is not updated immediately
  // So wait until the user is loaded before rendering the app
  if (state.user.status === "LOADING" || isLoading) {
    return (
      <Grid align="center" justify="center" style={{ minHeight: "100vh" }} />
    );
  }

  return (
    <DispatchContext.Provider value={dispatchValue}>
      <CurrentUserContext.Provider value={cuMemo}>
        <StatsigProvider
          sdkKey="client-1TA1a0RITCUpFkld8IDE7Epq8elQEqMxF1cXf8gp4x3"
          user={statsigUser}
          loadingComponent={<FullscreenSpinner />}
        >
          {isLoading ? <FullscreenSpinner /> : children}
        </StatsigProvider>
      </CurrentUserContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useCurrentUserState() {
  return useContext(CurrentUserContext);
}

export function useAuthenticatedUserState() {
  const { user } = useCurrentUserState();
  if (user.status !== "LOGGED_IN") {
    throw new Error("User is not authenticated");
  }
  return user;
}

export function useActiveBrandID() {
  const user = useAuthenticatedUserState();
  return useMemo(() => user?.activeBrand?.id, [user.activeBrand]);
}
export function useActiveBrandName() {
  const user = useAuthenticatedUserState();
  return useMemo(() => user?.activeBrand?.name, [user.activeBrand]);
}

export function useActiveBrandCurrency() {
  const user = useAuthenticatedUserState();
  return useMemo(() => user?.activeBrand?.currency_code, [user.activeBrand]);
}

export function useCurrentUserDispatch(): React.Dispatch<CurrentUserAction> {
  return nullthrows(
    useContext(DispatchContext),
    "Current User Context dispatch context is missing"
  );
}

export default CurrentUserContext;
