import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type InternBrandRecentEmailsResponse =
  operations["intern_api_get_brand_recent_emails"]["responses"][200]["content"]["application/json"];

const useInternBrandRecentEmailsQuery = (brandId: string) => {
  const {
    data: brandSentEmails,
    isLoading,
    isSuccess,
  } = useQuery<InternBrandRecentEmailsResponse>({
    queryKey: ["internBrandRecentEmails", brandId],
    queryFn: async (): Promise<InternBrandRecentEmailsResponse> => {
      const { data } = await axios.get(
        `/api/v1/intern/brand/${brandId}/recent-emails`
      );
      return data;
    },
  });

  return {
    brandSentEmails,
    isLoading,
    isSuccess,
  };
};

export default useInternBrandRecentEmailsQuery;
