import FormErrorText from "../auth/FormErrorText";
import SearchableExpandedSelect from "../campaign/wizard/inputs/SearchableExpandedSelect";
import InfoText from "../core/InfoText";
import AppButton from "../core/buttons/AppButton/AppButton";
import { components, DestinationType } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { Box, Flex, Switch, Text } from "@radix-ui/themes";

const LandingDestinationSelector = ({
  isLandingDestinationEnabled,
  setIsLandingDestinationEnabled,
  destination,
  setDestination,
  isSelecting,
  setIsSelecting,
  domain,
  isNewUI = false,
}: {
  isLandingDestinationEnabled: boolean | null;
  setIsLandingDestinationEnabled: (enabled: boolean) => void;
  destination: components["schemas"]["CampaignRecommendationDestinationSchema"];
  setDestination: (
    destination: components["schemas"]["CampaignRecommendationDestinationSchema"]
  ) => void;
  isSelecting: boolean;
  setIsSelecting: (selecting: boolean) => void;
  domain: string;
  isNewUI?: boolean;
}) => {
  const inputComponent = (
    <Flex direction={"row"} align={"end"} gap={"4"}>
      <Box width={"100%"}>
        <Form.Root onSubmit={(e) => e.preventDefault()}>
          <Form.Field name="landing-destination">
            <Form.Label>
              <Text size={"1"}>
                Enter Landing Destination URL or choose a collection page below
              </Text>
            </Form.Label>
            <Flex align="end" gap="3">
              <Form.Control asChild placeholder="https://www">
                <input
                  id="landing-destination"
                  type="url"
                  style={{
                    padding: "0 8px",
                    fontSize: "14px",
                    borderRadius: "12px",
                    border: "1px solid var(--border, #DDD7D7)",
                    width: "calc(100% - 16px)",
                    height: "45px",
                    backgroundColor: "white",
                    color: "black",
                    cursor: "default",
                  }}
                  value={destination?.url ?? ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setDestination({
                        type: DestinationType.custom,
                        url: e.target.value,
                      });
                      setIsLandingDestinationEnabled(true);
                      setIsSelecting(false);
                    } else {
                      setDestination({});
                      setIsSelecting(true);
                    }
                  }}
                />
              </Form.Control>
            </Flex>
            <Form.Message match={"typeMismatch"}>
              <FormErrorText>Please provide a valid URL</FormErrorText>
            </Form.Message>
          </Form.Field>
        </Form.Root>
      </Box>
    </Flex>
  );

  const yesNoComponent = isNewUI ? (
    <Flex gap="16px">
      <Flex align="center" justify="between" width="100%" gap="6">
        <Text size="3" color="gray">
          Generate a new Shopify collection page with the products included in
          this campaign
        </Text>
        <Switch
          checked={!isLandingDestinationEnabled}
          onCheckedChange={(checked) => {
            // This value is the opposite of the isLandingDestinationEnabled value
            setIsLandingDestinationEnabled(!checked);
            if (checked) {
              setDestination({});
            } else {
              setIsSelecting(true);
            }
          }}
        />
      </Flex>
    </Flex>
  ) : (
    <Flex direction="column" gap="2">
      <Flex gap="16px">
        <AppButton
          onClick={() => {
            setIsLandingDestinationEnabled(false);
            setDestination({});
          }}
          style={{
            border:
              isLandingDestinationEnabled === false
                ? "1px solid var(--text-link)"
                : undefined,
          }}
          radius="large"
          variant="soft"
        >
          No
        </AppButton>
        <AppButton
          onClick={() => {
            setIsLandingDestinationEnabled(true);
            setIsSelecting(true);
          }}
          style={{
            border:
              isLandingDestinationEnabled === true
                ? "1px solid var(--text-link)"
                : undefined,
          }}
          radius="large"
          variant="soft"
        >
          Yes
        </AppButton>
      </Flex>

      {!isLandingDestinationEnabled && (
        <InfoText>
          We’ll create a collection page in Shopify with the products included
          in the campaign.
        </InfoText>
      )}
    </Flex>
  );

  return (
    <Flex direction={"column"} gap="24px">
      {!isNewUI && yesNoComponent}

      {isLandingDestinationEnabled && inputComponent}

      {isLandingDestinationEnabled && isSelecting && (
        <SearchableExpandedSelect
          selected={[destination?.collection_id ?? ""]}
          onSelect={(item) => {
            setDestination({
              collection_id: item.id,
              name: item.title,
              handle: item.handle,
              type: DestinationType.collection,
              url: `${domain}/collections/${item.handle}`,
            });
          }}
        />
      )}

      {isNewUI && yesNoComponent}
    </Flex>
  );
};

export default LandingDestinationSelector;
