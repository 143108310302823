import InternEmailFullTemplatesAddSectionContainer from "./InternEmailFullTemplatesAddSectionContainer";
import { StrictModeDroppable } from "@core/StrictModeDroppable";
import { Editor } from "@monaco-editor/react";
import { components, EmailSectionType } from "@openapi";
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  TextField,
  Switch,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import Handlebars from "handlebars";
import { debounce } from "lodash";
import { Bug } from "lucide-react";
import { useState, useEffect, useMemo, useCallback } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import HtmlPreview from "~/components/core/HtmlPreview";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";
import useEmailTemplateQuery from "~/hooks/emails/useEmailTemplateQuery";
import { key as emailTemplatesKey } from "~/hooks/emails/useEmailTemplateQuery";
import useInternEmailSectionTemplateDeleteMutation from "~/hooks/intern/useInternEmailSectionTemplateDeleteMutation";
import useInternEmailSectionTemplateUpdateMutation from "~/hooks/intern/useInternEmailSectionTemplateUpdateMutation";
import useInternEmailTemplateScreenshotMutation from "~/hooks/intern/useInternEmailTemplateScreenshotMutation";
import useInternEmailTemplateUpdateMutation from "~/hooks/intern/useInternEmailTemplateUpdateMutation";
import { getSectionInitDefaults } from "~/utils/emails/getSectionInitDefaults";
import { useHandlebarsInit } from "~/utils/emails/useHandlebarsInit";

const EmailPreview = styled(Flex)`
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-6);
  border-radius: 8px;
  background-color: var(--gray-4);
  overflow: auto;
`;

const SectionWrapper = styled.div<{
  $isHovered: boolean;
  $isSelected: boolean;
}>`
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${(props) =>
      props.$isSelected
        ? "2px solid var(--accent-11)"
        : props.$isHovered
        ? "2px solid var(--accent-9)"
        : "none"};
    pointer-events: none;
  }
`;

const InternEmailFullTemplatesIdContainer = () => {
  useHandlebarsInit();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const activeBrandID = useActiveBrandID();
  const { brands } = useAuthenticatedUserState();
  const [hoveredSectionId, setHoveredSectionId] = useState<string | null>(null);
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
    null
  );
  const [editingHtml, setEditingHtml] = useState<string>("");
  const debouncedSetEditingHtml = useCallback(
    debounce((value: string) => {
      setEditingHtml(value);
    }, 1500),
    []
  );
  const [showAddSection, setShowAddSection] = useState(false);
  const [sections, setSections] = useState<
    components["schemas"]["EmailSectionTemplateSchema"][]
  >([]);
  const [parentHtml, setParentHtml] = useState<string>("");

  const { template, error, isLoading } = useEmailTemplateQuery(
    id!,
    activeBrandID
  );

  const selectedSection = useMemo(() => {
    if (!selectedSectionId || !template) {
      return null;
    }
    return template.sections.find((s) => s.id === selectedSectionId);
  }, [selectedSectionId, template]);

  const { screenshotEmailTemplate, isPending: isPendingScreenshot } =
    useInternEmailTemplateScreenshotMutation({
      templateID: id!,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: emailTemplatesKey(id!, activeBrandID),
          type: "all",
        });
      },
    });

  const { updateEmailTemplate, isPending, isSuccess } =
    useInternEmailTemplateUpdateMutation({
      templateId: id!,
    });

  const { updateEmailSectionTemplate, isPending: isPendingUpdate } =
    useInternEmailSectionTemplateUpdateMutation({
      templateId: selectedSection?.id!,
      onSuccess: () => {
        setEditingHtml("");
        setSelectedSectionId(null);
        queryClient.invalidateQueries({
          queryKey: emailTemplatesKey(id!, activeBrandID),
          type: "all",
        });
      },
    });

  const { deleteEmailSectionTemplate, isPending: isPendingDelete } =
    useInternEmailSectionTemplateDeleteMutation({
      templateId: selectedSection?.id!,
      onSuccess: () => {
        setEditingHtml("");
        setSelectedSectionId(null);
        queryClient.invalidateQueries({
          queryKey: emailTemplatesKey(id!, activeBrandID),
          type: "all",
        });
      },
    });

  const [templateName, setTemplateName] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState<string>("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [includesHeaderInHero, setIncludesHeaderInHero] = useState(false);

  useEffect(() => {
    if (template) {
      setTemplateName(template.name);
      setSelectedBrandId(template.brand?.id ?? "");
      setIsEnabled(template.is_enabled);
      setIncludesHeaderInHero(template.includes_header_in_hero);
      setSections(template.sections);
      setParentHtml(template.html_style || "");
    }
  }, [template]);

  useEffect(() => {
    if (selectedSection && template) {
      if (selectedSection.html) {
        setEditingHtml(selectedSection.html);
      }
    }
  }, [selectedSection, template]);

  const templates = useMemo(() => {
    if (!template) {
      return [];
    }
    return sections
      .filter((section) => section.section_type !== null)
      .map((section) => {
        const sectionDefaults = getSectionInitDefaults(
          section.section_type as EmailSectionType
        );
        try {
          return {
            section: sectionDefaults,
            template: Handlebars.compile(
              selectedSectionId === section.id ? editingHtml : section.html
            ),
            error: null,
          };
        } catch (err) {
          return {
            section: sectionDefaults,
            template: null,
            error: "Invalid Handlebars template format",
          };
        }
      });
  }, [template, sections, selectedSectionId, editingHtml]);

  const htmlBySectionId = useMemo(() => {
    return templates.map(({ section, template: loader, error }) => {
      if (error || !loader) {
        return {
          renderedHtml: null,
          error,
        };
      }
      try {
        return {
          renderedHtml: loader(section, {
            helpers: {
              editable: (id: string) => {
                setTimeout(() => {}, 50);
              },
              debug: (field: any, options: Handlebars.HelperOptions) => {
                console.log("-- debug", field, options);
              },
            },
          }),
          error: null,
        };
      } catch (err) {
        return {
          renderedHtml: null,
          error: `Error rendering template: ${err}`,
        };
      }
    });
  }, [templates]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update indices to match new order
    items.forEach((section, index) => {
      section.index = index;
    });

    setSections(items);
  };

  if (isLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: "200px" }}>
        <Text>Loading...</Text>
      </Flex>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!template) {
    return <div>Template not found</div>;
  }

  const inputForm = (
    <Flex direction="column" gap="2">
      <Flex direction="column" gap="1">
        <Text size="1" mb="1" weight="bold">
          Brand
        </Text>
        <Flex gap="2" style={{ width: "100%" }}>
          <Select.Root
            value={selectedBrandId ?? ""}
            onValueChange={(value) => setSelectedBrandId(value)}
          >
            <Select.Trigger
              placeholder="No brand"
              style={{
                flex: 1,
              }}
            />
            <Select.Content>
              <Select.Group>
                {brands?.map((brand) => (
                  <Select.Item key={brand.id} value={brand.id}>
                    {brand.name}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select.Root>
          <Button variant="soft" onClick={() => setSelectedBrandId("")}>
            Clear
          </Button>
        </Flex>
      </Flex>
      <Flex direction="column" gap="2">
        <Box>
          <Text size="1" mb="1" weight="bold">
            Template Name
          </Text>
          <TextField.Root
            placeholder="Template name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </Box>
        <Box>
          <Flex align="center" gap="2">
            <Text size="1" weight="bold">
              Enabled
            </Text>
            <Switch checked={isEnabled} onCheckedChange={setIsEnabled} />
          </Flex>
        </Box>
        <Box>
          <Flex align="center" gap="2">
            <Text size="1" weight="bold">
              Add Header On Generation
            </Text>
            <Switch
              checked={!includesHeaderInHero}
              onCheckedChange={(checked) => setIncludesHeaderInHero(!checked)}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );

  const isDisabled = !templateName;

  return (
    <Flex direction="column" gap="4" p="4" height="calc(100vh - 100px)">
      <Flex justify="between" align="center">
        <Flex align="center" gap="4" justify="between" width="100%">
          <Flex align="center" gap="4">
            <Button
              variant="soft"
              onClick={() => navigate("/intern/emails/full-templates")}
            >
              Back
            </Button>
            <Button
              variant="soft"
              onClick={() => {
                const url = window.location.origin;
                const isLocalhost = url === "http://localhost:3000";
                const baseUrl = isLocalhost ? "http://localhost:8000" : url;

                window.open(
                  `${baseUrl}/admin/emails/emailtemplate/${template.id}/change/`,
                  "_blank"
                );
              }}
            >
              <Bug size={16} />
            </Button>
            <Text size="4" weight="bold">
              {template.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap="4" height="100%">
        <Flex
          gap="4"
          direction={"column"}
          width="15%"
          p="2"
          justify={"start"}
          style={{
            background: "#fff",
            border: "1px solid var(--gray-6)",
            borderRadius: "8px",
          }}
        >
          <Flex position="relative" overflow="hidden" width={"100%"}>
            <img
              src={template.preview_image ?? ""}
              style={{
                minWidth: "100%",
                height: "450px",
                borderRadius: "8px",
                backgroundColor: "var(--gray-4)",
                objectFit: "contain",
                border: "1px solid var(--gray-6)",
              }}
            />
          </Flex>

          <Button
            onClick={() => {
              screenshotEmailTemplate({
                brand_id: selectedBrandId || activeBrandID,
                template_id: template.id,
              });
            }}
            disabled={isPendingScreenshot}
          >
            {isPendingScreenshot ? "Generating..." : "Sync Preview Image"}
          </Button>

          {inputForm}

          <Button
            onClick={() =>
              updateEmailTemplate({
                name: templateName,
                brand_id: selectedBrandId || null,
                is_enabled: isEnabled,
                includes_header_in_hero: includesHeaderInHero,
                sections: sections.sort((a, b) => a.index - b.index),
                // html: parentHtml,
              })
            }
            disabled={isPending || isDisabled}
          >
            {isPending ? "Saving..." : "Save"}
          </Button>
        </Flex>

        <Flex direction={"column"} width="40%">
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable
              droppableId="email-editor-preview-droppable"
              type="group"
            >
              {(provided) => (
                <EmailPreview
                  direction="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {Object.values(htmlBySectionId).map(
                    ({ renderedHtml, error }, index) => (
                      <Draggable
                        key={sections[index].id}
                        draggableId={sections[index].id}
                        index={index}
                      >
                        {(provided) => (
                          <SectionWrapper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() =>
                              setSelectedSectionId(sections[index].id)
                            }
                            onMouseEnter={() =>
                              setHoveredSectionId(sections[index].id)
                            }
                            onMouseLeave={() => setHoveredSectionId(null)}
                            $isHovered={hoveredSectionId === sections[index].id}
                            $isSelected={
                              selectedSectionId === sections[index].id
                            }
                          >
                            <div style={{ position: "relative" }}>
                              <div
                                style={{
                                  position: "absolute",
                                  top: 8,
                                  left: 8,
                                  background: "rgba(0,0,0,0.6)",
                                  color: "white",
                                  padding: "4px 8px",
                                  borderRadius: 4,
                                  fontSize: 12,
                                  zIndex: 1000,
                                }}
                              >
                                {sections[index].section_type}
                              </div>
                              {error ? (
                                <div style={{ padding: "20px", color: "red" }}>
                                  {error}
                                </div>
                              ) : (
                                <HtmlPreview html={renderedHtml!} />
                              )}
                            </div>
                          </SectionWrapper>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </EmailPreview>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </Flex>

        <Flex
          direction={"column"}
          style={{
            background: "#fff",
            border: "1px solid var(--gray-6)",
            borderRadius: "8px",
            padding: "16px",
            flex: 1,
            overflow: "scroll",
          }}
        >
          {selectedSectionId ? (
            <>
              <Flex gap="2" mb="4" justify={"between"} align={"center"}>
                <Button
                  color="red"
                  onClick={() => {
                    if (selectedSection) {
                      deleteEmailSectionTemplate();
                    }
                  }}
                  disabled={isPendingDelete}
                >
                  {isPendingDelete ? "Deleting..." : "Delete"}
                </Button>

                <Flex gap="2" justify="center" align="center">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setEditingHtml("");
                      setSelectedSectionId(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (selectedSection) {
                        updateEmailSectionTemplate({
                          id: selectedSection.id,
                          html: editingHtml,
                          is_enabled: true,
                          name: selectedSection.name,
                        });
                      }
                    }}
                    disabled={isPending}
                  >
                    {isPending ? "Saving..." : "Save"}
                  </Button>
                </Flex>
              </Flex>

              <Text size="1" weight="bold" mb="2">
                {`Edit ${selectedSection?.section_type} HTML`}
              </Text>
              <Editor
                height="100%"
                defaultLanguage="html"
                value={editingHtml}
                onChange={(value) => debouncedSetEditingHtml(value ?? "")}
                theme="vs-dark"
              />
            </>
          ) : (
            <>
              {showAddSection ? (
                <InternEmailFullTemplatesAddSectionContainer
                  onCancel={() => setShowAddSection(false)}
                  parentTemplateId={id!}
                />
              ) : (
                <>
                  <Flex justify="between" align="center" mb="4">
                    <Text size="1" weight="bold">
                      Sections
                    </Text>
                    <Button onClick={() => setShowAddSection(true)}>
                      Add Section
                    </Button>
                  </Flex>
                  {sections.map((section) => (
                    <Text key={section.id} size="1" mb="1">
                      {section.name}
                    </Text>
                  ))}

                  <Box mt="4">
                    <Text size="1" weight="bold" mb="2">
                      Parent Template HTML (Optional)
                    </Text>
                    <br />
                    <Text size="1" mb="2" color="gray">
                      This is custom styling that will be applied to the html
                      document in the
                    </Text>
                    <Editor
                      height="300px"
                      defaultLanguage="html"
                      value={parentHtml}
                      onChange={(value) => setParentHtml(value ?? "")}
                      theme="vs-dark"
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InternEmailFullTemplatesIdContainer;
