import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type ShopifyCollectionImagesType =
  operations["shopify_integration_api_get_collection_images"]["responses"]["200"]["content"]["application/json"];
type ShopifyCollectionImagesRequestData =
  operations["shopify_integration_api_get_collection_images"]["parameters"]["query"];

const key = "commerce-platform/collection/images";
const useShopifyCollectionImagesQuery = ({
  collectionId,
  limit,
  after,
  enabled,
}: {
  collectionId?: string | null;
  limit?: number;
  after?: string;
  enabled?: boolean;
}) => {
  const activeBrandID = useActiveBrandID();
  const collectionQuery = useInfiniteQuery({
    queryKey: [key, activeBrandID, collectionId],
    queryFn: async ({
      pageParam,
    }: {
      pageParam: string | undefined;
    }): Promise<ShopifyCollectionImagesType> => {
      const params: ShopifyCollectionImagesRequestData = {
        brand_id: activeBrandID,
        collection_id: collectionId ?? "",
        limit,
        after: pageParam ?? undefined,
      };
      const { data } = await axios.get(`/api/v1/${key}`, {
        params: params,
      });
      return data;
    },
    initialPageParam: after ?? undefined,
    getNextPageParam: (lastPage) =>
      lastPage.page_info.has_next_page
        ? lastPage.page_info.end_cursor
        : undefined,
    enabled: !!collectionId && !!enabled,
  });

  return collectionQuery;
};

export default useShopifyCollectionImagesQuery;
