import CloseIconButton from "../../../../../core/buttons/CloseIconButton";
import { AssetSelectorItem } from "../AssetSelectorDialog";
import CloudProviderContent from "./CloudProviderContent";
import UploadFromDeviceContent from "./UploadFromDeviceContent";
import {
  SiGoogledrive,
  SiDropbox,
  SiInstagram,
  IconType,
  SiUpcloud,
} from "@icons-pack/react-simple-icons";
import {
  ImageAssetCategory,
  PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider,
} from "@openapi";
import { Dialog, Flex, Heading, Text } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";
import { UploadIcon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { CloudAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useUploadImageAssets from "~/hooks/style-library/useUploadImageAssets";

const { google_oauth2, dropbox_oauth2, instagram } =
  PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider;

type CloudProvider = {
  name: string;
  icon: IconType;
  id: PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider;
};

const CLOUD_PROVIDERS: CloudProvider[] = [
  {
    id: google_oauth2,
    name: "Google Drive",
    icon: SiGoogledrive,
  },
  {
    id: dropbox_oauth2,
    name: "Dropbox",
    icon: SiDropbox,
  },
  {
    id: instagram,
    name: "Instagram",
    icon: SiInstagram,
  },
];

const DialogContent = styled(Flex)`
  flex-direction: column;
  height: 100%;
`;

const Sidebar = styled(Flex)`
  width: 300px;
  flex-shrink: 0;
  padding: 24px;
`;

const Content = styled(Flex)`
  flex: 1;
  min-height: 400px;
  border-left: 1px solid #e2e2e2;
  padding: 24px;
`;

const AssetSelectorUploadImageView = ({
  category,
  setSelectedAssets,
  onBack,
  onSelect,
}: {
  category: ImageAssetCategory;
  setSelectedAssets: (assets: AssetSelectorItem[]) => void;
  onBack: () => void;
  onSelect: (assets: AssetSelectorItem[]) => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [activeProvider, setActiveProvider] = useState<CloudProvider | null>(
    null
  );
  const [selectedAsset, setSelectedAsset] = useState<AssetSelectorItem | null>(
    null
  );

  const { mutate: uploadImageAssets, isPending: isUploadPending } =
    useUploadImageAssets({
      onSuccess: (data) => {
        onSelect([data.new_assets[0]]);
        onBack();
      },
      onError: (_, displayMessage) => {
        setUploadError?.(displayMessage);
      },
    });

  const handleCloudProviderSelect = async () => {
    if (selectedAsset) {
      const response = await fetch(
        (selectedAsset as any as CloudAssetSchema).download_url ||
          selectedAsset.url
      );
      const blob = await response.blob();
      const filename = (selectedAsset as any).name;
      const file = new File([blob], filename, { type: blob.type });
      uploadImageAssets({
        brand_id: activeBrandID,
        category: category as any,
        images: [file as unknown as string],
      });
    }
  };

  return (
    <DialogContent>
      <Flex
        justify="between"
        align="center"
        p="16px"
        pb="12px"
        style={{ borderBottom: "1px solid #E2E2E2" }}
      >
        <Dialog.Title size="4" weight="bold" mb="0">
          Upload Image
        </Dialog.Title>
        <Dialog.Close>
          <CloseIconButton />
        </Dialog.Close>
      </Flex>

      <Flex direction="row" style={{ flex: 1 }}>
        <Sidebar direction="column" gap="4">
          <AppButton
            size="4"
            radius="large"
            onClick={() => setActiveProvider(null)}
            variant={activeProvider ? "outlined" : "soft"}
            style={{ justifyContent: "flex-start", padding: "16px 24px" }}
          >
            <UploadIcon height={20} width={20} />
            <Text size="3" weight="medium">
              Upload from Device
            </Text>
          </AppButton>
          <DraperText size="3" weight="medium" variant="secondary">
            External Assets
          </DraperText>
          {CLOUD_PROVIDERS.map((provider) => {
            const Icon = provider.icon;
            return (
              <AppButton
                key={provider.id}
                onClick={() => setActiveProvider(provider)}
                variant={
                  activeProvider?.name === provider.name ? "soft" : "outlined"
                }
                size="4"
                radius="large"
                style={{ justifyContent: "flex-start", padding: "16px 24px" }}
              >
                <Icon size={20} />
                <Text size="3" weight="medium">
                  {provider.name}
                </Text>
              </AppButton>
            );
          })}
        </Sidebar>
        <Content>
          {uploadError && (
            <Heading size="1" color="red" align={"center"} mb="2">
              {uploadError}
            </Heading>
          )}
          {activeProvider ? (
            <CloudProviderContent
              category={category}
              providerName={activeProvider.name}
              providerId={activeProvider.id}
              selectedAsset={selectedAsset}
              onSelect={setSelectedAsset}
            />
          ) : (
            <UploadFromDeviceContent
              category={category}
              setSelectedAssets={setSelectedAssets}
              setUploadError={setUploadError}
              onBack={onBack}
            />
          )}
        </Content>
      </Flex>

      <Flex
        px="24px"
        py="12px"
        justify="end"
        gap="3"
        style={{ borderTop: "1px solid #E2E2E2" }}
      >
        <AppButton
          variant="outlined"
          size="3"
          radius="large"
          onClick={() => {
            onBack();
          }}
        >
          Back
        </AppButton>

        <Dialog.Close>
          <AppButton
            variant="primary"
            size="3"
            radius="large"
            disabled={!selectedAsset || isUploadPending}
            onClick={handleCloudProviderSelect}
          >
            Use Selected Image
          </AppButton>
        </Dialog.Close>
      </Flex>
    </DialogContent>
  );
};

export default AssetSelectorUploadImageView;
