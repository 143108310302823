import { operations } from "@openapi";
import { toast } from "sonner";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type CreateEmailSectionTemplateRequest =
  operations["intern_api_create_email_section_template"]["requestBody"]["content"]["application/json"];

export type CreateEmailSectionTemplateResponse =
  operations["intern_api_create_email_section_template"]["responses"]["200"]["content"]["application/json"];

const useInternEmailSectionTemplateCreateMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: CreateEmailSectionTemplateResponse) => void;
}) => {
  const { mutate, isPending, isSuccess } = useDraperApiPostMutation<
    CreateEmailSectionTemplateResponse,
    CreateEmailSectionTemplateRequest
  >({
    mutationKey: ["internEmailSectionTemplates"],
    path: "/intern/email-section-templates",
    onSuccess: (data) => {
      toast.success("Email section template created");
      onSuccess?.(data);
    },
    onError: () => {
      toast.error("Failed to create email section template");
    },
  });

  return {
    createEmailSectionTemplate: mutate,
    isPending,
    isSuccess,
  };
};

export default useInternEmailSectionTemplateCreateMutation;
