import dayjs from "dayjs";
import { useReducer, useContext, createContext } from "react";
import { PlannedCampaignData } from "~/components/core/inputs/AddCampaignPopover";

export type UserOptionType =
  | "text"
  | "date"
  | "add_campaign"
  | "text_input"
  | "emails_per_week";

export interface UserOption {
  text: string;
  type: UserOptionType;
  action?:
    | ((option: UserOption) => void)
    | ((date: string) => void)
    | ((campaignData: PlannedCampaignData) => void);
  value?: string;
}

// Define the state type
interface ChatState {
  campaignStartDate: string;
  plannedCampaigns: PlannedCampaignData[];
  eventContext: string;
  feedbackContext: string;
  emailsPerWeek: number;
}

// Define action types
type ChatAction =
  | { type: "SET_CAMPAIGN_START_DATE"; payload: string }
  | { type: "ADD_PLANNED_CAMPAIGN"; payload: PlannedCampaignData }
  | { type: "REMOVE_PLANNED_CAMPAIGN"; payload: number }
  | {
      type: "EDIT_PLANNED_CAMPAIGN";
      payload: { campaign: PlannedCampaignData };
    }
  | { type: "SET_EVENT_CONTEXT"; payload: string }
  | { type: "SET_FEEDBACK_CONTEXT"; payload: string }
  | { type: "SET_EMAILS_PER_WEEK"; payload: number };

// Create the reducer
const chatReducer = (state: ChatState, action: ChatAction): ChatState => {
  switch (action.type) {
    case "SET_CAMPAIGN_START_DATE":
      return { ...state, campaignStartDate: action.payload };
    case "ADD_PLANNED_CAMPAIGN":
      return {
        ...state,
        plannedCampaigns: [...state.plannedCampaigns, action.payload],
      };
    case "REMOVE_PLANNED_CAMPAIGN":
      return {
        ...state,
        plannedCampaigns: state.plannedCampaigns.filter(
          (campaign, index) => index !== action.payload
        ),
      };
    case "EDIT_PLANNED_CAMPAIGN":
      return {
        ...state,
        plannedCampaigns: state.plannedCampaigns.map((campaign, index) =>
          campaign.id === action.payload.campaign.id
            ? action.payload.campaign
            : campaign
        ),
      };
    case "SET_EVENT_CONTEXT":
      return { ...state, eventContext: action.payload };
    case "SET_FEEDBACK_CONTEXT":
      return { ...state, feedbackContext: action.payload };
    case "SET_EMAILS_PER_WEEK":
      return { ...state, emailsPerWeek: action.payload };
    default:
      return state;
  }
};

// Create the context
interface ChatContextType {
  state: ChatState;
  dispatch: React.Dispatch<ChatAction>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useOptionHandlers = () => {
  const { state, dispatch } = useChatContext();

  return {
    nextWeek: (option: UserOption) => {
      const today = new Date();
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + 7);
      const formattedDate = newDate.toISOString().split("T")[0];
      dispatch({ type: "SET_CAMPAIGN_START_DATE", payload: formattedDate });
      return { text: "Next week" };
    },
    nextMonth: (option: UserOption) => {
      const today = new Date();
      const newDate = new Date(today);
      newDate.setMonth(today.getMonth() + 1);
      const formattedDate = newDate.toISOString().split("T")[0];
      dispatch({ type: "SET_CAMPAIGN_START_DATE", payload: formattedDate });
      return { text: "Next month" };
    },
    customDate: (date: string) => {
      dispatch({
        type: "SET_CAMPAIGN_START_DATE",
        payload: date,
      });
      const displayDate = dayjs(date).format("MMMM D, YYYY");
      return { text: displayDate };
    },
    addPlannedCampaigns: (campaignData: PlannedCampaignData) => {
      dispatch({
        type: "ADD_PLANNED_CAMPAIGN",
        payload: campaignData,
      });
      return { text: campaignData.title };
    },
    standardText: (option: UserOption) => {
      // For standard text options that don't need special handling
      return { text: option.text };
    },
    setEventContext: (input: string) => {
      // For text input options that don't need special handling
      dispatch({
        type: "SET_EVENT_CONTEXT",
        payload: input,
      });
      return { text: input };
    },
    setFeedbackContext: (input: string) => {
      dispatch({
        type: "SET_FEEDBACK_CONTEXT",
        payload: input,
      });
      return { text: input };
    },
    setEmailsPerWeek: (emails: number) => {
      dispatch({
        type: "SET_EMAILS_PER_WEEK",
        payload: emails,
      });
      return {
        text: `Update it to ${emails} emails per week`,
      };
    },
  };
};

// Create a provider component
export const DEFAULT_EMAILS_PER_WEEK = 3;
export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(chatReducer, {
    campaignStartDate: "",
    plannedCampaigns: [],
    eventContext: "",
    feedbackContext: "",
    emailsPerWeek: DEFAULT_EMAILS_PER_WEEK,
  });

  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};

// Create a hook to use the context
export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
