import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);

export const getTimeDifference = (dateTime: string) => {
  const diffHours = Math.floor(
    (new Date(dateTime).getTime() - new Date().getTime()) / 1000 / 3600
  );
  const diffDays = Math.floor(Math.abs(diffHours) / 24);
  return {
    days: diffDays,
    hours: Math.abs(diffHours),
    isInFuture: diffHours > 0,
  };
};

export const nextRecommenderScheduleDate = () => {
  const scheduleDates = [
    { day: 1, hour: 3 },
    { day: 3, hour: 3 },
    { day: 5, hour: 3 },
  ];
  const now = dayjs().tz("America/New_York"); // EST/EDT
  const hour = now.hour();
  const dayOfWeek = now.day();
  const nextScheduleDate =
    scheduleDates.find(
      (date) =>
        date.day > dayOfWeek || (date.day === dayOfWeek && date.hour > hour)
    ) ?? scheduleDates[0];

  const date = now
    .weekday(nextScheduleDate.day)
    .hour(nextScheduleDate.hour)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (dayOfWeek > nextScheduleDate.day) {
    return date.add(1, "week");
  }
  return date;
};

export const formatPublishedAt = (
  publishedAt: string | null,
  showFullMonth: boolean = false,
  showTime: boolean = true
) => {
  let monthFormat = showFullMonth ? "MMMM" : "MMM";
  return publishedAt
    ? dayjs
        .utc(publishedAt)
        .tz(dayjs.tz.guess())
        .format(
          showTime
            ? `${monthFormat} D, YYYY [at] h:mm A z`
            : `${monthFormat} D, YYYY`
        )
    : null;
};

export const timeAgoDescription = (dateTime: string) => {
  const { days, hours, isInFuture } = getTimeDifference(dateTime);

  if (isInFuture) {
    return dayjs(dateTime).tz(dayjs.tz.guess()).format("MMM D");
  }

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  }
  if (hours === 0) {
    return "moments ago";
  }
  return `${hours} hour${hours > 1 ? "s" : ""} ago`;
};
