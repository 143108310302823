import { components, EmailSectionType } from "@openapi";
import {
  EmailEditorViews,
  EmailSection,
  NewEmailSection,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

export type EmailHeroSectionSchema = Omit<
  components["schemas"]["EmailHeroSectionSchema"],
  "type"
> & { type: EmailSectionType.hero };
export type EmailHeroProductSectionSchema = Omit<
  components["schemas"]["EmailHeroProductSectionSchema"],
  "type"
> & {
  type: EmailSectionType.hero_product;
};
export type EmailReviewsSectionSchema = Omit<
  components["schemas"]["EmailReviewsSectionSchema"],
  "type"
> & {
  type: EmailSectionType.reviews;
};
export type EmailHeroCollectionSectionSchema = Omit<
  components["schemas"]["EmailHeroCollectionSectionSchema"],
  "type"
> & {
  type: EmailSectionType.hero_collection;
};
export type EmailHeaderSectionSchema = Omit<
  components["schemas"]["EmailHeaderSectionSchema"],
  "type"
> & {
  type: EmailSectionType.header;
};
export type EmailFooterSectionSchema = Omit<
  components["schemas"]["EmailFooterSectionSchema"],
  "type"
> & {
  type: EmailSectionType.footer;
};
export type EmailBlogSectionSchema = Omit<
  components["schemas"]["EmailBlogSectionSchema"],
  "type"
> & {
  type: EmailSectionType.blog;
};
export type EmailProductsSectionSchema = Omit<
  components["schemas"]["EmailProductsSectionSchema"],
  "type"
> & {
  type: EmailSectionType.products;
};
export type EmailTextSectionSchema = Omit<
  components["schemas"]["EmailTextSectionSchema"],
  "type"
> & {
  type: EmailSectionType.text;
};
export type EmailImageSectionSchema = Omit<
  components["schemas"]["EmailImageSectionSchema"],
  "type"
> & {
  type: EmailSectionType.image;
};
export type EmailImageElementSchema =
  components["schemas"]["EmailImageElementSchema"];

export type EmailCTACardSectionSchema = Omit<
  components["schemas"]["EmailCTACardSectionSchema"],
  "type"
> & {
  type: EmailSectionType.cta_card;
};
export type EmailCollectionGridSectionSchema = Omit<
  components["schemas"]["EmailCollectionGridSectionSchema"],
  "type"
> & {
  type: EmailSectionType.collection_grid;
};
export type EmailListicleSectionSchema = Omit<
  components["schemas"]["EmailListicleSectionSchema"],
  "type"
> & {
  type: EmailSectionType.listicle;
};
export type EmailSalesEventHeroSectionSchema = Omit<
  components["schemas"]["EmailSalesEventHeroSectionSchema"],
  "type"
> & {
  type: EmailSectionType.hero_sales_event;
};

export const isNewSection = (
  section: EmailSection
): section is NewEmailSection => {
  return section.type === null;
};

export const isHeroSection = (
  section: EmailSection
): section is EmailHeroSectionSchema => {
  return section.type === EmailSectionType.hero;
};

export const isHeroProductSection = (
  section: EmailSection
): section is EmailHeroProductSectionSchema => {
  return section.type === EmailSectionType.hero_product;
};

export const isReviewSection = (
  section: EmailSection
): section is EmailReviewsSectionSchema => {
  return section.type === EmailSectionType.reviews;
};

export const isHeroCollectionSection = (
  section: EmailSection
): section is EmailHeroCollectionSectionSchema => {
  return section.type === EmailSectionType.hero_collection;
};

export const isHeaderSection = (
  section: EmailSection
): section is EmailHeaderSectionSchema => {
  return section.type === EmailSectionType.header;
};

export const isFooterSection = (
  section: EmailSection
): section is EmailFooterSectionSchema => {
  return section.type === EmailSectionType.footer;
};

export const isBlogSection = (
  section: EmailSection
): section is EmailBlogSectionSchema => {
  return section.type === EmailSectionType.blog;
};

export const isProductsSection = (
  section: EmailSection
): section is EmailProductsSectionSchema => {
  return section.type === EmailSectionType.products;
};

export const isTextSection = (
  section: EmailSection
): section is EmailTextSectionSchema => {
  return section.type === EmailSectionType.text;
};

export const isImageSection = (
  section: EmailSection
): section is EmailImageSectionSchema => {
  return section.type === EmailSectionType.image;
};

export const isCtaSection = (
  section: EmailSection
): section is EmailCTACardSectionSchema => {
  return section.type === EmailSectionType.cta_card;
};

export const isCollectionGridSection = (
  section: EmailSection
): section is EmailCollectionGridSectionSchema => {
  return section.type === EmailSectionType.collection_grid;
};

export const isListicleSection = (
  section: EmailSection
): section is EmailListicleSectionSchema => {
  return section.type === EmailSectionType.listicle;
};

export const isSalesEventHeroSection = (
  section: EmailSection
): section is EmailSalesEventHeroSectionSchema => {
  return section.type === EmailSectionType.hero_sales_event;
};

export const convertSectionToEnum = (
  section: EmailSection
): EmailEditorViews => {
  if (section.type === null) {
    return EmailEditorViews.AddBlock;
  }
  return section.type;
};
