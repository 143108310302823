import CloseIconButton from "../../../../core/buttons/CloseIconButton";
import AssetSelectorDialogGenerateView from "./AssetSelectorDialogGenerateView";
import AIGeneratedImages, {
  AIGeneratedImagesQueryObject,
} from "./sections/AIGeneratedImages";
import AssetSelectorSections from "./sections/AssetSelectorSections";
import LibraryAssets, { LibraryAssetsProps } from "./sections/LibraryAssets";
import RelatedImages, { RelatedImageType } from "./sections/RelatedImages";
import UploadImageView from "./upload-image-view/UploadImageView";
import { components, ImageAssetCategory } from "@openapi";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Dialog, Flex, Heading, TextField } from "@radix-ui/themes";
import { Text } from "@radix-ui/themes";
import { useGateValue } from "@statsig/react-bindings";
import { InfiniteData } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Toaster } from "sonner";
import styled from "styled-components";
import { SparklesIcon } from "~/assets/icons";
import { UploadIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import UploadImageButton from "~/components/core/buttons/UploadImageButton";
import {
  ImageAssetSchema,
  CloudAssetSchema,
} from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { DefaultRelatedImagesQueryObject } from "~/hooks/emails/useGetEmailRelatedImages";
import { getImageAssetsQueryKey } from "~/hooks/media/useImageAssetsQuery";
import useUploadImageAssets from "~/hooks/style-library/useUploadImageAssets";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

export const HoverOverlay = styled.div<{ $isSelected?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.$isSelected ? 1 : 0)};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export type AssetSelectorCommercePlatformItem = {
  url: string;
  commerce_platform_item_id: string | null;
};

export type AssetSelectorItem =
  | AssetSelectorCommercePlatformItem
  | ImageAssetSchema
  | CloudAssetSchema;

export type AssetSelectorDialogProps = {
  campaignId: string | null;
  singleAsset?: boolean;
  isDialogOpen: boolean;
  onToggleDialogOpen: (open: boolean) => void;
  preselectedAssets: AssetSelectorItem[];
  onSelect: (assets: AssetSelectorItem[]) => void;
  sourceObjectID?: string;
  type?: RelatedImageType;
  categoryForUploadedImages?: ImageAssetCategory;
} & Pick<LibraryAssetsProps, "categories" | "includeDefaultCategories">;

const AssetSelectorDialog = ({
  campaignId,
  singleAsset,
  isDialogOpen,
  onToggleDialogOpen,
  preselectedAssets,
  onSelect,
  sourceObjectID,
  type,
  categoryForUploadedImages = ImageAssetCategory.other,
  categories,
  includeDefaultCategories,
}: AssetSelectorDialogProps) => {
  const activeBrandID = useActiveBrandID();
  const queryClient = useQueryClient();
  const { related_commerce_item_ids } = useEmailState();

  const [selectedAssets, setSelectedAssets] = useState<AssetSelectorItem[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isGenerateImageViewActive, setIsGenerateImageViewActive] =
    useState<boolean>(false);
  const [isUploadImageViewActive, setIsUploadImageViewActive] =
    useState<boolean>(false);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");
  const isCloudSelectorEnabled = useGateValue("is_cloud_selector_enabled", {
    disableExposureLog: true,
  });

  useEffect(() => {
    if (isDialogOpen) {
      setSelectedAssets(preselectedAssets ?? []);
      setIsGenerateImageViewActive(false);
    }
  }, [isDialogOpen]);

  const {
    mutate: uploadImageAssetsMutation,
    isPending: isUploadImageAssetMutationPending,
  } = useUploadImageAssets({
    onSuccess: (data) => {
      setSelectedAssets(data.new_assets);
      handleSearchChange("");
    },
    onError: (_, displayMessage) => {
      setUploadError?.(displayMessage);
    },
  });

  const debouncedSearchCall = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchCall.cancel();
    };
  }, [debouncedSearchCall]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    debouncedSearchCall(value);
  };

  const selectAsset = (asset: AssetSelectorItem) => {
    if (singleAsset) {
      setSelectedAssets([asset]);
    } else {
      !!selectedAssets.find((file) => file.url === asset.url)
        ? setSelectedAssets(
            selectedAssets.filter((file) => file.url !== asset.url)
          )
        : setSelectedAssets([...selectedAssets, asset]);
    }
  };

  const header = (
    <Flex direction="column">
      <Flex
        justify="between"
        align="center"
        p="16px"
        pb="12px"
        style={{ borderBottom: "1px solid #E2E2E2" }}
      >
        <Dialog.Title size="4" weight="bold" mb="0">
          Select Image
        </Dialog.Title>
        <Dialog.Close>
          <CloseIconButton />
        </Dialog.Close>
      </Flex>

      <Flex gap="5" p="16px" align="center">
        <TextField.Root
          value={search}
          onChange={(event) => handleSearchChange(event.target.value)}
          style={{
            height: "40px",
            backgroundColor: "#F1F1F0",
            width: "calc(100% - 24px)",
          }}
          radius="large"
          color="teal"
          variant="soft"
          placeholder="Search…"
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
        {isCloudSelectorEnabled ? (
          <AppButton
            variant="outlined"
            size="3"
            radius="large"
            onClick={() => setIsUploadImageViewActive(true)}
          >
            <UploadIcon size={20} />
            <Text size="3" weight="medium">
              Upload
            </Text>
          </AppButton>
        ) : (
          <UploadImageButton
            brandID={activeBrandID}
            category={categoryForUploadedImages}
            setUploadError={setUploadError}
            onUpload={uploadImageAssetsMutation}
            isLoading={isUploadImageAssetMutationPending}
          />
        )}
      </Flex>
      {uploadError && (
        <Heading size="1" color="red" align={"center"} mb="2">
          {uploadError}
        </Heading>
      )}
    </Flex>
  );
  const content = (
    <Flex direction="column" px="24px" py="16px" gap="3">
      <AssetSelectorSections
        sections={[
          campaignId
            ? {
                title: "AI Generated",
                section: (
                  <AIGeneratedImages
                    selectAsset={selectAsset}
                    search={debouncedSearch}
                    selectedAssets={selectedAssets}
                    campaignId={campaignId}
                  />
                ),
              }
            : null,
          type
            ? {
                title: "Related Images",
                section: (
                  <RelatedImages
                    onSelect={selectAsset}
                    selectedAssets={selectedAssets}
                    sourceObjectID={sourceObjectID}
                    type={type}
                  />
                ),
              }
            : null,
          {
            title: "Library Assets",
            section: (
              <LibraryAssets
                search={debouncedSearch}
                selectedAssets={selectedAssets}
                selectAsset={selectAsset}
                categories={categories}
                includeDefaultCategories={includeDefaultCategories}
              />
            ),
          },
        ]}
      />
    </Flex>
  );
  const footer = (
    <Flex
      px="24px"
      py="12px"
      justify="between"
      gap="3"
      style={{ borderTop: "1px solid #E2E2E2" }}
    >
      {campaignId ? (
        <AppButton
          disabled={selectedAssets.length !== 1}
          variant="soft"
          size="3"
          radius="large"
          onClick={() => {
            // Unfortunately, on first load, selectedAssets are all just Commerce Platform Items
            // So we need to find the matching image asset from the query cache
            if (
              !(selectedAssets[0] as ImageAssetSchema)
                ?.commerce_platform_item_id
            ) {
              const aiGeneratedAssets = queryClient.getQueryData(
                getImageAssetsQueryKey(
                  AIGeneratedImagesQueryObject(activeBrandID, campaignId)
                )
              ) as InfiniteData<{
                assets: components["schemas"]["ImageAssetSchema"][];
                next_offset: number | null;
              }>;

              const matchingAsset = aiGeneratedAssets?.pages
                .flatMap((page) => page.assets)
                .find((asset) => asset.url === selectedAssets[0].url);

              if (matchingAsset) {
                setSelectedAssets([matchingAsset]);
              }

              const relatedImagesAssets = queryClient.getQueryData(
                getImageAssetsQueryKey(
                  DefaultRelatedImagesQueryObject(
                    activeBrandID,
                    related_commerce_item_ids
                  )
                )
              ) as InfiniteData<{
                assets: components["schemas"]["ImageAssetSchema"][];
                next_offset: number | null;
              }>;

              const matchingDefaultRelatedImagesAsset =
                relatedImagesAssets?.pages
                  .flatMap((page) => page.assets)
                  .find((asset) => asset.url === selectedAssets[0].url);

              if (matchingDefaultRelatedImagesAsset) {
                setSelectedAssets([matchingDefaultRelatedImagesAsset]);
              }
            }
            setIsGenerateImageViewActive(true);
          }}
        >
          <SparklesIcon size="20px" fill="var(--primary-deep-purple)" />
          Generate from Selected
        </AppButton>
      ) : (
        <div />
      )}

      <Flex gap="3">
        <Dialog.Close>
          <AppButton variant="outlined" size="3" radius="large">
            Cancel
          </AppButton>
        </Dialog.Close>
        <Dialog.Close>
          <AppButton
            disabled={!selectedAssets.length}
            onClick={() => onSelect(selectedAssets)}
            variant="primary"
            size="3"
            radius="large"
          >
            Use Selected Image{selectedAssets.length > 1 ? "s" : ""}
          </AppButton>
        </Dialog.Close>
      </Flex>
    </Flex>
  );
  let allContent = (
    <>
      {header}
      <div
        style={{
          overflowY: !isGenerateImageViewActive ? "auto" : undefined,
          maxHeight: !isGenerateImageViewActive ? "70vh" : undefined,
        }}
      >
        {content}
      </div>
      {footer}
    </>
  );

  if (isGenerateImageViewActive) {
    allContent = (
      <AssetSelectorDialogGenerateView
        sourceAsset={selectedAssets[0]}
        campaignId={campaignId}
        onSelect={onSelect}
        onBack={() => setIsGenerateImageViewActive(false)}
      />
    );
  }

  if (isCloudSelectorEnabled && isUploadImageViewActive) {
    allContent = (
      <UploadImageView
        category={categoryForUploadedImages}
        setSelectedAssets={setSelectedAssets}
        onSelect={onSelect}
        onBack={() => setIsUploadImageViewActive(false)}
      />
    );
  }

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={onToggleDialogOpen}>
      <Dialog.Content
        maxWidth={isUploadImageViewActive ? "1080px" : "780px"}
        style={{ padding: 0 }}
      >
        <Toaster richColors />
        {allContent}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AssetSelectorDialog;
