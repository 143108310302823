import Sidepane from "../../components/core/sidepane/Sidepane";
import { useAuthenticatedUserState } from "../../contexts/CurrentUserContext";
import { DynamicHeaderProvider } from "../../contexts/DynamicHeaderContext";
import { Flex, Text } from "@radix-ui/themes";
import {
  GalleryVertical,
  Images,
  Mail,
  Martini,
  Layers,
  Palette,
  SwatchBook,
  Library,
  LayoutTemplate,
  Calendar as CalendarIcon,
} from "lucide-react";
import { Link, NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";
import AdminBar from "~/components/core/layout/AdminBar";

enum InternSectionType {
  ADS = "ads",
  ASSETS = "assets",
  BRAND = "brand",
  BRAND_EMAILS = "brand-emails",
  CALENDAR = "calendar",
  CAMPAIGNS = "campaign",
  EMAILS = "emails",
  RECOMMENDER = "recommender",
  STYLES = "styles",
}

const SECTIONS = [
  { type: InternSectionType.BRAND, icon: SwatchBook, title: "Brand" },
  { type: InternSectionType.RECOMMENDER, icon: Layers, title: "Recommender" },
  { type: InternSectionType.STYLES, icon: Palette, title: "Styles" },
  { type: InternSectionType.CAMPAIGNS, icon: Library, title: "Campaigns" },
  { type: InternSectionType.CALENDAR, icon: CalendarIcon, title: "Calendar" },
  { type: InternSectionType.ASSETS, icon: Images, title: "Assets" },
  { type: InternSectionType.ADS, icon: GalleryVertical, title: "Ads" },
  { type: InternSectionType.EMAILS, icon: Mail, title: "Emails" },
  {
    type: InternSectionType.BRAND_EMAILS,
    icon: LayoutTemplate,
    title: "Brand Emails",
  },
];

const Icon = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: var(--primary-white);
  color: var(--primary-deep-purple);
  padding: 12px;
  stroke-width: 1.5;
  border-radius: 50%;
  border: 1px solid var(--border-primary);
  transition: background-color 0.3s;
  &:hover {
    background-color: rgb(0, 0, 0, 0.07);
  }
`;

export function SidepaneComponent() {
  return (
    <Sidepane
      padding="20px"
      header={
        <Link
          to={"/intern"}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            fontWeight: "600",
            textDecoration: "none",
            color: "inherit",
            marginBottom: "2rem",
          }}
        >
          <Martini
            style={{
              height: "1.5rem",
              width: "1.5rem",
            }}
          />
          <span>Draper</span>
        </Link>
      }
      navigation={
        <nav
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            fontSize: "0.875rem",
            fontWeight: "500",
            marginBottom: "1rem",
          }}
        >
          {SECTIONS.map((s) => {
            return (
              <NavLink
                to={"/intern/" + s.type}
                key={s.type}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                  gap: "0.2rem",
                }}
              >
                <Icon>
                  <s.icon
                    style={{
                      height: "1rem",
                      width: "1rem",
                    }}
                  />
                </Icon>
                <Text size="1" weight="bold">
                  {s.title}
                </Text>
              </NavLink>
            );
          })}
        </nav>
      }
    />
  );
}

export function MainContentComponent() {
  return (
    <main
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingRight: "1rem",
      }}
    >
      <AdminBar autohide={false} />
      <Outlet />
    </main>
  );
}

export default function InternRoute() {
  const { brands } = useAuthenticatedUserState();

  if (brands == null) return <div>No brands returned</div>;

  return (
    <Flex minHeight="100vh" width="100%" justify={"end"}>
      <SidepaneComponent />

      <Flex
        direction={"column"}
        width={`calc(100% - ${
          document.getElementById("root-sidepanel")?.clientWidth ?? 126
        }px)`}
      >
        <DynamicHeaderProvider>
          <MainContentComponent />
        </DynamicHeaderProvider>
      </Flex>
    </Flex>
  );
}
