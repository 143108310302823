import { Flex, IconButton } from "@radix-ui/themes";
import { useMemo } from "react";
import styled from "styled-components";
import { ChevronLeftIcon, ChevronRightIcon } from "~/assets/icons";

const generatePageNumbers = (totalPages: number, currentPage: number) => {
  if (!totalPages) return [];

  const maxVisiblePages = 6;
  const currentPageNumber = currentPage + 1;

  // If there are less than maxVisiblePages pages, show all pages
  if (totalPages <= maxVisiblePages) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  // If the current page is close to the beginning, show the first pages
  if (currentPageNumber <= 3) {
    return [1, 2, 3, 4, "...", totalPages];
  }

  // If the current page is close to the end, show the last pages
  if (currentPageNumber >= totalPages - 2) {
    return [
      1,
      "...",
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ];
  }

  // Show the current page and the pages around it
  return [
    1,
    "...",
    currentPageNumber - 1,
    currentPageNumber,
    currentPageNumber + 1,
    "...",
    totalPages,
  ];
};

const BaseButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  padding: 6px 4px;
  border: 1px solid #ddd7d7;
  border-radius: 5px;
  cursor: pointer;
`;

const PageNumber = styled(BaseButton)<{ $isCurrentPage: boolean }>`
  border: ${({ $isCurrentPage }) =>
    $isCurrentPage ? "1px solid #DDD7D7" : "none"};
  color: ${({ $isCurrentPage }) => ($isCurrentPage ? "#FFFFFF" : "#4C4747")};
  background-color: ${({ $isCurrentPage }) =>
    $isCurrentPage ? "#2F048A" : "transparent"};
  font-weight: ${({ $isCurrentPage }) => ($isCurrentPage ? "700" : "400")};
`;

const PrevNextButton = styled(BaseButton)<{ $isDisabled: boolean }>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
  background-color: transparent;
  border-radius: 8px; /* Override only if different */
`;

const Paginator = ({
  hasNextPage,
  hasPreviousPage,
  currentPage,
  updatePage,
  totalPages,
  showPaginationNumbers,
}: {
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
  currentPage: number;
  updatePage: (addend: number) => void;
  totalPages?: number;
  showPaginationNumbers?: boolean;
}) => {
  const pageNumbers = useMemo(() => {
    return totalPages ? generatePageNumbers(totalPages, currentPage) : [];
  }, [totalPages, currentPage]);

  return (
    <Flex gap="2">
      <PrevNextButton
        $isDisabled={!hasPreviousPage}
        onClick={() => updatePage(-1)}
        disabled={!hasPreviousPage}
      >
        <ChevronLeftIcon color={!hasPreviousPage ? "#DDD7D7" : "#595D62"} />
      </PrevNextButton>

      {showPaginationNumbers && (
        <Flex>
          {pageNumbers.map((page, index) => {
            return (
              <PageNumber
                key={index}
                $isCurrentPage={page === currentPage + 1}
                onClick={() =>
                  typeof page === "number" &&
                  updatePage(page - (currentPage + 1))
                }
              >
                {page}
              </PageNumber>
            );
          })}
        </Flex>
      )}

      <PrevNextButton
        $isDisabled={!hasNextPage}
        disabled={!hasNextPage}
        onClick={() => updatePage(1)}
      >
        <ChevronRightIcon color={hasNextPage ? "#595D62" : "#DDD7D7"} />
      </PrevNextButton>
    </Flex>
  );
};

export default Paginator;
