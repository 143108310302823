import { brandColorPalettesQueryKey } from "../brand/useBrandColorPalettesQuery";
import { getImageAssetsQueryKey } from "../media/useImageAssetsQuery";
import useGenerateEmailSectionStatusQuery from "./useGenerateEmailSectionStatusQuery";
import { EmailSectionType, operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { AIGeneratedImagesQueryObject } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/AIGeneratedImages";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  useEmailState,
  useInitSection,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type InitEmailSectionMutationParams =
  operations["emails_api_init_email_section"]["requestBody"]["content"]["application/json"];
type Response =
  operations["emails_api_init_email_section"]["responses"][200]["content"]["application/json"];

type GenerateParams =
  operations["emails_api_generate_email_section"]["requestBody"]["content"]["application/json"];
type GenerateResponse =
  operations["emails_api_generate_email_section"]["responses"][200]["content"]["application/json"];

const useInitEmailSectionMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: Response) => Response;
} = {}) => {
  const initSection = useInitSection();

  return useDraperApiPostMutation<Response, InitEmailSectionMutationParams>({
    path: "/emails/section/init",
    onSuccess: (data) => {
      const updatedData = onSuccess ? onSuccess(data) : data;
      initSection(updatedData);
    },
    onError: (error) => {
      console.error("Failed to init section", error);
      toast.error("Failed to generate section");
    },
  });
};

const useInitEmailSectionWithGenerateMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: Response) => Response;
  onError?: (error: string) => void;
} = {}) => {
  // Replacement for /emails/section/init, combines multiple requests, using the same parameters.
  // For generating new sections (with AI):
  //  1. /emails/section/generate to schedule the job
  //  2. check job status by querying /emails/section/generate/status
  //  3. When job is complete:
  //    a. successfully: call `onSuccess` with the generated section
  //    b. error:
  //      - if `generate_fallback_to_default` is true, fall back to original endpoint (/emails/section/init), passing `generate: false`
  //      - otherwise, call `onError`
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const { campaign_id } = useEmailState();
  const [generationSessionID, setGenerationSessionID] = useState<string | null>(
    null
  );
  const [delayedGenerationSessionID, setDelayedGenerationSessionID] = useState<
    string | null
  >(null);
  const [params, setParams] = useState<InitEmailSectionMutationParams | null>(
    null
  );
  const initSectionMutation = useInitEmailSectionMutation({
    onSuccess: onSuccess,
  });
  const initSectionState = useInitSection();

  useEffect(() => {
    setTimeout(() => {
      setDelayedGenerationSessionID(generationSessionID);
    }, 1000);
  }, [generationSessionID]);

  const handleError = (error: string) => {
    console.error(error);
    if (params?.generate_fallback_to_default) {
      console.error("Falling back to default section");
      initSectionMutation.mutate({
        ...params,
        generate: false,
      });
      return;
    }
    if (onError) {
      onError(error);
    } else {
      toast.error(error);
    }
  };

  const schedule = useDraperApiPostMutation<GenerateResponse, GenerateParams>({
    path: "/emails/section/generate",
    onSuccess: (data) => {
      if (data.job_session_id) {
        setGenerationSessionID(data.job_session_id);
      } else {
        handleError("Failed to generate section.");
        setGenerationSessionID(null);
      }
    },
    onError: (error) => {
      setGenerationSessionID(null);
      if (isAxiosError(error) && error.response?.status === 403) {
        handleError(
          "You do not have permission to generate new variants for this email."
        );
      } else {
        handleError("An error occurred while generating new section.");
      }
    },
  });

  const generateStatusQuery = useGenerateEmailSectionStatusQuery({
    brandId: params?.brand_id ?? activeBrandID,
    jobSessionId: generationSessionID ? delayedGenerationSessionID : null,
    sectionType: params?.section_type ?? EmailSectionType.cta_card,
  });

  useEffect(() => {
    if (!generateStatusQuery) {
      return;
    }
    if (generateStatusQuery.error) {
      handleError(generateStatusQuery.error);
    } else if (generationSessionID !== null && !!generateStatusQuery.section) {
      const updatedData = onSuccess
        ? onSuccess(generateStatusQuery.section)
        : generateStatusQuery.section;
      initSectionState(updatedData);

      // Invalidate campaign color palette query since new campaign color palettes may have been created
      queryClient.invalidateQueries({
        queryKey: brandColorPalettesQueryKey(activeBrandID, campaign_id),
        refetchType: "all",
      });
      // Invalidate Image Assets query since new image assets may have been created
      queryClient.invalidateQueries({
        queryKey: getImageAssetsQueryKey(
          AIGeneratedImagesQueryObject(activeBrandID, campaign_id)
        ),
        refetchType: "all",
      });
    }
    if (generateStatusQuery.is_finished) {
      setGenerationSessionID(null);
    }
  }, [generateStatusQuery, generationSessionID]);

  return {
    mutate: (params: InitEmailSectionMutationParams) => {
      if (schedule.isPending || !!generationSessionID) {
        console.error("Already generating a section");
        return;
      }

      if (!params.generate) {
        // proxy to original endpoint
        initSectionMutation.mutate(params);
        return;
      }

      setParams(params);
      schedule.mutate({
        section_type: params.section_type,
        email_creative_id: params.email_creative_id!,
        id: params.id,
        index: params.index,
        prompt_summary: params.prompt_summary!,
        asset_id: params.asset_id,
        product_ids: params.product_ids,
        review_ids: params.selected_review_ids,
      });
    },
    isPending:
      schedule.isPending ||
      !!generationSessionID ||
      initSectionMutation.isPending,
  };
};

export default useInitEmailSectionWithGenerateMutation;
