import LoadingDots from "../../../misc/LoadingDots";
import { AssetSelectorItem, HoverOverlay } from "../AssetSelectorDialog";
import { ImageAssetCategory, ImageAssetSource } from "@openapi";
import { CheckIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";
import { useMemo } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useImageAssetsQuery, {
  getImageAssetsQueryKey,
} from "~/hooks/media/useImageAssetsQuery";

const DEFAULT_PARAMS = {
  category: [
    ImageAssetCategory.other,
    ImageAssetCategory.background_image,
    ImageAssetCategory.lifestyle,
    ImageAssetCategory.product,
  ],
  source: [ImageAssetSource.file_upload, ImageAssetSource.scraping],
  limit: 6,
};
export const ImageAssetsDefaultKey = ({
  brandId,
  includeDefaultCategories = true,
}: {
  brandId: string;
  includeDefaultCategories?: boolean;
}) =>
  getImageAssetsQueryKey({
    brandId,
    ...DEFAULT_PARAMS,
    category: includeDefaultCategories ? DEFAULT_PARAMS.category : undefined,
  });

export interface LibraryAssetsProps {
  selectAsset: (asset: AssetSelectorItem) => void;
  search: string;
  selectedAssets: AssetSelectorItem[];
  categories?: ImageAssetCategory[];
  includeDefaultCategories?: boolean;
}

const LibraryAssets = ({
  selectAsset,
  search,
  selectedAssets,
  categories,
  includeDefaultCategories = true,
}: LibraryAssetsProps) => {
  const activeBrandID = useActiveBrandID();

  const { assetsData, isLoading, error, fetchNextPage, hasNextPage } =
    useImageAssetsQuery({
      brandId: activeBrandID,
      search: search,
      ...DEFAULT_PARAMS,
      category: [
        ...(categories ?? []),
        ...(includeDefaultCategories ? DEFAULT_PARAMS.category : []),
      ],
    });
  const assetImages = useMemo(
    () => assetsData?.flatMap((data) => data.assets),
    [assetsData]
  );

  if (error) {
    return <Text size="2">Failed to fetch assets</Text>;
  }

  if (!assetImages?.length && !isLoading) {
    return <Text size="2">No assets found</Text>;
  }

  return (
    <Flex direction="column" gap="2">
      <Flex
        wrap="wrap"
        gap="24px"
        style={{ gridTemplateColumns: "repeat(6, 1fr)", display: "grid" }}
      >
        {(assetImages ?? []).map((asset) => (
          <BrandAsset
            key={asset.id}
            asset={asset}
            overlay={
              <HoverOverlay
                $isSelected={
                  !!selectedAssets.find((file) => {
                    if ("id" in file) {
                      // some of the assets might have the same url - if there's an id, match only based on the id
                      return file.id === asset.id;
                    }
                    return file.url === asset.url;
                  })
                }
              >
                <CheckIcon color="white" width="24" height="24" />
              </HoverOverlay>
            }
            onClick={() => {
              selectAsset(asset);
            }}
          />
        ))}
      </Flex>
      {hasNextPage && !isLoading && (
        <Flex justify="start">
          <AppButton
            radius="large"
            size="3"
            variant="outlined"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage}
          >
            Load More
          </AppButton>
        </Flex>
      )}

      {isLoading && (
        <Flex justify={"center"} align={"center"} my="5">
          <LoadingDots />
        </Flex>
      )}
    </Flex>
  );
};

export default LibraryAssets;
