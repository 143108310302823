import HtmlPreview from "@core/HtmlPreview";
import { Box, Flex, Skeleton, Text } from "@radix-ui/themes";
import Handlebars from "handlebars";
import { useMemo } from "react";
import styled from "styled-components";
import { useTemplateEditorState } from "~/contexts/TemplateEditorContext";
import { useHandlebarsInit } from "~/utils/emails/useHandlebarsInit";

const MobilePreviewContainer = styled.div`
  width: 375px;
  height: 667px;
  margin: 20px auto;
  border: 10px solid #333;
  border-radius: 30px;
  overflow: hidden;
`;

const TemplateEditorPreview = () => {
  useHandlebarsInit();
  const { headerOptions, footerOptions, previewMode } =
    useTemplateEditorState();
  const state = useTemplateEditorState();

  const headerTemplate = useMemo(() => {
    const template = headerOptions.template;
    return Handlebars.compile(template.html);
  }, [headerOptions]);

  const footerTemplate = useMemo(() => {
    const template = footerOptions.template;
    return Handlebars.compile(template.html);
  }, [footerOptions]);

  const sectionComponents = [
    <HtmlPreview key="header" html={headerTemplate(state.headerOptions)} />,

    <Flex
      key="content"
      direction="column"
      gap="5"
      align="center"
      justify="center"
      p="4"
    >
      <Flex gap="5">
        <Box flexShrink={"0"}>
          <Skeleton height="140px" width="140px" />
        </Box>
        <Text
          size="5"
          style={{
            maxHeight: "140px",
            overflow: "hidden",
            wordBreak: "break-word",
          }}
        >
          <Skeleton>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
            accusantium voluptates recusandae vero ipsum reiciendis mollitia
            impedit autem est odit rerum dignissimos, voluptatem iure.
          </Skeleton>
        </Text>
      </Flex>
      <Text
        size="5"
        style={{
          maxHeight: "140px",
          overflow: "hidden",
          wordBreak: "break-word",
        }}
      >
        <Skeleton>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
          accusantium voluptates recusandae vero ipsum reiciendis mollitia
          impedit autem est odit rerum dignissimos, voluptatem iure.
        </Skeleton>
      </Text>
    </Flex>,

    <HtmlPreview key="footer" html={footerTemplate(state.footerOptions)} />,
  ];

  return (
    <Flex
      justify="center"
      align="center"
      width="100%"
      py="24px"
      overflow={"auto"}
      direction="column"
      id="email-editor-preview"
      pt="calc(var(--editor-top-bar-height) + var(--space-3))"
    >
      {previewMode === "desktop" ? (
        <div style={{ display: "block", width: "600px" }}>
          {sectionComponents}
        </div>
      ) : (
        <MobilePreviewContainer>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            {sectionComponents}
          </div>
        </MobilePreviewContainer>
      )}
    </Flex>
  );
};

export default TemplateEditorPreview;
