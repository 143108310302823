import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type BrandInspirationResponse =
  operations["brand_api_get_brand_inspirations"]["responses"][200]["content"]["application/json"];

const useBrandInspirationsQuery = () => {
  const {
    data: brandInspirations,
    isLoading,
    isSuccess,
  } = useQuery<BrandInspirationResponse>({
    queryKey: ["brand-inspirations"],
    queryFn: async (): Promise<BrandInspirationResponse> => {
      const { data } = await axios.get(`/api/v1/brand/brand-inspirations`);
      return data;
    },
  });

  return {
    brandInspirations,
    isLoading,
    isSuccess,
  };
};

export default useBrandInspirationsQuery;
