import { operations } from "../../../openapi";
import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type GenCreactiveRecommendationsFromCRResponse =
  operations["recommender_api_gen_creative_recommendation_from_campaign_recommendation"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationsFromCRRequestData =
  operations["recommender_api_gen_creative_recommendation_from_campaign_recommendation"]["requestBody"]["content"]["application/json"];

const useRecommenderCreativeRecommendationFromCampaignRecommendationCreateMutation =
  ({
    onSuccess,
    onError,
    onSettled,
  }: {
    onSuccess?: (data: GenCreactiveRecommendationsFromCRResponse) => void;
    onError?: (error: Error) => void;
    onSettled?: (
      data: GenCreactiveRecommendationsFromCRResponse | undefined
    ) => void;
  }) => {
    const queryClient = useQueryClient();
    const isMutatingRef = useRef(false);

    const mutation = useDraperApiPostMutation<
      GenCreactiveRecommendationsFromCRResponse,
      GenCreactiveRecommendationsFromCRRequestData
    >({
      mutationKey: [
        "/recommender/creative-recommendations-from-campaign-recommendation",
      ],
      path: "/recommender/creative-recommendations-from-campaign-recommendation",
      onError: (error) => {
        isMutatingRef.current = false;
        onError?.(error);
      },
      onSuccess: (data) => {
        isMutatingRef.current = false;
        onSuccess?.(data);
      },
      onSettled: (data) => {
        isMutatingRef.current = false;
        onSettled?.(data);
      },
      onMutate: async (variables) => {
        // Cancel any outgoing refetches to avoid overwriting our optimistic update
        await queryClient.cancelQueries({
          queryKey: [
            "/recommender/creative-recommendations-from-campaign-recommendation",
          ],
        });
      },
    });

    const genCreativeRecommendationsFromCR = (
      variables: GenCreactiveRecommendationsFromCRRequestData
    ) => {
      // Only allow one mutation at a time for a given campaign recommendation
      if (isMutatingRef.current) {
        console.log("Mutation already in progress, skipping");
        return;
      }

      isMutatingRef.current = true;
      mutation.mutate(variables);
    };

    return {
      genCreativeRecommendationsFromCR,
      isLoading: mutation.isPending,
      isSuccess: mutation.isSuccess,
      isError: mutation.isError,
      error: mutation.error,
    };
  };

export default useRecommenderCreativeRecommendationFromCampaignRecommendationCreateMutation;
