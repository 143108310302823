import { AssetSelectorItem, HoverOverlay } from "../AssetSelectorDialog";
import {
  components,
  ImageAssetCategory,
  ImageAssetSource,
  PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider,
} from "@openapi";
import { Heading, Text, Flex, Spinner } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import {
  ChevronsUpDownIcon,
  FolderOpenIcon,
  ArrowLeftIcon,
} from "lucide-react";
import { useState, useEffect } from "react";
import { CheckIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { CloudAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import useCloudImageAssetsQuery from "~/hooks/media/useCloudImageAssetsQuery";
import useCloudProviderAuth from "~/hooks/media/useCloudProviderAuth";

type CloudFolderSchema = components["schemas"]["CloudFolderSchema"];

const CloudProviderContent = ({
  category,
  providerName,
  providerId,
  selectedAsset,
  onSelect,
}: {
  category: ImageAssetCategory;
  providerName: string;
  providerId: PathsApiV1MediaAssetsCloudProviderGetParametersPathProvider;
  selectedAsset: AssetSelectorItem | null;
  onSelect: (asset: AssetSelectorItem) => void;
}) => {
  const [selectedFolder, setSelectedFolder] =
    useState<CloudFolderSchema | null>(null);
  const [parentFolder, setParentFolder] = useState<CloudFolderSchema | null>(
    null
  );
  const [sortFoldersState, setSortFoldersState] = useState<{
    by: "name" | "modified_at";
    direction: "asc" | "desc";
  }>({ by: "name", direction: "asc" });

  useEffect(() => {
    setSelectedFolder(null);
    setParentFolder(null);
  }, [providerId]);

  const {
    hasToken,
    isLoading: isCheckingAuth,
    startPolling,
    stopPolling,
  } = useCloudProviderAuth(providerId);

  useEffect(() => {
    if (hasToken) {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [hasToken, providerId]);

  const {
    assets: cloudAssets,
    folders: cloudFolders,
    isLoading: isLoadingAssets,
    fetchNextPage,
    hasNextPage,
  } = useCloudImageAssetsQuery(providerId, {
    page_size: 18,
    folder_id: selectedFolder?.id,
    enabled: hasToken,
  });

  const sortFolders = (folders: CloudFolderSchema[]) => {
    const sortDir = sortFoldersState.direction === "asc" ? 1 : -1;
    const sortKey = sortFoldersState.by;
    return [...folders].sort(
      (a, b) => sortDir * (a[sortKey] ?? "").localeCompare(b[sortKey] ?? "")
    );
  };

  const sortedFolders = sortFolders(cloudFolders);

  const assets = cloudAssets.map((asset: CloudAssetSchema) => ({
    id: asset.id,
    name: asset.name,
    url: asset.url,
    download_url: asset.download_url,
    mime_type: asset.mime_type,
    size: asset.size,
    modified_at: asset.modified_at,
    category: category,
    source: ImageAssetSource.file_upload,
    provider_id: providerId,
  })) as AssetSelectorItem[];

  const startOAuth = () => {
    const width = 600,
      height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    window.open(
      `/social-auth/login/${providerId}/`,
      "oauthPopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    startPolling();
  };

  if (isCheckingAuth) {
    return (
      <Flex direction="column" align="center" gap="3">
        <Spinner size="3" />
      </Flex>
    );
  }

  if (!hasToken) {
    return (
      <div>
        <Heading size="4" weight="bold" mb="3">
          Add {providerName} Account
        </Heading>
        <Text size="3" mb="4" as="p">
          Select your account from the popup that appears after clicking the
          button below. If you don't see any popup, please check your browser's
          popup blocker settings and try again.
        </Text>
        <AppButton variant="soft" size="3" radius="large" onClick={startOAuth}>
          Connect {providerName}
        </AppButton>
      </div>
    );
  }

  if (isLoadingAssets && !assets.length) {
    return (
      <Flex direction="column" align="center" gap="3">
        <Spinner size="3" />
      </Flex>
    );
  }

  if (!selectedFolder && !sortedFolders.length && !assets.length) {
    return (
      <div>
        <Text size="3" mb="4" as="p">
          No assets found in your {providerName} account.
        </Text>
        <Text size="3" mb="4" as="p">
          If you recently connected your {providerName} account, please wait a
          few moments or try re-connecting.
        </Text>
        <AppButton variant="soft" size="3" radius="large" onClick={startOAuth}>
          Re-connect {providerName}
        </AppButton>
      </div>
    );
  }

  return (
    <Flex
      direction="column"
      gap="3"
      style={{ maxHeight: "520px", overflowY: "auto", width: "100%" }}
    >
      {(sortedFolders.length > 0 || selectedFolder) && (
        <Flex direction="column" gap="3">
          <Flex
            style={{
              width: "100%",
              padding: "0 12px",
              justifyContent: "space-between",
            }}
            align="center"
          >
            <SortableButton
              text="Name"
              onClick={() => {
                setSortFoldersState((prev) => ({
                  by: "name",
                  direction:
                    prev.direction === "desc" || prev.by !== "name"
                      ? "asc"
                      : "desc",
                }));
              }}
            />
            {sortedFolders.length > 0 && sortedFolders[0].modified_at && (
              <SortableButton
                text="Modified"
                onClick={() => {
                  setSortFoldersState((prev) => ({
                    by: "modified_at",
                    direction:
                      prev.direction === "asc" || prev.by !== "modified_at"
                        ? "desc"
                        : "asc",
                  }));
                }}
              />
            )}
          </Flex>

          {selectedFolder && (
            <FolderLink
              key={selectedFolder.id}
              folder={selectedFolder}
              style={{ justifyContent: "flex-start" }}
              onClick={() => {
                setSelectedFolder(parentFolder);
                setParentFolder(null);
              }}
            >
              <ArrowLeftIcon size={16} />
              <Text size="3" weight="medium">
                ..
              </Text>
            </FolderLink>
          )}

          {sortedFolders.map((folder) => (
            <FolderLink
              key={folder.id}
              folder={folder}
              onClick={() => {
                setParentFolder(selectedFolder);
                setSelectedFolder(folder);
              }}
            />
          ))}
        </Flex>
      )}

      <Flex
        wrap="wrap"
        gap="24px"
        style={{
          gridTemplateColumns: "repeat(6, 1fr)",
          display: "grid",
        }}
      >
        {assets.map((asset: AssetSelectorItem) => (
          <BrandAsset
            key={asset.url}
            asset={asset}
            overlay={
              <HoverOverlay
                $isSelected={
                  selectedAsset !== null && selectedAsset.url === asset.url
                }
              >
                <CheckIcon color="white" width="24" height="24" />
              </HoverOverlay>
            }
            onClick={() => onSelect(asset)}
          />
        ))}
      </Flex>

      {hasNextPage && (
        <Flex justify="start">
          <AppButton
            variant="soft"
            size="3"
            radius="large"
            disabled={isLoadingAssets}
            onClick={() => fetchNextPage()}
          >
            Load more
          </AppButton>
        </Flex>
      )}
    </Flex>
  );
};

const FolderLink = ({
  folder,
  onClick,
  children = null,
  ...props
}: {
  folder: { id: string; name: string; modified_at?: string | null };
  onClick: () => void;
  children?: React.ReactNode;
  [key: string]: any;
}) => {
  return (
    <AppButton
      variant="outlined"
      size="3"
      radius="large"
      style={{
        width: "100%",
        justifyContent: "space-between",
        padding: "24px",
        ...props.style,
      }}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <>
          <Flex align="center" gap="2">
            <FolderOpenIcon size={20} />
            <Text size="3" weight="medium">
              {folder.name}
            </Text>
          </Flex>
          <Text size="2" weight="medium">
            {folder.modified_at &&
              new Date(folder.modified_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
          </Text>
        </>
      )}
    </AppButton>
  );
};

const SortableButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <AppButton
      variant="ghost"
      size="2"
      style={{ padding: 0 }}
      onClick={onClick}
    >
      <Text size="2" weight="bold">
        {text}
      </Text>
      <ChevronsUpDownIcon size={16} />
    </AppButton>
  );
};

export default CloudProviderContent;
