import GenerateSectionWithPrompt from "./generate-section/GenerateSectionWithPrompt";
import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import BrandCustomSectionPropertiesModifier from "./modifiers/BrandCustomSectionPropertiesModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isTextSection } from "~/utils/emails/useSectionTypeCheck";

const TextView = () => {
  const updateSection = useUpdateSectionField();
  const { selectedSection, id: emailCreativeId } = useEmailState();

  if (!selectedSection) {
    return null;
  }

  if (!isTextSection(selectedSection)) {
    return (
      <GenerateSectionWithPrompt
        section={{
          ...selectedSection,
          type: EmailSectionType.text,
        }}
        emailCreativeId={emailCreativeId}
      />
    );
  }

  const title = selectedSection.text;

  return (
    <Flex direction="column" gap="24px">
      <BrandCustomSectionPropertiesModifier />

      <TextModifier
        textElement={title}
        label="Title"
        fieldName="text.text"
        enabled={title.enabled}
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "text.enabled",
            value,
          })
        }
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </Flex>
  );
};

export default TextView;
