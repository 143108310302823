/**
 * Common components for the Email and Ad editors side panel
 */
import AppButton from "../../buttons/AppButton/AppButton";
import EditorCard from "./EditorCard";
import GenerateVariantButton from "./GenerateVariantButton";
import LayoutVariants from "./LayoutVariants";
import MarkMessage from "./MarkMessage";
import { Flex } from "@radix-ui/themes";
import React, { forwardRef } from "react";
import styled from "styled-components";

const SidePanelBox = styled(Flex)`
  background-color: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-secondary, #f0f0f0);

  box-shadow: 0px 3px 8px 0px #58422d0a, 0px 12px 16px 0px #58422d0a,
    0px 16px 36px 0px #58422d0a;
`;

const Button: React.FC<React.ComponentProps<typeof AppButton>> = forwardRef(
  (props, ref) => (
    <AppButton
      size="3"
      variant="outlined"
      radius="large"
      {...props}
      ref={ref}
    />
  )
);

export default {
  Button,
  EditorCard,
  GenerateVariantButton,
  LayoutVariants,
  MarkMessage,
  SidePanelBox,
};
