import DraperText from "../DraperText";
import ImagePreview from "../ImagePreview";
import AppButton from "../buttons/AppButton/AppButton";
import { Flex } from "@radix-ui/themes";
import { useEffect, useMemo, useState } from "react";
import { CheckIcon, CropIcon, ImageIcon } from "~/assets/icons";
import AssetSelectorDialog, {
  AssetSelectorCommercePlatformItem,
  AssetSelectorDialogProps,
  AssetSelectorItem,
  HoverOverlay,
} from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import useGetEmailRelatedImages from "~/hooks/emails/useGetEmailRelatedImages";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

export type ImageSelectorProps = {
  value?: string | File;
  onSelect: (assets: AssetSelectorItem[]) => void;
  isUploading?: boolean;
  alt?: string;
  buttonText?: string;
  addButtonText?: string;
  onCrop?: (file: File) => void;
  showCarousel?: boolean;
  selectedCommercePlatformId?: string;
  includeRelatedCommerceProductImages?: boolean;
} & Pick<
  AssetSelectorDialogProps,
  | "campaignId"
  | "type"
  | "categoryForUploadedImages"
  | "categories"
  | "includeDefaultCategories"
>;

const ImageSelector: React.FC<ImageSelectorProps> = ({
  value,
  onSelect,
  isUploading = false,
  alt,
  addButtonText = "Add Image",
  buttonText = "Change",
  onCrop,
  campaignId,
  showCarousel = false,
  selectedCommercePlatformId,
  type,
  categoryForUploadedImages,
  categories,
  includeDefaultCategories,
  includeRelatedCommerceProductImages,
}) => {
  const [cropOpen, setCropOpen] = useState(false);
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);

  const { images: relatedAssets } = useGetEmailRelatedImages({
    includeRelatedCommerceProductImages,
  });

  const imageUrl = useMemo(() => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return value;
    }
    return URL.createObjectURL(value);
  }, [value]);
  const { dimensions } = useGetImageDimensionsAndSize(imageUrl);
  const fileName = imageUrl ? getFilenameFromUrl(imageUrl) : undefined;

  const handleCrop = (data: string) => {
    const file = dataURIToFile(
      data,
      fileName ? `${fileName}.png` : "cropped-image.png"
    );
    onCrop?.(file);
  };

  const isBusy = isUploading;

  const assetDialog = (
    <AssetSelectorDialog
      singleAsset
      type={type}
      campaignId={campaignId}
      isDialogOpen={imageSelectorOpen}
      onToggleDialogOpen={(open) => setImageSelectorOpen(open)}
      onSelect={onSelect}
      preselectedAssets={
        imageUrl
          ? [
              {
                url: imageUrl,
                commerce_platform_item_id: selectedCommercePlatformId ?? null,
              },
            ]
          : []
      }
      categoryForUploadedImages={categoryForUploadedImages}
      categories={categories}
      includeDefaultCategories={includeDefaultCategories}
    />
  );

  const carouselImages: Array<
    ImageAssetSchema | AssetSelectorCommercePlatformItem
  > = [...(relatedAssets ?? [])].filter((image) => image !== undefined);

  useEffect(() => {
    if (imageUrl && carouselImages.length > 0) {
      const selectedImageIndex = carouselImages.findIndex(
        (image) => image.url === imageUrl
      );
      const carouselContainer = document.getElementById(
        "image-modifier-carousel"
      );
      if (selectedImageIndex !== -1 && carouselContainer) {
        const imageElements = document.querySelectorAll(
          "#image-modifier-carousel .rt-Flex > div"
        );
        const left =
          (imageElements[selectedImageIndex] as HTMLElement)?.offsetLeft ?? 0;
        const carouselLeft = (carouselContainer as HTMLElement).offsetLeft;

        carouselContainer.scrollTo({
          behavior: "smooth",
          left: left - carouselLeft,
        });
      }
    }
  }, [imageUrl, carouselImages.length]);

  if (!imageUrl) {
    return (
      <>
        <AppButton
          disabled={isBusy}
          variant="outlined"
          size="3"
          radius="large"
          onClick={() => setImageSelectorOpen(true)}
        >
          <ImageIcon />
          {addButtonText}
        </AppButton>
        {assetDialog}
      </>
    );
  }

  return (
    <Flex direction="column" gap="16px">
      <Flex gap="16px">
        <ImagePreview src={imageUrl} alt={alt ?? "logo"} />

        <Flex direction="column" gap="12px" overflow="hidden">
          <DraperText clamp={2} size="2" color="gray">
            {fileName ?? ""}
          </DraperText>
          <DraperText clamp={1} size="2" color="gray">
            {dimensions}
          </DraperText>
        </Flex>
      </Flex>
      <Flex direction="column" gap="3">
        <Flex gap="3" justify="between">
          <AppButton
            disabled={isBusy}
            variant="outlined"
            size="3"
            radius="large"
            style={{
              flex: 1,
            }}
            onClick={() => setImageSelectorOpen(true)}
          >
            <ImageIcon />
            {buttonText}
          </AppButton>
          {onCrop && (
            <>
              <AppButton
                variant="outlined"
                size="3"
                radius="large"
                disabled={!imageUrl}
                style={{ flex: 1 }}
                onClick={() => setCropOpen(true)}
              >
                <CropIcon />
                Crop
              </AppButton>
              {imageUrl && (
                <CropImageDialog
                  open={cropOpen}
                  onOpenChange={setCropOpen}
                  img={imageUrl}
                  onCrop={handleCrop}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>

      {showCarousel && carouselImages && carouselImages.length > 0 && (
        <Flex
          id="image-modifier-carousel"
          width="100%"
          style={{ overflowX: "auto" }}
        >
          <Flex gap="2" style={{ minWidth: "min-content" }}>
            {(carouselImages ?? []).map((image, index) => (
              <BrandAsset
                key={index}
                asset={image}
                overlay={
                  <HoverOverlay $isSelected={imageUrl === image.url}>
                    <CheckIcon color="white" width="24" height="24" />
                  </HoverOverlay>
                }
                onClick={() => {
                  onSelect([image]);
                }}
                width={80}
                height={80}
              />
            ))}

            <AppButton
              variant="outlined"
              size="3"
              radius="large"
              style={{
                width: "80px",
                height: "80px",
              }}
              onClick={() => setImageSelectorOpen(true)}
            >
              <ImageIcon />
            </AppButton>
          </Flex>
        </Flex>
      )}

      {assetDialog}
    </Flex>
  );
};

export default ImageSelector;
