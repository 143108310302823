import { toast } from "sonner";
import {
  CampaignData,
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "~/contexts/CampaignWizardContext";

export const campaignHasValidChannels = (
  channels: CampaignData["channels"]
): channels is NonNullable<CampaignData["channels"]> => {
  return (
    !!channels &&
    (channels.emails > 0 ||
      channels.meta_ads > 0 ||
      channels.sms > 0 ||
      channels.push_notifications > 0)
  );
};

export default function useCampaignWizardValidation() {
  const { campaignData, wizardType } = useCampaignWizardState();
  const dispatch = useCampaignWizardDispatch();

  return (): boolean => {
    if (!campaignHasValidChannels(campaignData.channels)) {
      toast.warning("Please select at least one channel for your new campaign");
      const channelStep =
        wizardType === "NEW"
          ? NewCampaignWizardSteps.CHANNEL
          : RecommendedCampaignWizardSteps.CHANNEL;
      dispatch({
        type: "SET_CURRENT_STEP",
        payload: { currentStep: channelStep },
      });
      return false;
    }

    if (
      campaignData.channels.emails > 0 &&
      (!campaignData.audiences || campaignData.audiences.length === 0)
    ) {
      toast.warning(
        "Please select at least one audience for your new campaign"
      );
      const audienceStep =
        wizardType === "NEW"
          ? NewCampaignWizardSteps.AUDIENCE
          : RecommendedCampaignWizardSteps.AUDIENCE;
      dispatch({
        type: "SET_CURRENT_STEP",
        payload: { currentStep: audienceStep },
      });
      return false;
    }

    return true;
  };
}
