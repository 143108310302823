import {
  Card,
  Flex,
  Heading,
  Dialog,
  TextField,
  Text,
  Select,
  Checkbox,
} from "@radix-ui/themes";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useCurrentUserState } from "~/contexts/CurrentUserContext";
import useInternEmailTemplateCreateMutation from "~/hooks/intern/useInternEmailTemplateCreateMutation";
import useInternEmailTemplatesQuery from "~/hooks/intern/useInternEmailTemplatesQuery";

const PreviewImage = styled.div<{ $background?: string }>`
  width: 250px;
  height: 450px;
  border-radius: 8px;
  background-color: var(--gray-4);
  background-image: url(${({ $background }) => $background});
  background-size: cover;
  background-position: center;
  border: 1px solid var(--gray-6);
  cursor: pointer;
`;

const InternEmailFullTemplatesContainer = () => {
  const { user } = useCurrentUserState();
  const brands = user.status === "LOGGED_IN" ? user.brands : [];
  const [selectedTemplates, setSelectedTemplates] = useState<Set<string>>(
    new Set()
  );

  const navigate = useNavigate();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState<string>("");

  const {
    templates,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInternEmailTemplatesQuery(20);

  const { createEmailTemplate } = useInternEmailTemplateCreateMutation({
    onSuccess: (template) => {
      navigate(`/intern/emails/full-templates/${template.email_template.id}`);
    },
  });

  const createDialog = (
    <Dialog.Root open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
      <Dialog.Trigger>
        <AppButton>Create</AppButton>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>Create New Template</Dialog.Title>
        <Flex direction="column" gap="3">
          <Flex direction="column" gap="1">
            <Text size="1" mb="1" weight="bold">
              Template Name
            </Text>
            <TextField.Root
              placeholder="Template name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </Flex>

          <Flex direction="column" gap="1">
            <Text size="1" mb="1" weight="bold">
              Brand
            </Text>
            <Select.Root
              defaultValue={selectedBrandId}
              onValueChange={(value) => setSelectedBrandId(value)}
            >
              <Select.Trigger
                placeholder="No brand"
                style={{ width: "100%" }}
              />
              <Select.Content>
                <Select.Group>
                  {brands.map((brand) => (
                    <Select.Item key={brand.id} value={brand.id}>
                      {brand.name}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
          </Flex>
          <Flex gap="3" justify="end">
            <Dialog.Close>
              <AppButton variant="outlined">Cancel</AppButton>
            </Dialog.Close>
            <AppButton
              disabled={!templateName}
              onClick={() =>
                createEmailTemplate({
                  name: templateName,
                  brand_id: selectedBrandId || undefined,
                  is_enabled: false,
                })
              }
            >
              Create
            </AppButton>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );

  const handleTemplateSelect = (templateId: string) => {
    setSelectedTemplates((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(templateId)) {
        newSelection.delete(templateId);
      } else {
        newSelection.add(templateId);
      }
      return newSelection;
    });
  };

  const handleExportSelected = () => {
    if (selectedTemplates.size === 0) {
      return;
    }

    const selectedTemplatesData = templates.filter((template) =>
      selectedTemplates.has(template.id)
    );

    const exportData = {
      version: "1.0.0",
      export_date: new Date().toISOString(),
      templates: selectedTemplatesData,
    };

    // Create a blob from the data
    const blob = new Blob([JSON.stringify(exportData, null, 2)], {
      type: "application/json",
    });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `email_templates_export_${
      new Date().toISOString().split("T")[0]
    }.json`;

    // Append to body, click, and cleanup
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Flex direction="column" gap="4" p="4" style={{ height: "100%" }}>
      <Flex justify="between" align="center" gap="4">
        {createDialog}
        <Flex gap="2">
          {/* button to select all/ deselect all */}
          <AppButton
            variant="outlined"
            onClick={() => {
              if (selectedTemplates.size === templates.length) {
                setSelectedTemplates(new Set());
              } else {
                setSelectedTemplates(
                  new Set(templates.map((template) => template.id))
                );
              }
            }}
          >
            {selectedTemplates.size === templates.length
              ? "Deselect All"
              : "Select All"}
          </AppButton>
          <AppButton
            disabled={selectedTemplates.size === 0}
            variant="outlined"
            onClick={handleExportSelected}
          >
            Export
          </AppButton>
        </Flex>
      </Flex>

      <Heading size="5">Public Email Templates</Heading>

      <InfiniteScroll
        dataLength={templates.length}
        next={fetchNextPage}
        hasMore={!isLoading && (hasNextPage ?? false)}
        loader={<Text>Loading more...</Text>}
        height="calc(100vh - 200px)"
        style={{ overflow: "auto" }}
        scrollThreshold={0.8}
      >
        <Flex wrap="wrap" gap="4">
          {isLoading ? (
            <Flex
              align="center"
              justify="center"
              style={{ width: "100%", minHeight: "200px" }}
            >
              <Text>Loading...</Text>
            </Flex>
          ) : (
            templates.map((template) => (
              <Card
                key={template.id}
                onClick={() => {
                  navigate(`/intern/emails/full-templates/${template.id}`);
                }}
              >
                <Flex direction="column" gap="2">
                  <PreviewImage $background={template.preview_image ?? ""} />
                  <Heading size="2" trim="start">
                    {template.name}
                  </Heading>
                  <Text size="1" color="gray">
                    {template?.brand?.name}
                  </Text>
                  <Flex direction="row" gap="2" justify="between">
                    <Text size="1" color="gray">
                      {template.is_enabled ? "Enabled" : "Disabled"}
                    </Text>
                    <Checkbox
                      checked={selectedTemplates.has(template.id)}
                      onCheckedChange={() => handleTemplateSelect(template.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Flex>
                </Flex>
              </Card>
            ))
          )}
        </Flex>
      </InfiniteScroll>
    </Flex>
  );
};

export default InternEmailFullTemplatesContainer;
