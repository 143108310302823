import AppButton from "../../buttons/AppButton/AppButton";
import GenerateVariantButton from "./GenerateVariantButton";
import { Box, Flex, Spinner } from "@radix-ui/themes";
import { useGateValue } from "@statsig/react-bindings";
import { useState } from "react";
import styled from "styled-components";
import { CheckIcon } from "~/assets/icons";
import EmailTemplateSelectorDialog from "~/components/emails/EmailTemplateSelectorDialog";

const VariantBox = styled(Box)<{ $imageUrl?: string }>`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  border: 1px solid var(--border-secondary, #f0f0f0);
  box-sizing: border-box;
  cursor: pointer;

  ${({ $imageUrl }) => $imageUrl && `background-image: url(${$imageUrl});`}
`;

const Overlay = styled(Flex)<{ $isSelected?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 4px 0 4px;
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  &:hover {
    opacity: 1;
  }
`;

type LayoutVariantItem = {
  preview?: string;
  id: string;
};

interface LayoutVariantsProps {
  isGenerating?: boolean;
  isGeneratingTemplate?: boolean;
  generationError?: string | null;
  onClickGenerate: (templateId?: string) => void;
  onSelect: (index: number) => void;
  variants: LayoutVariantItem[];
  selectedIndex: number;
}

const LayoutVariants: React.FC<LayoutVariantsProps> = ({
  isGenerating: isGeneratingVariant = false,
  isGeneratingTemplate = false,
  generationError,
  onClickGenerate,
  onSelect,
  variants,
  selectedIndex,
}) => {
  const is_full_template_generate_enabled = useGateValue(
    "is_full_template_generate_enabled",
    {
      disableExposureLog: true,
    }
  );

  const [isTemplateSelectorOpen, setIsTemplateSelectorOpen] = useState(false);
  const isGenerating = isGeneratingTemplate || isGeneratingVariant;

  const debugFullTemplateButton = is_full_template_generate_enabled && (
    <AppButton
      variant="soft"
      radius="large"
      onClick={() => {
        if (is_full_template_generate_enabled) {
          setIsTemplateSelectorOpen(true);
        }
      }}
      disabled={isGenerating}
    >
      <Spinner loading={isGeneratingTemplate} />
      {isGeneratingTemplate ? "Generating..." : "Generate from template"}
    </AppButton>
  );

  return (
    <Flex direction="column" gap="4">
      <GenerateVariantButton
        isGenerating={isGeneratingVariant}
        disabled={isGenerating}
        serverError={generationError}
        onClick={onClickGenerate}
      />

      {debugFullTemplateButton}

      <Flex direction="row" gap="2" wrap="wrap">
        {variants.map((variant, index) => (
          <VariantBox
            $imageUrl={variant.preview}
            key={variant.id}
            onClick={() => {
              onSelect(index);
            }}
          >
            <Overlay
              $isSelected={index === selectedIndex}
              align="center"
              justify="center"
            >
              <CheckIcon size={24} color="white" />
            </Overlay>
          </VariantBox>
        ))}
      </Flex>

      <EmailTemplateSelectorDialog
        open={isTemplateSelectorOpen && is_full_template_generate_enabled}
        onOpenChange={setIsTemplateSelectorOpen}
        onSelect={(templateId) => {
          onClickGenerate(templateId);
        }}
      />
    </Flex>
  );
};

export default LayoutVariants;
