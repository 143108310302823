import { CampaignStatus, components } from "@openapi";
import { Text, Flex, IconButton, Tooltip, Heading } from "@radix-ui/themes";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ChevronDownIcon } from "~/assets/icons";
import { CAMPAIGN_OVERVIEW_ROUTE } from "~/routes/constants";

type CampaignType = components["schemas"]["CampaignListSchema"];

export const campaignStatusToColorAndText = (status: CampaignStatus) => {
  switch (status) {
    case "cancelled":
    case "completed":
      return { color: "#D9D9D9", text: "Completed" };
    case "scheduled":
      return { color: "#3499F6", text: "Scheduled" };
    case "active":
      return { color: "#0EAF00", text: "Active" };
    default:
      return { color: "#D9D9D9", text: "Inactive" };
  }
};

const StatusDot = styled(Flex)<{ $status: CampaignStatus }>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) =>
    campaignStatusToColorAndText(props.$status).color};
`;

const StatusText = styled(Text)<{ status: CampaignStatus }>`
  color: ${(props) => campaignStatusToColorAndText(props.status).color};
`;

const Chevron = styled(ChevronDownIcon)<{ $isExpanded: boolean }>`
  cursor: pointer;
  transform: ${(props) =>
    !props.$isExpanded ? "rotate(0deg)" : "rotate(180deg)"};
  transition: transform 0.3s;
  color: #595d62;
  width: 20px;
  height: 20px;
`;

const HeaderTitleAndStatus = styled(Flex)`
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(237, 235, 240, 1);
  }
`;

const ChevronButton = styled(IconButton)`
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(237, 235, 240, 1);
  }
`;

const LinkToCampaignOverview = styled(Link)`
  text-decoration: none;
  width: 100%;
  color: inherit;
`;

const CampaignCardHeader = ({
  campaign,
  isExpanded,
  onExpandChange,
}: {
  campaign: CampaignType;
  isExpanded: boolean;
  onExpandChange: () => void;
}) => {
  const location = useLocation();
  return (
    <Flex p="16px" width="100%" gap="8px" align="center">
      <Tooltip content="Open campaign view" side="top" align="start">
        <LinkToCampaignOverview
          to={CAMPAIGN_OVERVIEW_ROUTE.replace(":id", campaign.id)}
          state={{
            from: location.pathname,
          }}
        >
          <HeaderTitleAndStatus justify="between" width="100%">
            <Heading size="4" weight="medium">
              {campaign.title}
            </Heading>
            <Flex gap="8px" align="center">
              <StatusDot $status={campaign.status} />
              <StatusText mr="8px" status={campaign.status}>
                {campaignStatusToColorAndText(campaign.status).text}
              </StatusText>
            </Flex>
          </HeaderTitleAndStatus>
        </LinkToCampaignOverview>
      </Tooltip>

      <ChevronButton size="4" radius="large" onClick={onExpandChange}>
        <Chevron $isExpanded={isExpanded} />
      </ChevronButton>
    </Flex>
  );
};

export default CampaignCardHeader;
