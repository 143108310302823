import {
  CampaignDetailResponse,
  getCampaignDetailsQueryKey,
} from "../campaign/useGetCampaignDetails";
import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

type Response =
  operations["emails_api_duplicate_email_creative"]["responses"]["200"]["content"]["application/json"];

interface DuplicateEmailCreativeVars {
  emailCreativeId: string;
  campaignId?: string;
}

const useDuplicateEmailCreativeMediaMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: Response) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation<Response, AxiosError, DuplicateEmailCreativeVars>({
    mutationFn: async ({ emailCreativeId }) => {
      const { data } = await axios.post(
        `/api/v1/emails/email-creative/${emailCreativeId}/duplicate`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data, vars) => {
      onSuccess?.(data);
      if (!vars.campaignId) {
        return;
      }
      queryClient.setQueryData<CampaignDetailResponse>(
        getCampaignDetailsQueryKey(vars.campaignId),
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            email_creatives: [data, ...oldData.email_creatives],
            email_count: oldData.email_creatives.length + 1,
          };
        }
      );
    },
    onError: (error) => {
      const errorMessage =
        error.status !== 500 &&
        error.response?.data &&
        typeof error.response?.data === "string"
          ? error.response.data
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });
};

export default useDuplicateEmailCreativeMediaMutation;
