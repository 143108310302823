import DraperTextWithLabel from "../DraperTextWithLabel";
import AppButton from "../buttons/AppButton/AppButton";
import DatePicker from "./DatePicker";
import { CampaignType } from "@openapi";
import {
  Popover,
  Flex,
  Text,
  Heading,
  IconButton,
  Select,
  Box,
} from "@radix-ui/themes";
import { X, Plus, Trash } from "lucide-react";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

const StyledPopoverContent = styled(Popover.Content)`
  width: 400px;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
`;

const FormLabel = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
`;

const AddProductButton = styled(AppButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
`;

export type PlannedCampaignData = {
  id: string;
  title: string;
  startDate: string;
  type: CampaignType;
  products: Map<string, MaybeFeaturedProduct>;
};

interface AddCampaignPopoverProps {
  title?: string;
  initialCampaign?: PlannedCampaignData;
  initialDate?: string;
  triggerComponent?: ReactNode;
  onAddCampaign?: (campaignData: PlannedCampaignData) => void;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onDeleteCampaign?: (campaignId: string) => void;
  primaryButtonOverride?: ReactNode;
}

const AddCampaignPopover = ({
  title,
  triggerComponent,
  onAddCampaign,
  isOpen,
  onOpenChange,
  initialCampaign,
  initialDate,
  onDeleteCampaign,
  primaryButtonOverride,
}: AddCampaignPopoverProps) => {
  const [open, setOpen] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState("");
  const [campaignDate, setCampaignDate] = useState("");
  const [campaignType, setCampaignType] = useState<CampaignType>(
    CampaignType.other
  );
  const [selectedProducts, setSelectedProducts] = useState<
    Map<string, MaybeFeaturedProduct>
  >(new Map());
  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const resetForm = () => {
    setCampaignTitle("");
    setCampaignDate("");
    setCampaignType(CampaignType.other);
    setSelectedProducts(new Map());
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      resetForm();
    } else {
      if (initialCampaign) {
        setCampaignTitle(initialCampaign.title);
        setCampaignDate(initialCampaign.startDate);
        setCampaignType(initialCampaign.type);
        setSelectedProducts(initialCampaign.products);
      } else if (initialDate) {
        setCampaignDate(initialDate);
      }
    }

    if (onOpenChange) {
      onOpenChange(newOpen);
    } else {
      setOpen(newOpen);
    }
  };

  const handleAddCampaign = () => {
    if (onAddCampaign) {
      if (initialCampaign) {
        onAddCampaign({
          ...initialCampaign,
          title: campaignTitle,
          startDate: campaignDate,
          type: campaignType,
          products: selectedProducts,
        });
      } else {
        onAddCampaign({
          id: crypto.randomUUID(),
          title: campaignTitle,
          startDate: campaignDate,
          type: campaignType,
          products: selectedProducts,
        });
      }
    }
    handleOpenChange(false);
  };

  const handleProductSelection = (
    products: Map<string, MaybeFeaturedProduct>
  ) => {
    setSelectedProducts(products);
  };

  const currentOpen = isOpen !== undefined ? isOpen : open;

  return (
    <Popover.Root open={currentOpen} onOpenChange={handleOpenChange}>
      <Popover.Trigger>
        {triggerComponent || (
          <AppButton variant="primary">Add Campaign</AppButton>
        )}
      </Popover.Trigger>
      <StyledPopoverContent sideOffset={20} align="start" side="top">
        <Flex direction="column" gap="3">
          <Flex align="center" justify="between" px="4" py="4">
            <Heading size="5" weight="bold">
              {initialCampaign ? "Edit Campaign" : title || "Add Campaign"}
            </Heading>
            <IconButton
              variant="ghost"
              size="1"
              onClick={() => handleOpenChange(false)}
              radius="full"
            >
              <X size={16} />
            </IconButton>
          </Flex>

          {/* Campaign form content */}
          <Flex direction="column" gap="3" px="4">
            {/* Campaign Title - Full Width */}
            <Flex direction="column" width="100%">
              <DraperTextWithLabel
                label="Campaign Title"
                width="100%"
                text={campaignTitle}
                onChange={setCampaignTitle}
              />
            </Flex>

            {/* Date and Campaign Type - Side by Side */}
            <Flex gap="3">
              <Flex direction="column" style={{ flex: 1 }}>
                <FormLabel>Start Date</FormLabel>
                <DatePicker
                  initialDate={campaignDate}
                  onSelect={(date) => setCampaignDate(date)}
                />
              </Flex>
              <Flex direction="column" style={{ flex: 1 }}>
                <FormLabel>Campaign Type</FormLabel>
                <Select.Root
                  value={campaignType}
                  onValueChange={(value) =>
                    setCampaignType(value as CampaignType)
                  }
                  size="3"
                >
                  <Select.Trigger
                    style={{
                      height: "48px",
                      borderRadius: "12px",
                      border: "1px solid #DDDDDD",
                    }}
                  />
                  <Select.Content>
                    {Object.values(CampaignType).map((type) => (
                      <Select.Item key={type} value={type}>
                        {type
                          .replace("_", " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </Flex>
            </Flex>

            {/* Product Thumbnails and Add Products Button */}
            <Flex direction="column" gap="2">
              {/* Product Thumbnails Row */}
              <Flex direction="row" gap="2" wrap="wrap">
                {Array.from(selectedProducts.values()).map((product) => (
                  <Box
                    key={product.product_id}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "4px",
                      overflow: "hidden",
                      border: "1px solid #DDDDDD",
                    }}
                  >
                    <img
                      src={product.image_url ?? ""}
                      alt={product.title ?? ""}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ))}
              </Flex>

              {/* Add Products Button */}
              <Flex direction="row" justify="start" align="center">
                <ProductSelectorDialog
                  initialSelectedProducts={selectedProducts}
                  onConfirm={handleProductSelection}
                  isOpen={productSelectorOpen}
                  onOpenChange={setProductSelectorOpen}
                  triggerComponent={
                    <AddProductButton size="3" variant="outlined">
                      <Plus size={16} />
                      <Text>Add Products</Text>
                    </AddProductButton>
                  }
                />
              </Flex>
            </Flex>
          </Flex>

          {/* Footer */}
          <Flex direction="row" gap="2" p="3" justify="between" align="center">
            {initialCampaign && onDeleteCampaign ? (
              <AppButton
                variant="outlined"
                onClick={() => onDeleteCampaign(initialCampaign.id)}
                radius="large"
                size="3"
              >
                <Trash size={16} />
              </AppButton>
            ) : (
              <Flex />
            )}
            <Flex justify="end" gap="2">
              {primaryButtonOverride ? (
                <>
                  <AppButton
                    variant="outlined"
                    onClick={handleAddCampaign}
                    radius="large"
                    disabled={!campaignTitle || !campaignDate || !campaignType}
                    size="3"
                  >
                    {initialCampaign ? "Save" : "Plan Campaign"}
                  </AppButton>
                  {primaryButtonOverride}
                </>
              ) : (
                <>
                  <AppButton
                    variant="outlined"
                    onClick={() => handleOpenChange(false)}
                    radius="large"
                    size="3"
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    variant="primary"
                    onClick={handleAddCampaign}
                    radius="large"
                    disabled={!campaignTitle || !campaignDate || !campaignType}
                    size="3"
                  >
                    {initialCampaign ? "Save Changes" : "Plan Campaign"}
                  </AppButton>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </StyledPopoverContent>
    </Popover.Root>
  );
};

export default AddCampaignPopover;
