import LoadingDots from "../../../misc/LoadingDots";
import { AssetSelectorItem, HoverOverlay } from "../AssetSelectorDialog";
import { Flex, Text } from "@radix-ui/themes";
import { CheckIcon } from "lucide-react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import useGetEmailRelatedImages from "~/hooks/emails/useGetEmailRelatedImages";

export enum RelatedImageType {
  COLLECTION = "collection",
  PRODUCT = "product",
  DEFAULT = "default",
}

const RelatedImages = ({
  onSelect,
  selectedAssets,
  sourceObjectID,
  type,
}: {
  onSelect: (asset: AssetSelectorItem) => void;
  selectedAssets: AssetSelectorItem[];
  sourceObjectID?: string; // either shopify id or django campaign id
  type: RelatedImageType;
}) => {
  let canLoadMore = true;
  let params = {};
  if (type === RelatedImageType.COLLECTION) {
    canLoadMore = false;
    params = {
      collectionId: sourceObjectID,
    };
  } else if (type === RelatedImageType.PRODUCT) {
    params = {
      productId: sourceObjectID,
    };
  }

  const {
    images: relatedAssets,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetEmailRelatedImages({
    ...params,
    includeRelatedCommerceProductImages: true,
  });

  if (!relatedAssets?.length && !isLoading) {
    return <Text size="2">No related images found</Text>;
  }

  return (
    <Flex direction="column" gap="2">
      <Flex
        wrap="wrap"
        gap="24px"
        style={{ gridTemplateColumns: "repeat(6, 1fr)", display: "grid" }}
      >
        {relatedAssets.map((asset) => (
          <BrandAsset
            key={asset.url}
            asset={asset}
            overlay={
              <HoverOverlay
                $isSelected={selectedAssets
                  .map((asset) => asset.url)
                  .includes(asset.url)}
              >
                <CheckIcon color="white" width="24" height="24" />
              </HoverOverlay>
            }
            onClick={() => onSelect(asset)}
          />
        ))}
      </Flex>

      {hasNextPage && fetchNextPage && !isLoading && (
        <Flex justify="start">
          <AppButton
            radius="large"
            size="3"
            variant="outlined"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage}
          >
            Load More
          </AppButton>
        </Flex>
      )}

      {isLoading && (
        <Flex justify={"center"} align={"center"} my="5">
          <LoadingDots />
        </Flex>
      )}
    </Flex>
  );
};
export default RelatedImages;
