import { assertNever } from "../utils/typeUtils";
import { CampaignType, components } from "@openapi";
import { produce } from "immer";
import { createContext, useReducer, useMemo, useContext } from "react";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

export enum RecommendedCampaignWizardSteps {
  SUMMARY = 0,
  PRODUCT,
  AUDIENCE,
  PROMOTION,
  CHANNEL,
  LANDING_DESTINATION,
  CREATIVE_CONCEPT,
  GENERATE,
}

export enum NewCampaignWizardSteps {
  TYPE = 0,
  DETAILS,
  LANDING_DESTINATION,
  CUSTOM_ASSETS,
  CHANNEL,
  AUDIENCE,
  PRODUCT,
  PROMOTION,
  CREATIVE_CONCEPT,
  GENERATE,
}

export enum ChannelType {
  EMAIL = "Email",
  META_ADS = "Meta Ads",
  // SMS,
  // PUSH_NOTIFICATIONS,
}

export type CampaignWizardSteps =
  | RecommendedCampaignWizardSteps
  | NewCampaignWizardSteps;

export const RecommendedCampaignWizardStepsLabels: Record<
  RecommendedCampaignWizardSteps,
  string
> = {
  [RecommendedCampaignWizardSteps.SUMMARY]: "Summary",
  [RecommendedCampaignWizardSteps.CHANNEL]: "Channel",
  [RecommendedCampaignWizardSteps.AUDIENCE]: "Audience",
  [RecommendedCampaignWizardSteps.PROMOTION]: "Promotion",
  [RecommendedCampaignWizardSteps.PRODUCT]: "Product",
  [RecommendedCampaignWizardSteps.LANDING_DESTINATION]: "Destination",
  [RecommendedCampaignWizardSteps.CREATIVE_CONCEPT]: "Creative concept",
  [RecommendedCampaignWizardSteps.GENERATE]: "Generate",
};

export const NewCampaignWizardStepsLabels: Record<
  NewCampaignWizardSteps,
  string
> = {
  [NewCampaignWizardSteps.TYPE]: "Campaign type",
  [NewCampaignWizardSteps.CUSTOM_ASSETS]: "Custom assets",
  [NewCampaignWizardSteps.DETAILS]: "Campaign details",
  [NewCampaignWizardSteps.LANDING_DESTINATION]: "Landing destination",
  [NewCampaignWizardSteps.CHANNEL]: "Channel",
  [NewCampaignWizardSteps.AUDIENCE]: "Audience",
  [NewCampaignWizardSteps.PRODUCT]: "Product",
  [NewCampaignWizardSteps.PROMOTION]: "Promotion",
  [NewCampaignWizardSteps.CREATIVE_CONCEPT]: "Creative concept",
  [NewCampaignWizardSteps.GENERATE]: "Generate",
};

type NewCampaignData = Partial<
  Omit<components["schemas"]["CampaignCreateDraftNewRequestData"], "products">
> & {
  products: MaybeFeaturedProduct[];
};
export type RecommendedCampaignData = Partial<
  Omit<
    components["schemas"]["CampaignCreateDraftRecommendationRequestData"],
    "products"
  >
> & {
  products: MaybeFeaturedProduct[];
};

export type CampaignData = NewCampaignData | RecommendedCampaignData;

interface CampaignWizardStateNew {
  wizardType: "NEW";
}

interface CampaignWizardStateRecommended {
  wizardType: "RECOMMENDED";
  recommendationID: string;
}

type CampaignWizardInterface = {
  /** Set as true after the first call to `SET_WIZARD_TYPE` */
  isWizardStateInitialized: boolean;
  isShowingMetaConnect: boolean;
  currentStep: CampaignWizardSteps;
  currentRequiredStep: CampaignWizardSteps;
  transitionIteration: number;
  campaignData: CampaignData;
} & (CampaignWizardStateNew | CampaignWizardStateRecommended);

const initialState: CampaignWizardInterface = {
  isWizardStateInitialized: false,
  isShowingMetaConnect: false,
  currentStep: 0,
  currentRequiredStep: 0,
  wizardType: "NEW",
  campaignData: {
    campaign_id: null,
    campaign_recommendation_id: "",
    asset_ids: [],
    audiences: [],
    campaign_type: CampaignType.other,
    destination: {},
    products: [],
    creative_id: "",
    brand_id: "",
    description_input: "",
    date: null,
  },
  transitionIteration: 0,
};

const isSameWizard = (
  wizard1: CampaignWizardStateNew | CampaignWizardStateRecommended,
  wizard2: CampaignWizardStateNew | CampaignWizardStateRecommended
) => {
  if (wizard1.wizardType !== wizard2.wizardType) {
    return false;
  }
  if (
    wizard1.wizardType === "RECOMMENDED" &&
    wizard2.wizardType === "RECOMMENDED"
  ) {
    return wizard1.recommendationID === wizard2.recommendationID;
  }
  return true;
};

interface SetCurrentstep {
  type: "SET_CURRENT_STEP";
  payload: {
    currentStep: CampaignWizardSteps;
  };
}

interface IncrementStep {
  type: "INCREMENT_STEP";
  payload?: number;
}

interface ResetRequiredStep {
  type: "RESET_REQUIRED_STEP";
}

interface SetWizardType {
  type: "SET_WIZARD_TYPE";
  payload: CampaignWizardStateNew | CampaignWizardStateRecommended;
}

interface PersistState {
  type: "PERSIST_STATE";
  payload: {
    isShowingMetaConnect?: boolean;
  };
}

interface ResetState {
  type: "RESET_STATE";
}

interface UpdateCampaignData {
  type: "UPDATE_CAMPAIGN_DATA";
  payload: Partial<CampaignData>;
}

interface SetTransitionAction {
  type: "SET_TRANSITION_ITERATION";
  payload: CampaignWizardInterface["transitionIteration"];
}

export type CampaignWizardAction =
  | SetCurrentstep
  | IncrementStep
  | ResetRequiredStep
  | SetTransitionAction
  | PersistState
  | ResetState
  | UpdateCampaignData
  | SetWizardType;

function reducer(
  state: CampaignWizardInterface,
  action: CampaignWizardAction
): CampaignWizardInterface {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_WIZARD_TYPE":
        // restore presisted state, if any
        const wizardSession = sessionStorage.getItem("campaignWizardState");
        setTimeout(() => {
          sessionStorage.removeItem("campaignWizardState");
        }, 1000 * 60);
        let persistedState = wizardSession ? JSON.parse(wizardSession) : null;
        if (persistedState && isSameWizard(persistedState, action.payload)) {
          return persistedState;
        }

        Object.assign(draft, action.payload);
        draft.isWizardStateInitialized = true;
        draft.currentRequiredStep =
          action.payload.wizardType === "RECOMMENDED"
            ? RecommendedCampaignWizardSteps.SUMMARY
            : NewCampaignWizardSteps.TYPE;
        draft.currentStep =
          action.payload.wizardType === "RECOMMENDED"
            ? RecommendedCampaignWizardSteps.SUMMARY
            : NewCampaignWizardSteps.TYPE;
        break;
      case "SET_CURRENT_STEP":
        draft.currentStep = action.payload.currentStep;
        draft.currentRequiredStep = Math.max(
          action.payload.currentStep,
          draft.currentRequiredStep
        );
        break;
      case "INCREMENT_STEP":
        const nextStep = draft.currentStep + (action.payload ?? 1);
        draft.currentStep = nextStep;
        draft.currentRequiredStep = Math.max(
          nextStep,
          draft.currentRequiredStep
        );
        break;
      case "RESET_REQUIRED_STEP":
        draft.currentRequiredStep = draft.currentStep;
        break;
      case "UPDATE_CAMPAIGN_DATA":
        Object.assign(draft.campaignData, action.payload);
        break;
      case "RESET_STATE":
        return initialState;
      case "PERSIST_STATE":
        Object.assign(draft, action.payload);
        sessionStorage.setItem("campaignWizardState", JSON.stringify(draft));
        break;
      case "SET_TRANSITION_ITERATION":
        draft.transitionIteration = action.payload;
        break;
      default:
        assertNever(action);
    }
  });
}

const CampaginWizardContext =
  createContext<CampaignWizardInterface>(initialState);
const DispatchContext =
  createContext<React.Dispatch<CampaignWizardAction> | null>(null);

export const CampaignWizardProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <CampaginWizardContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </CampaginWizardContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useCampaignWizardState() {
  return useContext(CampaginWizardContext);
}

export function useCampaignWizardDispatch(): React.Dispatch<CampaignWizardAction> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useCampaignWizardDispatch must be used within a CampaignWizardProvider"
    );
  }
  return dispatch;
}

export function useCampaignWizardNextStep() {
  const dispatch = useCampaignWizardDispatch();
  const { currentStep } = useCampaignWizardState();
  return () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { currentStep: currentStep + 1 },
    });
  };
}

export function useCampaignWizardPreviousStep() {
  const dispatch = useCampaignWizardDispatch();
  const { currentStep } = useCampaignWizardState();
  return () => {
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: { currentStep: currentStep - 1 },
    });
  };
}

export function useCampaignWizardUpdateCampaignData() {
  const dispatch = useCampaignWizardDispatch();
  return (payload: Partial<CampaignData>) => {
    dispatch({ type: "UPDATE_CAMPAIGN_DATA", payload });
  };
}
