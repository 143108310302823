import { CampaignBriefOverviewSection } from "../CampaignBriefOverview";
import { components } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import RadioWithText from "~/components/campaign/wizard/inputs/RadioWithText";
import CampaignCreativeSelector from "~/components/selectors/CampaignCreativeSelector";
import {
  CampaignData,
  useCampaignWizardState,
  useCampaignWizardUpdateCampaignData,
} from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  CampaignRecommendationResponse,
  getCampaignRecommendationQueryKey,
} from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";
import useRecommenderCreativeRecommendationFromCampaignRecommendationCreateMutation from "~/hooks/recommender/useRecommenderCreativeRecommendationFromCampaignRecommendationCreateMutation";

export const CampaignBriefOverviewCreativeConceptSection = ({
  campaignRecommendation,
}: {
  campaignRecommendation: CampaignRecommendationResponse;
}) => {
  const activeBrandID = useActiveBrandID();
  const { campaignData, isWizardStateInitialized } = useCampaignWizardState();
  const queryClient = useQueryClient();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();
  const shouldListenToDestinationUpdates =
    !campaignData.destination?.collection_id &&
    !campaignData.destination?.url &&
    (campaignData.products?.length ?? 0) > 1;
  const productIDs = useMemo(
    () => campaignData.products.map((product) => product.product_id),
    [campaignData.products]
  );

  const activeCreativeRecommendation =
    campaignRecommendation.active_creative_recommendation;

  const hasactiveCreativeRec = !!activeCreativeRecommendation;

  // State
  const [localActiveCreative, setLocalActiveCreative] = useState<
    components["schemas"]["CreativeRecommendationSchema"] | null
  >(activeCreativeRecommendation ?? null);

  const onUpdateActualCreative = (
    creative: components["schemas"]["CreativeRecommendationSchema"]
  ) => {
    const newData: Partial<CampaignData> = {
      creative_id: creative?.id ?? undefined,
    };
    if (creative?.discount_code && campaignData?.discount) {
      newData.discount = {
        ...campaignData.discount,
        code: creative.discount_code,
      };
    }
    if (creative?.collection_title && shouldListenToDestinationUpdates) {
      newData.destination = {
        ...campaignData.destination,
        name: creative.collection_title,
      };
    }
    updateCampaignData(newData);

    queryClient.setQueryData(
      getCampaignRecommendationQueryKey(
        activeBrandID,
        campaignRecommendation.id
      ),
      (oldData: CampaignRecommendationResponse) => {
        return {
          ...oldData,
          active_creative_recommendation: creative,
        };
      }
    );
  };

  // Mutations
  const { genCreativeRecommendationsFromCR } =
    useRecommenderCreativeRecommendationFromCampaignRecommendationCreateMutation(
      {
        onSuccess: (data) => {
          setLocalActiveCreative(data[0]);
          onUpdateActualCreative(data[0]);
        },
        onSettled: (data) => {
          queryClient.invalidateQueries({
            queryKey: getCampaignRecommendationQueryKey(
              activeBrandID,
              campaignRecommendation.id
            ),
          });
        },
      }
    );

  const { updateCampaignRecommendation } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onSuccess: (data) => {
        if (data.active_creative_recommendation) {
          onUpdateActualCreative(data.active_creative_recommendation);
        }
      },
    });

  useEffect(() => {
    // If there is no first message, and we have a campaign recommendation ID, generate a creative
    if (!hasactiveCreativeRec && isWizardStateInitialized) {
      console.log("No first message, generating creative");
      genCreativeRecommendationsFromCR({
        campaign_recommendation_id: campaignRecommendation.id,
        brand_id: activeBrandID,
        commerce_platform_item_ids: productIDs,
        discount_data: {
          value_type: campaignData?.discount?.value_type,
          amount: campaignData?.discount?.amount,
        },
        should_generate_collection_title: shouldListenToDestinationUpdates,
      });
    }
  }, [
    hasactiveCreativeRec,
    isWizardStateInitialized,
    productIDs,
    campaignRecommendation.id,
    activeBrandID,
    shouldListenToDestinationUpdates,
    campaignData.discount,
  ]);

  return (
    <CampaignBriefOverviewSection
      title="Creative Concept"
      onEdit={() => {
        setLocalActiveCreative(
          campaignRecommendation.active_creative_recommendation ?? null
        );
      }}
      maxDialogWidth="800px"
      editDialogBody={
        <CampaignCreativeSelector
          rootCreativeID={
            campaignRecommendation.active_creative_recommendation?.id ?? null
          }
          activeCreativeID={localActiveCreative?.id ?? null}
          onActiveCreativeChange={(creative) => {
            setLocalActiveCreative(creative);
          }}
          onDiscountCodeChange={(discountCode) => {
            if (localActiveCreative) {
              setLocalActiveCreative({
                ...localActiveCreative,
                discount_code: discountCode,
              });
            }
          }}
          onCollectionTitleChange={(collectionTitle) => {
            if (localActiveCreative) {
              setLocalActiveCreative({
                ...localActiveCreative,
                collection_title: collectionTitle,
              });
            }
          }}
          shouldListenToDestinationUpdates={shouldListenToDestinationUpdates}
          discountData={campaignData.discount ?? null}
        />
      }
      onSave={() => {
        updateCampaignRecommendation({
          campaign_recommendation_id: campaignRecommendation.id,
          brand_id: activeBrandID,
          active_creative_recommendation_id: localActiveCreative?.id ?? null,
          active_creative_recommendation_discount_code:
            localActiveCreative?.discount_code ?? null,
          active_creative_recommendation_collection_title:
            localActiveCreative?.collection_title ?? null,
        });
      }}
    >
      <RadioWithText
        isActive={true}
        isReadOnly={true}
        onClick={() => {}}
        value={activeCreativeRecommendation?.id}
        title={activeCreativeRecommendation?.title}
        description={activeCreativeRecommendation?.description}
        collectionTitle={activeCreativeRecommendation?.collection_title ?? null}
        generatedDiscountCode={
          activeCreativeRecommendation?.discount_code ?? null
        }
      />
    </CampaignBriefOverviewSection>
  );
};
