import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type EmailInsightsResponse =
  operations["emails_api_get_email_insights_status"]["responses"][200]["content"]["application/json"]["email_insights"];

interface EmailInsightsStatusResponse {
  is_finished: boolean;
  email_insights: EmailInsightsResponse | null;
}

const fetchEmailInsightsStatus = async (
  brandId: string,
  jobSessionId: string
): Promise<EmailInsightsStatusResponse> => {
  const { data } = await axios.get<EmailInsightsStatusResponse>(
    "/api/v1/emails/insights-status",
    {
      params: { brand_id: brandId, job_session_id: jobSessionId },
    }
  );
  return data;
};

export const useEmailInsightsStatus = (
  brandId: string,
  jobSessionId?: string,
  enabled = true
) => {
  return useQuery<EmailInsightsStatusResponse, Error>({
    queryKey: ["emailInsightsStatus", brandId, jobSessionId],
    queryFn: async () => {
      if (!jobSessionId) throw new Error("No jobSessionId provided");
      return fetchEmailInsightsStatus(brandId, jobSessionId);
    },
    enabled: enabled && !!jobSessionId,
    refetchInterval: (query) => {
      if (query.state.data?.is_finished) return false;

      const attempts = query.state.dataUpdateCount || 0;
      const hasReachedFiveSeconds = attempts >= 5; // 5 attempts at 1 second each

      return hasReachedFiveSeconds ? 10000 : 1000;
    },
    retry: 1,
  });
};
