import PreviewEmailDialog from "../dialogs/PreviewEmailDialog";
import SendTestEmailDialog from "../dialogs/SendTestEmailDialog";
import * as Popover from "@radix-ui/react-popover";
import { Flex, Select, Text, Theme } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Bug } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import styled from "styled-components";
import { CheckIcon, ChevronDownIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import EditorTopBar from "~/components/core/editor/layout/EditorTopBar";
import PublishCreativeButton from "~/components/core/editor/layout/top-bar/PublishCreativeButton";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";
import { key } from "~/hooks/emails/useEmailCreativePreviewQuery";
import { CAMPAIGNS_ROUTE } from "~/routes/constants";
import {
  useEmailState,
  useSetActiveEmailId,
  useSetActiveTipTapID,
  useSetIsEmailDirty,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import useUpdateEmailCreative from "~/routes/intern/email_editor/hooks/useUpdateEmailCreative";
import usePreventDataLoss from "~/utils/usePreventDataLoss";

const SelectTrigger = styled(Select.Trigger)`
  font-size: 18px;
  font-weight: 500;
`;

const PopoverContent = styled(Popover.Content)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd7d7;
`;

const MenuItem = styled(Flex)`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
`;

const EmailEditorTopBar = () => {
  const user = useAuthenticatedUserState();
  const isInternal = user?.isInternal;

  const queryClient = useQueryClient();
  const { campaignId } = useParams();
  const email = useEmailState();
  const setActiveEmailId = useSetActiveEmailId();
  const setIsDirty = useSetIsEmailDirty();
  const setActiveTipTapID = useSetActiveTipTapID();

  const [previewEmailDialogState, setPreviewEmailDialogState] = useState<{
    open: boolean;
    isSlicedEmail: boolean;
  }>({
    open: false,
    isSlicedEmail: false,
  });
  const [isSendTestDialogOpen, setIsSendTestDialogOpen] = useState(false);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  // Mutations and Queries
  const { data: campaignDetail, isLoading: isCreativesLoading } =
    useGetCampaignDetails({
      campaignId: campaignId,
      switchToCampaignBrand: true,
    });
  const creativesData = campaignDetail?.email_creatives ?? [];
  const variant = email.variants[email.currentVariantIndex];
  const updateEmailCreativeMutation = useUpdateEmailCreative({
    onSuccess: () => {
      saveModalOnSucceed();
      setIsDirty(false);
    },
    onError: () => {
      saveModalOnFail();
    },
  });

  const {
    component: saveStateModal,
    onSucceed: saveModalOnSucceed,
    onFail: saveModalOnFail,
  } = usePreventDataLoss({
    enabled: email.isDirty && !email.isEditingDisabled,
    onProceed: () => mutate(), // Changed to arrow function to avoid hoisting issues
    isLoading: updateEmailCreativeMutation.isPending,
    description:
      "You have made some changes to the email, would you like to save them\
        before closing this email ?",
  });

  const emailStateRef = useRef(email);
  useEffect(() => {
    emailStateRef.current = email;
  }, [email]);

  const mutate = useCallback(
    ({
      onError,
      onSuccess,
      onMutate,
    }: {
      onError?: () => void;
      onSuccess?: () => void;
      /** called when mutation is triggered (can be after a short delay) */
      onMutate?: () => void;
    } = {}): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        setActiveTipTapID("");
        setTimeout(() => {
          const email = emailStateRef.current;
          const variant = email.variants[email.currentVariantIndex];
          updateEmailCreativeMutation.mutate(
            {
              id: email.id,
              variant_id: variant?.id ?? "",
              name: email.name,
              subtitle: email.subtitle,
              description: email.description,
              audiences: email.audiences,
              excluded_audiences: email.excluded_audiences,
              sections: variant?.sections // Manually spread because we have to update AND create
                ?.filter((section) => section.type !== null)
                ?.map((section, index) => {
                  return {
                    ...section,
                    id: section.id.startsWith("new-section-") ? "" : section.id, // set empty string for new sections
                    index: index,
                  };
                })
                ?.filter(Boolean),
              campaignId,
            },
            {
              onSuccess: () => {
                saveModalOnSucceed();
                setIsDirty(false);
                if (onSuccess) {
                  onSuccess();
                }
                resolve();
              },
              onError: () => {
                saveModalOnFail();

                if (onError) {
                  onError();
                }

                reject();
              },
            }
          );
          onMutate?.();
        }, 50);
      });
    },
    [
      updateEmailCreativeMutation,
      email,
      variant,
      campaignId,
      saveModalOnSucceed,
      saveModalOnFail,
      setIsDirty,
    ]
  );

  const selectEmailComponent = creativesData.length > 0 && (
    <Select.Root onValueChange={setActiveEmailId} value={email.id} size="2">
      <SelectTrigger
        disabled={isCreativesLoading}
        variant="ghost"
        color="gray"
      />
      <Select.Content color="gray" highContrast position="popper">
        {creativesData.map((creative) => (
          <Select.Item key={creative.id} value={creative.id}>
            <Text size={"4"} weight={"medium"}>
              {creative.title}
            </Text>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );

  const openPreview = async ({
    isSlicedEmail = false,
  }: { isSlicedEmail?: boolean } = {}) => {
    const openDialog = () => {
      setPreviewEmailDialogState({
        open: true,
        isSlicedEmail,
      });
    };

    if (email.isDirty) {
      try {
        await mutate({
          onError: () => {
            setPreviewEmailDialogState({
              open: false,
              isSlicedEmail,
            });
          },
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: key(email.id),
            });
          },
          onMutate: openDialog,
        });
      } catch (e) {
        setPreviewEmailDialogState({
          open: false,
          isSlicedEmail,
        });
        return;
      }
    } else {
      openDialog();
    }
  };

  const internalPreview = isInternal && (
    <MenuItem onClick={() => openPreview({ isSlicedEmail: true })}>
      <Flex gap="4px" align={"center"}>
        <Bug size={16} />
        <Text>Preview</Text>
      </Flex>
    </MenuItem>
  );

  const previewTestDropdown = (
    <Flex gap="12px">
      <Popover.Root open={isActionMenuOpen} onOpenChange={setIsActionMenuOpen}>
        <Popover.Trigger asChild>
          <AppButton
            variant="outlined"
            disabled={updateEmailCreativeMutation.isPending}
          >
            Actions
            <ChevronDownIcon strokeWidth={1.5} size={20} />
          </AppButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Theme>
            <PopoverContent sideOffset={5} align="start">
              <Flex direction={"column"} gap="2">
                <MenuItem onClick={() => openPreview()}>
                  <Text>Preview</Text>
                </MenuItem>
                {internalPreview}
                <MenuItem
                  style={{
                    cursor: updateEmailCreativeMutation.isPending
                      ? "not-allowed"
                      : "pointer",
                  }}
                  onClick={async () => {
                    setIsActionMenuOpen(false);
                    if (email.isDirty) {
                      try {
                        await mutate();

                        toast.info("Downloading HTML");
                      } catch (e) {
                        toast.error("Failed to download HTML");
                        return;
                      }
                    }

                    const data = await queryClient.fetchQuery<string>({
                      queryKey: key(
                        email.id,
                        previewEmailDialogState.isSlicedEmail
                      ),
                    });

                    if (data) {
                      const blob = new Blob([data], {
                        type: "text/html",
                      });
                      const url = URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = `${email.name}.html`;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      URL.revokeObjectURL(url);
                    }
                  }}
                >
                  <Text>Download HTML</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSendTestDialogOpen(true);
                  }}
                >
                  <Text>Send Test</Text>
                </MenuItem>
              </Flex>
            </PopoverContent>
          </Theme>
        </Popover.Portal>
      </Popover.Root>

      {email.published_at ? (
        <AppButton
          variant="outlined"
          style={{ background: "var(--primary-white)" }}
          disabled
        >
          <CheckIcon /> Published
        </AppButton>
      ) : (
        <>
          <PublishCreativeButton
            campaignId={campaignId ?? null}
            isDisabled={updateEmailCreativeMutation.isPending || email.isDirty}
            preselectedCreativeIds={{
              emails: [email.id],
            }}
          />
          <AppButton
            variant="primary"
            radius="full"
            onClick={() => mutate().catch(() => {})}
            disabled={updateEmailCreativeMutation.isPending || !email.isDirty}
          >
            {updateEmailCreativeMutation.isPending ? "Saving..." : "Save"}
          </AppButton>
        </>
      )}
    </Flex>
  );

  return (
    <>
      <EditorTopBar
        backButtonDestination={CAMPAIGNS_ROUTE}
        left={
          (creativesData.length ?? 0) > 1 ? (
            selectEmailComponent
          ) : (
            <Text size={"4"} weight={"medium"}>
              {creativesData?.[0]?.title}
            </Text>
          )
        }
        center={<></>}
        right={previewTestDropdown}
      />

      <PreviewEmailDialog
        state={previewEmailDialogState}
        onOpenChange={(open) =>
          setPreviewEmailDialogState({
            open,
            isSlicedEmail: previewEmailDialogState.isSlicedEmail,
          })
        }
        isLoading={updateEmailCreativeMutation.isPending}
      />
      <SendTestEmailDialog
        open={isSendTestDialogOpen}
        onOpenChange={setIsSendTestDialogOpen}
        saveEmail={mutate}
      />
      {saveStateModal}
    </>
  );
};

export default EmailEditorTopBar;
