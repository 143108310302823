import { EmailSectionType, operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

type TemplateResponse =
  operations["emails_api_get_email_section_templates"]["responses"][200]["content"]["application/json"];

export const key = "emails/email-section-templates";

const useEmailSectionTemplatesQuery = (
  brandId: string,
  sectionType: EmailSectionType | null,
  pageSize: number = 10
) => {
  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [key, brandId, sectionType],
    queryFn: async ({ pageParam = 1 }): Promise<TemplateResponse> => {
      const { data } = await axios.get(`/api/v1/${key}`, {
        params: {
          brand_id: brandId,
          section_type: sectionType,
          page: pageParam,
          page_size: pageSize,
        },
      });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
    enabled: !!sectionType,
  });

  const templates = data?.pages.flatMap((page) => page.templates) ?? [];

  return {
    templates,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useEmailSectionTemplatesQuery;
