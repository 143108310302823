import OnboardingMarketingChatStep from "./OnboardingMarketingChatStep";
import DraperText from "@components/core/DraperText";
import AppButton from "@components/core/buttons/AppButton/AppButton";
import { Avatar, Box, Flex } from "@radix-ui/themes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import markSrc from "~/assets/markBig.png";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import { HOME_ROUTE } from "~/routes/constants";
import { useOnboardingContext } from "~/routes/onboarding";

enum MarketingChatStep {
  INTRO = 0,
  CHAT = 1,
  OUTRO = 2,
}

const FullScreenContainer = styled(Flex)`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
`;

const MarkAvatar = styled(Avatar)`
  width: 240px;
  height: 240px;
  border-radius: 50%;
  border: 4px solid var(--background_white, #ffffff);
`;

const useMarketingChatStep = (
  step: MarketingChatStep,
  setStep: (step: MarketingChatStep) => void
) => {
  const navigate = useNavigate();
  const user = useAuthenticatedUserState();
  const { onNextOnboardingStep, isLoading: isLoadingNextOnboardingStep } =
    useOnboardingContext();

  const firstName = user.firstName ? `, ${user.firstName}` : "!";
  switch (step) {
    case MarketingChatStep.INTRO:
      return (
        <FullScreenContainer>
          <Box style={{ marginBottom: "32px" }}>
            <MarkAvatar src={markSrc} fallback="M" />
          </Box>
          <Flex direction="row" gap="2">
            <DraperText
              variant="accent"
              type="heading"
              size="6"
              weight="bold"
              align="center"
            >
              Hello{firstName}
            </DraperText>
            <DraperText
              colorOverride="#000000"
              type="heading"
              size="6"
              align="center"
              style={{ marginBottom: "32px", maxWidth: "600px" }}
            >
              Let's plan your marketing strategy.
            </DraperText>
          </Flex>
          <AppButton
            size="4"
            onClick={() => setStep(MarketingChatStep.CHAT)}
            variant="primary"
          >
            Get Started
          </AppButton>
        </FullScreenContainer>
      );

    case MarketingChatStep.CHAT:
      return (
        <FullScreenContainer>
          <OnboardingMarketingChatStep
            onNextStep={() => setStep(MarketingChatStep.OUTRO)}
          />
        </FullScreenContainer>
      );
    case MarketingChatStep.OUTRO:
      return (
        <FullScreenContainer>
          <Box style={{ marginBottom: "32px" }}>
            <MarkAvatar src={markSrc} fallback="M" />
          </Box>
          <Flex direction="column" gap="4" align="center" width={"50%"}>
            <DraperText
              colorOverride="#000000"
              type="heading"
              size="6"
              align="center"
              wordBreak="normal"
            >
              I'm working on new campaign ideas based on the outlined plan, this
              will take a few hours.
            </DraperText>
            <DraperText
              colorOverride="#000000"
              type="heading"
              size="6"
              align="center"
            >
              You will get an email when its ready.
            </DraperText>
            <AppButton
              size="4"
              onClick={() => {
                onNextOnboardingStep();
              }}
              disabled={isLoadingNextOnboardingStep}
              variant="primary"
            >
              Continue to Dashboard
            </AppButton>
          </Flex>
        </FullScreenContainer>
      );

    default:
      throw Error("Marketing chat step not implemented");
  }
};

const OnboardingMarketingChat = () => {
  const [step, setStep] = useState<MarketingChatStep>(MarketingChatStep.INTRO);

  return useMarketingChatStep(step, setStep);
};

export default OnboardingMarketingChat;
