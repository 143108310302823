import {
  CampaignRecommendation,
  getCampaignRecommendationsQueryKey,
} from "../../hooks/recommendations/useGetCampaignRecommendations";
import CampaignProductsPreview from "../common/CampaignProductsPreview";
import HomeCampaignCardLabels from "../common/HomeCampaignCardLabels";
import { ItemSetType } from "@openapi";
import {
  Flex,
  Text,
  IconButton,
  Tooltip,
  Dialog,
  TextArea,
  Checkbox,
} from "@radix-ui/themes";
import { useGateValue } from "@statsig/react-bindings";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Calendar1, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ArrowRightIcon,
  DiscountIcon,
  HeartSparksIcon,
  PackageBoxIcon,
  PackageOpenIcon,
  ShoppingBagClickIcon,
  StarSparksIcon,
  StorageBoxesIcon,
} from "~/assets/icons";
import DraperPill from "~/components/core/DraperPill";
import DraperText, { DraperHeading } from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import DialogHeader from "~/components/core/dialog/DialogHeader";
import DatePicker from "~/components/core/inputs/DatePicker";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";
import { getCampaignHomeCalendarQueryKey } from "~/hooks/campaign/useCampaignHomeCalendarQuery";
import { getCampaignHomeQueryKey } from "~/hooks/campaign/useCampaignHomeQuery";
import useRecommenderCampaignRecommendationDeleteMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationDeleteMutation";
import { useRecommenderCampaignRecommendationUpdateMutationInHomePage } from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";
import { CAMPAIGN_BRIEF_ROUTE } from "~/routes/constants";
import { getCampaignUnifiedStatusFromRecommendation } from "~/utils/campaign/utils";

const StyledPill = styled(DraperPill)`
  position: relative;
  top: 4px;
`;

export const campaignRecommendationTypeContent: {
  [key in ItemSetType]: {
    icon: typeof PackageOpenIcon;
    title: string;
    description?: React.ReactNode;
  };
} = {
  [ItemSetType.new_products]: {
    icon: StarSparksIcon,
    title: "New Products",
    description: (
      <>
        New added products are preforming well, selling <b>1.7x</b> better than
        average products.
      </>
    ),
  },
  [ItemSetType.back_in_stock]: {
    icon: StorageBoxesIcon,
    title: "Back in Stock",
  },
  [ItemSetType.best_sellers]: {
    icon: ShoppingBagClickIcon,
    title: "Best Sellers",
  },
  [ItemSetType.discounted_products]: {
    icon: DiscountIcon,
    title: "Discounted Products",
  },
  [ItemSetType.hero_product]: {
    icon: PackageOpenIcon,
    title: "Hero Product",
  },
  [ItemSetType.new_collection]: {
    icon: HeartSparksIcon,
    title: "New Collection",
  },
  [ItemSetType.new_hero_product]: {
    icon: PackageOpenIcon,
    title: "New Hero Product",
  },
  [ItemSetType.purchased_together]: {
    icon: PackageBoxIcon,
    title: "Purchased Together",
  },
  [ItemSetType.slower_moving_goods]: {
    icon: StorageBoxesIcon,
    title: "Slow Moving Goods",
    description: (
      <>
        Products are not preforming well, selling <b>1.7x</b> below the average
        products.
      </>
    ),
  },
  [ItemSetType.slowest_moving_goods]: {
    icon: StorageBoxesIcon,
    title: "Slowest Moving Goods",
  },
  [ItemSetType.trending_products]: {
    icon: ShoppingBagClickIcon,
    title: "Trending Products",
  },
  [ItemSetType.custom]: {
    icon: ShoppingBagClickIcon,
    title: "Cultural Moment",
  },
};

const Card = styled(Flex)`
  padding: 20px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const RecommenderCard = ({
  recommendation,
  onDeleteCallback,
  onUpdateCallback,
}: {
  recommendation: CampaignRecommendation;
  onDeleteCallback?: () => void;
  onUpdateCallback?: () => void;
}) => {
  const queryClient = useQueryClient();
  const user = useAuthenticatedUserState();
  const isInternalUser = user.isInternal;
  const activeBrandID = useActiveBrandID();
  const navigate = useNavigate();
  const isNewHomePage = useGateValue("is_new_home_ui", {
    disableExposureLog: true,
  });

  // State
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [userFeedback, setUserFeedback] = useState<string | null>(null);
  const [isStopGeneratingCheckboxChecked, setIsStopGeneratingCheckboxChecked] =
    useState<boolean>(false);

  const productsData = recommendation.products ?? [];
  const recommendationConfig =
    campaignRecommendationTypeContent[recommendation.item_set_type];
  const IconComponent = recommendationConfig.icon;

  // Mutations
  const { updateCampaignRecommendation, isCampaignRecommendationUpdating } =
    useRecommenderCampaignRecommendationUpdateMutationInHomePage();

  const { mutate: deleteCampaignRecommendation, isPending: isDeleting } =
    useRecommenderCampaignRecommendationDeleteMutation({
      onSuccess: (data) => {
        if (isNewHomePage) {
          queryClient.setQueriesData(
            {
              queryKey: getCampaignHomeQueryKey(activeBrandID, false),
              type: "all",
              exact: false,
            },
            (oldData: any) => {
              if (!oldData) return oldData;
              return {
                ...oldData,
                pages: oldData.pages.map((page: any) => {
                  return {
                    ...page,
                    campaigns: page.campaigns.filter((c: any) => c.id !== data),
                  };
                }),
              };
            }
          );
          queryClient.setQueriesData(
            {
              queryKey: getCampaignHomeCalendarQueryKey(activeBrandID),
              type: "all",
              exact: false,
            },
            (oldData: any) => {
              if (!oldData) return oldData;

              return {
                campaigns: oldData.campaigns.filter(
                  (c: any) => c.id !== recommendation.id
                ),
              };
            }
          );
        } else {
          queryClient.setQueriesData(
            { queryKey: getCampaignRecommendationsQueryKey(activeBrandID) },
            (oldData: any) => {
              if (!oldData) return oldData;

              return {
                ...oldData,
                pages: oldData.pages.map((page: any) => ({
                  ...page,
                  campaign_recommendations:
                    page.campaign_recommendations.filter(
                      (recommendation: any) => recommendation.id !== data
                    ),
                })),
              };
            }
          );
        }
        setIsDeleteDialogOpen(false);
        onDeleteCallback?.();
      },
    });

  const handleDelete = () => {
    deleteCampaignRecommendation({
      campaign_recommendation_id: recommendation.id,
      brand_id: activeBrandID,
      should_regenerate: !isStopGeneratingCheckboxChecked,
      user_feedback: userFeedback,
    });
  };

  const dateComponent = (
    <DatePicker
      initialDate={recommendation.date}
      onSelect={(formattedDate) => {}}
      onConfirm={(formattedDate) => {
        updateCampaignRecommendation({
          campaign_recommendation_id: recommendation.id,
          date: formattedDate,
          brand_id: activeBrandID,
        });
      }}
      triggerComponent={
        <AppButton
          variant="outlined"
          radius="full"
          disabled={isCampaignRecommendationUpdating}
        >
          <Flex gap="2" align="center" px="12px">
            <Calendar1 size={20} />
            <Text size="3">
              {dayjs(recommendation.date).format("MMM D, YYYY")}
            </Text>
          </Flex>
        </AppButton>
      }
    />
  );

  return (
    <Card
      direction="row"
      position="relative"
      gap="5"
      overflow="hidden"
      minWidth="780px"
    >
      <CampaignProductsPreview productsData={productsData} />

      <Flex direction="column" gap="5" overflow="hidden" width="100%">
        <Flex direction="column" gap="3">
          <Flex direction="row" gap="3">
            {isNewHomePage && (
              <HomeCampaignCardLabels
                status={getCampaignUnifiedStatusFromRecommendation(
                  recommendation
                )}
              />
            )}
            <DraperHeading size="6">{recommendation.title}</DraperHeading>
          </Flex>
          <DraperText
            size="4"
            clamp={2}
            wordBreak="break-word"
            weight="medium"
            variant="secondary"
          >
            {recommendation.description}
          </DraperText>
        </Flex>
        <DraperText
          size="4"
          variant="secondary"
          wordBreak="break-word"
          lineHeight="26px"
        >
          <StyledPill
            flexShrink="0"
            backgroundColor="var(--black-4, #0000000A)"
            mr="10px"
            height="26px"
            align="baseline"
            icon={
              <IconComponent
                size={20}
                strokeWidth={1.25}
                color="var(--primary-vibrant-orange)"
              />
            }
          >
            {recommendationConfig.title}
          </StyledPill>

          <Text size="3">{recommendation.reasoning_description}</Text>
        </DraperText>
        <Flex direction="row" gap="5" justify="between" align="center">
          <AppButton
            onClick={() =>
              navigate(
                CAMPAIGN_BRIEF_ROUTE.replace(
                  ":recommendationID",
                  recommendation.id
                )
              )
            }
            mt="auto"
            variant="soft"
          >
            <Flex direction="row" gap="2" align="center">
              <Text size={"3"}>View Brief</Text>
              <ArrowRightIcon size={20} />
            </Flex>
          </AppButton>
          <Flex
            gap="2"
            align="center"
            style={{
              color: "var(--primary-deep-purple)",
            }}
          >
            {dateComponent}

            {isInternalUser && ( // Remove this once functionality is added
              <Tooltip content="Delete">
                <IconButton
                  disabled={isDeleting}
                  variant="outline"
                  color="gray"
                  aria-label="Delete Campaign"
                  radius="full"
                  size="4"
                  onClick={() => setIsDeleteDialogOpen(true)}
                >
                  <Trash2 size={18} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Dialog.Root
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <Dialog.Content style={{ maxWidth: 450 }}>
          <DialogHeader title="Discard Campaign Idea" />
          <Dialog.Description size="2" mb="4">
            Please provide feedback about why <b>{recommendation.title}</b>{" "}
            doesn't work for you.
          </Dialog.Description>

          <TextArea
            value={userFeedback ?? ""}
            onChange={(e) => setUserFeedback(e.target.value)}
            maxLength={1000}
            placeholder="Type your feedback here..."
            style={{
              borderRadius: "12px",
              height: "150px",
            }}
          />

          <Flex align="center" gap="2" mt="3">
            <Checkbox
              id="dont-show-again"
              checked={isStopGeneratingCheckboxChecked}
              onCheckedChange={(checked) => {
                setIsStopGeneratingCheckboxChecked(!!checked);
              }}
            />
            <label htmlFor="dont-show-again">
              <DraperText size="2">
                Stop generating campaigns for{" "}
                <b>
                  {new Date(recommendation.date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </b>
              </DraperText>
            </label>
          </Flex>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <AppButton variant="outlined" radius="large">
                Cancel
              </AppButton>
            </Dialog.Close>
            <AppButton radius="large" variant="dark" onClick={handleDelete}>
              Discard
            </AppButton>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </Card>
  );
};

export default RecommenderCard;
