import { EmailSectionType } from "@openapi";
import { IconButton, Spinner, Tooltip } from "@radix-ui/themes";
import { useCallback } from "react";
import styled from "styled-components";
import { RefreshCcwIcon } from "~/assets/icons";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionMutation";
import {
  useEmailState,
  useSetIsEmailDirty,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  align-items: center;
`;

const ResyncBrandSection: React.FC<{
  sectionType: EmailSectionType.header | EmailSectionType.footer;
}> = ({ sectionType }) => {
  const activeBrandId = useActiveBrandID();
  const { selectedSection, activeEmailId } = useEmailState();
  const { mutate: initEmailSection, isPending } = useInitEmailSectionMutation({
    onSuccess: (response) => {
      setEmailDirty(true);
      return response;
    },
  });
  const setEmailDirty = useSetIsEmailDirty();

  const handleResync = useCallback(() => {
    if (!selectedSection) {
      return;
    }

    initEmailSection({
      brand_id: activeBrandId,
      section_type: sectionType,
      id: selectedSection.id,
      index: selectedSection.index,
      email_creative_id: activeEmailId,
      generate: false,
    });
  }, [selectedSection, initEmailSection]);

  if (selectedSection?.type !== sectionType) {
    return null;
  }

  return (
    <Tooltip
      content={`Resync with brand ${sectionType}`}
      align="center"
      side="top"
    >
      <StyledIconButton variant="ghost" onClick={handleResync}>
        {isPending ? <Spinner /> : <RefreshCcwIcon />}
      </StyledIconButton>
    </Tooltip>
  );
};

export default ResyncBrandSection;
