import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type TemplateResponse =
  operations["emails_api_get_email_template"]["responses"][200]["content"]["application/json"];

export const key = (templateId: string, brandId?: string) =>
  ["emails/email-templates", templateId, brandId] as const;

const useEmailTemplateQuery = (templateId: string, brandId?: string) => {
  const { data, error, isLoading } = useQuery({
    queryKey: key(templateId, brandId),
    queryFn: async (): Promise<TemplateResponse> => {
      const { data } = await axios.get(
        `/api/v1/emails/email-templates/${templateId}`,
        {
          params: {
            brand_id: brandId,
          },
        }
      );
      return data;
    },
  });

  return {
    template: data,
    error,
    isLoading,
  };
};

export default useEmailTemplateQuery;
