import { Editor } from "@monaco-editor/react";
import { EmailSectionType } from "@openapi";
import { Box, Button, Flex, Text, Select } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { key as emailTemplatesKey } from "~/hooks/emails/useEmailTemplateQuery";
import useInternEmailSectionTemplateCreateMutation from "~/hooks/intern/useInternEmailSectionTemplateCreateMutation";

interface Props {
  onCancel: () => void;
  parentTemplateId: string;
}

const InternEmailFullTemplatesAddSectionContainer = ({
  onCancel,
  parentTemplateId,
}: Props) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const [sectionType, setSectionType] = useState<EmailSectionType>(
    EmailSectionType.text
  );
  const [editingHtml, setEditingHtml] = useState(
    `<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
<div style="margin:0px auto;max-width:600px;background-color:{{ default palette.background "#fff" }}; color:{{ default palette.foreground "#000" }}; ">

</div>`
  );

  const { createEmailSectionTemplate, isPending, isSuccess } =
    useInternEmailSectionTemplateCreateMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: emailTemplatesKey(parentTemplateId, activeBrandID),
          type: "all",
        });
        onCancel();
      },
    });

  return (
    <Box>
      <Flex direction="column" gap="2">
        <Text size="2" weight="bold">
          Add New Section
        </Text>

        <Select.Root
          defaultValue={EmailSectionType.text}
          onValueChange={(value) => setSectionType(value as EmailSectionType)}
        >
          <Select.Trigger placeholder="Select section type..." />
          <Select.Content>
            <Select.Group>
              <Select.Label>Section Types</Select.Label>
              {Object.values(EmailSectionType).map((type) => (
                <Select.Item key={type} value={type}>
                  {type}
                </Select.Item>
              ))}
            </Select.Group>
          </Select.Content>
        </Select.Root>

        <Editor
          height="200px"
          defaultLanguage="html"
          theme="vs-dark"
          value={editingHtml}
          onChange={(value) => setEditingHtml(value ?? "")}
        />

        <Flex gap="2" justify="end">
          <Button variant="soft" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              createEmailSectionTemplate({
                section_type: sectionType,
                html: editingHtml,
                parent_template_id: parentTemplateId,
              })
            }
            disabled={isPending || !editingHtml}
          >
            Add Section
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InternEmailFullTemplatesAddSectionContainer;
