import FooterView from "../editor/sidepanel-views/FooterView";
import HeaderView from "../editor/sidepanel-views/HeaderView";
import ReviewView from "../editor/sidepanel-views/ReviewView";
import TextView from "../editor/sidepanel-views/TextView";
import {
  MousePointerClick,
  PresentationIcon,
  LayoutGridIcon,
  LayoutPanelTop,
  ListIcon,
  NewspaperIcon,
  TextIcon,
  StarIcon,
} from "lucide-react";
import { ImageIcon, SparkleIcon } from "~/assets/icons";
import EditorAside from "~/components/core/editor/layout/EditorAside";
import ResyncBrandSection from "~/components/core/editor/sidepane/ResyncBrandSection";
import AddBlockView from "~/components/editor/editor/sidepanel-views/AddBlockView";
import BlogView from "~/components/editor/editor/sidepanel-views/BlogView";
import ButtonView from "~/components/editor/editor/sidepanel-views/ButtonView";
import CollectionGridView from "~/components/editor/editor/sidepanel-views/CollectionGridView";
import CtaView from "~/components/editor/editor/sidepanel-views/CtaView";
import GenerateVariantView from "~/components/editor/editor/sidepanel-views/GenerateVariantView";
import HeroCollectionView from "~/components/editor/editor/sidepanel-views/HeroCollectionView";
import HeroProductView from "~/components/editor/editor/sidepanel-views/HeroProductView";
import HeroView from "~/components/editor/editor/sidepanel-views/HeroView";
import ImageView from "~/components/editor/editor/sidepanel-views/ImageView";
import ListicleView from "~/components/editor/editor/sidepanel-views/ListicleView";
import ProductGridView from "~/components/editor/editor/sidepanel-views/ProductGridView";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import {
  EmailEditorViews,
  useEmailState,
  usePopEditorView,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { assertNever } from "~/utils/typeUtils";

export const getViewValues = ({
  view,
  isEditingDisabled,
}: {
  view: EmailEditorViews;
  isEditingDisabled: boolean;
}): {
  name: string;
  Component: React.FC;
  Icon?: React.FC;
  ActionItem?: React.FC;
} => {
  switch (view) {
    case EmailEditorViews.GenerateVariant:
      return {
        name: "Generate Variant",
        Component: GenerateVariantView,
      };
    case EmailEditorViews.Button:
      return {
        name: "Button",
        Component: ButtonView,
      };
    case EmailEditorViews.AddBlock:
      return {
        name: "Add Block",
        Component: AddBlockView,
      };
    case EmailEditorViews.blog:
      return {
        name: "Blog",
        Component: BlogView,
        Icon: NewspaperIcon,
      };
    case EmailEditorViews.collection_grid:
      return {
        name: "Collection Grid",
        Component: CollectionGridView,
        Icon: LayoutPanelTop,
      };
    case EmailEditorViews.cta_card:
      return {
        name: "Cta Card",
        Component: CtaView,
        Icon: MousePointerClick,
      };
    case EmailEditorViews.footer:
      return {
        name: "Footer",
        Component: FooterView,
        ActionItem: !isEditingDisabled
          ? () => <ResyncBrandSection sectionType={view} />
          : undefined,
      };
    case EmailEditorViews.header:
      return {
        name: "Header",
        Component: HeaderView,
        ActionItem: !isEditingDisabled
          ? () => <ResyncBrandSection sectionType={view} />
          : undefined,
      };
    case EmailEditorViews.hero:
      return {
        name: "Hero",
        Component: HeroView,
        Icon: PresentationIcon,
      };
    case EmailEditorViews.hero_collection:
      return {
        name: "Collection Spotlight",
        Component: HeroCollectionView,
        Icon: SparkleIcon,
      };
    case EmailEditorViews.hero_product:
      return {
        name: "Featured Product",
        Component: HeroProductView,
        Icon: SparkleIcon,
      };
    case EmailEditorViews.hero_sales_event:
      return {
        name: "Hero",
        Component: HeroView,
      };
    case EmailEditorViews.text:
      return {
        name: "Text",
        Component: TextView,
        Icon: TextIcon,
      };
    case EmailEditorViews.image:
      return {
        name: "Image",
        Component: ImageView,
        Icon: ImageIcon,
      };
    case EmailEditorViews.listicle:
      return {
        name: "Listicle",
        Component: ListicleView,
        Icon: ListIcon,
      };
    case EmailEditorViews.products:
      return {
        name: "Product Grid",
        Component: ProductGridView,
        Icon: LayoutGridIcon,
      };
    case EmailEditorViews.reviews:
      return {
        name: "Reviews",
        Component: ReviewView,
        Icon: StarIcon,
      };
    default:
      assertNever(view);
  }
};

const EmailEditorAside = () => {
  const { editorViewStack, isEditingDisabled } = useEmailState();
  const popView = usePopEditorView();
  const currentEditorView = editorViewStack[editorViewStack.length - 1];

  const { name, Component, ActionItem } = getViewValues({
    view: currentEditorView,
    isEditingDisabled,
  });

  return (
    <BrandStylingProvider includeAllBrandFonts>
      <EditorAside
        showHeader={editorViewStack.length > 1}
        headerActions={ActionItem && <ActionItem />}
        title={name}
        onBack={() => popView()}
        isEditingDisabled={isEditingDisabled}
      >
        <Component />
      </EditorAside>
    </BrandStylingProvider>
  );
};

export default EmailEditorAside;
