import { CampaignBriefOverviewSection } from "../CampaignBriefOverview";
import { Flex, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import AudiencePill from "~/components/assets/selector/AudiencePill";
import AudienceSelector, {
  AudienceSelectorItem,
} from "~/components/assets/selector/AudienceSelector";
import {
  useCampaignWizardState,
  useCampaignWizardUpdateCampaignData,
} from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { getCampaignRecommendationQueryKey } from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

export const CampaignBriefOverviewAudienceSection = ({
  campaignRecommendationID,
}: {
  campaignRecommendationID: string;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();
  const { campaignData } = useCampaignWizardState();

  const [localAudiences, setLocalAudiences] = useState<AudienceSelectorItem[]>(
    []
  );
  const [localExcludedAudiences, setLocalExcludedAudiences] = useState<
    AudienceSelectorItem[]
  >([]);

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onMutate: () => {
        updateCampaignData({
          audiences: localAudiences,
          excluded_audiences: localExcludedAudiences,
        });

        const previousCampaignRecommendation = queryClient.getQueryData(
          getCampaignRecommendationQueryKey(
            activeBrandID,
            campaignRecommendationID
          )
        );

        return { previousCampaignRecommendation };
      },
      onError: (error, variables, context) => {
        if (context?.previousCampaignRecommendation) {
          updateCampaignData({
            audiences: context.previousCampaignRecommendation.audiences,
            excluded_audiences:
              context.previousCampaignRecommendation.excluded_audiences,
          });
        }
      },
    });

  const audiences = campaignData.audiences || [];
  const excludedAudiences = campaignData.excluded_audiences || [];

  const syncAudienceState = (
    audiences: AudienceSelectorItem[],
    excludedAudiences: AudienceSelectorItem[]
  ) => {
    setLocalAudiences(audiences);
    setLocalExcludedAudiences(excludedAudiences);
  };

  return (
    <CampaignBriefOverviewSection
      title="Audience"
      isLoading={isLoading}
      editDialogBody={
        <AudienceSelector
          audiences={localAudiences}
          onChange={setLocalAudiences}
          exclusions={localExcludedAudiences}
          onChangeExclusions={setLocalExcludedAudiences}
        />
      }
      onEdit={() => {
        syncAudienceState(audiences, excludedAudiences);
      }}
      onSave={() => {
        updateCampaignRecommendation({
          brand_id: activeBrandID,
          campaign_recommendation_id: campaignRecommendationID,
          audiences: localAudiences,
          excluded_audiences: localExcludedAudiences,
        });
      }}
    >
      <Flex direction="column" gap="16px">
        <Flex direction="column" gap="8px">
          <Text size="2" weight="medium">
            Target Audience
          </Text>
          {audiences.length > 0 ? (
            <Flex gap="2" wrap="wrap">
              {audiences.map((audience) => (
                <Flex
                  key={audience.audience_id}
                  display="inline-flex"
                  width="fit-content"
                >
                  <AudiencePill audience={audience} />
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex gap="2" wrap="wrap">
              <AudiencePill />
            </Flex>
          )}
        </Flex>

        <Flex direction="column" gap="8px">
          <Text size="2" weight="medium">
            Excluded Audience
          </Text>
          {excludedAudiences.length > 0 ? (
            <Flex gap="2" wrap="wrap">
              {excludedAudiences.map((audience) => (
                <Flex
                  key={audience.audience_id}
                  display="inline-flex"
                  width="fit-content"
                >
                  <AudiencePill audience={audience} variant="exclude" />
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex gap="2" wrap="wrap">
              <AudiencePill />
            </Flex>
          )}
        </Flex>
      </Flex>
    </CampaignBriefOverviewSection>
  );
};
