import {
  useCampaignWizardState,
  useCampaignWizardUpdateCampaignData,
} from "../../../../contexts/CampaignWizardContext";
import {
  CampaignBriefOverviewSection,
  SectionContent,
} from "../CampaignBriefOverview";
import { Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Calendar1 } from "lucide-react";
import { useState } from "react";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import DatePicker from "~/components/core/inputs/DatePicker";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  CampaignRecommendationResponse,
  getCampaignRecommendationQueryKey,
} from "~/hooks/campaign/useCampaignRecommendationQuery";
import useRecommenderCampaignRecommendationUpdateMutation from "~/hooks/recommender/useRecommenderCampaignRecommendationUpdateMutation";

export const CampaignBriefOverviewDateSection = ({
  campaignRecommendation,
}: {
  campaignRecommendation: CampaignRecommendationResponse;
}) => {
  const activeBrandID = useActiveBrandID();
  const updateCampaignData = useCampaignWizardUpdateCampaignData();
  const queryClient = useQueryClient();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const { updateCampaignRecommendation, isLoading } =
    useRecommenderCampaignRecommendationUpdateMutation({
      onSuccess: (data) => {
        updateCampaignData({
          date: data.date,
        });
        setIsDatePickerOpen(false);
        queryClient.setQueryData(
          getCampaignRecommendationQueryKey(
            activeBrandID,
            campaignRecommendation.id
          ),
          (oldData: CampaignRecommendationResponse) => {
            return {
              ...oldData,
              date: data.date,
            };
          }
        );
      },
    });

  const datePicker = (
    <DatePicker
      isOpen={isDatePickerOpen}
      onOpenChange={setIsDatePickerOpen}
      initialDate={campaignRecommendation.date ?? undefined}
      onSelect={(formattedDate) => {}}
      triggerComponent={
        <AppButton
          variant="outlined"
          radius="full"
          disabled={isLoading}
          size={"3"}
        >
          <Flex gap="2" align="center" px="12px">
            <Calendar1 size={20} />
            <Text size="2">
              {campaignRecommendation.date
                ? dayjs(campaignRecommendation.date).format("MMM D, YYYY")
                : "Set Date"}
            </Text>
          </Flex>
        </AppButton>
      }
      onConfirm={(formattedDate) => {
        updateCampaignRecommendation({
          campaign_recommendation_id: campaignRecommendation.id,
          brand_id: activeBrandID,
          date: formattedDate,
        });
      }}
    />
  );

  return (
    <CampaignBriefOverviewSection title="Proposed Date and Time">
      <SectionContent>
        <Flex direction="row" align="center">
          <Text>Campaign will start on: </Text>
          <Flex gap="2" align="center" px="12px">
            <DraperText size="2" variant="secondary">
              {datePicker}
            </DraperText>
          </Flex>
        </Flex>
      </SectionContent>
    </CampaignBriefOverviewSection>
  );
};
