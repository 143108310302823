import { useMarkPopupDispatch } from "./MarkPopupContext";
import { BrandVoiceCategory } from "@openapi";
import _ from "lodash";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import useBrandVoiceQuery, {
  BrandVoiceItem,
} from "~/hooks/voices/useBrandVoiceQuery";
import nullthrows from "~/utils/nullthrows";

interface BrandVoiceContextState {
  isEditingItem: boolean;
  isLoading: boolean;
  isGenerating: boolean;
  missionStatement: string;
  items: { [key in BrandVoiceCategory]?: BrandVoiceItem[] };
}

const initialState: BrandVoiceContextState = {
  isEditingItem: false,
  isLoading: true,
  isGenerating: false,
  missionStatement: "",
  items: {},
};

type ActionSetVoice = {
  type: "SET_VOICES";
  payload: Pick<
    BrandVoiceContextState,
    "items" | "missionStatement" | "isGenerating"
  >;
};

type ActionPatchStates = {
  type: "PATCH";
  payload: Partial<
    Pick<
      BrandVoiceContextState,
      "isEditingItem" | "isLoading" | "missionStatement"
    >
  >;
};

export type BrandVoiceAction = ActionPatchStates | ActionSetVoice;

function reducer(
  state: BrandVoiceContextState,
  action: BrandVoiceAction
): BrandVoiceContextState {
  switch (action.type) {
    case "PATCH": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SET_VOICES": {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }
  }
}

export const BrandVoiceContext = createContext(initialState);
const DispatchContext = createContext<React.Dispatch<BrandVoiceAction> | null>(
  null
);

export const BrandVoiceProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, isLoading } = useBrandVoiceQuery();
  const popupDispatch = useMarkPopupDispatch();

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: "PATCH",
        payload: { isLoading: true },
      });
      return;
    }
    if (!data) {
      return;
    }

    const groupedItems = _.groupBy(data.values, "category");
    dispatch({
      type: "SET_VOICES",
      payload: {
        items: groupedItems,
        missionStatement: data.mission_statement,
        isGenerating: data.is_generating,
      },
    });
  }, [data, isLoading]);

  useEffect(() => {
    const message = state.isGenerating
      ? "Your brand voice is currently being generated. This may take a few minutes."
      : undefined;
    if (message) {
      popupDispatch({
        type: "SHOW_MARK_POPUP",
        payload: message,
      });
    } else {
      popupDispatch({
        type: "SET_MESSAGE",
        payload: message,
      });
      popupDispatch({
        type: "SET_IS_POPUP_OPEN",
        payload: false,
      });
    }

    return () => {
      popupDispatch({
        type: "SET_MESSAGE",
        payload: undefined,
      });
    };
  }, [state.isGenerating]);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <BrandVoiceContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </BrandVoiceContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useBrandVoiceState() {
  return useContext(BrandVoiceContext);
}

export function useBrandVoiceDispatch(): React.Dispatch<BrandVoiceAction> {
  return nullthrows(
    useContext(DispatchContext),
    "Brand Voice Context dispatch context is missing"
  );
}

export default BrandVoiceProvider;
