import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import { CampaignWizardCardProps } from "@components/campaign/wizard/cards/CampaignWizardCard";
import {
  CampaignType,
  ItemSetType,
  ShopifyDiscountType,
  ShopifyDiscountValueType,
} from "@openapi";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";

export const COUPON_DEFAULTS: Record<CampaignType, number | null> = {
  [CampaignType.inventory_clearance]: 15,
  [CampaignType.sales_event]: 25,
  [CampaignType.new_products]: null, // Unused, need this for typing
  [CampaignType.other]: null, // Unused, need this for typing
};

export const ITEM_SET_COUPON_DEFAULTS = {
  [ItemSetType.slower_moving_goods]: 20,
  [ItemSetType.slowest_moving_goods]: 25,
  [ItemSetType.new_products]: null,
  [ItemSetType.best_sellers]: null,
  [ItemSetType.discounted_products]: null,
  [ItemSetType.new_collection]: null,
  [ItemSetType.purchased_together]: null,
  [ItemSetType.new_hero_product]: null,
  [ItemSetType.hero_product]: null,
  [ItemSetType.trending_products]: null,
  [ItemSetType.back_in_stock]: null,
  [ItemSetType.custom]: null,
};

const useCampaignPromotionProps = () => {
  const dispatch = useCampaignWizardDispatch();
  const { wizardType, campaignData, isWizardStateInitialized } =
    useCampaignWizardState();
  const [initDiscountValue, setInitDiscountValue] = useState<number | null>(
    null
  );
  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [discountType, setDiscountType] = useState<ShopifyDiscountValueType>(
    ShopifyDiscountValueType.percentage
  );
  const [isDiscountStateReady, setIsDiscountStateReady] =
    useState<boolean>(false);
  const [showDiscountUI, setShowDiscountUI] = useState<boolean>(false);

  const { recommendationID } = useParams();
  const activeBrandID = useActiveBrandID();

  const { campaignRecommendation } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  useEffect(() => {
    if (!isWizardStateInitialized) {
      return;
    }
    setDiscountValue(campaignData.discount?.amount ?? null);
    if (campaignData.discount?.value_type) {
      setDiscountType(campaignData.discount?.value_type);
    }
    setIsDiscountStateReady(true);
  }, [isWizardStateInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDiscountStateReady) {
      return;
    }
    const couponValue =
      wizardType === "NEW"
        ? COUPON_DEFAULTS[campaignData?.campaign_type as CampaignType]
        : wizardType === "RECOMMENDED"
        ? ITEM_SET_COUPON_DEFAULTS[
            campaignRecommendation?.item_set_type ?? ItemSetType.new_products
          ] ?? 0
        : null;
    if (couponValue) {
      setInitDiscountValue(couponValue);
      setDiscountValue((prev) => prev ?? couponValue);
      setShowDiscountUI(true);
    } else {
      setInitDiscountValue(null);
      setDiscountValue((prev) => prev ?? null);
      setShowDiscountUI(false);
    }
  }, [
    campaignData?.campaign_type,
    campaignRecommendation?.item_set_type,
    wizardType,
    isDiscountStateReady,
  ]);

  useEffect(() => {
    if (!isDiscountStateReady) {
      return;
    }
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        discount:
          discountValue && showDiscountUI
            ? {
                type: ShopifyDiscountType.coupon,
                value_type: discountType,
                amount: discountValue,
              }
            : null,
      },
    });
  }, [
    discountType,
    discountValue,
    showDiscountUI,
    dispatch,
    isDiscountStateReady,
  ]);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children" | "step">>(
    () => ({
      badgeTitle: "Promotion",
      nextButtonProps: {
        disabled: showDiscountUI && !discountValue,
      },
    }),
    [showDiscountUI, discountValue]
  );
  const inputProps = useMemo(
    () => ({
      discountValue,
      setDiscountValue,
      discountType,
      setDiscountType,
      setShowDiscountUI,
      showDiscountUI,
      initDiscountValue,
    }),
    [
      discountValue,
      setDiscountValue,
      discountType,
      setDiscountType,
      showDiscountUI,
      setShowDiscountUI,
      initDiscountValue,
    ]
  );

  return { cardProps, inputProps };
};

export default useCampaignPromotionProps;
