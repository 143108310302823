import CampaignWizardLayout from "../../../components/campaign/wizard/layout/CampaignWizardLayout";
import {
  NewCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardNextStep,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import { NewCampaignCards } from "../../../views/campaign/wizard/CampaignCards";
import { CampaignType } from "@openapi";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import FullscreenSpinner from "~/components/core/FullscreenSpinner";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";

const EditCampaignWizardPage = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const { currentRequiredStep, currentStep } = useCampaignWizardState();
  const dispatch = useCampaignWizardDispatch();
  const nextStep = useCampaignWizardNextStep();

  const { data: campaignDetails, isLoading } = useGetCampaignDetails({
    campaignId,
  });

  const hasRun = useRef(false);

  useEffect(() => {
    dispatch({
      type: "SET_WIZARD_TYPE",
      payload: { wizardType: "NEW" },
    });
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    // Load campaign data when available
    if (campaignDetails && !isLoading) {
      if (!hasRun.current) {
        dispatch({
          type: "UPDATE_CAMPAIGN_DATA",
          payload: {
            campaign_id: campaignDetails.id,
            campaign_type: campaignDetails.type as CampaignType,
            date: campaignDetails.date,
            // Products are set in useCampaignProductsProps bc state is set there
            // products: campaignDetails.items?.map((item) => ({
            //   ...item,
            //   is_featured: true,
            // })),
          },
        });
        hasRun.current = true;
        nextStep();
      }
    }
  }, [campaignDetails, isLoading]);

  if (isLoading) {
    return (
      <CampaignWizardLayout>
        <FullscreenSpinner />
      </CampaignWizardLayout>
    );
  }

  return (
    <CampaignWizardLayout>
      {(Object.values(NewCampaignWizardSteps) as NewCampaignWizardSteps[])
        .filter((value) => !isNaN(value))
        .map((value) => {
          const Card = NewCampaignCards[value];
          return (
            <div
              style={{
                display:
                  currentRequiredStep >= Number(value) ? undefined : "none",
                opacity: currentRequiredStep >= Number(value) ? undefined : 0,
                pointerEvents:
                  currentStep === Number(value) ? undefined : "none",
                width: "800px",
              }}
              id={`step-${value}`}
              key={value}
            >
              <Card step={value} />
            </div>
          );
        })}
    </CampaignWizardLayout>
  );
};

export default EditCampaignWizardPage;
