import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import {
  NewCampaignWizardSteps,
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import useCampaignWizardValidation from "./useCampaignWizardValidation";
import { CampaignType, DestinationType, operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WandSparklesIcon } from "~/assets/icons";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationCreativeMessagesQuery, {
  CampaignRecommendationCreativeMessagesResponse,
} from "~/hooks/campaign/useCampaignCreativeRecommendationMessagesQuery";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useCenterCampaignCard from "~/hooks/campaign/useCenterCampaignCard";
import useCreateCampaignMutation from "~/hooks/campaign/useCreateCampaignMutation";
import nullthrows from "~/utils/nullthrows";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type GenCreactiveRecommendationsResponse =
  operations["recommender_api_gen_creative_recommendations"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationsRequestData =
  operations["recommender_api_gen_creative_recommendations"]["requestBody"]["content"]["application/json"];

type GenCreactiveRecommendationsFromCRResponse =
  operations["recommender_api_gen_creative_recommendation_from_campaign_recommendation"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationsFromCRRequestData =
  operations["recommender_api_gen_creative_recommendation_from_campaign_recommendation"]["requestBody"]["content"]["application/json"];

type GenCreactiveRecommendationUpdateResponse =
  operations["recommender_api_gen_creative_recommendation_update"]["responses"]["200"]["content"]["application/json"];
type GenCreactiveRecommendationUpdateRequestData =
  operations["recommender_api_gen_creative_recommendation_update"]["requestBody"]["content"]["application/json"];

const useCampaignCreativesProps = (step: number) => {
  const { campaignData, wizardType, currentStep } = useCampaignWizardState();
  const { centerCurrentCard } = useCenterCampaignCard({
    cardStep: step,
  });

  const validateCampaignWizard = useCampaignWizardValidation();
  const campaignType = campaignData?.campaign_type;
  const discount = campaignData?.discount;
  const destination = campaignData?.destination;
  const description =
    "description_input" in campaignData
      ? campaignData.description_input
      : undefined;

  const activeBrandID = useActiveBrandID();
  const dispatch = useCampaignWizardDispatch();
  const navigate = useNavigate();

  const [prompt, setPrompt] = useState("");
  const newCampaignCreativeDoneRef = useRef<boolean>(false);
  const recommendedCampaignCreativeDoneRef = useRef<boolean>(false);
  const activeMessageRef = useRef<HTMLDivElement | null>(null);

  const { recommendationID } = useParams();
  const { campaignRecommendation } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  const [newCampaignRootCreative, setNewCampaignRootCreative] = useState<
    GenCreactiveRecommendationsResponse[0] | undefined
  >(undefined);
  const [activeCreative, setActiveCreative] = useState<
    GenCreactiveRecommendationsResponse[0] | undefined
  >(undefined);

  const shouldListenToDestinationUpdates =
    !campaignData.destination?.collection_id &&
    !campaignData.destination?.url &&
    (campaignData.products?.length ?? 0) > 1;

  const queryClient = useQueryClient();
  const { campaignRecommendationCreativeMessages } =
    useCampaignRecommendationCreativeMessagesQuery(
      activeBrandID,
      campaignRecommendation?.creative_recommendations?.[0]?.id ??
        newCampaignRootCreative?.id ??
        null
    );

  const rootCreativeID =
    wizardType === "RECOMMENDED"
      ? campaignRecommendation?.creative_recommendations?.[0]?.id ?? null
      : wizardType === "NEW"
      ? newCampaignRootCreative?.id ?? null
      : null;

  const { createCampaign, isLoading: isCreatingCampaign } =
    useCreateCampaignMutation({
      onSuccess: (data) => {
        navigate(`/campaigns/wizard/${data.id}/generate`);
      },
      onError: (error) => {
        console.error(error);
      },
    });

  const onActiveCreativeChange = useCallback(
    (activeCreative: GenCreactiveRecommendationsResponse[0]) => {
      console.log("Updating active creative...");
      let newPayload = {
        creative_id: nullthrows(activeCreative.id),
        discount,
        destination,
      };

      if (activeCreative.discount_code && discount) {
        newPayload.discount = {
          ...discount,
          code: activeCreative.discount_code,
        };
      }

      if (activeCreative.collection_title && shouldListenToDestinationUpdates) {
        newPayload.destination = {
          ...destination,
          name: activeCreative.collection_title,
        };
      }

      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: {
          ...newPayload,
        },
      });
      setActiveCreative(activeCreative);
    },
    [discount, destination, shouldListenToDestinationUpdates, dispatch]
  );

  const {
    mutate: genCreactiveRecommendations,
    isPending: isGeneratingCreativeRecommendations,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationsResponse,
    GenCreactiveRecommendationsRequestData
  >({
    mutationKey: ["/recommender/creative-recommendations"],
    path: "/recommender/creative-recommendations",
    onError: (error) => {
      newCampaignCreativeDoneRef.current = false;
    },
    onSuccess: (data) => {
      setNewCampaignRootCreative(data[0]);
      onActiveCreativeChange(data[0]);
    },
    onMutate: async (newMessage) => {
      newCampaignCreativeDoneRef.current = true;

      await queryClient.cancelQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
      });

      // Just the first message, so add an empty value
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
        [
          {
            recommendation: null,
            message: null,
          },
        ]
      );
    },
  });

  // Only called for initial Creative
  const {
    mutate: genCreactiveRecommendationsFromCR,
    isPending: isCreativeReommendationsFromCRPending,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationsFromCRResponse,
    GenCreactiveRecommendationsFromCRRequestData
  >({
    mutationKey: [
      "/recommender/creative-recommendations-from-campaign-recommendation",
    ],
    path: "/recommender/creative-recommendations-from-campaign-recommendation",
    onError: (error) => {
      recommendedCampaignCreativeDoneRef.current = false;
    },
    onSuccess: (data) => {
      setNewCampaignRootCreative(data[0]);
      onActiveCreativeChange(data[0]);
    },
    onMutate: async (newMessage) => {
      recommendedCampaignCreativeDoneRef.current = true;

      await queryClient.cancelQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
      });

      // Just the first message, so add an empty value
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
        [
          {
            recommendation: null,
            message: null,
          },
        ]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "recommender-campaign-recommendation",
          activeBrandID,
          recommendationID,
        ],
      });
    },
  });

  const {
    mutate: genCreactiveRecommendationUpdate,
    isPending: isCreativeUpdating,
    isSuccess: isCreativeUpdateSuccess,
  } = useDraperApiPostMutation<
    GenCreactiveRecommendationUpdateResponse,
    GenCreactiveRecommendationUpdateRequestData
  >({
    mutationKey: ["/recommender/creative-recommendations/update"],
    path: "/recommender/creative-recommendations/update",
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
        context.previousCreative
      );
    },
    onSuccess: (data) => {
      if (data) {
        setActiveCreative(data);
      }
    },
    onMutate: async (newMessage) => {
      await queryClient.cancelQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
      });

      const previousCreative: CampaignRecommendationCreativeMessagesResponse =
        queryClient.getQueryData([
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ]) ?? [];

      const lastMessage =
        previousCreative.length > 0
          ? previousCreative[previousCreative.length - 1]
          : null;

      let newData = null;
      if (lastMessage) {
        newData = [
          ...previousCreative.slice(0, -1),
          {
            message: newMessage.prompt,
            recommendation: lastMessage.recommendation,
          },
          {
            message: null,
            recommendation: null,
          },
        ];
      } else {
        newData = [
          {
            message: null,
            recommendation: null,
          },
        ];
      }

      // Optimistically update to the new message
      queryClient.setQueryData(
        [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
        newData
      );

      return { previousCreative };
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "recommender/campaign-recommendation/creative-messages",
          activeBrandID,
          rootCreativeID,
        ],
      });
    },
  });

  const genCreatives = useCallback(() => {
    if (!campaignType || !description) {
      console.error("Missing campaign type or description");
      return;
    }
    newCampaignCreativeDoneRef.current = true;
    return genCreactiveRecommendations({
      brand_id: activeBrandID,
      campaign_type: campaignType,
      description: description,
      commerce_platform_item_ids: (campaignData?.products ?? []).map(
        (product) => product.product_id
      ),
      collection_id:
        campaignData?.destination?.type === DestinationType.collection
          ? campaignData?.destination?.collection_id
          : undefined,
      discount_data: {
        value_type: campaignData?.discount?.value_type,
        amount: campaignData?.discount?.amount,
      },
      should_generate_collection_title: shouldListenToDestinationUpdates,
    });
  }, [
    activeBrandID,
    campaignType,
    description,
    campaignData,
    genCreactiveRecommendations,
    newCampaignCreativeDoneRef,
  ]);

  useEffect(() => {
    if (
      wizardType === "NEW" &&
      !newCampaignRootCreative &&
      !newCampaignCreativeDoneRef.current &&
      currentStep === NewCampaignWizardSteps.CREATIVE_CONCEPT
    ) {
      genCreatives();
    }
  }, [
    wizardType,
    newCampaignRootCreative,
    genCreatives,
    currentStep,
    newCampaignCreativeDoneRef,
  ]);

  // invalidate creatives if relevant data changes
  useEffect(() => {
    recommendedCampaignCreativeDoneRef.current = false;
    newCampaignCreativeDoneRef.current = false;
    setNewCampaignRootCreative(undefined);
  }, [JSON.stringify(campaignData.products)]);

  useEffect(() => {
    // TODO: init state at the very beginning of the wizard, causes this to trigger multiple times. Also causes this to trigger with incomplete data
    // TODO: this is still called twice
    // TODO: needs to change with discount change too
    if (
      wizardType === "RECOMMENDED" &&
      !recommendedCampaignCreativeDoneRef.current &&
      currentStep === RecommendedCampaignWizardSteps.CREATIVE_CONCEPT
    ) {
      console.log("Generating CC");
      genCreactiveRecommendationsFromCR({
        campaign_recommendation_id: nullthrows(recommendationID),
        brand_id: activeBrandID,
        commerce_platform_item_ids: (campaignData?.products ?? []).map(
          (product) => product.product_id
        ),
        discount_data: {
          value_type: campaignData?.discount?.value_type,
          amount: campaignData?.discount?.amount,
        },
        should_generate_collection_title: shouldListenToDestinationUpdates,
      });
    }
  }, [
    wizardType,
    currentStep,
    recommendationID,
    activeBrandID,
    campaignData.products,
    campaignData.discount,
    shouldListenToDestinationUpdates,
    genCreactiveRecommendationsFromCR,
    recommendedCampaignCreativeDoneRef.current,
  ]);

  useEffect(() => {
    isCreativeUpdateSuccess && centerCurrentCard();
  }, [isCreativeUpdateSuccess, centerCurrentCard]);

  useEffect(() => {
    if (!campaignRecommendationCreativeMessages) {
      return;
    }
    centerCurrentCard();
  }, [campaignRecommendationCreativeMessages, centerCurrentCard]);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children" | "step">>(
    () => ({
      badgeTitle: "Creative Concept",
      nextButtonProps: {
        icon: <WandSparklesIcon />,
        text: "Generate Campaign Graphics",
        onClick: () => {
          if (!validateCampaignWizard()) {
            return;
          }

          const products = campaignData.products.map((product) => ({
            id: product.product_id,
            commerce_platform_id: product.product_id,
            is_featured: product.is_featured,
            url: product.url,
          }));

          if (wizardType === "NEW") {
            createCampaign({
              asset_ids: campaignData.asset_ids ?? [],
              audiences: campaignData.audiences ?? [],
              excluded_audiences: campaignData.excluded_audiences ?? [],
              campaign_id: campaignData.campaign_id ?? undefined,
              channels: campaignData.channels ?? {
                emails: 0,
                sms: 0,
                meta_ads: 0,
                push_notifications: 0,
              },
              products,
              destination: campaignData.destination ?? {},
              creative_id: nullthrows(activeCreative?.id),
              brand_id: activeBrandID,
              campaign_type: campaignData.campaign_type ?? CampaignType.other,
              description_input: nullthrows(description),
              discount: campaignData.discount ?? null,
              date: campaignData.date,
            });
          } else if (wizardType === "RECOMMENDED") {
            createCampaign({
              asset_ids: campaignData.asset_ids ?? [],
              audiences: campaignData.audiences ?? [],
              excluded_audiences: campaignData.excluded_audiences ?? [],
              channels: campaignData.channels ?? {
                emails: 0,
                sms: 0,
                meta_ads: 0,
                push_notifications: 0,
              },
              products,
              destination: campaignData.destination ?? {},
              brand_id: activeBrandID,
              creative_id: nullthrows(activeCreative?.id),
              campaign_type: campaignData.campaign_type ?? CampaignType.other,
              campaign_recommendation_id: nullthrows(recommendationID),
              discount: campaignData.discount ?? null,
              date: campaignData.date,
            });
          }
        },
        disabled:
          !activeCreative ||
          isGeneratingCreativeRecommendations ||
          isCreativeReommendationsFromCRPending ||
          isCreatingCampaign,
      },
    }),
    [
      activeCreative,
      wizardType,
      isGeneratingCreativeRecommendations,
      isCreativeReommendationsFromCRPending,
      isCreatingCampaign,
      createCampaign,
      campaignData,
      activeBrandID,
      description,
      recommendationID,
      validateCampaignWizard,
    ]
  );

  return {
    cardProps,
    isCreativeUpdating,
    onSendPrompt: () => {
      genCreactiveRecommendationUpdate({
        brand_id: activeBrandID,
        creative_recommendation_id: nullthrows(rootCreativeID),
        should_generate_collection_title: shouldListenToDestinationUpdates,
        prompt: prompt,
        discount_data: {
          value_type: campaignData?.discount?.value_type,
          amount: campaignData?.discount?.amount,
        },
      });
      setPrompt("");
    },
    prompt,
    setPrompt,
    campaignRecommendationCreativeMessages,
    activeMessageRef,
    activeCreative,

    onActiveCreativeChange,
  };
};

export default useCampaignCreativesProps;
