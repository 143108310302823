import { useDraperApiDeleteMutation } from "../../utils/useDraperMutation";
import { operations } from "@openapi";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { getCampaignRecommendationsQueryKey } from "~/components/home/hooks/recommendations/useGetCampaignRecommendations";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type DeleteCampaignRecommendationResponse =
  operations["recommender_api_delete_campaign_recommendation"]["responses"][200]["content"]["application/json"];

type DeleteCampaignRecommendationParams =
  operations["recommender_api_delete_campaign_recommendation"]["requestBody"]["content"]["application/json"];

const useRecommenderCampaignRecommendationDeleteMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: DeleteCampaignRecommendationResponse) => void;
}) => {
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  return useDraperApiDeleteMutation<
    DeleteCampaignRecommendationResponse,
    DeleteCampaignRecommendationParams
  >({
    path: "/recommender/campaign-recommendation",
    onSuccess: (data) => {
      onSuccess?.(data);

      toast.success("Campaign idea deleted");
    },
    onError: () => {
      toast.error("Failed to delete campaign idea");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getCampaignRecommendationsQueryKey(activeBrandID),
        type: "all",
      });
    },
  });
};

export default useRecommenderCampaignRecommendationDeleteMutation;
