import { brandColorPalettesQueryKey } from "../brand/useBrandColorPalettesQuery";
import { getImageAssetsQueryKey } from "../media/useImageAssetsQuery";
import useEmailRegenerateMutation, {
  GenerateEmailVariantResponse,
} from "./useEmailRegenerateMutation";
import useGenerateEmailVariantStatusQuery, {
  GenerateEmailVariantStatusResponse,
} from "./useGenerateEmailVariantStatusQuery";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { AIGeneratedImagesQueryObject } from "~/components/campaign/wizard/dialogs/asset-selector-dialog/sections/AIGeneratedImages";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { key as emailCreativePreviewQueryKey } from "~/hooks/emails/useEmailCreativePreviewQuery";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

function useGenerateEmailVariantWithStatus({
  onScheduleSuccess,
  onScheduleError,
  onGenerationSuccess,
  onGenerationError,
  onComplete,
}: {
  onScheduleSuccess?: (data: GenerateEmailVariantResponse) => void;
  onScheduleError?: (error: string) => void;
  onGenerationSuccess?: (
    variant: NonNullable<GenerateEmailVariantStatusResponse["variant"]>
  ) => void;
  onGenerationError?: (error: string) => void;
  onComplete?: () => void;
}) {
  const activeBrandID = useActiveBrandID();
  const queryClient = useQueryClient();
  const { campaign_id, activeEmailId } = useEmailState();

  const [generationSessionID, setGenerationSessionID] = useState<string | null>(
    null
  );

  const schedule = useEmailRegenerateMutation({
    onSuccess: (data) => {
      if (data.job_session_id) {
        onScheduleSuccess?.(data);
        setGenerationSessionID(data.job_session_id);
      } else {
        onScheduleError?.("An error occurred while generating new variant.");
        setGenerationSessionID(null);
      }
    },
    onError: (error) => {
      setGenerationSessionID(null);
      if (isAxiosError(error) && error.response?.status === 403) {
        onScheduleError?.(
          "You do not have permission to generate new variants for this email."
        );
      } else {
        console.log(error);
        onScheduleError?.("An error occurred while generating new variant.");
      }
    },
  });

  const generateStatusQuery = useGenerateEmailVariantStatusQuery({
    brandId: activeBrandID,
    jobSessionId: generationSessionID,
  });

  useEffect(() => {
    if (!generateStatusQuery) {
      return;
    }
    if (generateStatusQuery.error) {
      onGenerationError?.(generateStatusQuery.error);
    } else if (generationSessionID !== null && !!generateStatusQuery.variant) {
      onGenerationSuccess?.(generateStatusQuery.variant);

      // Invalidate campaign color palette query since new campaign color palettes may have been created
      queryClient.invalidateQueries({
        queryKey: brandColorPalettesQueryKey(activeBrandID, campaign_id),
        refetchType: "all",
      });
      // Invalidate Image Assets query since new image assets may have been created
      queryClient.invalidateQueries({
        queryKey: getImageAssetsQueryKey(
          AIGeneratedImagesQueryObject(activeBrandID, campaign_id)
        ),
        refetchType: "all",
      });

      // Invalidate email creative preview query since new email creative has been generated
      if (activeEmailId) {
        queryClient.invalidateQueries({
          queryKey: emailCreativePreviewQueryKey(activeEmailId),
          refetchType: "all",
        });
      }
    }
    if (generateStatusQuery.is_finished) {
      setGenerationSessionID(null);
      onComplete?.();
    }
  }, [generateStatusQuery, generationSessionID]);

  return {
    regenerateEmail: schedule.regenerateEmail,
    isGenerating: schedule.isLoading || !!generationSessionID,
    generationSessionID,
    generateStatusQuery,
  };
}

export default useGenerateEmailVariantWithStatus;
