import { operations, components } from "@openapi";
import { QueryClient, useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

export type CampaignHomeResponse =
  operations["campaign_api_get_home_campaigns"]["responses"][200]["content"]["application/json"];

export const getCampaignHomeQueryKey = (
  brandID: string,
  onlyCount: boolean,
  status?: components["schemas"]["CampaignUnifiedStatus"]
) => {
  // This ordering matters, as it's used by the query client to invalidate old queries
  // Make sure optimistic updates still work (delete, update, create) when changing this
  const queryKey =
    status !== undefined
      ? ["campaign-home", brandID, onlyCount, status]
      : ["campaign-home", brandID, onlyCount];

  return queryKey;
};

export const invalidateCampaignHomeQueryCount = (
  brandID: string,
  queryClient: QueryClient,
  status?: components["schemas"]["CampaignUnifiedStatus"]
) => {
  queryClient.invalidateQueries({
    queryKey: getCampaignHomeQueryKey(brandID, true, status),
  });
};

const useCampaignHomeQuery = ({
  page = 0,
  enabled = true,
  status,
  onlyCount = false,
}: {
  page?: number;
  enabled?: boolean;
  status?: components["schemas"]["CampaignUnifiedStatus"];
  onlyCount?: boolean;
}) => {
  const activeBrandID = useActiveBrandID();
  return useInfiniteQuery({
    queryKey: getCampaignHomeQueryKey(activeBrandID, onlyCount, status),
    queryFn: async ({ pageParam = 0 }): Promise<CampaignHomeResponse> => {
      const { data } = await axios.get(`/api/v1/campaign/home`, {
        params: {
          brand_id: activeBrandID,
          start_date: dayjs().startOf("day").format("YYYY-MM-DD"), // Today's date in the user's timezone
          after: pageParam,
          status,
          only_count: onlyCount,
        },
      });
      return data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      lastPage.has_more ? lastPageParam + 1 : undefined,
    enabled,
  });
};

export default useCampaignHomeQuery;
